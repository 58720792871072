
import React from "react";
import Modal from 'react-bootstrap/Modal';
import Card from 'components/Card/Card';

import useStyles from './CardModal.styles';

export default function CardModal({ children, isOpen, onClose }) {
    const cls = useStyles();
    return (
        <Modal className={cls.modal} backdropClassName={cls.modalBackdrop} centered show={isOpen} onHide={onClose}>
            <Card className={cls.modalCard}>
                {children}
            </Card>
        </Modal >

    );
}
