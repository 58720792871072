import React, { useEffect } from "react";
import Typography from "components/Typography/Typography";
import Icon from "components/Icon/Icon";
import screenWithEmail from 'assets/img/screenWithEmail.png';
import useStyles from './WizardMobilePlaceholder.styles';
import { setupBlockedOnMobile } from "utils/api/mobileSignup";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useWizardData } from "contexts/WizardData";


const WizardMobilePlaceholder = () => {
    const cls = useStyles();
    const { user } = useAuthenticator((context) => [context.user]);
    const [wizardData] = useWizardData();

    useEffect(() => {
        const lastMobileBlockedTime = localStorage.getItem('lastMobileBlockedTime')
        if (!lastMobileBlockedTime || ((Date.now() - parseInt(lastMobileBlockedTime)) > 1000 * 60 * 3)) {
            const email = user.attributes.email
            setupBlockedOnMobile(email, wizardData.envDomain).then(() => {
                localStorage.setItem('lastMobileBlockedTime', Date.now())
            })
        }
    }, [])

    return (
        <div className={cls.container}>
            <Icon name="logoWithNameVertical" className={cls.headerLogoVertical} />

            <img className={cls.avatar} src={screenWithEmail} alt="Email sent" />
            <Typography className={cls.title} variant="h2" color="textPrimary">Check your email to complete setup</Typography>
            <Typography className={cls.desc} variant="body2" color="textSecondary">For the best experience, we recommend completing setup on a larger device. We’ve emailed you a magic link so that you can pick up where you left off when you’re ready.  </Typography>

            {/*   <Button variant="outline" color="textSecondary" target="_blank" rel="noreferrer noopener" component="a" href="https://docs.sedai.io/get-started/">View Quickstart Guide <FiExternalLink /></Button> */}
        </div>
    )
};

export default WizardMobilePlaceholder;
