import React from "react";
import cn from "classnames";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import Button from 'components/Button/Button';

import segmentioTracking from "utils/segmentioTracking";
import useStyles from './DefaultStepFooterContent.styles';
import { Spinner } from "react-bootstrap";

export default function DefaultStepFooterContent({
  nextBtnRef,
  moveToNextStep,
  moveNextBtnText = "Next",
  moveNextIsLoading,
  disableNextBtn,
  hideNextBtn,
  renderMoveNextBtn,
  className,
}) {
  const cls = useStyles();
  const nextBtnClick = (e) => {
    segmentioTracking.wizardNextClick({ current_step_url: window.location.href, btn_text: moveNextBtnText })
    if (typeof moveToNextStep === "function") moveToNextStep(e)
  }
  return (
    <div className={cn(cls.container, className)}>
      {!hideNextBtn && (
        typeof renderMoveNextBtn === 'function' ? renderMoveNextBtn() : (
          <Button ref={nextBtnRef} className={cls.formBtn} size="medium" type="submit" variant="filled" color="primary" disabled={disableNextBtn || moveNextIsLoading} onPress={nextBtnClick}>{moveNextBtnText} {moveNextIsLoading ? <Spinner animation="border" role="status" size="sm" /> : <FiArrowRight />}</Button>
        )
      )}
    </div>
  );
}

