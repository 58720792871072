import COLORS from 'themes/colors';
import { createStylesWithTheme } from 'utils/functions';

const styles = (theme) => ({
    container: {
        background: theme.palette.background.card,
        borderRadius: 4,
        border: `1px solid ${theme.type === "dark" ? COLORS.DARK_NEUTRALS[400] : COLORS.LIGHT_NEUTRALS[300]}`
        //boxShadow: "0px 1px 3px #00000029",
    },
    padding: {
        padding: "11px 16px",
    },
});

export default createStylesWithTheme(styles);