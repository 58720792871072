import { useTheme } from "react-jss";
import { FiX } from "react-icons/fi";
import { useToasts } from 'react-toast-notifications';
import Typography from "components/Typography/Typography";
import Button from "components/Button/Button";
import CardModal from "components/CardModal/CardModal";
import { Spinner } from "react-bootstrap";
import IconButton from "components/IconButton/IconButton";
import MultiChipInput from "views/common/MultiChipInput/MultiChipInput";
import { useMemo, useState } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import useStyles from './InviteTeamMembersModal.styles';
import { useMutation } from "react-query";
import { inviteMembers } from "utils/api/invite";
import segmentioTracking from "utils/segmentioTracking";

function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
}

/**
 * 
 * @typedef {Object} InviteTeamMembersModalProps
 * @param {boolean} isOpen
 * @param {Function} onClose
 * @param {string} envDomain
 * @param {Function} onSubmit
 * @param {Promise} invitePromise // send extra logic inside invitePromise to keep the button loading 
 */

const InviteTeamMembersModal = ({ wizardData, isOpen, onClose, email, envDomain, onSubmit, invitePromise }) => {
    const cls = useStyles();
    const [emails, setEmails] = useState([]);
    const { addToast } = useToasts();
    const { user } = useAuthenticator((context) => [context.user]);
    const { status, mutate: inviteMembersMutation } = useMutation((emails) => inviteMembers(email, envDomain, emails, user.pool.userPoolId).then(() => invitePromise ? invitePromise(emails) : Promise.resolve()), {
        onSuccess: (data) => {
            onClose();
            if (typeof onSubmit === "function") onSubmit(emails)
            setEmails([]);
        },
        onError: (error) => {
            addToast("Failed to invite members", {
                appearance: 'error',
                autoDismiss: true,
            })
        }
    })
    const existingUserEmails = wizardData?.userEmails || [];
    const readdedEmails = useMemo(() => emails.filter(email => existingUserEmails.includes(email)), [emails, existingUserEmails]);
    return (
        <CardModal
            isOpen={isOpen}
            onClose={onClose}>
            <div className={cls.cardHeader}>
                <Typography fontWeight={600} className={cls.title} variant="body1" color="textPrimary">Ask for help to complete setup</Typography>
                <IconButton variant="text" color="textSecondary" onPress={() => onClose()}><FiX /></IconButton>
            </div>
            <form onSubmit={(e) => {
                e.preventDefault();
                segmentioTracking.sendInvitation({
                    current_step_url: window.location.href,
                    emails: emails,
                })
                inviteMembersMutation(emails);
            }}>
                <div className={cls.pickersContainer}>
                    <Typography variant="body3" color="textTernary">Invite your teammate to create their own Sedai account. We will send instructions on how to grant IAM access so that they can log in and help complete setup.</Typography>
                    <MultiChipInput color="textSecondary" value={emails} onChange={setEmails} validateValue={validateEmail} />
                    {readdedEmails.length > 0 && status !== "loading" && <Typography variant="body3" color="error">Invitation already sent to {readdedEmails.join(", ")}</Typography>}
                </div>
                <div className={cls.cardFooterBtns}>
                    <Button
                        size="medium"
                        variant="outline"
                        color="textSecondary"
                        onClick={(e) => { onClose(false) }} >Cancel</Button>
                    <Button
                        size="medium"
                        type="submit"
                        variant="filled"
                        color="primary"
                        disabled={emails.length === 0 || readdedEmails.length > 0}>Send Invite {status === "loading" && <Spinner animation="border" role="status" size="sm" />}</Button>
                </div>
            </form>
        </CardModal>
    )
};

export default InviteTeamMembersModal;
