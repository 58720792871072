import COLORS from 'themes/colors';
import { createStylesWithTheme } from 'utils/functions';

const styles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 583,
    width: "100%",
    alignSelf: "center",
  },
  header: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "&>*:not(:last-child)": {
      marginBottom: 8,
    },
    marginBottom: 24,
  },
  cloudEnvironmentTypes: {
    marginTop: 16,
    display: "flex",
    flexDirection: "column",
    gridGap: 16,
    marginBottom: 24,
  },
  cloudEnvCard: {
    padding: [16, "!important"],
    display: "flex",
    alignItems: "center",
    border: `1px solid transparent`,
    "&:hover": {
      borderColor: theme.palette.primary.main,
    },
    "& svg": {
      marginRight: 12,
      height: 32,
    }
  },
  activeCloudEnvCard: {
    position: "relative",
    backgroundColor: [COLORS.PRIMARY[1300], "!important"],
    borderColor: theme.palette.primary.main,
  },
  multiIconConainer: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(32px, max-content))",
    justifyContent: "center",
    gridGap: 8,
  },
  checkMark: {
    display: "flex",
    position: "absolute",
    right: 8,
    color: theme.palette.primary.main,
  }
});

export default createStylesWithTheme(styles);