import React, { useMemo, useState } from "react";
import { FiCheck, FiPlus } from "react-icons/fi";
import cn from "classnames";
import { keyBy } from "lodash";
import Typography from "components/Typography/Typography";
import Card from "components/Card/Card";
import Button from "components/Button/Button";
import Icon from "components/Icon/Icon";
import WizardLayout from "views/AddCloudWizard/WizardLayout/WizardLayout";
import DefaultStepFooterContent from "views/common/WizardFooter/DefaultStepFooterContent/DefaultStepFooterContent";
import { getAccountMonitoringProvidersConfig } from "utils/monitoringProviders";

import { getCloudProviderConfig } from "utils/cloudProviders";
import segmentioTracking from "utils/segmentioTracking";
import UpsertMonitoringProvider from "../UpsertMonitoringProvider/UpsertMonitoringProvider";
import useStyles from './MonitoringProviders.styles';
import { useWizardData, wizardDataActionsTypes } from "contexts/WizardData";
import { useToasts } from "react-toast-notifications";
import Bugsnag from "@bugsnag/js";



const MonitoringProviders = (props) => {
    const { wizardProps, isCurrentStepValid, moveToNextStep,envDomain, email, currentStep } = props;
    const cls = useStyles();

    const [isLoading, setIsLoading] = useState(false);
    const [wizardData, wizardDataDispatch] = useWizardData();
    const { addToast } = useToasts();

    const currentMpType = wizardData?.resourceDetails?.monitoringProviders?.[0]
    const accMonitoringProviders = useMemo(() => {
        const accountDetails = wizardData?.uiStateDetails?.data?.sedaiAccount?.accountDetails;
        const monitoringConfigs = getAccountMonitoringProvidersConfig({
            cloudProviderTypeConfig: getCloudProviderConfig(accountDetails?.cloudProvider, accountDetails?.metadata?.clusterProvider),
            cloudProviderIntegrationType: accountDetails?.integrationType
        });
        return Object.values(monitoringConfigs);
    }, [wizardData?.uiStateDetails?.data?.sedaiAccount?.accountDetails]);

    return (
        <WizardLayout {...wizardProps}>
            <div className={cls.container}>
                <div className={cls.header}>
                    <Typography variant="h2" color="textPrimary">Which monitoring platform do you primarily use?</Typography>
                </div>

                <div className={cls.monitoringCards}>
                    {accMonitoringProviders.map((mp) => {
                        return (
                            <Button key={mp.key} variant="content" onPress={() => {
                                segmentioTracking.monitoringProviderClick({ monitoring_provider_type: mp.key });
                                wizardDataDispatch({ type: wizardDataActionsTypes.SET_MONITORING_PROVIDER_TYPE, payload: mp.key })
                            }}>
                                <Card className={cn(cls.monitoringCard, mp.key === currentMpType && cls.activeMonitoringCard)}>
                                    <Icon name={mp.iconName} />
                                    <Typography variant="body2" color="textPrimary">{mp.label}</Typography>
                                    {mp.key === currentMpType && <FiCheck className={cls.checkMark} />}
                                </Card>
                            </Button>
                        );
                    })}
                </div>
                <DefaultStepFooterContent
                    className={cls.actionButtonsSection}
                    moveNextIsLoading={isLoading}
                    moveToNextStep={() => {
                        setIsLoading(true);
                        currentStep.submit(wizardData, { envDomain, email }).then(() => {
                            setIsLoading(false);
                            moveToNextStep();
                        }).catch((e) => {
                            setIsLoading(false);
                            Bugsnag.notify(e);
                            addToast("Failed to submit monitoring provider", {
                                appearance: 'error',
                                autoDismiss: true,
                            });
                        })

                    }}
                    disableNextBtn={!isCurrentStepValid}
                   
                />
            </div>
        </WizardLayout>
    )
};

export default MonitoringProviders;