import React from "react";
import Typography from '../Typography/Typography';
import useStyles from './PageContentError.styles';
import cn from 'classnames';

export default function PageContentError({ msg, className }) {
    const cls = useStyles();
    return (
        <div className={cn(cls.container, className)}>
            <Typography variant="h6" color="textPrimary">{msg || "ERROR"}</Typography>
        </div>
    );
}
