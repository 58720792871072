import { useEffect, useMemo, useState } from "react";
import { findIndex } from "lodash";
import { matchPath, Outlet, useLocation, useNavigate } from "react-router";
import Bugsnag from '@bugsnag/js';

import WizardMobilePlaceholder from "./WizardMobilePlaceholder/WizardMobilePlaceholder";
import { useWizardData, wizardDataActionsTypes } from "contexts/WizardData";
import { getCurrentStateSteps } from "./AddCloudWizard.utils";
import useStyles from './AddCloudWizard.styles';
import { useAuthenticator } from "@aws-amplify/ui-react";
import useWindowTargetWidth from "hooks/useWindowTargetWidth";
import WizardShrinkedScreenPlaceholder from "./WizardShrinkedScreenPlaceholder/WizardShrinkedScreenPlaceholder";
import { MIN_SCREEN_WIDTH } from "utils/constants";



const AddCloudWizard = ({ envDomain, onComplete }) => {
    const cls = useStyles();
    const [wizardData, wizardDataDispatch, getWizardData] = useWizardData()
    const { user } = useAuthenticator((context) => [context.user]);
    const navigate = useNavigate();
    const location = useLocation();
    const { isSmallScreen, screenCanReachTargetWidth } = useWindowTargetWidth(MIN_SCREEN_WIDTH);

    useEffect(() => {
        // if user is strictly sigining for a forced env type, we will set the flow type to that type
        if (wizardData.forcedFlowType && wizardData?.resourceDetails?.resourceTypes?.[0] !== wizardData.forcedFlowType) {
            wizardDataDispatch({ type: wizardDataActionsTypes.SET_FLOW_TYPE, payload: wizardData.forcedFlowType });
        }// if the user came through our main website selecting a specific env type, we will set the flow type to that type
        else if (!wizardData.forcedFlowType && wizardData.initialFlowType && wizardData?.resourceDetails?.resourceTypes?.[0] !== wizardData.initialFlowType && !wizardData?.uiStateDetails?.uiData?.ignoreInitialFlowType) {
            wizardDataDispatch({ type: wizardDataActionsTypes.SET_FLOW_TYPE, payload: wizardData.initialFlowType });
        }
    }, [wizardData.forcedFlowType, wizardData.initialFlowType]);



    const [inProgressAccountData, setInProgressAccountData] = useState({});
    const [inProgressAccountUIData, setInProgressAccountUIData] = useState({}); // holds UI specific data that wont be submitted through the api but used to controls the UI
    const [steps, setSteps] = useState(getCurrentStateSteps(getWizardData()));

    const currentStepIndex = useMemo(() => findIndex(steps, ({ routePath }) => matchPath({ path: routePath }, location.pathname)), [steps, location])
    const currentStep = steps[currentStepIndex];

    const [initialLoad, setInitialLoad] = useState(false);

    useEffect(() => {
        const newSteps = getCurrentStateSteps(wizardData);
        setSteps(newSteps);
    }, [wizardData]);

    useEffect(() => {
        if (!initialLoad) setInitialLoad(true);
    }, [])

    useEffect(() => {
        if (initialLoad && !currentStep) {
            const freshSteps = getCurrentStateSteps(getWizardData());
            const firstInvalidStep = freshSteps.find(({ dataIsValid }) => typeof dataIsValid !== "function" || !dataIsValid(wizardData)) || freshSteps[freshSteps.length - 1];
            //on initial load navigate to the first invalid step  to continue the flow from there
            if (firstInvalidStep?.routePath)
                navigate(firstInvalidStep.routePath)

        }
    }, [initialLoad])

    useEffect(() => {
        if (!initialLoad) return;
        // if the user remains on current step that is empty for more than 2 seconds, we will navigate him to the first step
        // this may happen if something is wrong with steps array or if there is a logical error with navigation
        if (!currentStep) {
            const t = setTimeout(() => {
                navigate(steps[0].routePath)
                Bugsnag.notify(new Error("currentStep is undefined"), (event) => {
                    event.addMetadata("AddCloudWizard state", {
                        steps,
                        currentStepIndex,
                        currentStep,
                        initialLoad,
                        location,
                    });
                });
            }, 2000);
            return () => clearTimeout(t);
        }
    }, [currentStep, initialLoad])






    const getStepValidity = (step) => typeof step.dataIsValid === "function" && !!step.dataIsValid(wizardData);

    const moveToNextStep = () => {
        const newSteps = getCurrentStateSteps(getWizardData());
        setSteps(newSteps);
        const currIndex = findIndex(newSteps, ({ routePath }) => matchPath({ path: routePath }, location.pathname))
        navigate(newSteps[Math.min(newSteps.length - 1, currIndex + 1)].routePath)
    };
    const moveToPrevStep = () => navigate(steps[Math.max(0, currentStepIndex - 1)].routePath);

    const moveToStep = (stepKey) => {
        const newSteps = getCurrentStateSteps(getWizardData());
        setSteps(newSteps);
        const currIndex = findIndex(newSteps, ({ key }) => key === stepKey)
        if (currIndex !== -1)
            navigate(newSteps[currIndex].routePath)
    };



    if (!currentStep || !initialLoad) return null;

    if (isSmallScreen && typeof currentStep?.getIsBlockedOnMobile === "function" && currentStep?.getIsBlockedOnMobile()) return screenCanReachTargetWidth ? <WizardShrinkedScreenPlaceholder /> : <WizardMobilePlaceholder />;

    return (
        <div className={cls.container}>
            <Outlet
                context={{
                    // this props will be passed internally to WizardLayout component
                    wizardProps: {
                        steps, currentStepKey: currentStep.key,
                        currentStep, inProgressAccountUIData,
                        inProgressAccountData,
                        navigateToStepByKey: (stepKey) => navigate(steps.find(({ key }) => key === stepKey).routePath),
                        onStepClick: (groupKey) => navigate(steps.find(({ group }) => group === groupKey).routePath), getStepValidity, stepTitle: currentStep.title
                    },
                    currentStep,
                    isCurrentStepValid: getStepValidity(currentStep),
                    inProgressAccountUIData,
                    setInProgressAccountUIData,
                    inProgressAccountData,
                    setInProgressAccountData,
                    wizardSteps: steps,
                    updateWizardSteps: setSteps,
                    moveToNextStep,
                    moveToPrevStep,
                    moveToStep,
                    envDomain,
                    email: user.attributes.email,
                    onSetupComplete: onComplete,
                }} />

        </div>
    )
};

export default AddCloudWizard;