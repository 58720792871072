
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useSetupData } from "contexts/setupData";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import segmentioTracking from "utils/segmentioTracking";

export default function useSegmentTracker() {
  const [isReady, setIsReady] = useState(false);
  const location = useLocation();
  const { user } = useAuthenticator((context) => [context.user]);
  const [setupDataState] = useSetupData();
  const { given_name, email } = user?.attributes || {};
  
  useEffect(() => {
    window.analytics.ready(() => setIsReady(true))
  }, []);

  useEffect(() => {
    if (isReady)
      segmentioTracking.page(location.pathname, { page_title: document.title, search_params: location.search });
  }, [location, isReady]);

  useEffect(() => {
    if (isReady) {
      // clear analytics identity if the app userId is different than the analytics userId
      // reset won't happen in case the was no userId in analytics (in that case we wont need to reset to keep the anonymousId created by the lib)
      // reset wont' happen in case the user logined with the same userId for the analytics
      const currentUserId = window.analytics.user().id();
      if (currentUserId && currentUserId !== setupDataState.userTrackingId) {
        window.analytics.reset();

      }
      if (setupDataState.userTrackingId) {
        segmentioTracking.identify(setupDataState.userTrackingId, { email, name: given_name, tenant: setupDataState.envDomain });
      } else if (email && given_name) {
        segmentioTracking.anonymousIdentify({ email, name: given_name, tenant: setupDataState.envDomain });
      }
    }


  }, [setupDataState.userTrackingId, setupDataState.envDomain, email, given_name, isReady]);
};