import COLORS from 'themes/colors';
import { createStylesWithTheme } from 'utils/functions';

// Create your Styles. Remember, since React-JSS uses the default preset,
// most plugins are available without further configuration needed.
const styles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 583,
    alignSelf: "center",
    width: "100%",
  },
  header: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "8px",
    marginBottom: 24,
  },
  monitoringCards: {
    marginTop: 16,
    display: "flex",
    flexDirection: "column",
    gridGap: 16,
    marginBottom: 24,
  },
  monitoringCard: {
    padding: [16, "!important"],
    display: "flex",
    alignItems: "center",
    border: `1px solid transparent`,
    background: [COLORS.DARK_NEUTRALS[800],"!important"],
    "&:hover": {
      borderColor: theme.palette.primary.main,
    },
    "& svg": {
      marginRight: 12,
      height: 32,
    }
  },
  activeMonitoringCard: {
    position: "relative",
    backgroundColor: [COLORS.PRIMARY[1300], "!important"],
    borderColor: theme.palette.primary.main,
  },
  checkMark: {
    display: "flex",
    position: "absolute",
    right: 8,
    color: theme.palette.primary.main,
  }

});

export default createStylesWithTheme(styles);