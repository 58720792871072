import * as yup from 'yup';
import { camelCase } from 'lodash';
import analytics from "react-segment";
import Bugsnag from '@bugsnag/js';


if (process.env.NODE_ENV === 'production') {
    analytics.default.load("UPGSjCFXIM0O9PqZf6Jz1jxDDozSVCGk");
} else if (process.env.NODE_ENV === 'staging' /* || process.env.NODE_ENV === 'development' */) { // uncomment to test segment in dev
    analytics.default.load("VPCXciQjS7uPWHDF8KdvIi3tHs7oezDj");
}

const validateSchema = (schema, data) => {
    if (process.env.NODE_ENV === 'development') schema.validateSync(data); // show error in dev
    else {
        try {
            schema.validateSync(data);
        } catch (e) {
            Bugsnag.notify(new Error(`Invalid data for schema: ${e.message}`), function (event) {
                event.severity = 'error'
                event.addMetadata('segmentInvalidSchema', data)
            });
        }
    }

}

const specialTrackingMethods = {
    page: (pageName, traits) => {
        if (!pageName) throw new Error("Page name is missing");
        const traitsSchema = yup.object().shape({
            page_title: yup.string().required(),
            search_params: yup.string(),
        });
        validateSchema(traitsSchema, traits);
        return window.analytics.page(pageName, traits)
    },
    identify: (id, traits) => {
        if (!id) throw new Error("User id is missing");

        const traitsSchema = yup.object().shape({
            email: yup.string().email().required(),
            name: yup.string(),
            tenant: yup.string().required(),
        });
        validateSchema(traitsSchema, traits);
        return window.analytics.identify(id, traits)
    },
    anonymousIdentify: (traits) => {
        const traitsSchema = yup.object().shape({
            email: yup.string().email().required(),
            name: yup.string(),
            tenant: yup.string().notRequired(),
        });
        validateSchema(traitsSchema, traits);
        return window.analytics.identify(traits)
    },
}

// custom events
const events = [
    {
        eventName: "Custom Signup",
        schema: yup.object().shape({
            email: yup.string().email().required(),
        }),
    },
    {
        eventName: "Custom Signup Error",
        schema: yup.object().shape({
            email: yup.string().email().required(),
            error_message: yup.string().required(),
        }),
    },
    {
        eventName: "Cloud Provider Account Created",
        schema: yup.object().shape({
            account_id: yup.string().required(),
            cloud_provider: yup.string().required(),
        }),
    },
    {
        eventName: "Cloud Provider Account Deleted",
        schema: yup.object().shape({
            account_id: yup.string().required(),
            cloud_provider: yup.string().required(),
        }),
    },
    {
        eventName: "Account Type Click",
        schema: yup.object().shape({
            account_type_click: yup.string().required(),
        }),
    },
    {
        eventName: "Monitoring Provider Click",
        schema: yup.object().shape({
            monitoring_provider_type: yup.string().required(),
        }),
    },
    {
        eventName: "Wizard Next Click",
        schema: yup.object().shape({
            current_step_url: yup.string().required(),
            btn_text: yup.string().required(),
        }),
    },
    {
        eventName: "Schedule Onboarding Call Click",
        schema: yup.object().shape({
            current_step_url: yup.string().required(),
        }),
    },
    {
        eventName: "Invite Teammate Click",
        schema: yup.object().shape({
            current_step_url: yup.string().required(),
        }),
    },
    {
        eventName: "Send Invitation",
        schema: yup.object().shape({
            emails: yup.array().of(yup.string().required()).required(),
            current_step_url: yup.string().required(),
        }),
    },
    {
        eventName: "View Docs Click",
        schema: yup.object().shape({
            current_step_url: yup.string().required(),
            docs_url: yup.string().required(),
        }),
    },
    {
        eventName: "Git Ops Selection",
        schema: yup.object().shape({
            selected_answer: yup.string().required(),
        }),
    },
]

/**
 * The eventName would be used to generate the function name that can be called to fired the event
 * for example My Event Name can be fired by calling  segmentioTracking.myEventName(...)
 * the reason behind taking this approach is to follow how the official segment lib works
 * so that in future we can move to defining events on segment website and using them directly through the app without
 * much refactoring
 */

const eventTrackingMethods = events.reduce((result, e) => {
    result[camelCase(e.eventName)] = (traits) => {
        if (e.schema) validateSchema(e.schema, traits);
        return window.analytics.track(e.eventName, traits)
    }
    return result;
}, {})

export default { ...eventTrackingMethods, ...specialTrackingMethods };