import COLORS from 'themes/colors';
import { createStylesWithTheme } from 'utils/functions';


const styles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 583,
    alignSelf: "center",
    width: "100%",
  },
  header: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "8px",
    marginBottom: 24,
  },
  content: {
    display: "flex",
    marginTop: 32,
    gap: "32px",
    "&>*": {
      flex: 1,
      display: "flex",
      flexDirection: "column",
    },
    "&>*:not(:last-child)": {
      paddingRight: 32,
      borderRight: `1px solid ${theme.palette.divider}`,
    },
  },
  subtitleDesc: {
    marginTop: 4,
    marginBottom: 24,
  },
  launchBtn: {
    marginBottom: 12,
  },
  list: {
    listStyleType: "none",
    padding: 0,
    "&>*:not(:last-child)": {
      marginBottom: 12,
    },
    marginTop: 12,
    marginBottom: 12,
  },
  listItem: {
    display: "flex",
  },
  listIcon: {
    color: theme.palette.primary.main,
    minWidth: 16,
    marginRight: 10,
    marginTop: 2,

  },
  listItemText: {
    display: "flex",
    flexDirection: "column",
    "&>*:not(:last-child)": {
      marginBottom: 4,
    },
  },
  iamTxt: {
    marginTop: 12,
    marginBottom: 12,
    textAlign: "center",
  },
  linksBtn: {
    marginBottom: 16,
  },
  iconContainer: {
    marginRight: 8,
    minWidth: 16,
    color: theme.type === 'dark' ? COLORS.BLUE[400] : COLORS.BLUE[700]
  },
  alertCard: {
    background: theme.type === "dark" ? COLORS.BLUE[1300] : COLORS.LIGHT_NEUTRALS[200],
    border: `1px solid ${theme.type === "dark" ? COLORS.BLUE[1000] : COLORS.LIGHT_NEUTRALS[500]}`,
    display: "flex",
    alignItems: 'flex-start',
    marginBottom: 24,
    gap: 4,
    padding: 12
  },
  invitedMessage: {
    marginTop: 24,
    padding: 8,
    background: COLORS.GREEN[1300],
    borderRadius: 4,
  },
});

export default createStylesWithTheme(styles);