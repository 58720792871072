import {
    SET_ENV_DOMAIN,
    SET_USER_TRACKING_ID,
} from './actionsTypes';

export default function reducer(state, action) {
    switch (action.type) {
        case SET_ENV_DOMAIN: {
            return { ...state, envDomain: action.payload }
        }
        case SET_USER_TRACKING_ID: {
            return { ...state, userTrackingId: action.payload }
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
};