import { useState } from "react";
import { useToasts } from "react-toast-notifications";
import { FiCopy, FiExternalLink } from "react-icons/fi";
import { VscCircleFilled } from "react-icons/vsc";
import copy from 'copy-to-clipboard';
import Typography from "components/Typography/Typography";
import Button from "components/Button/Button";
import WizardLayout from "views/AddCloudWizard/WizardLayout/WizardLayout";
import DefaultStepFooterContent from "views/common/WizardFooter/DefaultStepFooterContent/DefaultStepFooterContent";

import useStyles from './AWSIAMRole.styles';
import { useTheme } from "react-jss";
import { CONNECT_TO_AWS_CREDENTIALS_TYPES, STEP_KEYS } from "views/AddCloudWizard/AddCloudWizard.utils";
import { useWizardData, wizardDataActionsTypes } from "contexts/WizardData";
import CopyTextBtn from "./CopyTextBtn/CopyTextBtn";
import Bugsnag from "@bugsnag/js";


const AWSIAMRole = ({ wizardProps, envDomain, email, currentStep, moveToNextStep, moveToStep, isCurrentStepValid }) => {
    const cls = useStyles();
    const theme = useTheme();

    const [isLoading, setIsLoading] = useState(false);
    const [wizardData, wizardDataDispatch, getWizardData] = useWizardData();
    const { addToast } = useToasts();

    return (
        <WizardLayout {...wizardProps}>
            <div className={cls.container}>
                <div className={cls.header}>
                    <Typography variant="h2" color="textPrimary">Create IAM Role in your AWS Console</Typography>
                    <Typography variant="body3" color="textSecondary">Set up secure access for Sedai to connect to resources.</Typography>
                </div>
                <div className={cls.content}>
                    <ul className={cls.list}>
                        <li className={cls.listItem}>
                            <VscCircleFilled className={cls.listIcon} />
                            <div className={cls.listItemText}>
                                <Typography variant="body3" color="textPrimary">Go to <Typography component="span" color="primary"><Button variant="link" color="primary" target="_blank" rel="noreferrer noopener" component="a" href="https://console.aws.amazon.com/iam/" >{"AWS Console > IAM >Access Management > Roles"}</Button></Typography> and select <Typography component="span" fontWeight={800}>Create Role</Typography></Typography>
                            </div>
                        </li>
                        <li className={cls.listItem}>
                            <VscCircleFilled className={cls.listIcon} />
                            <div className={cls.listItemText}>
                                <Typography variant="body3" color="textPrimary">Select <Typography component="span" fontWeight={800}>AWS Account</Typography> as the Trusted Entity</Typography>
                            </div>
                        </li>
                        <li className={cls.listItem}>
                            <VscCircleFilled className={cls.listIcon} />
                            <div className={cls.listItemText}>
                                <Typography variant="body3" color="textPrimary">Enter Sedai’s AWS Account ID  <Typography variant="body1">
                                    <CopyTextBtn className={cls.accountId} value="504216784688" variant="link" color="textPrimary">504216784688 <FiCopy color={theme.palette.text.primary} /></CopyTextBtn>
                                </Typography>
                                </Typography>
                            </div>
                        </li>
                        <li className={cls.listItem}>
                            <VscCircleFilled className={cls.listIcon} />
                            <div className={cls.listItemText}>
                                <Typography variant="body3" color="textPrimary">Attach policy from previous step</Typography>
                            </div>
                        </li>
                        <li className={cls.listItem}>
                            <VscCircleFilled className={cls.listIcon} />
                            <div className={cls.listItemText}>
                                <Typography variant="body3" color="textPrimary">Copy the Role ARN</Typography>
                            </div>
                        </li>
                    </ul>
                    <Button
                        variant="outline"
                        color="textSecondary"
                        component="a"
                        target="_blank"
                        rel="noreferrer noopener"
                        href="https://docs.sedai.io/get-started/setup/connect-cloud/amazon-web-services/configure-iam/create-iam-role">Learn More <FiExternalLink /></Button>

                    <Typography variant="body3" color="textSecondary">Or set up <Button color="primary" variant="link"
                        onPress={() => {
                            wizardDataDispatch({ type: wizardDataActionsTypes.SET_AWS_CREDENTIALS_TYPE, payload: CONNECT_TO_AWS_CREDENTIALS_TYPES.IAM_USER })
                            moveToStep(STEP_KEYS.AWS_MANUAL_IAM_USER)
                        }}
                    >IAM User</Button></Typography>


                    <div>
                        <DefaultStepFooterContent
                            moveNextIsLoading={isLoading}
                            moveToNextStep={() => {
                                setIsLoading(true);
                                wizardDataDispatch({ type: wizardDataActionsTypes.SET_IS_IAM_ROLE_CREATED, payload: true })
                                const currentWizardData = getWizardData();
                                currentStep.submit(currentWizardData, { envDomain, email }).then(() => {
                                    setIsLoading(false);
                                    moveToNextStep();
                                }).catch((e) => {
                                    setIsLoading(false);
                                    Bugsnag.notify(e);
                                    addToast("Failed to submit data", {
                                        appearance: 'error',
                                        autoDismiss: true,
                                    });
                                })

                            }}
                            moveNextBtnText="I created Sedai’s IAM Role"
                        //disableNextBtn={!isCurrentStepValid} // allow the user to click the button as the step is valid after submitting the form
                        />
                    </div>
                </div>
            </div>
        </WizardLayout>
    )
};

export default AWSIAMRole;