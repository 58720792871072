import { useToasts } from "react-toast-notifications";
import { get } from "lodash";
import Typography from "components/Typography/Typography";

import WizardLayout from "views/AddCloudWizard/WizardLayout/WizardLayout";

import DefaultStepFooterContent from "views/common/WizardFooter/DefaultStepFooterContent/DefaultStepFooterContent";
import { immutableSet } from "utils/functions";
import { CP_CREDETIALS_BASE_PATH } from "utils/cloudProviders";
import Button from "components/Button/Button";
import TextField from "components/TextField/TextField";
import useStyles from './CloudFormationARN.styles';
import { useWizardData, wizardDataActionsTypes } from "contexts/WizardData";
import { useState } from "react";
import Bugsnag from "@bugsnag/js";


const CloudFormationARN = ({ wizardProps, currentStep, moveToNextStep, envDomain, email, isCurrentStepValid }) => {
    const cls = useStyles();
    const [wizardData, wizardDataDispatch] = useWizardData();
    const { addToast } = useToasts();
    const [isLoading, setIsLoading] = useState(false);

    return (
        <>
            <WizardLayout {...wizardProps}>
                <div className={cls.container}>
                    <div className={cls.header}>
                        <Typography variant="h2" color="textPrimary">Enter Role ARN</Typography>
                        <Typography variant="body3" color="textSecondary">This can be found from the Outputs tab of the CloudFormation Stack or from the <Button variant="link" color="primary" target="_blank" rel="noreferrer noopener" component="a" href="https://console.aws.amazon.com/cloudformation/home?region=us-east-1#/stacks/new?stackName=cfn-iam-sedai-integration-service-role&templateURL=https://s3.amazonaws.com/sedai-onboarding-templates-prod/sedai-integration-service-role-autonomous.yml" >{"IAM > Access Management > Roles"}</Button> page within your AWS Console.</Typography>
                    </div>
                    <Typography variant="body3" color="textSecondary">In addition to connecting to your resources, Sedai will also use this Role ARN to connect to CloudWatch and pull monitoring data.</Typography>
                    <TextField placeholder="Role ARN" autoFocus value={get(wizardData, `uiStateDetails.data.sedaiAccount.${CP_CREDETIALS_BASE_PATH}.role`)} className={cls.arnInput} onChange={(e) => {
                        const arnSplit = get(e, "target.value", "").split(":");
                        if(arnSplit.length > 4) {
                            wizardDataDispatch({ type: wizardDataActionsTypes.SET_AWS_EXTERNAL_ID, payload: "ext-" + arnSplit[4] });
                        }
                        wizardDataDispatch({ type: wizardDataActionsTypes.SET_AWS_ROLE_ARN, payload: e.target.value.trim() });
                        
                    }} />
                    <TextField placeholder="External Id" value={get(wizardData, `uiStateDetails.data.sedaiAccount.${CP_CREDETIALS_BASE_PATH}.externalId`)} className={cls.arnInput} onChange={(e) => {
                        wizardDataDispatch({ type: wizardDataActionsTypes.SET_AWS_EXTERNAL_ID, payload: e.target.value.trim() });
                    }} />
                    <div>
                        <DefaultStepFooterContent
                            moveNextIsLoading={isLoading}
                            moveToNextStep={() => {
                                if (isCurrentStepValid) {
                                    setIsLoading(true);
                                    currentStep.submit(wizardData, { envDomain, email }).then(() => {
                                        setIsLoading(false);
                                        moveToNextStep();
                                    }).catch((e) => {
                                        setIsLoading(false);
                                        Bugsnag.notify(e);
                                        addToast("Failed to submit ARN", {
                                            appearance: 'error',
                                            autoDismiss: true,
                                        });
                                    })

                                }
                            }}
                            disableNextBtn={!isCurrentStepValid}
                        />
                    </div>
                </div>
            </WizardLayout>

        </>
    )
};

export default CloudFormationARN;