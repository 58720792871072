import tinycolor from 'tinycolor2';
import { createStylesWithTheme, getThemeColor } from 'utils/functions';

const styles = {
    container: {
        background: ({ theme, props }) => tinycolor(getThemeColor(props.color, theme)).setAlpha(0.1),
        border: ({ theme, props }) => `1px solid ${getThemeColor(props.color, theme)}`,
        color: ({ theme, props }) => getThemeColor(props.color, theme),
        borderRadius: 4,
        padding: 16,
    }
};

export default createStylesWithTheme(styles);