import React, { useState, useRef, useMemo } from "react";
import { FiArrowRight, FiCheck, FiCheckCircle, FiChevronRight, FiExternalLink, FiMinus, FiPlus } from "react-icons/fi";
import { Formik } from "formik";
import cn from "classnames";
import { Form, Spinner } from "react-bootstrap";
import { get, keyBy } from "lodash";
import Typography from "components/Typography/Typography";
import InfoIconTooltip from "components/InfoIconTooltip/InfoIconTooltip";
import Button from "components/Button/Button";
import Icon from "components/Icon/Icon";
import DefaultStepFooterContent from "views/common/WizardFooter/DefaultStepFooterContent/DefaultStepFooterContent";
import WizardLayout from "views/AddCloudWizard/WizardLayout/WizardLayout";
import { FIELD_GROUPS, getAccountMonitoringProvidersConfig } from "utils/monitoringProviders";

import { INTEGRATION_TYPE } from "enums";
import { getCloudProviderConfig } from "utils/cloudProviders";
import { testMonitoringProviderConnection } from "utils/api/testConnection";

import useStyles from './UpsertMonitoringProvider.styles';
import IconButton from "components/IconButton/IconButton";
import { motion, AnimatePresence } from "framer-motion";
import { useWizardData, wizardDataActionsTypes } from "contexts/WizardData";
import { useToasts } from "react-toast-notifications";
import TextField from "components/TextField/TextField";
import InviteTeamMembersModal from "views/AddCloudWizard/InviteTeamMembersModal/InviteTeamMembersModal";
import segmentioTracking from "utils/segmentioTracking";
import Bugsnag from "@bugsnag/js";


const fieldGroupTabOptions = [{ name: FIELD_GROUPS.ADVANCED_SETUP, label: "" }, { name: FIELD_GROUPS.DIMENSIONS, label: "Map Dimensions" }]
const fieldGroupTabOptionsByName = keyBy(fieldGroupTabOptions, "name")
const UpsertMonitoringProvider = ({ wizardProps, email, envDomain, currentStep, moveToNextStep }) => {
    const cls = useStyles();
    const testBtnRef = useRef();
    const [isInviteTeamMembersModalOpen, setIsInviteTeamMembersModalOpen] = useState(false);
    const [isAdvancedExpanded, setIsAdvancedExpanded] = useState();
    const [wizardData, wizardDataDispatch, getWizardData] = useWizardData();
    const { addToast } = useToasts();

    const accountDetails = wizardData?.uiStateDetails?.data?.sedaiAccount?.accountDetails;

    const cpTypeConfig = useMemo(() => {
        return getCloudProviderConfig(accountDetails?.cloudProvider, accountDetails?.metadata?.clusterProvider);
    }, [accountDetails]);

    const accMonitoringProvidersConfig = useMemo(() => {
        if (!accountDetails?.integrationType || !cpTypeConfig) return;
        return getAccountMonitoringProvidersConfig({ cloudProviderTypeConfig: cpTypeConfig, cloudProviderIntegrationType: accountDetails?.integrationType })
    }, [cpTypeConfig]);

    const mpKey = wizardData?.resourceDetails?.monitoringProviders?.[0];
    const selectedNewMPTypeConfig = accMonitoringProvidersConfig[mpKey];
    const mpData = useMemo(() => {
        return (wizardData?.uiStateDetails?.data?.sedaiMonitoringProviders || []).find(({ monitoringProvider }) => monitoringProvider === mpKey);
    }, [mpKey])
    const [testConnectionState, setTestConnectionState] = useState();

    const initValues = useMemo(() => {
        if (mpData) return mpData;
        return { ...selectedNewMPTypeConfig.initValues, name: `${selectedNewMPTypeConfig.label} for ${wizardData?.uiStateDetails?.data?.sedaiAccount?.name || cpTypeConfig.label}` }
    }, [mpData, selectedNewMPTypeConfig.initValues])

    const invitedEmails = wizardData?.uiStateDetails?.uiData?.invitations?.[email] || [];;

    return (
        <>
            <WizardLayout {...wizardProps}>
                <div className={cls.container}>
                    <div className={cls.header}>
                        <Typography variant="h2" color="textPrimary">Connect {selectedNewMPTypeConfig.label} to Sedai</Typography>
                    </div>
                    <div className={cls.pargraphsContainer}>
                        <div>
                            <Typography variant="h4" color="textPrimary">I’ll connect {selectedNewMPTypeConfig.label} <br /> myself</Typography>
                            <Typography variant="body3" color="textPrimary">You will need to provide sufficient credentials based on your preferred connection method in order to proceed.</Typography>
                            {selectedNewMPTypeConfig.setupSupportLink && <Button className={cls.supportBtn} variant="filled" color="primary" component="a"
                                onClick={() => {
                                    segmentioTracking.viewDocsClick({
                                        current_step_url: window.location.href,
                                        docs_url: selectedNewMPTypeConfig.setupSupportLink,
                                    });
                                }}
                                href={selectedNewMPTypeConfig.setupSupportLink} target="_blank" rel="noreferrer noopener">View Docs</Button>}
                        </div>
                        <div>
                            <Typography variant="h4" color="textPrimary">Someone else will connect {selectedNewMPTypeConfig.label}</Typography>
                            <Typography variant="body3" color="textPrimary">Invite your colleague to join setup so that they can provide sufficient credentials to connect {selectedNewMPTypeConfig.label}.</Typography>
                            <Button
                                className={cls.supportBtn}
                                variant="filled"
                                color="primary"
                                onPress={() => {
                                    segmentioTracking.inviteTeammateClick({ current_step_url: window.location.href })
                                    setIsInviteTeamMembersModalOpen(true);
                                }}>Invite my teammate</Button>

                            {invitedEmails.length > 0 && (
                                <div className={cls.invitedMessage}>
                                    <Typography variant="body3" color="success"><FiCheckCircle /> Invitation sent to <strong>{invitedEmails.join(', ')}</strong></Typography>
                                </div>
                            )}
                        </div>
                    </div>

                    <Formik
                        initialValues={initValues}
                        validateOnMount
                        validationSchema={selectedNewMPTypeConfig.validationSchema}
                        onSubmit={(values) => {
                            wizardDataDispatch({ type: wizardDataActionsTypes.SET_MONITORING_PROVIDER_DATA, payload: values })
                            return currentStep.submit(getWizardData(), { envDomain, email }).then(() => {
                                moveToNextStep();
                            }).catch((e) => {
                                Bugsnag.notify(e);
                                addToast("Failed to select monitoring provider", {
                                    appearance: 'error',
                                    autoDismiss: true,
                                });
                            })
                        }}
                    >
                        {(props) => {
                            const {
                                values,
                                errors,
                                touched,
                                isValid,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                submitCount,
                                isSubmitting,
                            } = props;
                            const mpFields = selectedNewMPTypeConfig.formFields.filter(({ hide }) => !(typeof hide === "function" && hide(props, { cloudProviderTypeConfig: cpTypeConfig })));
                            const shouldHideTestingButton = selectedNewMPTypeConfig.hideTestConnection && selectedNewMPTypeConfig.hideTestConnection(values);
                            const authGroupFields = mpFields.filter(({ fieldGroup }) => fieldGroup === FIELD_GROUPS.AUTHENTICATION);
                            const advancedSectionFields = fieldGroupTabOptions.map(({ name }) => mpFields.filter(({ fieldGroup }) => fieldGroup === name)).flat(1);
                            return (
                                <Form className={cls.mpContent} onSubmit={handleSubmit} onChange={() => setTestConnectionState(undefined)}>
                                    {authGroupFields.map(({ component: FieldComponent, key, label, labelTooltip, getHandler, hide, transformValueForField, ...fieldProps }, index) => (
                                        <>
                                            <Form.Group controlId={`MonitoringProvider-Settings-Field-${key}`}>
                                                {FieldComponent === TextField && (
                                                    <TextField
                                                        {...fieldProps}
                                                        label={<>{label} {labelTooltip && <InfoIconTooltip>{labelTooltip}</InfoIconTooltip>}</>}
                                                        name={key}
                                                        value={typeof transformValueForField === "function" ? transformValueForField(get(values, key)) : get(values, key, '')}
                                                        hasError={get(errors, key) && (get(touched, key) || submitCount)}
                                                        onChange={typeof getHandler === 'function' ? getHandler(props) : handleChange}
                                                        onBlur={handleBlur} />
                                                )}
                                                {FieldComponent !== TextField && (
                                                    <>
                                                        <Form.Label>
                                                            <Typography variant="body2" color="textSecondary">{label} {labelTooltip && <InfoIconTooltip>{labelTooltip}</InfoIconTooltip>}</Typography>
                                                        </Form.Label>
                                                        <FieldComponent
                                                            {...fieldProps}
                                                            name={key}
                                                            value={typeof transformValueForField === "function" ? transformValueForField(get(values, key)) : get(values, key)}
                                                            color={get(errors, key) && (get(touched, key) || submitCount) ? "error" : undefined}

                                                            onChange={typeof getHandler === 'function' ? getHandler(props) : handleChange}
                                                            onBlur={handleBlur} />
                                                    </>
                                                )}
                                            </Form.Group>
                                        </>
                                    ))}
                                    {mpFields.length > authGroupFields.length && (
                                        <>
                                            <div className={cls.advanceSetupToggleWrapper}>
                                                <Typography variant="body3" color="textTernary">Advanced setup</Typography>
                                                <IconButton size={16} color="textSecondary" onPress={() => setIsAdvancedExpanded((v) => !v)}> {isAdvancedExpanded ? <FiMinus /> : <FiPlus />}</IconButton>
                                            </div>
                                            <AnimatePresence initial={false}>
                                                {isAdvancedExpanded && (
                                                    <motion.div
                                                        initial="collapsed"
                                                        animate="open"
                                                        exit="collapsed"
                                                        variants={{
                                                            open: { opacity: 1, height: "auto", transition: { delay: 0.2 } },
                                                            collapsed: { opacity: 0, height: 0, transition: { duration: 0 } }
                                                        }}
                                                        className={cn(cls.collapsibleSection, "flex-column")}>
                                                        {advancedSectionFields.map(({ component: FieldComponent, key, label, labelTooltip, getHandler, hide, transformValueForField, fieldGroup, ...fieldProps }, index) => (
                                                            <>

                                                                {advancedSectionFields?.[index - 1]?.fieldGroup !== fieldGroup && fieldGroupTabOptionsByName[fieldGroup]?.label && <Typography className={cls.advancesSetupSectionHeader} variant="body1" fontWeight="700" color="textPrimary">{fieldGroupTabOptionsByName[fieldGroup]?.label}</Typography>}
                                                                {FieldComponent === TextField && (
                                                                    <TextField
                                                                        {...fieldProps}
                                                                        label={<>{label} {labelTooltip && <InfoIconTooltip>{labelTooltip}</InfoIconTooltip>}</>}
                                                                        name={key}
                                                                        value={typeof transformValueForField === "function" ? transformValueForField(get(values, key)) : get(values, key, '')}
                                                                        hasError={get(errors, key) && (get(touched, key) || submitCount)}
                                                                        onChange={typeof getHandler === 'function' ? getHandler(props) : handleChange}
                                                                        onBlur={handleBlur} />
                                                                )}
                                                                {FieldComponent !== TextField && (
                                                                    <Form.Group controlId={`MonitoringProvider-Settings-Field-${key}`}>
                                                                        <Form.Label>
                                                                            <Typography variant="body2" color="textSecondary">{label} {labelTooltip && <InfoIconTooltip>{labelTooltip}</InfoIconTooltip>}</Typography>
                                                                        </Form.Label>
                                                                        <FieldComponent
                                                                            {...fieldProps}
                                                                            name={key}
                                                                            value={typeof transformValueForField === "function" ? transformValueForField(get(values, key)) : get(values, key, '')}
                                                                            color={get(errors, key) && (get(touched, key) || submitCount) ? "error" : undefined}

                                                                            onChange={typeof getHandler === 'function' ? getHandler(props) : handleChange}
                                                                            onBlur={handleBlur} />
                                                                    </Form.Group>
                                                                )}

                                                            </>
                                                        ))}
                                                    </motion.div>
                                                )}
                                            </AnimatePresence>
                                        </>
                                    )}
                                    {shouldHideTestingButton && (
                                        <Button className={cls.submitBtn} type="submit" variant="filled" size="medium" color="primary" disabled={!isValid} >
                                            Next {isSubmitting ? <Spinner animation="border" role="status" size="sm" /> : <FiArrowRight />}
                                        </Button>
                                    )}

                                    {!shouldHideTestingButton && (
                                        <>
                                            <Button
                                                ref={testBtnRef}
                                                className={cls.testBtn}
                                                size="medium"
                                                variant={testConnectionState === "success" ? "text" : "outline"}
                                                active={testConnectionState === "success" ? true : undefined}
                                                color={testConnectionState === "success" ? "success" : "textSecondary"}
                                                disabled={testConnectionState === "loading" || !isValid}
                                                type={testConnectionState === "success" ? "submit" : "button"}
                                                onPress={(e) => {
                                                    // disable the onPress and fallback to form  submit callback if the test is already successfull
                                                    if (testConnectionState === "success") return;

                                                    setTestConnectionState("loading");
                                                    testMonitoringProviderConnection(values).then((result) => {
                                                        if (result.connectionStatus) {
                                                            setTestConnectionState("success");
                                                            setTimeout(() => handleSubmit(e), 2000);// auto submit the form after successfull connection test

                                                        }
                                                        else {
                                                            setTestConnectionState("error");
                                                        }

                                                    }).catch(() => {
                                                        setTestConnectionState("error");
                                                    })
                                                }}
                                            >
                                                {testConnectionState !== "success" && "Test connection"} {testConnectionState === "loading" && <Spinner animation="border" role="status" size="sm" />}  {testConnectionState === "success" && <>Connected <FiCheck /></>}
                                            </Button>
                                            {!testConnectionState && <Typography className={cls.testErrorMsg} variant="body5" color="textTernary">(You must click this in order to proceed)</Typography>}
                                            {testConnectionState === "error" && <Typography className={cls.testErrorMsg} variant="body5" color="error">Hmm, something doesn’t look right — try again.</Typography>}

                                        </>
                                    )}

                                </Form>
                            )
                        }}
                    </Formik>
                </div>
            </WizardLayout>
            <InviteTeamMembersModal
                wizardData={wizardData}
                isOpen={isInviteTeamMembersModalOpen}
                envDomain={envDomain}
                email={email}
                onClose={setIsInviteTeamMembersModalOpen}
                invitePromise={(invitedEmails) => {
                    const currentWizardData = getWizardData();
                    wizardDataDispatch({ type: wizardDataActionsTypes.SET_INVITED_EMAILS_BY_CURRENT_USER, payload: { invitedByEmail: email, invitedEmails } })

                    return currentStep.invitationUIDataSubmit(currentWizardData, { envDomain, email });
                }}
            />
        </>
    )
};

export default UpsertMonitoringProvider;