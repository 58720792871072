import { FiCheckCircle, FiInfo } from "react-icons/fi";

import Typography from "components/Typography/Typography";
import Button from "components/Button/Button";
import Card from "components/Card/Card";
import WizardLayout from "views/AddCloudWizard/WizardLayout/WizardLayout";

import useStyles from './AWSCloudFormation.styles';
import { CONNECT_TO_AWS_METHODS, STEP_KEYS } from "views/AddCloudWizard/AddCloudWizard.utils";
import DefaultStepFooterContent from "views/common/WizardFooter/DefaultStepFooterContent/DefaultStepFooterContent";
import InviteTeamMembersModal from "views/AddCloudWizard/InviteTeamMembersModal/InviteTeamMembersModal";
import { useState } from "react";
import { PopupModal } from "react-calendly";
import { useTheme } from "react-jss";
import { useWizardData, wizardDataActionsTypes } from "contexts/WizardData";
import { useToasts } from "react-toast-notifications";
import segmentioTracking from "utils/segmentioTracking";
import Bugsnag from "@bugsnag/js";

const AWSCloudFormation = ({ wizardProps, currentStep, moveToNextStep, moveToStep, isCurrentStepValid, envDomain, email }) => {
    const cls = useStyles();
    const [isInviteTeamMembersModalOpen, setIsInviteTeamMembersModalOpen] = useState(false);
    const [isCalendlyPopupOpen, setIsCalendlyPopupOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [wizardData, wizardDataDispatch, getWizardData] = useWizardData();
    const { addToast } = useToasts();

    const theme = useTheme();

    const calendlyPageSettings = {
        hideEventTypeDetails: false,
        hideLandingPageDetails: false,
        primaryColor: theme.palette.primary.main,
    }

    const invitedEmails = wizardData?.uiStateDetails?.uiData?.invitations?.[email] || [];;
    return (
        <>
            <WizardLayout {...wizardProps}>
                <div className={cls.container}>
                    <div className={cls.header}>
                        <Typography variant="h2" color="textPrimary">Grant IAM access to your AWS account</Typography>
                        <Typography variant="body2" color="textTernary">Sedai programmatically accesses topology via Identity and Access Management.</Typography>
                    </div>
                    <div className={cls.content}>
                        <div>
                            <Typography variant="h4" color="textPrimary" fontWeight={600}>I’ll set up IAM myself</Typography>
                            <Typography className={cls.subtitleDesc} variant="body3" color="textPrimary">You need permission to set up IAM within your organization’s  AWS console to proceed.</Typography>

                            <Button
                                className={cls.launchBtn}
                                component="a"
                                variant="filled"
                                color="primary"
                                href="https://us-east-1.console.aws.amazon.com/cloudformation/home?region=us-east-1#/stacks/create/review?templateURL=https://sedai-onboarding-templates-prod.s3.amazonaws.com/sedai-integration-service-role-autonomous-v3.yml&stackName=cfn-iam-sedai-integration-service-role"
                                target="_blank"
                                rel="noreferrer noopener"
                                onClick={() => {
                                    wizardDataDispatch({ type: wizardDataActionsTypes.SET_IS_CLOUD_FORMATION_LAUNCHED, payload: { isCloudFormationLinkClicked: true } })
                                }}>Launch CloudFormation</Button>

                            <Typography variant="body3" color="textPrimary">By launching CloudFormation, Sedai will open your AWS Console in a new tab. Log in so that Sedai can automatically configure the following access:</Typography>

                            <ul className={cls.list}>
                                <li className={cls.listItem}>
                                    <FiCheckCircle className={cls.listIcon} />
                                    <div className={cls.listItemText}>
                                        <Typography variant="body3" color="textPrimary">Create Sedai Policy</Typography>
                                        <Typography variant="body5" color="textSecondary">Includes <Typography component="strong" color="textTernary">read-write access</Typography> to selected resource types; as well as <Typography component="strong" color="textTernary">read-only access</Typography> to CloudWatch, Kinesis, X-Ray and DynamoDB</Typography>
                                    </div>
                                </li>
                                <li className={cls.listItem}>
                                    <FiCheckCircle className={cls.listIcon} />
                                    <div className={cls.listItemText}>
                                        <Typography variant="body3" color="textPrimary">Create IAM Role</Typography>
                                    </div>
                                </li>
                                <li className={cls.listItem}>
                                    <FiCheckCircle className={cls.listIcon} />
                                    <div className={cls.listItemText}>
                                        <Typography variant="body3" color="textPrimary">Attach Sedai Policy to new Role</Typography>
                                    </div>
                                </li>
                            </ul>

                            <Typography className={cls.iamTxt} variant="body3" color="textSecondary">Or do <Button color="primary" variant="link" onPress={() => {
                                wizardDataDispatch({ type: wizardDataActionsTypes.SET_AWS_CONNETION_METHOD, payload: CONNECT_TO_AWS_METHODS.MANUAL })
                                moveToStep(STEP_KEYS.AWS_MANUAL_AUTONOMOUS)
                            }}>these steps manually</Button> (~10 minutes)</Typography>

                            <Card className={cls.alertCard}>
                                <FiInfo className={cls.iconContainer} />
                                <Typography variant="body3" color="textSecondary">Please ensure the Permission section of the template reflects your prior choices for which cloud products Sedai can manage. </Typography>
                            </Card>

                            <Button
                                className={cls.linksBtn}
                                variant="outline"
                                color="textSecondary"
                                onPress={() => {
                                    segmentioTracking.scheduleOnboardingCallClick({ current_step_url: window.location.href })
                                    setIsCalendlyPopupOpen(true);
                                }}
                            >Schedule onboarding call</Button>
                            <Button
                                className={cls.linksBtn}
                                variant="outline"
                                color="textSecondary"
                                component="a"
                                target="_blank"
                                rel="noreferrer noopener"
                                onClick={() => {
                                    segmentioTracking.viewDocsClick({
                                        current_step_url: window.location.href,
                                        docs_url: "https://docs.sedai.io/get-started/setup/connect-cloud/amazon-web-services/configure-iam"
                                    });
                                }}
                                href="https://docs.sedai.io/get-started/setup/connect-cloud/amazon-web-services/configure-iam">View Docs</Button>

                            <DefaultStepFooterContent
                                moveNextIsLoading={isLoading}
                                moveToNextStep={() => {
                                    if (isCurrentStepValid) {
                                        setIsLoading(true);
                                        currentStep.submit(wizardData, { envDomain, email }).then(() => {
                                            setIsLoading(false);
                                            moveToNextStep();
                                        }).catch((e) => {
                                            setIsLoading(false);
                                            Bugsnag.notify(e);
                                            addToast("Failed to submit details", {
                                                appearance: 'error',
                                                autoDismiss: true,
                                            });
                                        })
                                    }
                                }}
                                disableNextBtn={!isCurrentStepValid}
                            />
                        </div>
                        <div>
                            <Typography variant="h4" color="textPrimary" fontWeight={600}>Someone else will set up IAM</Typography>
                            <Typography className={cls.subtitleDesc} variant="body3" color="textPrimary">Invite your colleague to help create Sedai’s policy and attach it to a new IAM Role or User.</Typography>

                            <Button
                                variant="filled"
                                color="primary"
                                onPress={() => {
                                    segmentioTracking.inviteTeammateClick({ current_step_url: window.location.href })
                                    setIsInviteTeamMembersModalOpen(true);
                                }}>Invite my teammate</Button>

                            {invitedEmails.length > 0 && (
                                <div className={cls.invitedMessage}>
                                    <Typography variant="body3" color="success"><FiCheckCircle /> Invitation sent to <strong>{invitedEmails.join(', ')}</strong></Typography>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </WizardLayout>
            <InviteTeamMembersModal
                wizardData={wizardData}
                isOpen={isInviteTeamMembersModalOpen}
                envDomain={envDomain}
                email={email}
                onClose={setIsInviteTeamMembersModalOpen}
                invitePromise={(invitedEmails) => {
                    const currentWizardData = getWizardData();
                    wizardDataDispatch({ type: wizardDataActionsTypes.SET_INVITED_EMAILS_BY_CURRENT_USER, payload: { invitedByEmail: email, invitedEmails } })

                    return currentStep.invitationUIDataSubmit(currentWizardData, { envDomain, email });
                }}
            />
            <PopupModal
                url="https://calendly.com/sedai-eng/technical-onboarding-help"
                pageSettings={calendlyPageSettings}
                onModalClose={() => setIsCalendlyPopupOpen(false)}
                open={isCalendlyPopupOpen}
                rootElement={document.getElementById("root")}
            />
        </>
    )
};

export default AWSCloudFormation;