import React, { useState } from "react";
import cn from "classnames";
import { MdAdd, MdCancel } from 'react-icons/md';
import Typography from 'components/Typography/Typography';
import Input from 'components/Input/Input';
import IconButton from 'components/IconButton/IconButton';
import useStyles from './MultiKeyValueChipInput.styles';

const defaultRenderChipText = (item) => (<>{item.key} {item.value ? <><Typography component="span" color="textSecondary">: </Typography>{item.value}</> : null}</>);

export default function MultiKeyValueChipInput({ value, onChange, color, disabled, keyInputPlaceholder, valueInputPlaceholder, renderChipText = defaultRenderChipText, ...rest }) {
  const cls = useStyles();
  const [keyText, setKeyText] = useState("");
  const [valueText, setValueText] = useState("");
  const isDisabled = disabled || !keyText.trim() || !valueText.trim()
  return (
    <div className={cls.container}>
      {value && !!value.length && (
        <div className={cls.chipsContainer}>
          {value.map((v, index) => (
            <Typography wordBreakAll color="textPrimary" variant="body1" className={cls.chip}>{renderChipText(v, index)} {!disabled && <MdCancel className={cls.closeIcon} onClick={() => onChange((value || []).filter((_, i) => i !== index))} />}</Typography>
          ))}
        </div>
      )}
      <div className={cls.inputAddContainer}>
        <Input
          className={cn(cls.input)}
          placeholder={keyInputPlaceholder}
          disabled={disabled}
          color={color}
          onChange={(e) => setKeyText(e.target.value)} value={keyText}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault()
              if (!isDisabled && typeof onChange === 'function' && keyText) {
                onChange([...(value || []), { key: keyText, value: valueText }]);
                setKeyText("");
                setValueText("");
              }
            }
          }} />
        <Input
          className={cn(cls.input)}
          placeholder={valueInputPlaceholder}
          disabled={disabled}
          color={color}
          onChange={(e) => setValueText(e.target.value)} value={valueText}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault()
              if (!isDisabled && typeof onChange === 'function' && keyText) {
                onChange([...(value || []), { key: keyText, value: valueText }]);
                setKeyText("");
                setValueText("");
              }
            }
          }} />
        <IconButton className={cls.addBtn} variant="filled" color="primary" disabled={isDisabled} onClick={() => {
          onChange([...(value || []), { key: keyText, value: valueText }]);
          setKeyText("");
          setValueText("");
        }}>
          <MdAdd />
        </IconButton>
      </div>
    </div>
  );
};

