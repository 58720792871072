import { useToasts } from "react-toast-notifications";
import { useState } from "react";
import { get } from "lodash";
import Typography from "components/Typography/Typography";
import TextField from "components/TextField/TextField";

import WizardLayout from "views/AddCloudWizard/WizardLayout/WizardLayout";

import DefaultStepFooterContent from "views/common/WizardFooter/DefaultStepFooterContent/DefaultStepFooterContent";
import { immutableSet } from "utils/functions";
import { CP_CREDETIALS_BASE_PATH } from "utils/cloudProviders";
import { CREDENTIALS_PROVIDERS } from "enums";
import useStyles from './AWSCredentials.styles';
import { useWizardData, wizardDataActionsTypes } from "contexts/WizardData";
import Bugsnag from "@bugsnag/js";



const AWSCredentials = ({ wizardProps, currentStep, moveToNextStep, envDomain, email, isCurrentStepValid }) => {
    const cls = useStyles();

    const [isLoading, setIsLoading] = useState(false);
    const [wizardData, wizardDataDispatch] = useWizardData();
    const { addToast } = useToasts();

    return (
        <>
            <WizardLayout {...wizardProps}>
                <div className={cls.container}>
                    <div className={cls.header}>
                        <Typography variant="h2" color="textPrimary">Enter Access and Secret Key</Typography>
                        <Typography variant="body3" color="textSecondary">In addition to connecting to your resources, Sedai will also use these credentials to connect to CloudWatch and pull monitoring data.</Typography>
                    </div>
                    <div className={cls.inputWrapper} >
                        <Typography className={cls.inputLabel} variant="body2" color="textSecondary">Access Key</Typography>
                        <TextField autoFocus value={get(wizardData, `uiStateDetails.data.sedaiAccount.${CP_CREDETIALS_BASE_PATH}.accessKey`)} onChange={(e) => {
                            wizardDataDispatch({ type: wizardDataActionsTypes.SET_AWS_ACCESS_KEY, payload: e.target.value.trim() })
                        }} />
                    </div>
                    <div className={cls.inputWrapper} >
                        <Typography className={cls.inputLabel} variant="body2" color="textSecondary">Secret Key</Typography>
                        <TextField value={get(wizardData, `uiStateDetails.data.sedaiAccount.${CP_CREDETIALS_BASE_PATH}.secretKey`)} onChange={(e) => {
                            wizardDataDispatch({ type: wizardDataActionsTypes.SET_AWS_SECRET_KEY, payload: e.target.value.trim() })
                        }} />
                    </div>

                    <div>
                        <DefaultStepFooterContent
                            moveNextIsLoading={isLoading}
                            moveToNextStep={() => {
                                if (isCurrentStepValid) {
                                    setIsLoading(true);
                                    currentStep.submit(wizardData, { envDomain, email }).then(() => {
                                        setIsLoading(false);
                                        moveToNextStep();
                                    }).catch((e) => {
                                        setIsLoading(false);
                                        Bugsnag.notify(e);
                                        addToast("Failed to submit data", {
                                            appearance: 'error',
                                            autoDismiss: true,
                                        });
                                    })

                                }
                            }}
                            disableNextBtn={!isCurrentStepValid}
                        />
                    </div>
                </div>
            </WizardLayout>

        </>
    )
};

export default AWSCredentials;