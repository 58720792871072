import React from "react";
import cn from 'classnames';
import Form from 'react-bootstrap/Form';
import useStyles from './Textarea.styles';

export default function Textarea({ className, color, height, children, variant = "outline", ...rest }) {
    const cls = useStyles({ color, height });
    return (
        <Form.Control {...rest} as="textarea" className={cn(cls.input, cls[variant], className)}>
            {children}
        </Form.Control>
    );
}

