import { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { I18n } from 'aws-amplify';
import { useAuthenticator, translations } from "@aws-amplify/ui-react";
import FullStory from 'fullstory-react';
import 'utils/bugsnagStart';
import AppProviders from "./AppProviders";
import AuthenticationRoute from "components/Routing/AuthenticationRoute";
import PrivateRoute from "components/Routing/PrivateRoute";
import Login from "./views/Login/Login";
import LoadingPage from "views/common/LoadingPage/LoadingPage";
import Home from "views/Home/Home";
import Signup from "views/Signup/Signup";



import CompanyForm from "views/CompanyForm/CompanyForm";
import EnvironmentCreated from "views/EnvironmentCreated/EnvironmentCreated";
import EnvironmentCreationInProgress from "views/EnvironmentCreationInProgress/EnvironmentCreationInProgress";
import AddCloudWizard from "views/AddCloudWizard/AddCloudWizard";
import EnvironmentCreationFailed from "views/EnvironmentCreationFailed/EnvironmentCreationFailed";
import SelectGoal from "views/AddCloudWizard/Steps/SelectGoal/SelectGoal";
import SelectAccountType from "views/AddCloudWizard/Steps/SelectAccountType/SelectAccountType";
import Confirm from "views/AddCloudWizard/Steps/Confirm/Confirm";
import AWSCloudFormation from "views/AddCloudWizard/Steps/AWSCloudFormation/AWSCloudFormation";
import CloudFormationARN from "views/AddCloudWizard/Steps/CloudFormationARN/CloudFormationARN";
import AWSAutonomousPolicy from "views/AddCloudWizard/Steps/AWSAutonomousPolicy/AWSAutonomousPolicy";
import AWSReadOnlyPolicy from "views/AddCloudWizard/Steps/AWSReadOnlyPolicy/AWSReadOnlyPolicy";
import AWSARN from "views/AddCloudWizard/Steps/AWSARN/AWSARN";
import AWSIAMUser from "views/AddCloudWizard/Steps/AWSIAMUser/AWSIAMUser";
import AWSCredentials from "views/AddCloudWizard/Steps/AWSCredentials/AWSCredentials";
import MonitoringProviders from "views/AddCloudWizard/Steps/MonitoringProviders/MonitoringProviders";
import AccountName from "views/AddCloudWizard/Steps/AccountName/AccountName";
import AWSIAMRole from "views/AddCloudWizard/Steps/AWSIAMRole/AWSIAMRole";
import ErrorBoundary from "views/ErrorBoundary/ErrorBoundary";
import SomethingWentWrong from "views/SomethingWentWrong/SomethingWentWrong";
import NotFound from "views/NotFound/NotFound";
import useSegmentTracker from "hooks/useSegmentTracker";
import { ROUTE_PATHS } from "enums";
import { useSetupData } from "contexts/setupData";

import 'bootstrap/dist/css/bootstrap.min.css';
import "./App.css";
import UserRole from "views/UserRole/UserRole";
import SelectKubAccountType from "views/AddCloudWizard/Steps/SelectKubAccountType/SelectKubAccountType";
import UpsertMonitoringProvider from "views/AddCloudWizard/Steps/UpsertMonitoringProvider/UpsertMonitoringProvider";
import InviteTeam from "views/InviteTeam/InviteTeam";
import AdditionalCloudProducts from "views/AddCloudWizard/Steps/AdditionalCloudProducts/AdditionalCloudProducts";

const FULL_STORY_ORG = "o-19T4PX-na1";



I18n.putVocabularies(translations);

// update confirmation code text
I18n.putVocabulariesForLanguage('en', {
  "Your code is on the way. To log in, enter the code we emailed to": "Enter the verification code we sent to",
  "It may take a minute to arrive.": " ",
  "We Sent A Code": "Check your inbox",
  "We Emailed You": "Check your inbox",
  "Your code is on the way. To log in, enter the code we sent you. It may take a minute to arrive.": "Enter the verification code in order to proceed. If you did not receive an email from us, please try to resend the code or contact support@sedai.io",
  "User does not exist.":"Incorrect username or password."
})


const Analytics = () => {
  useSegmentTracker();
  const { user } = useAuthenticator((context) => [context.user]);
  const [setupDataState] = useSetupData();
  const { given_name, email } = user?.attributes || {};

  if (process.env.NODE_ENV === "production")
    return <FullStory orgId={FULL_STORY_ORG} userUid={setupDataState.userTrackingId} userDisplayName={given_name} userEmail={email} />;

  return null;
}

const AppContent = () => {
  const { route } = useAuthenticator(context => [context.route]);

  // redirect to the host added to the config if the app is loaded from different host
  useEffect(() => {
    const configAppDomain = new URL(process.env.REACT_APP_AUTH_REDIRECT_URL);
    if (window.location.hostname !== configAppDomain.hostname) {
      const url = new URL(window.location.href);
      url.hostname = configAppDomain.hostname;
      window.location.replace(url.href)
    }
  }, []);

  return (
    <>
      {route === 'idle' && <LoadingPage />}
      {route !== 'idle' && (
        <ErrorBoundary errorView={SomethingWentWrong}>
          <Router>
            <Analytics />
            <Routes>
              <Route path={ROUTE_PATHS.LOGIN} title="Login" element={<AuthenticationRoute children={<Login />} />} />
              <Route path={ROUTE_PATHS.SIGN_UP} title="Sign up" element={<AuthenticationRoute children={<Signup />} />} />
              <Route path={ROUTE_PATHS.HOME} element={<PrivateRoute children={<Home />} />} >
                <Route path={ROUTE_PATHS.NAME_YOUR_SEDAI} element={<PrivateRoute title="Name your Sedai" children={({ envUrl, onComplete }) => <CompanyForm envUrl={envUrl} onComplete={onComplete} />} />} />
                <Route path={ROUTE_PATHS.SELECT_YOUR_ROLE} element={<PrivateRoute title="Select your role" children={({ envDomain, onComplete }) => <UserRole envDomain={envDomain} onComplete={onComplete} />} />} />
                <Route path={ROUTE_PATHS.SETUP_WIZARD} element={<PrivateRoute children={({ envDomain, onComplete }) => <AddCloudWizard envDomain={envDomain} onComplete={onComplete} />} />} >
                  <Route path={ROUTE_PATHS.SETUP_WIZARD_SELECT_GOAL} element={<PrivateRoute title="Select Your Goal" children={(props) => <SelectGoal {...props} />} />} />
                  <Route path={ROUTE_PATHS.SETUP_WIZARD_SELECT_ACCOUNT_TYPE} element={<PrivateRoute title="Select Cloud" children={(props) => <SelectAccountType {...props} />} />} />
                  <Route path={ROUTE_PATHS.SETUP_WIZARD_SELECT_KUB_ACCOUNT_TYPE} element={<PrivateRoute title="Select Kub Service" children={(props) => <SelectKubAccountType {...props} />} />} />

                  <Route path={ROUTE_PATHS.SETUP_WIZARD_CONFIRM} element={<PrivateRoute title="Setup Complete | Confirm Account" children={(props) => <Confirm {...props} />} />} />
                  <Route path={ROUTE_PATHS.SETUP_WIZARD_CLOUD_FORMATION_CONNECT} element={<PrivateRoute title="Connect with CloudFormation" children={(props) => <AWSCloudFormation {...props} />} />} />
                  <Route path={ROUTE_PATHS.SETUP_WIZARD_AWS_NAME_CLOUD_ACCOUNT} element={<PrivateRoute title="Enter Cloud Details" children={(props) => <AccountName {...props} />} />} />
                  <Route path={ROUTE_PATHS.SETUP_WIZARD_KUB_NAME_CLOUD_ACCOUNT} element={<PrivateRoute title="Enter Cluster Details" children={(props) => <AccountName {...props} />} />} />
                  <Route path={ROUTE_PATHS.SETUP_WIZARD_CLOUD_FORMATION_ARN} element={<PrivateRoute title="Cloudformation ARN" children={(props) => <CloudFormationARN {...props} />} />} />
                  <Route path={ROUTE_PATHS.SETUP_WIZARD_AWS_CONNECT_ADDITIONAL_PRODUCTS} element={<PrivateRoute title="Select Additional AWS Products" children={(props) => <AdditionalCloudProducts {...props} />} />} />
                  <Route path={ROUTE_PATHS.SETUP_WIZARD_AWS_MANUAL_AUTONOMOUS} element={<PrivateRoute title="Copy Autonomous Policy" children={(props) => <AWSAutonomousPolicy {...props} />} />} />
                  <Route path={ROUTE_PATHS.SETUP_WIZARD_AWS_MANUAL_READ_ONLY} element={<PrivateRoute title="Copy Read-only Policy" children={(props) => <AWSReadOnlyPolicy {...props} />} />} />
                  <Route path={ROUTE_PATHS.SETUP_WIZARD_AWS_MANUAL_IAM_ROLE} element={<PrivateRoute title=" Create IAM Role" children={(props) => <AWSIAMRole {...props} />} />} />
                  <Route path={ROUTE_PATHS.SETUP_WIZARD_AWS_MANUAL_IAM_ROLE_ARN} element={<PrivateRoute title="Enter IAM Role ARN" children={(props) => <AWSARN {...props} />} />} />
                  <Route path={ROUTE_PATHS.SETUP_WIZARD_AWS_MANUAL_IAM_USER} element={<PrivateRoute title="Create IAM User" children={(props) => <AWSIAMUser {...props} />} />} />
                  <Route path={ROUTE_PATHS.SETUP_WIZARD_AWS_MANUAL_CREDENTIALS} element={<PrivateRoute title="Enter IAM User Credentials" children={(props) => <AWSCredentials {...props} />} />} />
                  <Route path={ROUTE_PATHS.SETUP_WIZARD_MONITORING_PROVIDERS} element={<PrivateRoute title="Select Monitoring Providers" children={(props) => <MonitoringProviders {...props} />} />} />
                  <Route path={ROUTE_PATHS.SETUP_WIZARD_UPSERT_MONITORING_PROVIDER} element={<PrivateRoute title="Connect Monitoring Provider" children={(props) => <UpsertMonitoringProvider {...props} />} />} />
                </Route>
                <Route path={ROUTE_PATHS.CREATING_ENVIRONMENT} element={<PrivateRoute title="Creating Environment" children={({ envUrl, envDomain }) => <EnvironmentCreationInProgress envDomain={envDomain} envUrl={envUrl} />} />} />
                <Route path={ROUTE_PATHS.ENVIRONMENT_CREATED} element={<PrivateRoute title="Your Sedai is Ready" children={({ envUrl }) => <EnvironmentCreated envUrl={envUrl} />} />} />
                <Route path={ROUTE_PATHS.ENVIRONMENT_CREATION_FAILED} element={<PrivateRoute title="Environment Creation Failed" children={() => <EnvironmentCreationFailed />} />} />
              </Route>
              <Route path={ROUTE_PATHS.INVITE_USER_AFTER_ONBOARDING} title="Invite Team" element={<PrivateRoute children={(props) => <InviteTeam {...props} />} />} />
              <Route path="*" element={<NotFound />} title="Page Not Found" />
            </Routes>
          </Router>
        </ErrorBoundary>
      )}
    </>
  )
};


const App = () => {
  return (
    <AppProviders>
      <AppContent />
    </AppProviders>
  )
};

export default App;