import { createStylesWithTheme } from 'utils/functions';

const styles = {
    iconBtn: {
        height: ({ props }) => props.size,
        minWidth: ({ props }) => props.size,
        position: "relative",
        "&>*": {
            height: ({ props }) => Math.min(props.size, props.iconSize),
            width: ({ props }) => Math.min(props.size, props.iconSize),
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
        }
    }
};

export default createStylesWithTheme(styles);