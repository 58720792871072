import { createStylesWithTheme } from 'utils/functions';

// Create your Styles. Remember, since React-JSS uses the default preset,
// most plugins are available without further configuration needed.
const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignSelf: "center",
    flex: 1,
  },

  title: {
    textAlign: "center",
    marginBottom: 24
  },
};

export default createStylesWithTheme(styles);