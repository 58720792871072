import { useState } from "react";
import { useToasts } from "react-toast-notifications";
import cn from "classnames";
import { FiCheck } from "react-icons/fi";
import Bugsnag from "@bugsnag/js";

import Button from "components/Button/Button";
import Icon from "components/Icon/Icon";
import Card from "components/Card/Card";
import Typography from "components/Typography/Typography";

import WizardLayout from "views/AddCloudWizard/WizardLayout/WizardLayout";
import { useWizardData, wizardDataActionsTypes } from "contexts/WizardData";
import DefaultStepFooterContent from "views/common/WizardFooter/DefaultStepFooterContent/DefaultStepFooterContent";
import { supportedAWSManagedServices } from "views/AddCloudWizard/AddCloudWizard.utils";

import useStyles from './AdditionalCloudProducts.styles';

const AdditionalCloudProducts = ({ wizardProps, moveToNextStep, isCurrentStepValid, currentStep, email, envDomain }) => {
    const cls = useStyles();
    const { addToast } = useToasts();
    const [wizardData, wizardDataDispatch, getWizardData] = useWizardData();
    const [isLoading, setIsLoading] = useState(false);
    const filteredServices = supportedAWSManagedServices.filter(service => {
        // Filter out the service that is already selected in the previous step
        return !wizardData.resourceDetails.resourceTypes.some(type => service.key === type);
    });

    const [allowedAWSServices, setAllowedAWSServices] = useState(filteredServices.map(service => service.key));
    const toggleService = (service) => {
        setAllowedAWSServices((prevServices) => {
            if (prevServices.includes(service)) {
                // Remove service if it exists in the array
                return prevServices.filter((prevService) => prevService !== service);
            } else {
                // Add service if it doesn't exist in the array
                return [...prevServices, service];
            }
        });
    };

    return (
        <>
            <WizardLayout {...wizardProps}>
                <div className={cls.container}>
                    <div className={cls.header}>
                        <Typography variant="h2" color="textPrimary">Select additional cloud products</Typography>
                        <Typography variant="body2" color="textTernary">Select other cloud products you might connect to this account in the future so Sedai will have permission to manage them.</Typography>
                    </div>
                    <div className={cls.content}>
                        {filteredServices.map((service) => (
                            <Button variant="content" onPress={() => { toggleService(service.key) }}>
                                <Card className={cn(cls.awsServiceCard, allowedAWSServices.includes(service.key) && cls.allowedServiceButton)}>
                                    <Typography variant="body2" color="textPrimary"> <Icon name={service.icon} />{service.label}</Typography>
                                    {allowedAWSServices.includes(service.key) && <Typography color="primary" variant="body2"><FiCheck /></Typography>}
                                </Card>
                            </Button>
                        ))}
                    </div>
                    <div className={cls.header}>
                        <Typography variant="body3" color="textTernary">Please note that Sedai will not have permission to optimize cloud products if you elect read-only access in the following screens.</Typography>
                    </div>
                    <div>
                        <DefaultStepFooterContent
                            moveNextIsLoading={isLoading}
                            moveToNextStep={() => {
                                if (isCurrentStepValid) {
                                    setIsLoading(true);
                                    // Add the previously selected service to the allowed services
                                    allowedAWSServices.push(wizardData.resourceDetails.resourceTypes?.[0]);

                                    const payload = allowedAWSServices.map(service => ['AwsManagedService', service]);
                                    wizardDataDispatch({ type: wizardDataActionsTypes.SET_ADDITIONAL_CLOUD_PRODUCTS, payload });

                                    currentStep.submit(getWizardData(), { envDomain, email }).then(() => {
                                        setIsLoading(false);
                                        moveToNextStep();
                                    }).catch((e) => {
                                        Bugsnag.notify(e);
                                        addToast("Failed to submit data", {
                                            appearance: 'error',
                                            autoDismiss: true,
                                        });
                                    })
                                }
                            }}
                            disableNextBtn={!isCurrentStepValid}
                        />
                    </div>
                </div>
            </WizardLayout>
        </>
    )
};

export default AdditionalCloudProducts;