import React from "react";
import cn from 'classnames';
import { useCheckbox } from "@react-aria/checkbox";
import useStyles from './Checkbox.styles';
import { VisuallyHidden } from "@react-aria/visually-hidden";
import { useToggleState } from '@react-stately/toggle';
import Typography from "components/Typography/Typography";
import { useObjectRef } from '@react-aria/utils';
import { motion } from "framer-motion";

const svgVariants = {
    start: {
        opacity: 0,
        pathLength: 0,
    },
    finished: {
        opacity: 1,
        pathLength: 1,
        transition: {
            pathLength: { type: "spring", duration: 0.3 },
            opacity: { duration: 0.1 }
        }
    }
};

const Checkbox = React.forwardRef((props, forwardedRef) => {
    const { value, disabled, label, size = "large", renderLabel, ariaLabel, isIndeterminate, checkboxClassName, className, labelColor = "textPrimary", alignTop } = props;
    let state = useToggleState({ isSelected: value, ...props });
    let ref = useObjectRef(forwardedRef);
    let { inputProps } = useCheckbox({ isDisabled: disabled, "aria-label": ariaLabel || (typeof label === "string" ? label : undefined), ...props }, state, ref)
    const cls = useStyles();
    const checkLabel = typeof renderLabel === 'function' ? renderLabel() : (label ? (<Typography className={size && cls[`${size}SizeLabel`]} color={labelColor}>{label}</Typography>) : null);

    return (
        <label className={cn(cls.checkboxContainer, disabled && cls.disabledContainer, className, alignTop && cls.checkboxDesc)}>
            <VisuallyHidden className={cls.visuallyHidden}>
                <input {...inputProps} ref={ref} />
            </VisuallyHidden>
            <div className={cn(cls.checkbox, size && cls[`${size}Size`], (state.isSelected || isIndeterminate) && cls.isSelected, disabled && cls.isDisabled, checkLabel && cls.withLabel, checkboxClassName)} aria-hidden="true">
                {(state.isSelected && !isIndeterminate) && (
                    <svg width="20" height="20" viewBox="0 0 16 16" x="50%" y="50%" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <motion.path
                            d="M2.6665,8L5.99984,11.3333L13.3332,4"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            variants={svgVariants}
                            initial="start"
                            animate="finished"
                        />
                    </svg>
                )}
                {(isIndeterminate) && (
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.3335 8H12.6668" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                )}
            </div>
            {checkLabel}
        </label>

    )
});

export default Checkbox;