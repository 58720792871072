import React from "react";
import cn from "classnames";
import useStyles from './CornerLines.styles';

const SIZES = {
  SMALL: "small",
  LARGE: "large",
}

export default function CornerLines({ size = SIZES.SMALL, className }) {
  const cls = useStyles();

  if (size === SIZES.LARGE)
    return (
      <div className={className}>
        <div className={cn(cls.bottomRightCircle, cls.bottomRightCircleLarge1)}>
          <svg viewBox="0 0 924 859" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.98112 1081.89C208.91 425.845 795.165 671.713 1068.93 185.948C1096.46 137.105 1147.55 68.0511 1205.92 3.71438" stroke="#7376FF" strokeWidth="6" strokeLinecap="round" />
          </svg>

        </div>
        <div className={cn(cls.bottomRightCircle, cls.bottomRightCircleLarge2)}>
          <svg viewBox="0 0 880 738" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1285.86 3.47908C1200.83 15.7093 1117.62 -2.85912 916.725 75.464C660.55 175.34 359.881 516.248 253.407 735.497C201.534 842.314 96.2963 956.256 3.96558 1048.4" stroke="#FE7EC9" strokeWidth="6" strokeLinecap="round" />
          </svg>
        </div>
      </div>
    );
    
  return (
    <div className={className}>
      <div className={cn(cls.bottomRightCircle, cls.bottomRightCircle1)}>
        <svg viewBox="0 0 564 717" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M204,880 C313,511 651,346 932,210" stroke={"#7373FF"} strokeWidth="6" strokeLinecap="round" />
          <path d="M204,880 C313,511 651,346 932,210" />
        </svg>
      </div>
      <div className={cn(cls.bottomRightCircle, cls.bottomRightCircle2)}>
        <svg viewBox="0 0 564 717" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M204,880 C313,511 651,346 932,210" stroke={"#FE7EC9"} strokeWidth="6" strokeLinecap="round" />
          <path d="M204,880 C313,511 651,346 932,210" />
        </svg>
      </div>
    </div>
  );
};

