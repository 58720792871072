import React from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import Icon from "components/Icon/Icon";
import Typography from "components/Typography/Typography";
import Button from "components/Button/Button";
import sedaiAvatarSidePointing from 'assets/img/sedaiAvatarSidePointing.png';
import useStyles from './EnvironmentCreated.styles';
import { FiArrowRight } from "react-icons/fi";


const EnvironmentCreated = ({ envUrl }) => {
    const cls = useStyles();
    const { user } = useAuthenticator((context) => [context.user]);
    return (
        <div className={cls.container}>
            <Icon name="logoWithNameHorizonal" className={cls.headerLogoHorizontal} />

            <div className={cls.textAndAvatartContainer}>
                <div className={cls.textContainer}>
                    <Typography variant="h2" color="textPrimary">Say hello to autonomous ✨</Typography>
                    <Typography className={cls.subTitle} variant="body2" color="textTernary">
                        Hey {user?.attributes?.given_name}, your account is ready! <br />
                    </Typography>
                    <Button variant="filled" color="primary" fitContent component="a" href={envUrl}>Go to my Sedai <FiArrowRight /></Button>
                </div>
                <img className={cls.avatar} src={sedaiAvatarSidePointing} alt="Sedai's Excited Avatar" />
            </div>
        </div>
    )
};

export default EnvironmentCreated;