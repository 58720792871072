import { createStylesWithTheme } from 'utils/functions';
import tinycolor from "tinycolor2";
import COLORS from 'themes/colors';

const styles = (theme) => ({
    container: {
        display: "inline-flex",
        alignItems: "center",
        flexWrap: "wrap",
        padding: "5px 7px",
        border: `1px solid ${theme.palette.border.card}`,
        background: theme.type === "dark" ? COLORS.DARK_NEUTRALS[800] : COLORS.LIGHT_NEUTRALS[0],
        borderRadius: 4,
        "&:focus-within": {
            outline: `1px solid ${theme.palette.primary.main}`,
            boxShadow: `0px 0px 0px 4px ${theme.type === "dark" ? COLORS.PRIMARY[1000] : COLORS.PRIMARY[100]}`
        },
        width: "100%"
    },
    chip: {
        margin: 5,
        padding: "4px 6px",
        borderRadius: 2,
        background:  COLORS.PRIMARY[1200],
    },
    closeIcon: {
        fontSize: 18,
        color:  theme.palette.text.secondary,
        marginLeft: 7,
        cursor: "pointer",
        "&:hover": {
            opacity: 0.7
        }
    },
    addBtn: {
        marginLeft: 5,
        background:  theme.type === "dark" ? tinycolor(theme.palette.primary.main).setAlpha(.1) : theme.palette.primary.main,
    },
    buttonAndInputContainer: {
        display: "flex",
        alignItems: "center",
        margin: 5,
        minWidth: 100,
        overflow: "hidden",
        flex: 1,
    },
    input:  ({
        ...(theme.typography.body1),
        background: "transparent",
        height: "fit-content",
        flex: 1,
        width: 0,
        border: 0,
        outline: 0,
        color: theme.palette.text.primary,
        "&:-webkit-autofill": {
            backgroundColor: "transparent",
        },
        "&:focus": {
            background: "transparent",
        },
        "&::placeholder": { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: theme.palette.text.secondary,
            opacity: 1, /* Firefox */
        },
        "&:disabled": {
            background: "transparent",
        },
        "&:read-only": {
            background: "transparent",
        }
    }),
})

export default createStylesWithTheme(styles);