import { useState } from "react";
import { get } from "lodash";
import { useToasts } from "react-toast-notifications";
import Typography from "components/Typography/Typography";

import WizardLayout from "views/AddCloudWizard/WizardLayout/WizardLayout";

import DefaultStepFooterContent from "views/common/WizardFooter/DefaultStepFooterContent/DefaultStepFooterContent";
import { CP_CREDETIALS_BASE_PATH } from "utils/cloudProviders";
import TextField from "components/TextField/TextField";
import { useWizardData, wizardDataActionsTypes } from "contexts/WizardData";
import useStyles from './AWSARN.styles';
import Bugsnag from "@bugsnag/js";


const AWSARN = ({ wizardProps, moveToNextStep, envDomain, email, currentStep, isCurrentStepValid }) => {
    const cls = useStyles();
    const [wizardData, wizardDataDispatch] = useWizardData();
    const { addToast } = useToasts();
    const [isLoading, setIsLoading] = useState(false);

    return (
        <>
            <WizardLayout {...wizardProps}>
                <div className={cls.container}>
                    <div className={cls.header}>
                        <Typography variant="h2" color="textPrimary">Enter Role ARN</Typography>
                        <Typography variant="body3" color="textSecondary">In addition to connecting to your resources, Sedai will also use this Role ARN to connect to CloudWatch and pull monitoring data.</Typography>
                    </div>
                    <TextField autoFocus value={get(wizardData, `uiStateDetails.data.sedaiAccount.${CP_CREDETIALS_BASE_PATH}.role`)} className={cls.arnInput} onChange={(e) => {
                        wizardDataDispatch({ type: wizardDataActionsTypes.SET_AWS_ROLE_ARN, payload: e.target.value.trim() })
                    }} />
                    <div>
                        <DefaultStepFooterContent
                            moveNextIsLoading={isLoading}
                            moveToNextStep={() => {
                                if (isCurrentStepValid) {
                                    setIsLoading(true);
                                    currentStep.submit(wizardData, { envDomain, email }).then(() => {
                                        setIsLoading(false);
                                        moveToNextStep();
                                    }).catch((e) => {
                                        Bugsnag.notify(e);
                                        setIsLoading(false);
                                        addToast("Failed to submit ARN", {
                                            appearance: 'error',
                                            autoDismiss: true,
                                        });
                                    })

                                }
                            }}
                            disableNextBtn={!isCurrentStepValid}
                        />
                    </div>
                </div>
            </WizardLayout>

        </>
    )
};

export default AWSARN;