import { createStylesWithTheme } from 'utils/functions';

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    maxHeight: "100dvh",
    padding: "44px 24px 32px 24px",
    overflow: "auto",

  },
  headerLogoHorizontal: {
    minHeight: 24,
    height: 24,
    marginBottom: 64,
  },
  subTitle: {
    marginTop: 24,
    maxWidth:422,
  },
  avatar: {
    maxWidth: 267,
    marginBottom: 8,
  },
};

export default createStylesWithTheme(styles);