import COLORS from 'themes/colors';
import { createStylesWithTheme } from 'utils/functions';

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 583,
    alignSelf: "center",
    width: "100%",
  },
  header: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign:"center",
    gap: "8px",
    marginBottom: 24,
  },
  inputWrapper: {
    marginBottom: 24,
  },
  inputLabel: {
    marginBottom: 8,
  },
};

export default createStylesWithTheme(styles);