import { useEffect } from "react";
import cn from "classnames";
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import Icon from "components/Icon/Icon";
import { ROUTE_PATHS } from "enums";
import Button from "components/Button/Button";
import '@aws-amplify/ui-react/styles.css';
import styles from './Login.module.css';
import Typography from "components/Typography/Typography";
import CornerLines from "views/common/CornerLines/CornerLines";



const components = {
    Header() {
        const { route } = useAuthenticator((context) => [context.route]);
        if (route === 'idle' || route === 'setup') return null;
        return (
            <div layout className={styles.headerLogoContainer}>
                <Icon name="logoWithNameVertical" className={styles.headerLogoVertical} />
                <Icon name="logoWithNameHorizonal" className={styles.headerLogoHorizontal} />
            </div>
        );
    },
    SignIn: {
        Header() {
            return (
                <div>
                    <Typography component="h2" variant="h2" color="textPrimary" className={styles.createAccountHeader}>
                        Welcome back
                    </Typography>
                </div >
            );
        },
        Footer() {
            const { toResetPassword } = useAuthenticator((context) => [context.toResetPassword]);
            return (
                <div className={styles.signInFooter}>
                    <Typography className={styles.forgotPasswordSection} variant="body3" color="textTernary"><Button variant="link" color="primary" onPress={() => toResetPassword()} >Forgot Password?</Button></Typography>
                    <Typography variant="body3" color="textTernary">New here? <Button component="a" variant="link" color="primary" href={ROUTE_PATHS.SIGN_UP} >Create Account</Button></Typography>
                </div>
            );
        },
    },
};

const App = (props) => {
    const { route, toSignIn } = useAuthenticator((context) => [context.route, context.toSignIn]);

    // This useEffect is to make sure that the signup form is shown. if the navigation was made from the signin page without a full page load (SPA link navigation) the state of auth remains the same and the Authenticator component
    // shows the signUp form. so in order to avoid that case this line is needed.
    useEffect(() => {
        if (route === "signUp") toSignIn()
    }, [route])

    return (
        <div className={styles.container}>
            <div className={styles.wrapper}>
                <CornerLines className={styles.cornerLines} size="small" />

                <div className={styles.content}>
                    <Authenticator socialProviders={['google']} loginMechanisms={['email']} components={components} hideSignUp />
                </div>
            </div>
        </div>
    )
};

export default App;