import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { PKG_VERSION } from './constants';

 export default Bugsnag.start({
    apiKey: 'a38bd618997125f84a40c9a387dfe890',
    enabledReleaseStages: [ 'production', 'staging' ],
    plugins: [new BugsnagPluginReact()],
    appVersion: PKG_VERSION,
  })
