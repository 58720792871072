import axios from "../axios";

export function buildCompanyEnvironment({
    username,
    company,
    userPoolId,
    email,
    source,
    token,
    envDomain,
}) {
    return axios.post(`build`, {
        username,
        company,
        userPoolId,
        email,
        source,
        token,
        envDomain,
    }).then(({ data }) => data);
}


export function submitGoal(
    email,
    envDomain,
    currentState,
    goal,
) {
    return axios.post(`build`, {
        email,
        envDomain,
        companyDetails: {
            goal
        },
        currentState
    }).then(({ data }) => data);
}


export function submitFlowType(
    email,
    envDomain,
    currentState,
    resourceDetails,
    accountDetails,
    uiStateDetails,
) {
    return axios.post(`build`, {
        email,
        envDomain,
        resourceDetails,
        accountDetails,
        uiStateDetails,
        currentState
    }).then(({ data }) => data);
}

export function submitKubType(
    email,
    envDomain,
    currentState,
    kubeProviders,
    uiStateDetails,
) {
    return axios.post(`build`, {
        email,
        envDomain,
        resourceDetails: {
            kubeProviders,
        },
        uiStateDetails,
        currentState
    }).then(({ data }) => data);
}

export function submitMonitoringProviderType(
    email,
    envDomain,
    currentState,
    monitoringProviders,
) {
    return axios.post(`build`, {
        email,
        envDomain,
        resourceDetails: {
            monitoringProviders
        },
        currentState
    }).then(({ data }) => data);
}


export function submitAccountDetails(
    email,
    envDomain,
    currentState,
    uiStateDetails,
    accountDetails,
) {
    return axios.post(`build`, {
        email,
        envDomain,
        uiStateDetails,
        accountDetails,
        currentState,
    }).then(({ data }) => data);
}


export function submitUIState(
    email,
    envDomain,
    currentState,
    uiStateDetails,
) {
    return axios.post(`build`, {
        email,
        envDomain,
        uiStateDetails,
        currentState
    }).then(({ data }) => data);
}

