const CLUSTER_PROVIDERS = {
    SELF_MANAGED: "SELF_MANAGED",
    AWS: "AWS",
    AZURE: "AZURE",
    GCP: "GCP",
    OPEN_SHIFT: "OPEN_SHIFT",
    ORACLE: "ORACLE",
    PLATFORM_9: "PLATFORM_9",
    VMWARE_TANZU: "VMWARE_TANZU",
    RANCHER: "RANCHER",
    IBM: "IBM",
    DIGITAL_OCEAN: "DIGITAL_OCEAN",
    ALIBABA: "ALIBABA"

};

export default CLUSTER_PROVIDERS;