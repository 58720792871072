
const LOGIN = '/login';
const SIGN_UP = '/signup';

const HOME = '/';
const NAME_YOUR_SEDAI = `${HOME}name-your-sedai`;
const SELECT_YOUR_ROLE = `${HOME}select-your-role`;

const SETUP_WIZARD = `${HOME}wizard`;
const SETUP_WIZARD_SELECT_GOAL = `${SETUP_WIZARD}/choose-goal`;
const SETUP_WIZARD_SELECT_KUB_ACCOUNT_TYPE = `${SETUP_WIZARD}/select-kubernetes-service`;

const SETUP_WIZARD_AWS_ACCOUNT_NAME_ARN = `${SETUP_WIZARD}/aws-name-arn`;


const SETUP_WIZARD_SELECT_ACCOUNT_TYPE = `${SETUP_WIZARD}/select-resource-type`;
const SETUP_WIZARD_AWS_NAME_CLOUD_ACCOUNT = `${SETUP_WIZARD}/enter-cloud-details`;
const SETUP_WIZARD_KUB_NAME_CLOUD_ACCOUNT = `${SETUP_WIZARD}/enter-cluster-details`;

const SETUP_WIZARD_AWS_CONNECT_ADDITIONAL_PRODUCTS = `${SETUP_WIZARD}/additional-products`;

const SETUP_WIZARD_CLOUD_FORMATION_CONNECT = `${SETUP_WIZARD}/connect-with-cloudformation`;
const SETUP_WIZARD_CLOUD_FORMATION_ARN = `${SETUP_WIZARD}/cloudformation-enter-arn`;
const SETUP_WIZARD_AWS_MANUAL_AUTONOMOUS = `${SETUP_WIZARD}/autonomous`;
const SETUP_WIZARD_AWS_MANUAL_READ_ONLY = `${SETUP_WIZARD}/read-only`;
const SETUP_WIZARD_AWS_MANUAL_IAM_ROLE = `${SETUP_WIZARD}/create-iam-role`;
const SETUP_WIZARD_AWS_MANUAL_IAM_ROLE_ARN = `${SETUP_WIZARD}/enter-iam-role-arn`;
const SETUP_WIZARD_AWS_MANUAL_IAM_USER = `${SETUP_WIZARD}/create-iam-user`;
const SETUP_WIZARD_AWS_MANUAL_CREDENTIALS = `${SETUP_WIZARD}/enter-iam-user-credentials`;
const SETUP_WIZARD_MONITORING_PROVIDERS = `${SETUP_WIZARD}/select-monitoring-provider`;
const SETUP_WIZARD_UPSERT_MONITORING_PROVIDER = `${SETUP_WIZARD}/configure-monitoring-provider`;

const SETUP_WIZARD_CONFIRM = `${SETUP_WIZARD}/setup-complete`;

const CREATING_ENVIRONMENT = `${HOME}creating-your-environment`;
const ENVIRONMENT_CREATED = `${HOME}ready`;
const ENVIRONMENT_CREATION_FAILED = `${HOME}env-failed`;

const INVITE_USER_AFTER_ONBOARDING = '/invite-team';

const ROUTE_PATHS = {
    LOGIN,
    SIGN_UP,
    SELECT_YOUR_ROLE,
    NAME_YOUR_SEDAI,
    SETUP_WIZARD,
    SETUP_WIZARD_SELECT_GOAL,
    SETUP_WIZARD_SELECT_KUB_ACCOUNT_TYPE,
    SETUP_WIZARD_AWS_ACCOUNT_NAME_ARN,

    SETUP_WIZARD_AWS_CONNECT_ADDITIONAL_PRODUCTS,

    SETUP_WIZARD_SELECT_ACCOUNT_TYPE,
    SETUP_WIZARD_AWS_NAME_CLOUD_ACCOUNT,
    SETUP_WIZARD_KUB_NAME_CLOUD_ACCOUNT,
    SETUP_WIZARD_CLOUD_FORMATION_CONNECT,
    SETUP_WIZARD_CLOUD_FORMATION_ARN,
    SETUP_WIZARD_AWS_MANUAL_AUTONOMOUS,
    SETUP_WIZARD_AWS_MANUAL_READ_ONLY,
    SETUP_WIZARD_AWS_MANUAL_IAM_ROLE,
    SETUP_WIZARD_AWS_MANUAL_IAM_ROLE_ARN,
    SETUP_WIZARD_AWS_MANUAL_IAM_USER,
    SETUP_WIZARD_AWS_MANUAL_CREDENTIALS,
    SETUP_WIZARD_MONITORING_PROVIDERS,
    SETUP_WIZARD_UPSERT_MONITORING_PROVIDER,
    SETUP_WIZARD_CONFIRM,

    CREATING_ENVIRONMENT,
    ENVIRONMENT_CREATED,
    ENVIRONMENT_CREATION_FAILED,

    HOME,

    INVITE_USER_AFTER_ONBOARDING,

};

export default ROUTE_PATHS;