import React from "react";
import cn from "classnames";
import useStyles from './Card.styles';

export default React.forwardRef(({ children, component = "div", noPadding, className, ...rest }, ref) => {
    const cls = useStyles();
    const Component = component;
    return (
        <Component {...rest}  ref={ref} className={cn(cls.container, !noPadding && cls.padding, className)}>
            {children}
        </Component >
    );
})


