import React from "react";
import Typography from 'components/Typography/Typography';
import Bugsnag from '@bugsnag/js';


const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

const KNOWN_ERROR_TYPES = {
    CHUNK_LOAD_ERROR: "ChunkLoadError",
}

const ErrorView = ({ error }) => {
    const errorType = error.name === KNOWN_ERROR_TYPES.CHUNK_LOAD_ERROR ? KNOWN_ERROR_TYPES.CHUNK_LOAD_ERROR : undefined;

    return (
        <div style={{ display: "flex", flex: 1, alignItems: "center", justifyContent: "center" }}>
            {errorType === KNOWN_ERROR_TYPES.CHUNK_LOAD_ERROR && <Typography variant="mono2" color="textPrimary">Failed to load content, try refreshing the page.</Typography>}
            {!errorType && <Typography variant="h6" color="error">Something went wrong</Typography>}
        </div>
    )
}

export default (props) => {
    return (
        <ErrorBoundary FallbackComponent={props.errorView || ErrorView}>
            {props.children}
        </ErrorBoundary>
    )
}