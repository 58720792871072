import COLORS from 'themes/colors';
import { createStylesWithTheme } from 'utils/functions';

const styles = (theme) => ({
  container: {
    display: "flex",
    position: "relative",
    flex: "1 1 auto",
    height: 0,

  },
  sideNavigation: {
    display: "flex",
    flexDirection: "column",
    width: 427,
    background: theme.palette.background.sideBar,
    padding: 32,
    position: "sticky",
    top: 0,
    overflow: "auto",
  },
  headerLogo: {
    marginBottom: 130,
    "& > svg": {
      height: 40,
    }
  },
  sideNavTitle: {
    marginBottom: 8,
  },
  stepsContainer: {
    margin: "32px 0"
  },
  stepWrapper: {
    position: "relative"
  },
  clickableStepLabel: {
    color: theme.palette.text.primary,
    "&:hover": {
      color: theme.palette.primary.main,
      textDecoration: "none",
    }
  },
  stepLine: {
    width: "1px",
    position: "absolute",
    top: 22,
    left: 11.5,
    bottom: -2,
  },
  iconsContainer: {
    position: "relative",
    display: "flex",
  },
  checkIcon: {
    position: "absolute",
    top: 4,
    left: 3,
  },
  stepContainer: {
    display: "grid",
    gridTemplateColumns: "max-content 1fr",
    gridGap: 16,
    paddingBottom: 24,
  },
  labelsContainer: {
    display: "flex",
    flexDirection: "column",
    "&>*:not(:last-child)": {
      marginBottom: 4,
    }
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    padding: 72,
    paddingTop: 198,
    paddingBottom: 32,
    overflowY: "auto",
    alignItems: "center",
    background: COLORS.DARK_NEUTRALS[900],
  },
  content: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: 885,
  },
  helpText: {
    marginTop: "auto",
    paddingTop: 32,
  },
  helpTextTitle: {
    display: "grid",
    alignItems: "center",
    gridTemplateColumns: "max-content max-content",
    gridGap: 4,
    marginBottom: 4,
  },
  buttonWithIcon: {
    display: "grid",
    alignItems: "center",
    gap: "8px",
    gridTemplateColumns: "max-content max-content",
    justifyContent: "start",
    "&:disabled": {
      background: ["transparent", "!important"]
    }
  },
  hideCheck: {
    opacity: 0,
  },
});

export default createStylesWithTheme(styles);