import COLORS from 'themes/colors';
import { createStylesWithTheme } from 'utils/functions';

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    flex: "1 1 auto",
    height: 0,
    padding: "44px 24px 32px 24px",
    overflow: "auto",
  },
  backArrowWrapper: {
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    position: "fixed",
    top: 32,
    left: 24,
  },
  headerLogoHorizontal: {
    minHeight: 24,
    height: 24,
    marginBottom: 60,
  },
  completeLabel: {
    display: "grid",
    gridTemplateColumns: "max-content max-content",
    gridGap: 4,
    marginBottom: 8,
    alignItems: "center",
  },
  avatar: {
    maxWidth: 267,
    marginBottom: 20,
  },
  accountsText: {
    marginBottom: 20,
    display: "flex",
    flexDirection: "row",
    gap: "4px",
    maxWidth: 600,
  },
  launchBtn: {
    marginTop: 20,
    marginBottom: 20,
  },
  bottomDesc: {
    maxWidth: 320
  },
};

export default createStylesWithTheme(styles);