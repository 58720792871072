import React from 'react';
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query';
import { ThemeProvider } from 'react-jss';
import { ToastProvider } from 'react-toast-notifications';
import { Authenticator } from '@aws-amplify/ui-react';
import darkTheme from './themes/darkTheme';
import { SetupDataProvider } from 'contexts/setupData';
import { WizardDataProvider } from 'contexts/WizardData';

const queryConfig = { defaultOptions: { queries: { refetchOnWindowFocus: false, retry: false } } };
const queryClient = new QueryClient(queryConfig)


export default function AppProviders({ children }) {
  return (
    <ThemeProvider theme={darkTheme}>
      <ToastProvider placement="bottom-right">
        <Authenticator.Provider>
          <SetupDataProvider>
            <WizardDataProvider>
              <QueryClientProvider client={queryClient}>
                {children}
              </QueryClientProvider>
            </WizardDataProvider>
          </SetupDataProvider>
        </Authenticator.Provider>
      </ToastProvider>
    </ThemeProvider>
  );
};

