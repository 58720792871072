import { useMemo } from "react";
import { Formik } from "formik";
import { Spinner } from "react-bootstrap";
import { useQuery, useQueryClient } from "react-query";
import { useToasts } from 'react-toast-notifications';
import { TextField, useAuthenticator } from '@aws-amplify/ui-react';
import Icon from "components/Icon/Icon";
import { setupDataActionsTypes, useSetupData } from "contexts/setupData";
import { checkCompanyIsNotTaken } from "utils/api/check";
import { buildCompanyEnvironment } from "utils/api/build";

import Typography from "components/Typography/Typography";
import Button from "components/Button/Button";

import { QUERY_KEYS } from "enums";
import PageContentLoading from "components/PageContentLoading/PageContentLoading";
import '@aws-amplify/ui-react/styles.css';
import styles from './CompanyForm.module.css';
import { getEnrichForm } from "utils/api/enrichForm";
import CornerLines from "views/common/CornerLines/CornerLines";
import Bugsnag from "@bugsnag/js";

const urlSuffix = ".sedai.app";
const nameToURL = (name = "") => name.replace(/([^a-zA-Z0-9-_])|(^[0-9]+)/g, "").toLowerCase()
const CompanyForm = (props) => {
    const { onComplete, envDomain } = props;
    const queryClient = useQueryClient()
    const [setupDataState, setupDataDispatch] = useSetupData();
    const { user } = useAuthenticator((context) => [context.user]);
    const { addToast } = useToasts();
    const { data: enrichData, status: enrichStatus } = useQuery([QUERY_KEYS.GET_ENRICH_FORM, user?.attributes?.email], ({ queryKey: [_, email] }) => getEnrichForm(email), { enabled: !!user?.attributes?.email });

    const initialValues = useMemo(() => {
        return {
            companyName: enrichData?.company?.name || '',
            urlPrefix: enrichData?.company?.name ? nameToURL(enrichData.company.name) : ''
        };
    }, [enrichData]);

    return (
        <div className={styles.container}>
            <div className={styles.cicleBgContainer}>
                <div className={styles.circleBg} />
            </div>
            <CornerLines className={styles.cornerLines} />
            <div>
                <Icon name="logoWithNameVertical" className={styles.headerLogoVertical} />
                <Icon name="logoWithNameHorizonal" className={styles.headerLogoHorizontal} />
            </div>

            <Typography component="h2" variant="h2" color="textPrimary" className={styles.title}>Name your Sedai space</Typography>
            <Typography variant="body3" color="textSecondary" className={styles.subtitle}>
                Choose wisely, you will not be able to change this later.
            </Typography>
            {enrichStatus === "loading" && <PageContentLoading />}
            {enrichStatus !== "loading" && (
                <Formik
                    initialValues={initialValues}
                    validateOnBlur={false}
                    validateOnChange={false}
                    validate={values => {
                        const errors = {};
                        // add required validation
                        if (!values.companyName) errors.companyName = "Company name is required";
                        else if (values.companyName?.length > 35) errors.companyName = "Company name too long";

                        if (!values.urlPrefix) errors.urlPrefix = "Url is required"
                        else if (values.urlPrefix > 35) errors.urlPrefix = "Url too long"
                        else if (!/^[a-zA-Z0-9][a-zA-Z0-9-_]*$/.test(values.urlPrefix)) {
                            errors.urlPrefix = "Invalid URL"
                        }
                        //if all values are empty ignore api validation or there are some errors in the form 
                        if (Object.values(values).filter((val) => !val).length > 0 || Object.values(errors).filter(Boolean).length) return errors;
                        const payload = {
                            "company": values.companyName ? values.companyName : undefined,
                            "envDomain": values.urlPrefix
                        }
                        return checkCompanyIsNotTaken(payload).then((res) => {
                            if (res.companyRegistered) errors.companyName = "Company name already taken";
                            if (res.envDomainRegistered) errors.urlPrefix = "URL already taken";
                            return errors;
                        })
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                        try {
                            await buildCompanyEnvironment({
                                "username": user.username,
                                "company": values.companyName,
                                "userPoolId": user.pool.userPoolId,
                                "email": user.attributes.email,
                                "envDomain": values.urlPrefix,
                                "source": user.attributes["custom:source"],
                                "token": user.attributes["custom:token"],
                            })
                            queryClient.invalidateQueries(QUERY_KEYS.CHECK_ENVIRONMENT_CREATION)
                            if (typeof onComplete === "function") {
                                setupDataDispatch({ type: setupDataActionsTypes.SET_ENV_DOMAIN, payload: envDomain });
                                onComplete();
                            }

                        } catch (e) {
                            Bugsnag.notify(e);
                            addToast("Failed to create account", {
                                appearance: 'error',
                                autoDismiss: true,
                            })
                        }


                        setSubmitting(false);

                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        isValidating,
                        setFieldValue,
                        isValid,
                        /* and other goodies */
                    }) => {
                        const isLoading = isSubmitting || isValidating
                        return (
                            <form className={styles.formContainer} onSubmit={handleSubmit}>
                                <TextField
                                    name="companyName"
                                    label={(<Typography variant="body2" color="textSecondary">Company Name</Typography>)}
                                    isRequired
                                    onChange={(e) => {
                                        if (!touched.urlPrefix) {
                                            setFieldValue("urlPrefix", nameToURL(e.target.value))
                                        }
                                        handleChange(e);
                                    }}
                                    value={values.companyName}
                                    hasError={!isValidating && !!touched.companyName && !!errors.companyName}
                                    errorMessage={errors.companyName}
                                    autoComplete="off"
                                />
                                <TextField
                                    name="urlPrefix"
                                    label={(<Typography variant="body2" color="textSecondary">Your Sedai URL</Typography>)}
                                    isRequired
                                    className={styles.urlInput}
                                    innerEndComponent={<p className={styles.inputInnerAddonText}>{urlSuffix}</p>}
                                    onChange={(e) => {
                                        e.target.value = e.target.value.toLowerCase();
                                        handleChange(e);
                                    }}
                                    onBlur={handleBlur}
                                    value={values.urlPrefix}
                                    hasError={!isValidating && !!touched.urlPrefix && !!errors.urlPrefix}
                                    errorMessage={errors.urlPrefix}
                                    autoComplete="off"
                                />

                                <Button
                                    className={styles.submitButton}
                                    fitContent
                                    variant="filled" color="primary" type="submit"
                                    disabled={!values.companyName.trim() || !values.urlPrefix.trim() || isLoading}>
                                    {isLoading && (
                                        <>
                                            {isValidating ? "Checking" : "Creating"} <Spinner animation="border" role="status" size="sm" />
                                        </>
                                    )}
                                    {!isLoading && "Continue"}
                                </Button>
                            </form>
                        )
                    }}
                </Formik>
            )}

        </div>
    )
};

export default CompanyForm;