import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useAuthenticator } from '@aws-amplify/ui-react';
import { findIndex } from "lodash";

import { checkEnvironmentCreation } from "utils/api/health";
import '@aws-amplify/ui-react/styles.css';
import styles from './Home.module.css';
import LoadingPage from "views/common/LoadingPage/LoadingPage";
import { setupDataActionsTypes, useSetupData } from "contexts/setupData";
import { matchPath, Outlet, useLocation, useNavigate } from "react-router";
import { QUERY_KEYS, ROUTE_PATHS } from "enums";
import SomethingWentWrong from "views/SomethingWentWrong/SomethingWentWrong";
import { getTenantDetails } from "utils/api/tenant";
import { useWizardData, wizardDataActionsTypes } from "contexts/WizardData";




const ENVIRONMENT_STATUS = {
    EMAIL_NOT_FOUND: "EMAIL_NOT_FOUND",
    WAITING_FOR_ONBOARDING_CONFIGS: "WAITING_FOR_ONBOARDING_CONFIGS",
    NOT_PROVISIONED: "NOT_PROVISIONED",
    READY_TO_PROVISION: "READY_TO_PROVISION",
    PROVISIONING: "PROVISIONING",
    PROVISIONED: "PROVISIONED",
    PROVISIONING_FAILED: "PROVISIONING_FAILED",
}

const STEP_KEYS = {
    SELECT_YOUR_ROLE: "SELECT_YOUR_ROLE",
    USER_ROLE: "USER_ROLE",
    COMPANY_FORM: "COMPANY_FORM",
    ADD_CLOUD_ENVIRONMENT: "ADD_CLOUD_ENVIRONMENT",
    ENVIRONMENT_CREATION_IN_PROGRESS: "ENVIRONMENT_CREATION_IN_PROGRESS",
    ENVIRONMENT_CREATED: "ENVIRONMENT_CREATED",
    ENVIRONMENT_CREATION_FAILED: "ENVIRONMENT_CREATION_FAILED",
}


// order of steps is important
const setupSteps = [
    {
        key: STEP_KEYS.USER_ROLE,
        routePath: ROUTE_PATHS.SELECT_YOUR_ROLE,
    },
    {
        key: STEP_KEYS.COMPANY_FORM,
        routePath: ROUTE_PATHS.NAME_YOUR_SEDAI,
    },
    { key: STEP_KEYS.ADD_CLOUD_ENVIRONMENT, routePath: ROUTE_PATHS.SETUP_WIZARD },
    { key: STEP_KEYS.ENVIRONMENT_CREATION_IN_PROGRESS, routePath: ROUTE_PATHS.CREATING_ENVIRONMENT },
    { key: STEP_KEYS.ENVIRONMENT_CREATED, routePath: ROUTE_PATHS.ENVIRONMENT_CREATED, shouldRefetchStatus: false, isEndStep: true },
    { key: STEP_KEYS.ENVIRONMENT_CREATION_FAILED, routePath: ROUTE_PATHS.ENVIRONMENT_CREATION_FAILED, shouldRefetchStatus: false, isEndStep: true },
];

const Home = () => {
    const { user } = useAuthenticator((context) => [context.user]);
    const navigate = useNavigate();
    const location = useLocation();
    const [setupDataState, setupDataDispatch] = useSetupData();
    const [wizardData, wizardDataDispatch] = useWizardData();
    const currentSetupStep = setupSteps.find(({ routePath }) => matchPath({ path: routePath, end: false }, location.pathname))
    const defaultOnCompleteHandler = !currentSetupStep?.isEndStep ? (() => {
        const index = findIndex(setupSteps, ({ key }) => key === currentSetupStep.key)
        if (currentSetupStep?.key === STEP_KEYS.USER_ROLE && data.status !== ENVIRONMENT_STATUS.EMAIL_NOT_FOUND) {
            if (data.status === ENVIRONMENT_STATUS.PROVISIONED) {
                const createdStepIndex = findIndex(setupSteps, ({ key }) => key === STEP_KEYS.ENVIRONMENT_CREATED);
                navigate(setupSteps[createdStepIndex].routePath, { replace: true });
            }
            else {
                navigate(setupSteps[index + 2].routePath, { replace: true }); // skip company form
            }
        }
        else navigate(setupSteps[index + 1].routePath, { replace: true });
    }) : null;

    const { data, status, refetch } = useQuery([QUERY_KEYS.CHECK_ENVIRONMENT_CREATION, user.attributes.email], ({ queryKey: [_, email] }) => checkEnvironmentCreation(email));
    const tenantQueryEnabled = !!data?.status && data.status !== ENVIRONMENT_STATUS.EMAIL_NOT_FOUND;
    const { data: tenantDetails, status: tenantDetailsStatus } = useQuery([QUERY_KEYS.TENANT_DETAILS, user.attributes.email], ({ queryKey: [_, email] }) => getTenantDetails(email), { enabled: tenantQueryEnabled });
    const [initialDataLoaded, setInitialDataLoaded] = useState(false);
    const queriesStatus = [status, ...(tenantQueryEnabled && !initialDataLoaded ? [tenantDetailsStatus] : [])]
    const isLoading = queriesStatus.includes("loading");
    const isSuccessful = queriesStatus.every((s) => s === "success");
    const isError = queriesStatus.includes("error");

    useEffect(() => {
        if (tenantDetailsStatus === "success") wizardDataDispatch({ type: wizardDataActionsTypes.WIZARD_DATA_INIT, payload: tenantDetails });
    }, [tenantDetailsStatus])

    useEffect(() => {
        if (!initialDataLoaded && isSuccessful) setInitialDataLoaded(true);
    }, [isSuccessful, data, tenantDetails])

    useEffect(() => {
        if (data?.userId && data?.userId !== setupDataState.userTrackingId)
            setupDataDispatch({ type: setupDataActionsTypes.SET_USER_TRACKING_ID, payload: data.userId });
        if (data?.envDomain && data.envDomain !== setupDataState.envDomain)
            setupDataDispatch({ type: setupDataActionsTypes.SET_ENV_DOMAIN, payload: data.envDomain });
    }, [data])

    useEffect(() => {
        if (currentSetupStep?.shouldRefetchStatus !== false) {
            const interval = setInterval(() => {
                refetch();
            }, 30000);

            return () => clearInterval(interval)
        }
    }, [currentSetupStep?.shouldRefetchStatus]);


    // navigate to those steps whenever we have those states
    useEffect(() => {
        if (currentSetupStep?.key !== STEP_KEYS.ENVIRONMENT_CREATED && status === "success" && data.status === ENVIRONMENT_STATUS.PROVISIONED) {
            const setupStep = setupSteps.find(({ key }) => key === STEP_KEYS.ENVIRONMENT_CREATED)
            navigate(setupStep.routePath, { replace: true })
        }
        else if (currentSetupStep?.key !== STEP_KEYS.ENVIRONMENT_CREATION_FAILED && status === "success" && data.status === ENVIRONMENT_STATUS.PROVISIONING_FAILED) {
            const setupStep = setupSteps.find(({ key }) => key === STEP_KEYS.ENVIRONMENT_CREATION_FAILED)
            navigate(setupStep.routePath, { replace: true })
        }

    }, [data]);

    // navigate to those pages automatically only if the page was first time to load
    // this would prevent redirecting multiple times since we already navigate between those pages manually through the wizard
    useEffect(() => {
        if (isSuccessful && !user.attributes["custom:role"]) {
            const setupStep = setupSteps.find(({ key }) => key === STEP_KEYS.USER_ROLE)
            navigate(setupStep.routePath, { replace: true });
        }
        else if (isSuccessful && data.status === ENVIRONMENT_STATUS.EMAIL_NOT_FOUND) {
            const setupStep = setupSteps.find(({ key }) => key === STEP_KEYS.COMPANY_FORM)
            navigate(setupStep.routePath, { replace: true });
        }
        else if (isSuccessful && (data.status === ENVIRONMENT_STATUS.NOT_PROVISIONED || data.status === ENVIRONMENT_STATUS.READY_TO_PROVISION || data.status === ENVIRONMENT_STATUS.PROVISIONING)) {
            const setupStep = setupSteps.find(({ key }) => key === STEP_KEYS.ENVIRONMENT_CREATION_IN_PROGRESS)
            navigate(setupStep.routePath, { replace: true })
        }
        else if (isSuccessful && data.status && data?.status !== ENVIRONMENT_STATUS.PROVISIONED && data?.status !== ENVIRONMENT_STATUS.PROVISIONING_FAILED) {
            const setupStep = setupSteps.find(({ key }) => key === STEP_KEYS.ADD_CLOUD_ENVIRONMENT)
            if (!matchPath({ path: setupStep.routePath, end: false }, location.pathname))
                navigate(setupStep.routePath, { replace: true })
        }
    }, [initialDataLoaded]);

    return (
        <>
            {isError && (
                <SomethingWentWrong />
            )}
            {(isLoading || (isSuccessful && !currentSetupStep)) && (
                <LoadingPage />
            )}
            {isSuccessful && currentSetupStep && (
                <div className={styles.container}>
                    <Outlet context={{ envUrl: data.envUrl, onComplete: defaultOnCompleteHandler, envDomain: setupDataState.envDomain }} />
                </div>
            )}
        </>
    )
};

export default Home;