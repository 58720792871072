import COLORS from 'themes/colors';
import { createStylesWithTheme } from 'utils/functions';

const styles = (theme) => ({
    inputContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: "100%",
    },
    label: {
        color: "textSecondary",
    },
    inputFieldContainer: {
        display: "flex",
        alignItems: "center",
        transition: "0.1s",
        borderRadius: 4,
        // color variants
        "&$textSecondary": {
            border: `1px solid ${theme.palette.border.card}`,
            background: theme.type === "dark" ? COLORS.DARK_NEUTRALS[800] : COLORS.LIGHT_NEUTRALS[0],
            "&:focus-within": {
                outline: `1px solid ${theme.palette.primary.main}`,
                boxShadow: `0px 0px 0px 4px ${theme.type === "dark" ? COLORS.PRIMARY[1000] : COLORS.PRIMARY[100]}`
            },
        },
        "&$hasError": {
            background: theme.type === "dark" ? COLORS.RED[1300] : COLORS.RED[50],
            border: `1px solid ${theme.palette.error.main}`,
            "&:focus-within": {
                outline: `1px solid ${theme.palette.error.main}`,
                boxShadow: `0px 0px 0px 4px ${theme.type === "dark" ? COLORS.RED[700] : COLORS.RED[200]}`
            },
        },

    },
    inputStartAdornment: {
        paddingLeft: 12,
        color: theme.palette.text.ternary,
    },
    inputEndAdornment: {
        paddingRight: 12,
    },
    input: {
        ...(theme.typography.body1),
        border: 0,
        outline: 0,
        height: 40,
        padding: "0 12px",
        width: "100%",
        borderRadius: 4,
        color: theme.palette.text.primary,
        background: "transparent",
        "&::placeholder": {
            color: theme.palette.text.ternary,
            opacity: 1,
        },
    },
    textSecondary: {},
    hasError: {},
    errorText: {
        marginTop: 8
    },
    alertIcon: {
        color: theme.palette.error.main
    }
})

export default createStylesWithTheme(styles)