import React, { Fragment } from 'react';
import { get, pick, set } from 'lodash';
import * as Yup from 'yup';
import Dropdown from 'components/BetaDropdown/Dropdown';
import Typography from 'components/Typography/Typography';
import Checkbox from 'components/Checkbox/Checkbox';
import MultiChipInput from 'views/common/MultiChipInput/MultiChipInput';
import MultiKeyValueChipInput from 'views/common/MultiKeyValueChipInput/MultiKeyValueChipInput';
import InfoIconTooltip from 'components/InfoIconTooltip/InfoIconTooltip';

import { MONITORING_PROVIDERS, CLOUD_PROVIDERS, CREDENTIALS_PROVIDERS, INTEGRATION_TYPE } from 'enums';
import { getMonitoringProviderLabel } from 'utils/functions';
import { getAccountMPsKeys } from './cloudProviders';
import TextField from 'components/TextField/TextField';

export const MP_CREDETIALS_BASE_PATH = 'details.credentialsDetail.credentials'

const urlRegex = /^[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;
const urlSchema = Yup.string().matches(urlRegex, 'Enter correct url!').required();

const fieldsSortFunc = (a, b) => (a.order || 0) - (b.order || 0);

const connectionPreferenceOptions = [
    { name: INTEGRATION_TYPE.AGENTLESS, label: "Agentless" },
    { name: INTEGRATION_TYPE.AGENT_BASED, label: "Smart Agent" }
];

export const FIELD_GROUPS = {
    AUTHENTICATION: "AUTHENTICATION",
    DIMENSIONS: "DIMENSIONS",
    ADVANCED_SETUP: "ADVANCED_SETUP",
}


const instanceIdPatterTooltip = (
    <>
        <Typography variant="h5" color="textPrimary">Supported Placeholder Patterns:</Typography>
        <Typography variant="body1" color="textPrimary">
            {`$\{instanceId}
            $\{instanceName}
            $\{appId}
            $\{appName}
            $\{regionId}
            $\{loadBalancerId}
            $\{kubeNamespace}
            $\{kubeClusterName}`
                .split('\n').map((item, index) => {
                    return <Fragment key={index}>{index !== 0 && <br />}{item}</Fragment>
                })}
        </Typography>
    </>
);


const commonMPFields = (cloudProviderTypeConfig) => [
    {
        key: 'name',
        label: 'Nickname',
        component: TextField,
        order: -2,
        fieldGroup: FIELD_GROUPS.ADVANCED_SETUP,
    },
    {
        key: 'siteDiscoveryEnabled',
        label: '', //empty form field label
        style: { alignItems: "flex-start" },
        renderLabel: () => (
            <div>
                <Typography variant="body2" fontWeight={500} color="textPrimary">Use monitoring data to discover topology</Typography>
                <Typography variant="body2" fontWeight={500} color="textSecondary">Sedai primarily uses cloud credentials to infer your resources, but can optionally also use monitoring data. </Typography>
            </div>
        ), //checkbox label
        component: Checkbox,
        alignTop: true,
        getHandler: (formikProps) => (v) => formikProps.setFieldValue("siteDiscoveryEnabled", v),
        fieldGroup: FIELD_GROUPS.ADVANCED_SETUP,
        order: Number.MAX_SAFE_INTEGER - 7,
    },
    {
        key: 'clusterDimensions',
        label: 'Cluster labels',
        component: MultiChipInput,
        getHandler: (formikProps) => (v) => formikProps.setFieldValue("clusterDimensions", v),
        order: Number.MAX_SAFE_INTEGER - 6,
        hide: () => cloudProviderTypeConfig.type !== CLOUD_PROVIDERS.KUBERNETES,
        fieldGroup: FIELD_GROUPS.DIMENSIONS,
    },
    {
        key: 'namespaceDimensions',
        label: 'Namespace labels',
        component: MultiChipInput,
        getHandler: (formikProps) => (v) => formikProps.setFieldValue("namespaceDimensions", v),
        order: Number.MAX_SAFE_INTEGER - 5,
        hide: () => cloudProviderTypeConfig.type !== CLOUD_PROVIDERS.KUBERNETES,
        fieldGroup: FIELD_GROUPS.DIMENSIONS,
    },
    {
        key: 'lbDimensions',
        label: 'Load Balancer labels',
        component: MultiChipInput,
        getHandler: (formikProps) => (v) => formikProps.setFieldValue("lbDimensions", v),
        order: Number.MAX_SAFE_INTEGER - 4,
        fieldGroup: FIELD_GROUPS.DIMENSIONS,
    },
    {
        key: 'appDimensions',
        label: 'Application labels',
        component: MultiChipInput,
        getHandler: (formikProps) => (v) => formikProps.setFieldValue("appDimensions", v),
        order: Number.MAX_SAFE_INTEGER - 3,
        fieldGroup: FIELD_GROUPS.DIMENSIONS,
    },
    {
        key: 'instanceDimensions',
        label: cloudProviderTypeConfig.type === CLOUD_PROVIDERS.KUBERNETES ? 'Pod labels' : 'Instance labels',
        component: MultiChipInput,
        getHandler: (formikProps) => (v) => formikProps.setFieldValue("instanceDimensions", v),
        order: Number.MAX_SAFE_INTEGER - 2,
        fieldGroup: FIELD_GROUPS.DIMENSIONS,
    },
    {
        key: 'containerDimensions',
        label: 'Container labels',
        component: MultiChipInput,
        getHandler: (formikProps) => (v) => formikProps.setFieldValue("containerDimensions", v),
        order: Number.MAX_SAFE_INTEGER - 1,
        hide: () => cloudProviderTypeConfig.type !== CLOUD_PROVIDERS.KUBERNETES,
        fieldGroup: FIELD_GROUPS.DIMENSIONS,
    },
    {
        key: 'instanceIdPattern',
        label: <div style={{ display: "flex", gap: 4, alignItems: 'center' }}>Instance ID Pattern<InfoIconTooltip>{instanceIdPatterTooltip}</InfoIconTooltip></div>,
        component: TextField,
        order: Number.MAX_SAFE_INTEGER,
        fieldGroup: FIELD_GROUPS.DIMENSIONS,
    },
];

const commonValidations = (cloudProviderTypeConfig) => ({
    name: Yup.string().required(),
    clusterDimensions: Yup.array().of(Yup.string()).nullable(),
    namespaceDimensions: Yup.array().of(Yup.string()).nullable(),
    lbDimensions: Yup.array().of(Yup.string()).nullable(),
    appDimensions: Yup.array().of(Yup.string()).nullable(),
    instanceDimensions: Yup.array().of(Yup.string()).nullable(),
    integrationType: Yup.string().oneOf(Object.values(INTEGRATION_TYPE)).required(), // all types have integrationType field but not all can change it
    ...(cloudProviderTypeConfig.type === CLOUD_PROVIDERS.KUBERNETES ? { containerDimensions: Yup.array().of(Yup.string()).nullable() } : {}),
    instanceIdPattern: Yup.string().nullable(),
    siteDiscoveryEnabled: Yup.boolean().required(),
});

const instanceDimensionsResolver = (cloudProviderTypeConfig, mpType) => {
    if (cloudProviderTypeConfig.type === CLOUD_PROVIDERS.KUBERNETES && mpType === MONITORING_PROVIDERS.DATADOG)
        return ["pod_name"];
    if (cloudProviderTypeConfig.type === CLOUD_PROVIDERS.KUBERNETES && mpType === MONITORING_PROVIDERS.FEDERATEDPROMETHEUS)
        return ["pod"];
    if (cloudProviderTypeConfig.type === CLOUD_PROVIDERS.KUBERNETES && mpType === MONITORING_PROVIDERS.SIGNALFX)
        return ["kubernetes_pod_name"];
    if (mpType === MONITORING_PROVIDERS.DATADOG)
        return ["host"];
    return ["instance_id"];
}
const containerDimensionsResolver = (cloudProviderTypeConfig, mpType) => {
    if (mpType === MONITORING_PROVIDERS.DATADOG)
        return ["container_name"];
    if (mpType === MONITORING_PROVIDERS.SIGNALFX)
        return ["container_spec_name"];
    return ["container"];
}

const nameSpaceDimensionsResolver = (mpType) => {
    if (mpType === MONITORING_PROVIDERS.DATADOG)
        return ["kube_namespace", "namespace"];
    if (mpType === MONITORING_PROVIDERS.FEDERATEDPROMETHEUS)
        return ["exporter_namespace", "envoy", "namespace", "destination_service_namespace"];
    if (mpType === MONITORING_PROVIDERS.WAVEFRONT)
        return ["namespace_name"];
    if (mpType === MONITORING_PROVIDERS.NEWRELIC)
        return ["exporter_namespace", "namespace"];
    return ["namespace"];
}

const commonDefaultValues = (cloudProviderTypeConfig, cloudProviderIntegrationType, mpType) => ({
    "instanceDimensions": instanceDimensionsResolver(cloudProviderTypeConfig, mpType),
    "appDimensions": ["application_id"],
    "lbDimensions": ["load_balancer_name"],
    "integrationType": INTEGRATION_TYPE.AGENTLESS,// all types have integrationType field but not all can change it
    ...(cloudProviderTypeConfig.type === CLOUD_PROVIDERS.KUBERNETES ? {
        containerDimensions: containerDimensionsResolver(cloudProviderTypeConfig, mpType),
        namespaceDimensions: nameSpaceDimensionsResolver(mpType),
    } : {}),
    "siteDiscoveryEnabled": false,
})

export const getAccountMonitoringProvidersConfig = ({ cloudProviderTypeConfig, cloudProviderIntegrationType }) => {
    const keysToPick = getAccountMPsKeys(cloudProviderTypeConfig.key);
    const mpsConf = {
        [MONITORING_PROVIDERS.CLOUDWATCH]: {
            key: MONITORING_PROVIDERS.CLOUDWATCH,
            label: getMonitoringProviderLabel(MONITORING_PROVIDERS.CLOUDWATCH),
            iconName: "cloudwatch",
            setupSupportLink: "https://docs.sedai.io/get-started/setup/connect-monitoring-data#cloudwatch",
            hideTestConnection: (values) => !get(values, `${MP_CREDETIALS_BASE_PATH}.credentialsProvider`) || get(values, `integrationType`) === INTEGRATION_TYPE.AGENT_BASED,
            formFields: [
                ...commonMPFields(cloudProviderTypeConfig),
                {
                    key: `${MP_CREDETIALS_BASE_PATH}.credentialsProvider`,
                    label: 'Select a method to connect to Sedai',
                    component: Dropdown,
                    options: [
                        {
                            name: "",
                            label: "Use AWS cloud account credentials",
                        },
                        {
                            name: CREDENTIALS_PROVIDERS.AWS_ENV_SUPPLIED,
                            label: "Use AWS IAM Role",
                        },
                        {
                            name: CREDENTIALS_PROVIDERS.AWS_STATIC,
                            label: "Use CloudWatch credentials",
                        },
                    ],
                    getHandler: (formikProps) => (v) => formikProps.setValues({
                        ...formikProps.values,
                        details: {
                            ...(formikProps.values.details || {}),
                            credentialsDetail: v ? formikProps.values.details.credentialsDetail : null,
                            ...(v ? set({}, MP_CREDETIALS_BASE_PATH, { credentialsProvider: v }).details : {}),
                        }
                    }),
                    isCredentialInput: true,
                    fieldGroup: FIELD_GROUPS.AUTHENTICATION,
                },
                {
                    key: `${MP_CREDETIALS_BASE_PATH}.accessKey`,
                    label: "Access Key",
                    placeholder: 'Enter access key id',
                    component: TextField,
                    hide: (formikProps) => get(formikProps, `values.${MP_CREDETIALS_BASE_PATH}.credentialsProvider`) !== CREDENTIALS_PROVIDERS.AWS_STATIC,
                    isCredentialInput: true,
                    fieldGroup: FIELD_GROUPS.AUTHENTICATION,
                    labelTooltip: "Use the Access Key that was generated from your new new IAM user for Sedai.",
                },
                {
                    key: `${MP_CREDETIALS_BASE_PATH}.secretKey`,
                    label: "Secret Key",
                    type: "password",
                    placeholder: "Enter secret access key",
                    component: TextField,
                    hide: (formikProps) => get(formikProps, `values.${MP_CREDETIALS_BASE_PATH}.credentialsProvider`) !== CREDENTIALS_PROVIDERS.AWS_STATIC,
                    isCredentialInput: true,
                    fieldGroup: FIELD_GROUPS.AUTHENTICATION,
                    labelTooltip: "Use the Secret Key that was generated from your new new IAM user for Sedai.",
                },
                {
                    key: `${MP_CREDETIALS_BASE_PATH}.role`,
                    label: "Role ARN",
                    component: TextField,
                    hide: (formikProps) => get(formikProps, `values.${MP_CREDETIALS_BASE_PATH}.credentialsProvider`) !== CREDENTIALS_PROVIDERS.AWS_ENV_SUPPLIED,
                    placeholder: "Enter optional role ARN",
                    isCredentialInput: true,
                    fieldGroup: FIELD_GROUPS.AUTHENTICATION,
                },
            ].sort(fieldsSortFunc),
            initValues: Object.assign(
                commonDefaultValues(cloudProviderTypeConfig, cloudProviderIntegrationType, MONITORING_PROVIDERS.CLOUDWATCH),
                {
                    "monitoringProvider": MONITORING_PROVIDERS.CLOUDWATCH,
                    "instanceDimensions": ["instance_id"],
                    "details": {
                        "monitoringProvider": MONITORING_PROVIDERS.CLOUDWATCH,
                        "credentialsDetail": null
                    },
                },
            ),
            validationSchema: Yup.object().shape({
                monitoringProvider: Yup.string().oneOf([MONITORING_PROVIDERS.CLOUDWATCH]).required(),
                details: Yup.object().shape({
                    monitoringProvider: Yup.string().oneOf([MONITORING_PROVIDERS.CLOUDWATCH]).required(),
                    credentialsDetail: Yup.object().shape({
                        credentials: Yup.object().shape({
                            credentialsProvider: Yup.string().oneOf([CREDENTIALS_PROVIDERS.AWS_ENV_SUPPLIED, CREDENTIALS_PROVIDERS.AWS_STATIC]).required(),
                            accessKey: Yup.string().when('credentialsProvider', {
                                is: CREDENTIALS_PROVIDERS.AWS_STATIC,
                                then: Yup.string().required(),
                                otherwise: Yup.string().nullable(),
                            }),
                            secretKey: Yup.string().when('credentialsProvider', {
                                is: CREDENTIALS_PROVIDERS.AWS_STATIC,
                                then: Yup.string().required(),
                                otherwise: Yup.string().nullable(),
                            }),
                            role: Yup.string().nullable(),
                        }).default(undefined)
                    }).nullable(),
                }).required(),
                ...commonValidations(cloudProviderTypeConfig),
            }),
        },
        [MONITORING_PROVIDERS.FEDERATEDPROMETHEUS]: {
            key: MONITORING_PROVIDERS.FEDERATEDPROMETHEUS,
            label: getMonitoringProviderLabel(MONITORING_PROVIDERS.FEDERATEDPROMETHEUS),
            iconName: "federatedprometheus",
            setupSupportLink: "https://docs.sedai.io/get-started/setup/connect-monitoring-data#prometheus",
            hideTestConnection: (values) => get(values, `integrationType`) === INTEGRATION_TYPE.AGENT_BASED || get(values, `${MP_CREDETIALS_BASE_PATH}.credentialsProvider`) === CREDENTIALS_PROVIDERS.FEDERATEDPROMETHEUS_NO_AUTH,
            formFields: [
                ...((cloudProviderTypeConfig.type === CLOUD_PROVIDERS.KUBERNETES && cloudProviderIntegrationType === INTEGRATION_TYPE.AGENT_BASED) ? [{
                    key: "integrationType",
                    label: "Connection Preference",
                    component: Dropdown,
                    options: connectionPreferenceOptions,
                    getHandler: (formikProps) => (v) => formikProps.setFieldValue("integrationType", v),
                    order: -2,
                    fieldGroup: FIELD_GROUPS.ADVANCED_SETUP,
                }] : []),
                ...commonMPFields(cloudProviderTypeConfig),
                {
                    key: 'details.endpoint',
                    label: 'Endpoint',
                    component: TextField,
                    getHandler: (formikProps) => formikProps.handleChange,
                    fieldGroup: FIELD_GROUPS.AUTHENTICATION,
                },
                {
                    key: `${MP_CREDETIALS_BASE_PATH}.credentialsProvider`,
                    label: `Authentication method`,
                    component: Dropdown,
                    options: [
                        {
                            name: CREDENTIALS_PROVIDERS.FEDERATEDPROMETHEUS_NO_AUTH,
                            label: "No authentication (use Smart Agent)",
                        },
                        {
                            name: CREDENTIALS_PROVIDERS.BASIC_AUTH,
                            label: "Basic Auth",
                        },
                        {
                            name: CREDENTIALS_PROVIDERS.FEDERATEDPROMETHEUS_JWT,
                            label: "JWT Credentials",
                        },
                        {
                            name: CREDENTIALS_PROVIDERS.FEDERATEDPROMETHEUS_CLIENT_CREDENTIALS,
                            label: "OIDC Client Provider",
                        },
                        // {
                        //     name: CREDENTIALS_PROVIDERS.FEDERATEDPROMETHEUS_ROP,
                        //     label: "OIDC Resource Owner Password",
                        // }
                    ],
                    getHandler: (formikProps) => (v) => formikProps.setValues({
                        ...formikProps.values,
                        details: {
                            ...(formikProps.values.details || {}),
                            ...set({}, `${MP_CREDETIALS_BASE_PATH}.credentialsProvider`, v).details,
                        },
                    }),
                    isCredentialInput: true,
                    fieldGroup: FIELD_GROUPS.AUTHENTICATION,
                },
                {
                    key: `${MP_CREDETIALS_BASE_PATH}.bearerToken`,
                    label: 'Token',
                    component: TextField,
                    hide: (formikProps) => get(formikProps, `values.${MP_CREDETIALS_BASE_PATH}.credentialsProvider`) !== CREDENTIALS_PROVIDERS.FEDERATEDPROMETHEUS_JWT,
                    getHandler: (formikProps) => formikProps.handleChange,
                    isCredentialInput: true,
                    fieldGroup: FIELD_GROUPS.AUTHENTICATION,
                },
                {
                    key: `${MP_CREDETIALS_BASE_PATH}.tokenEndpoint`,
                    label: 'Token URL',
                    component: TextField,
                    hide: (formikProps) => get(formikProps, `values.${MP_CREDETIALS_BASE_PATH}.credentialsProvider`) !== CREDENTIALS_PROVIDERS.FEDERATEDPROMETHEUS_CLIENT_CREDENTIALS && get(formikProps, `values.${MP_CREDETIALS_BASE_PATH}.credentialsProvider`) !== CREDENTIALS_PROVIDERS.FEDERATEDPROMETHEUS_ROP,
                    getHandler: (formikProps) => formikProps.handleChange,
                    isCredentialInput: true,
                    fieldGroup: FIELD_GROUPS.AUTHENTICATION,
                },
                {
                    key: `${MP_CREDETIALS_BASE_PATH}.clientID`,
                    label: 'Client ID',
                    component: TextField,
                    hide: (formikProps) => get(formikProps, `values.${MP_CREDETIALS_BASE_PATH}.credentialsProvider`) !== CREDENTIALS_PROVIDERS.FEDERATEDPROMETHEUS_CLIENT_CREDENTIALS && get(formikProps, `values.${MP_CREDETIALS_BASE_PATH}.credentialsProvider`) !== CREDENTIALS_PROVIDERS.FEDERATEDPROMETHEUS_ROP,
                    getHandler: (formikProps) => formikProps.handleChange,
                    isCredentialInput: true,
                    fieldGroup: FIELD_GROUPS.AUTHENTICATION,

                },
                {
                    key: `${MP_CREDETIALS_BASE_PATH}.clientSecret`,
                    label: 'Client Secret',
                    component: TextField,
                    hide: (formikProps) => get(formikProps, `values.${MP_CREDETIALS_BASE_PATH}.credentialsProvider`) !== CREDENTIALS_PROVIDERS.FEDERATEDPROMETHEUS_CLIENT_CREDENTIALS && get(formikProps, `values.${MP_CREDETIALS_BASE_PATH}.credentialsProvider`) !== CREDENTIALS_PROVIDERS.FEDERATEDPROMETHEUS_ROP,
                    getHandler: (formikProps) => formikProps.handleChange,
                    isCredentialInput: true,
                    fieldGroup: FIELD_GROUPS.AUTHENTICATION,

                },
                {
                    key: 'details.disableCertificateValidation',
                    label: '', //empty form field label
                    style: { alignItems: "flex-start" },
                    renderLabel: () => (
                        <Typography variant="body2" fontWeight={500} color="textPrimary">Use certificate authority</Typography>
                    ), //checkbox label
                    component: Checkbox,
                    getHandler: (formikProps) => (v) => formikProps.setFieldValue("details.disableCertificateValidation", v),
                    fieldGroup: FIELD_GROUPS.AUTHENTICATION,
                },
                {
                    key: 'details.customCertificates',
                    label: <div style={{ display: "flex", gap: 4, alignItems: 'center' }}>Certificate Authority<InfoIconTooltip>PEM format</InfoIconTooltip></div>,
                    component: TextField,
                    hide: (formikProps) => get(formikProps, `values.details.disableCertificateValidation`) !== true,
                    getHandler: (formikProps) => formikProps.handleChange,
                    fieldGroup: FIELD_GROUPS.AUTHENTICATION,
                },
                {
                    key: `${MP_CREDETIALS_BASE_PATH}.username`,
                    label: 'Username',
                    component: TextField,
                    hide: (formikProps) => ![CREDENTIALS_PROVIDERS.FEDERATEDPROMETHEUS_ROP, CREDENTIALS_PROVIDERS.BASIC_AUTH].includes(get(formikProps, `values.${MP_CREDETIALS_BASE_PATH}.credentialsProvider`)),
                    getHandler: (formikProps) => formikProps.handleChange,
                    isCredentialInput: true,
                    fieldGroup: FIELD_GROUPS.AUTHENTICATION,
                },
                {
                    key: `${MP_CREDETIALS_BASE_PATH}.password`,
                    label: 'Password',
                    type: "password",
                    component: TextField,
                    hide: (formikProps) => ![CREDENTIALS_PROVIDERS.FEDERATEDPROMETHEUS_ROP, CREDENTIALS_PROVIDERS.BASIC_AUTH].includes(get(formikProps, `values.${MP_CREDETIALS_BASE_PATH}.credentialsProvider`)),
                    getHandler: (formikProps) => formikProps.handleChange,
                    isCredentialInput: true,
                    fieldGroup: FIELD_GROUPS.AUTHENTICATION,
                },
                {
                    key: 'alertIntegrationEnabled',
                    label: '', //empty form field label
                    style: { alignItems: "flex-start" },
                    renderLabel: () => (
                        <div>
                            <Typography variant="body2" fontWeight={500} color="textPrimary">Use alerts data to create Sedai operations</Typography>
                            <Typography variant="body2" fontWeight={500} color="textSecondary">Sedai primarily uses cloud credentials to infer your resources, but can optionally also use monitoring data.</Typography>
                        </div>
                    ), //checkbox label
                    component: Checkbox,
                    alignTop: true,
                    getHandler: (formikProps) => (v) => formikProps.setFieldValue("alertIntegrationEnabled", v),
                    fieldGroup: FIELD_GROUPS.ADVANCED_SETUP,
                },
            ].sort(fieldsSortFunc),
            initValues: Object.assign(
                commonDefaultValues(cloudProviderTypeConfig, cloudProviderIntegrationType, MONITORING_PROVIDERS.FEDERATEDPROMETHEUS),
                {
                    "monitoringProvider": MONITORING_PROVIDERS.FEDERATEDPROMETHEUS,
                    "alertIntegrationEnabled": false,
                    "integrationType": (cloudProviderTypeConfig.type === CLOUD_PROVIDERS.KUBERNETES && cloudProviderIntegrationType === INTEGRATION_TYPE.AGENT_BASED) ? INTEGRATION_TYPE.AGENT_BASED : INTEGRATION_TYPE.AGENTLESS,
                    "details": {
                        "monitoringProvider": MONITORING_PROVIDERS.FEDERATEDPROMETHEUS,
                        ...set({}, `${MP_CREDETIALS_BASE_PATH}.credentialsProvider`, CREDENTIALS_PROVIDERS.FEDERATEDPROMETHEUS_NO_AUTH).details,
                        "disableCertificateValidation": true,
                    }
                },
            ),
            validationSchema: Yup.object().shape({
                monitoringProvider: Yup.string().oneOf([MONITORING_PROVIDERS.FEDERATEDPROMETHEUS]).required(),
                alertIntegrationEnabled: Yup.boolean().required(),
                details: Yup.object().shape({
                    monitoringProvider: Yup.string().required(),
                    endpoint: Yup.string(urlSchema).required(),
                    customCertificates: Yup.string().nullable(),
                    disableCertificateValidation: Yup.boolean().required(),
                    osDimensions: Yup.array().of(Yup.string()).nullable(),
                    architectureDimensions: Yup.array().of(Yup.string()).nullable(),
                    instanceTypeDimensions: Yup.array().of(Yup.string()).nullable(),
                    metricNameWildcards: Yup.array().of(Yup.string()).nullable(),
                    credentialsDetail: Yup.object().shape({
                        credentials: Yup.object().shape({
                            credentialsProvider: Yup.string().oneOf([CREDENTIALS_PROVIDERS.FEDERATEDPROMETHEUS_NO_AUTH, CREDENTIALS_PROVIDERS.FEDERATEDPROMETHEUS_JWT, CREDENTIALS_PROVIDERS.FEDERATEDPROMETHEUS_CLIENT_CREDENTIALS, CREDENTIALS_PROVIDERS.FEDERATEDPROMETHEUS_ROP, CREDENTIALS_PROVIDERS.BASIC_AUTH]),
                            bearerToken: Yup.string().when('credentialsProvider', {
                                is: CREDENTIALS_PROVIDERS.FEDERATEDPROMETHEUS_JWT,
                                then: Yup.string().required(),
                                otherwise: Yup.string().nullable(),
                            }),
                            tokenEndpoint: Yup.string().when('credentialsProvider', {
                                is: (val) => val === CREDENTIALS_PROVIDERS.FEDERATEDPROMETHEUS_CLIENT_CREDENTIALS || val === CREDENTIALS_PROVIDERS.FEDERATEDPROMETHEUS_ROP,
                                then: Yup.string().required(),
                                otherwise: Yup.string().nullable(),
                            }),
                            clientID: Yup.string().when('credentialsProvider', {
                                is: (val) => val === CREDENTIALS_PROVIDERS.FEDERATEDPROMETHEUS_CLIENT_CREDENTIALS || val === CREDENTIALS_PROVIDERS.FEDERATEDPROMETHEUS_ROP,
                                then: Yup.string().required(),
                                otherwise: Yup.string().nullable(),
                            }),
                            clientSecret: Yup.string().when('credentialsProvider', {
                                is: (val) => val === CREDENTIALS_PROVIDERS.FEDERATEDPROMETHEUS_CLIENT_CREDENTIALS || val === CREDENTIALS_PROVIDERS.FEDERATEDPROMETHEUS_ROP,
                                then: Yup.string().required(),
                                otherwise: Yup.string().nullable(),
                            }),
                            username: Yup.string().when('credentialsProvider', {
                                is: (val) => val === CREDENTIALS_PROVIDERS.FEDERATEDPROMETHEUS_ROP || val === CREDENTIALS_PROVIDERS.BASIC_AUTH,
                                then: Yup.string().required(),
                                otherwise: Yup.string().nullable(),
                            }),
                            password: Yup.string().when('credentialsProvider', {
                                is: (val) => val === CREDENTIALS_PROVIDERS.FEDERATEDPROMETHEUS_ROP || val === CREDENTIALS_PROVIDERS.BASIC_AUTH,
                                then: Yup.string().required(),
                                otherwise: Yup.string().nullable(),
                            })

                        }).default(undefined),
                    })
                }).required(),

                ...commonValidations(cloudProviderTypeConfig),
            }),
        },
        [MONITORING_PROVIDERS.NEWRELIC]: {
            key: MONITORING_PROVIDERS.NEWRELIC,
            label: getMonitoringProviderLabel(MONITORING_PROVIDERS.NEWRELIC),
            iconName: "newrelic",
            setupSupportLink: "https://docs.sedai.io/get-started/setup/connect-monitoring-data#new-relic",
            hideTestConnection: (values) => get(values, `integrationType`) === INTEGRATION_TYPE.AGENT_BASED,
            formFields: [
                ...commonMPFields(cloudProviderTypeConfig),
                {
                    key: `${MP_CREDETIALS_BASE_PATH}.apiKey`,
                    label: 'API Key',
                    placeholder: 'Type key...',
                    component: TextField,
                    getHandler: (formikProps) => formikProps.handleChange,
                    isCredentialInput: true,
                    fieldGroup: FIELD_GROUPS.AUTHENTICATION,
                },
                {
                    key: 'details.apiServer',
                    label: 'API Server',
                    placeholder: 'Type server...',
                    component: TextField,
                    getHandler: (formikProps) => formikProps.handleChange,
                    fieldGroup: FIELD_GROUPS.AUTHENTICATION,
                },
                {
                    key: 'alertIntegrationEnabled',
                    label: '', //empty form field label
                    style: { alignItems: "flex-start" },
                    renderLabel: () => (
                        <div>
                            <Typography variant="body3" fontWeight={500} color="textPrimary">Use alerts data to create Sedai operations</Typography>
                            <Typography variant="body3" fontWeight={500} color="textSecondary">Sedai primarily uses cloud credentials to infer your resources, but can optionally also use monitoring data.</Typography>
                        </div>
                    ), //checkbox label
                    component: Checkbox,
                    getHandler: (formikProps) => (v) => formikProps.setFieldValue("alertIntegrationEnabled", v),
                    fieldGroup: FIELD_GROUPS.ADVANCED_SETUP,
                },
            ].sort(fieldsSortFunc),
            initValues: Object.assign(
                commonDefaultValues(cloudProviderTypeConfig, cloudProviderIntegrationType, MONITORING_PROVIDERS.NEWRELIC),
                {
                    "monitoringProvider": MONITORING_PROVIDERS.NEWRELIC,
                    "alertIntegrationEnabled": false,
                    "details": {
                        "monitoringProvider": MONITORING_PROVIDERS.NEWRELIC,
                        ...set({}, `${MP_CREDETIALS_BASE_PATH}.credentialsProvider`, CREDENTIALS_PROVIDERS.NEWRELIC).details,
                    },
                }
            ),
            validationSchema: Yup.object().shape({
                monitoringProvider: Yup.string().oneOf([MONITORING_PROVIDERS.NEWRELIC]).required(),
                alertIntegrationEnabled: Yup.boolean().required(),
                details: Yup.object().shape({
                    monitoringProvider: Yup.string().oneOf([MONITORING_PROVIDERS.NEWRELIC]).required(),
                    apiServer: Yup.string().required(),
                    credentialsDetail: Yup.object().shape({
                        credentials: Yup.object().shape({
                            credentialsProvider: Yup.string().oneOf([MONITORING_PROVIDERS.NEWRELIC]).required(),
                            apiKey: Yup.string().required(),
                        }).default(undefined),
                    })
                }).required(),
                ...commonValidations(cloudProviderTypeConfig),
            }),
        },
        [MONITORING_PROVIDERS.WAVEFRONT]: {
            key: MONITORING_PROVIDERS.WAVEFRONT,
            label: getMonitoringProviderLabel(MONITORING_PROVIDERS.WAVEFRONT),
            iconName: "wavefront",
            setupSupportLink: "https://docs.sedai.io/get-started/setup/connect-monitoring-data#wavefront",
            hideTestConnection: (values) => get(values, `integrationType`) === INTEGRATION_TYPE.AGENT_BASED,
            formFields: [
                ...commonMPFields(cloudProviderTypeConfig),
                {
                    key: `${MP_CREDETIALS_BASE_PATH}.apiKey`,
                    label: 'API Key',
                    placeholder: 'Type key...',
                    component: TextField,
                    getHandler: (formikProps) => formikProps.handleChange,
                    isCredentialInput: true,
                    fieldGroup: FIELD_GROUPS.AUTHENTICATION,
                },
                {
                    key: 'details.apiServer',
                    label: 'API Server',
                    placeholder: 'Type server...',
                    component: TextField,
                    getHandler: (formikProps) => formikProps.handleChange,
                    fieldGroup: FIELD_GROUPS.AUTHENTICATION,
                },
            ].sort(fieldsSortFunc),
            initValues: Object.assign(
                commonDefaultValues(cloudProviderTypeConfig, cloudProviderIntegrationType, MONITORING_PROVIDERS.WAVEFRONT),
                {
                    "monitoringProvider": MONITORING_PROVIDERS.WAVEFRONT,
                    "details": {
                        "monitoringProvider": MONITORING_PROVIDERS.WAVEFRONT,
                        ...set({}, `${MP_CREDETIALS_BASE_PATH}.credentialsProvider`, CREDENTIALS_PROVIDERS.WAVEFRONT).details,
                    },
                }),
            validationSchema: Yup.object().shape({
                monitoringProvider: Yup.string().oneOf([MONITORING_PROVIDERS.WAVEFRONT]).required(),
                details: Yup.object().shape({
                    monitoringProvider: Yup.string().oneOf([MONITORING_PROVIDERS.WAVEFRONT]).required(),
                    apiServer: Yup.string().required(),
                    credentialsDetail: Yup.object().shape({
                        credentials: Yup.object().shape({
                            credentialsProvider: Yup.string().oneOf([MONITORING_PROVIDERS.WAVEFRONT]).required(),
                            apiKey: Yup.string().required(),
                        }).default(undefined),
                    })
                }).required(),
                ...commonValidations(cloudProviderTypeConfig),
            }),
        },
        [MONITORING_PROVIDERS.APPDYNAMICS]: {
            key: MONITORING_PROVIDERS.APPDYNAMICS,
            label: getMonitoringProviderLabel(MONITORING_PROVIDERS.APPDYNAMICS),
            iconName: "appdynamics",
            setupSupportLink: "https://docs.sedai.io/get-started/setup/connect-monitoring-data#appdynamics",
            hideTestConnection: (values) => get(values, `integrationType`) === INTEGRATION_TYPE.AGENT_BASED,
            formFields: [
                ...commonMPFields(cloudProviderTypeConfig),

                {
                    key: 'details.controllerName',
                    label: 'Controller Name',
                    placeholder: 'Type name...',
                    component: TextField,
                    getHandler: (formikProps) => formikProps.handleChange,
                    fieldGroup: FIELD_GROUPS.AUTHENTICATION,
                },
                {
                    key: 'details.controllerEndpoint',
                    label: 'Controller Endpoint',
                    placeholder: 'Type url...',
                    component: TextField,
                    getHandler: (formikProps) => formikProps.handleChange,
                    fieldGroup: FIELD_GROUPS.AUTHENTICATION,
                },
                {
                    key: `${MP_CREDETIALS_BASE_PATH}.clientId`,
                    label: 'Client Id',
                    placeholder: 'Type id...',
                    component: TextField,
                    getHandler: (formikProps) => formikProps.handleChange,
                    isCredentialInput: true,
                    fieldGroup: FIELD_GROUPS.AUTHENTICATION,
                },
                {
                    key: `${MP_CREDETIALS_BASE_PATH}.clientSecret`,
                    label: 'Client Secret',
                    placeholder: 'Type secret...',
                    component: TextField,
                    getHandler: (formikProps) => formikProps.handleChange,
                    isCredentialInput: true,
                    fieldGroup: FIELD_GROUPS.AUTHENTICATION,

                },
            ].sort(fieldsSortFunc),
            initValues: Object.assign(
                commonDefaultValues(cloudProviderTypeConfig, cloudProviderIntegrationType, MONITORING_PROVIDERS.APPDYNAMICS),
                {
                    "monitoringProvider": MONITORING_PROVIDERS.APPDYNAMICS,
                    "details": {
                        "monitoringProvider": MONITORING_PROVIDERS.APPDYNAMICS,
                        ...set({}, `${MP_CREDETIALS_BASE_PATH}.credentialsProvider`, CREDENTIALS_PROVIDERS.APPDYNAMICS).details,
                    },
                }
            ),
            validationSchema: Yup.object().shape({
                monitoringProvider: Yup.string().oneOf([MONITORING_PROVIDERS.APPDYNAMICS]).required(),
                details: Yup.object().shape({
                    monitoringProvider: Yup.string().oneOf([MONITORING_PROVIDERS.APPDYNAMICS]).required(),
                    controllerName: Yup.string().required(),
                    controllerEndpoint: Yup.string().required(),
                    credentialsDetail: Yup.object().shape({
                        credentials: Yup.object().shape({
                            credentialsProvider: Yup.string().oneOf([CREDENTIALS_PROVIDERS.APPDYNAMICS]).required(),
                            clientId: Yup.string().required(),
                            clientSecret: Yup.string().required(),
                        }).default(undefined),
                    }),
                }).required(),
                ...commonValidations(cloudProviderTypeConfig),
            }),
        },
        [MONITORING_PROVIDERS.SIGNALFX]: {
            key: MONITORING_PROVIDERS.SIGNALFX,
            label: getMonitoringProviderLabel(MONITORING_PROVIDERS.SIGNALFX),
            iconName: "signalfx",
            setupSupportLink: "https://docs.sedai.io/get-started/setup/connect-monitoring-data#signal-fx",
            formFields: [
                ...commonMPFields(cloudProviderTypeConfig),
                {
                    key: 'details.signalFxApiServer',
                    label: 'SignalFX API Server',
                    placeholder: 'Type url...',
                    component: TextField,
                    getHandler: (formikProps) => formikProps.handleChange,
                    fieldGroup: FIELD_GROUPS.AUTHENTICATION,
                },
                {
                    key: 'details.signalFlowApiServer',
                    label: 'SignalFlow API Server',
                    placeholder: 'Type url...',
                    component: TextField,
                    getHandler: (formikProps) => formikProps.handleChange,
                    fieldGroup: FIELD_GROUPS.AUTHENTICATION,
                },
                {
                    key: `${MP_CREDETIALS_BASE_PATH}.apiKey`,
                    label: 'API Key',
                    placeholder: 'Type key...',
                    component: TextField,
                    getHandler: (formikProps) => formikProps.handleChange,
                    isCredentialInput: true,
                    fieldGroup: FIELD_GROUPS.AUTHENTICATION,
                },
                {
                    key: 'regionDimensions',
                    label: 'Region Dimensions',
                    placeholder: 'Type dimension...',
                    component: MultiChipInput,
                    getHandler: (formikProps) => (v) => formikProps.setFieldValue("regionDimensions", v),
                    order: Number.MAX_SAFE_INTEGER - 1,
                    fieldGroup: FIELD_GROUPS.DIMENSIONS,
                },
                {
                    key: 'azDimensions',
                    label: 'Availability Zone Dimensions',
                    placeholder: 'Type dimension...',
                    component: MultiChipInput,
                    getHandler: (formikProps) => (v) => formikProps.setFieldValue("azDimensions", v),
                    order: Number.MAX_SAFE_INTEGER - 1,
                    fieldGroup: FIELD_GROUPS.DIMENSIONS,
                },
                {
                    key: 'details.programs',
                    label: 'SignalFlow Programs',
                    placeholder: 'Type SignalFlow program...',
                    component: MultiChipInput,
                    getHandler: (formikProps) => (v) => formikProps.setFieldValue("details.programs", v),
                    order: Number.MAX_SAFE_INTEGER - 1,
                    fieldGroup: FIELD_GROUPS.DIMENSIONS,
                },
                {
                    key: 'details.metricNameWildcards',
                    label: 'Metric Name Wildcards',
                    placeholder: 'Add metic name wildcard...',
                    component: MultiChipInput,
                    getHandler: (formikProps) => (v) => formikProps.setFieldValue("details.metricNameWildcards", v),
                    order: Number.MAX_SAFE_INTEGER - 1,
                    fieldGroup: FIELD_GROUPS.DIMENSIONS,
                },
                {
                    key: 'details.dimensionFilters',
                    label: 'Dimensions Filters',
                    component: MultiKeyValueChipInput,
                    transformValueForField: (v) => {
                        return Object.entries(v || {}).reduce((result, [key, valArr]) => {
                            valArr.forEach((value) => (result || []).push({ key, value }));
                            return result;
                        }, []);
                    },
                    getHandler: (formikProps) => (v) => {
                        const groupedDimenstions = (v || []).reduce((result, item) => {
                            result[item.key] = [...(result[item.key] || []), item.value];
                            return result;
                        }, {})
                        formikProps.setFieldValue("details.dimensionFilters", groupedDimenstions);
                    },
                    keyInputPlaceholder: "Type dimension...",
                    valueInputPlaceholder: "Type dimension value...",
                    order: Number.MAX_SAFE_INTEGER - 1,
                    fieldGroup: FIELD_GROUPS.DIMENSIONS,
                },
            ].sort(fieldsSortFunc),
            initValues: Object.assign(
                commonDefaultValues(cloudProviderTypeConfig, cloudProviderIntegrationType, MONITORING_PROVIDERS.SIGNALFX),
                {
                    "monitoringProvider": MONITORING_PROVIDERS.SIGNALFX,
                    "details": {
                        "monitoringProvider": MONITORING_PROVIDERS.SIGNALFX,
                        ...set({}, `${MP_CREDETIALS_BASE_PATH}.credentialsProvider`, CREDENTIALS_PROVIDERS.SIGNALFX).details,

                    },
                }
            ),
            validationSchema: Yup.object().shape({
                monitoringProvider: Yup.string().oneOf([MONITORING_PROVIDERS.SIGNALFX]).required(),
                details: Yup.object().shape({
                    monitoringProvider: Yup.string().oneOf([MONITORING_PROVIDERS.SIGNALFX]).required(),
                    signalFxApiServer: Yup.string().required(),
                    signalFlowApiServer: Yup.string().required(),
                    credentialsDetail: Yup.object().shape({
                        credentials: Yup.object().shape({
                            credentialsProvider: Yup.string().oneOf([CREDENTIALS_PROVIDERS.SIGNALFX]).required(),
                            apiKey: Yup.string().required(),
                        }).default(undefined),
                    }),
                    metricNameWildcards: Yup.array().of(Yup.string()).nullable(),
                    dimensionFilters: Yup.object().nullable(),
                    programs: Yup.array().of(Yup.string()).nullable(),
                }).required(),
                regionDimensions: Yup.array().of(Yup.string()).nullable(),
                azDimensions: Yup.array().of(Yup.string()).nullable(),
                ...commonValidations(cloudProviderTypeConfig),
            }),
        },
        [MONITORING_PROVIDERS.DATADOG]: {
            key: MONITORING_PROVIDERS.DATADOG,
            label: getMonitoringProviderLabel(MONITORING_PROVIDERS.DATADOG),
            iconName: "datadog",
            setupSupportLink: "https://docs.sedai.io/get-started/setup/connect-monitoring-data#datadog",
            hideTestConnection: (values) => get(values, `integrationType`) === INTEGRATION_TYPE.AGENT_BASED,
            formFields: [
                ...commonMPFields(cloudProviderTypeConfig),
                {
                    key: 'details.endpoint',
                    label: 'Endpoint',
                    placeholder: 'Type endpoint url...',
                    component: TextField,
                    getHandler: (formikProps) => formikProps.handleChange,
                    fieldGroup: FIELD_GROUPS.ADVANCED_SETUP,
                },
                {
                    key: `${MP_CREDETIALS_BASE_PATH}.apiKey`,
                    label: 'API Key',
                    placeholder: 'Type key...',
                    component: TextField,
                    getHandler: (formikProps) => formikProps.handleChange,
                    isCredentialInput: true,
                    fieldGroup: FIELD_GROUPS.AUTHENTICATION,
                },
                {
                    key: `${MP_CREDETIALS_BASE_PATH}.applicationKey`,
                    label: 'Application Key',
                    placeholder: 'Type key...',
                    component: TextField,
                    getHandler: (formikProps) => formikProps.handleChange,
                    isCredentialInput: true,
                    fieldGroup: FIELD_GROUPS.AUTHENTICATION,
                },
            ].sort(fieldsSortFunc),
            initValues: Object.assign(
                commonDefaultValues(cloudProviderTypeConfig, cloudProviderIntegrationType, MONITORING_PROVIDERS.DATADOG),
                {
                    "monitoringProvider": MONITORING_PROVIDERS.DATADOG,
                    "details": {
                        "monitoringProvider": MONITORING_PROVIDERS.DATADOG,
                        "endpoint": "https://api.datadoghq.com/",
                        ...set({}, `${MP_CREDETIALS_BASE_PATH}.credentialsProvider`, CREDENTIALS_PROVIDERS.DATADOG).details,

                    }
                },
            ),
            validationSchema: Yup.object().shape({
                monitoringProvider: Yup.string().oneOf([MONITORING_PROVIDERS.DATADOG]).required(),
                details: Yup.object().shape({
                    monitoringProvider: Yup.string().oneOf([MONITORING_PROVIDERS.DATADOG]).required(),
                    endpoint: Yup.string().required(),
                    credentialsDetail: Yup.object().shape({
                        credentials: Yup.object().shape({
                            credentialsProvider: Yup.string().oneOf([CREDENTIALS_PROVIDERS.DATADOG]).required(),
                            apiKey: Yup.string().required(),
                            applicationKey: Yup.string().required(),
                        }).default(undefined),
                    }),
                }).required(),
                ...commonValidations(cloudProviderTypeConfig),
            }),
        },
        [MONITORING_PROVIDERS.NETDATA]: {
            key: MONITORING_PROVIDERS.NETDATA,
            label: getMonitoringProviderLabel(MONITORING_PROVIDERS.NETDATA),
            iconName: "netdata",
            setupSupportLink: "https://docs.sedai.io/get-started/setup/connect-monitoring-data#netdata",
            hideTestConnection: (values) => get(values, `integrationType`) === INTEGRATION_TYPE.AGENT_BASED,
            formFields: [
                ...commonMPFields(cloudProviderTypeConfig),
                {
                    key: 'details.endpoints',
                    label: 'Endpoint',
                    placeholder: 'Type endpoint url...',
                    component: TextField,
                    getHandler: (formikProps) => formikProps.handleChange,
                    fieldGroup: FIELD_GROUPS.AUTHENTICATION,
                },
            ].sort(fieldsSortFunc),
            initValues: Object.assign(
                commonDefaultValues(cloudProviderTypeConfig, cloudProviderIntegrationType, MONITORING_PROVIDERS.NETDATA),
                {
                    "monitoringProvider": MONITORING_PROVIDERS.NETDATA,
                    "details": {
                        "monitoringProvider": MONITORING_PROVIDERS.NETDATA,
                    },
                }
            ),
            validationSchema: Yup.object().shape({
                monitoringProvider: Yup.string().oneOf([MONITORING_PROVIDERS.NETDATA]).required(),
                details: Yup.object().shape({
                    monitoringProvider: Yup.string().oneOf([MONITORING_PROVIDERS.NETDATA]).required(),
                    endpoints: Yup.string().required(),
                }).required(),
            }).default(undefined),
            ...commonValidations(cloudProviderTypeConfig),
        },
    }
    return pick(mpsConf, keysToPick)
};
