import React from "react";
import Icon from "components/Icon/Icon";
import Typography from "components/Typography/Typography";
import Button from "components/Button/Button";
import sedaiAvatarShockedFace from 'assets/img/sedaiAvatarShockedFace.png';
import useStyles from './SomethingWentWrong.styles';


const SomethingWentWrong = () => {
    const cls = useStyles();
    return (
        <div className={cls.container}>
            <Icon name="logoWithNameHorizonal" className={cls.headerLogoHorizontal} />

            <img className={cls.avatar} src={sedaiAvatarShockedFace} alt="Sedai's Shocked Octobus Avatar" />
            <Typography variant="h2" color="textPrimary">Uh oh! We inked up...</Typography>
            <Typography className={cls.subTitle} variant="body2" color="textPrimary">Something went wrong. Please try refreshing the page.</Typography>
            <Typography className={cls.support}  variant="body3" color="textTernary">
                We keep track of these errors, but feel free to contact <Button variant="link" color="primary" component="a" href="mailto:support@sedai.io">support@sedai.io</Button> if this problem persists.
            </Typography>
        </div >
    )
};

export default SomethingWentWrong;