import COLORS from 'themes/colors';
import { createStylesWithTheme } from 'utils/functions';

const styles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 583,
    alignSelf: "center",
    width: "100%",
  },
  header: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "8px",
  },
  checkMark: {
    display: "flex",
    position: "absolute",
    right: 16,
  },
  goalsList: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    margin: "24px 0"
  },
  button: {
    "&:focus, &:focus-visible": {
      outline: "none",
      "&>$goal": {
        borderColor: theme.palette.primary.main,
      }
    }
  },
  goal: {
    display: "flex",
    alignItems: "center",
    padding: 16,
    paddingRight: 48,
    border: `1px solid transparent`,
    background: [COLORS.DARK_NEUTRALS[800], "!important"],
    "&:hover": {
      borderColor: theme.palette.primary.main,
    }
  },
  activeGoal: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    padding: 16,
    paddingRight: 48,
    backgroundColor: [COLORS.PRIMARY[1300], "!important"],
    borderColor: [theme.palette.primary.main, "!important"],
  },
});

export default createStylesWithTheme(styles);