import { get, set, pick } from 'lodash';
import * as Yup from 'yup';
import Switch from 'components/Switch/Switch';
import Input from 'components/Input/Input';
import Textarea from 'components/Textarea/Textarea';
import Dropdown from 'components/BetaDropdown/Dropdown';
import { CLOUD_PROVIDERS, CREDENTIALS_PROVIDERS, KUBERNETES_TYPES, MONITORING_PROVIDERS, INTEGRATION_TYPE, CLUSTER_PROVIDERS } from 'enums';

export const CP_CREDETIALS_BASE_PATH = 'accountDetails.credentialsDetail.credentials'

const commonMonitoringProviders = [MONITORING_PROVIDERS.FEDERATEDPROMETHEUS, MONITORING_PROVIDERS.DATADOG, MONITORING_PROVIDERS.NEWRELIC, MONITORING_PROVIDERS.APPDYNAMICS, MONITORING_PROVIDERS.SIGNALFX, MONITORING_PROVIDERS.NETDATA, MONITORING_PROVIDERS.WAVEFRONT];
const awsMonitoringProvider = [MONITORING_PROVIDERS.CLOUDWATCH, ...commonMonitoringProviders]
export const getAccountMPsKeys = (key) => CLOUD_PROVIDERS.AWS === key ? awsMonitoringProvider : commonMonitoringProviders;

const commonKubCredentialsOptions = [
    { name: CREDENTIALS_PROVIDERS.TOKEN, label: "Token" },
    { name: CREDENTIALS_PROVIDERS.X509_CLIENT_CERT, label: "Client Certificate" },
    { name: CREDENTIALS_PROVIDERS.KUBERNETES_ENV_SUPPLIED, label: "Kubernetes Environment Credentials" },
];

const connectionPreferenceOptions = [
    { name: INTEGRATION_TYPE.AGENTLESS, label: "Agentless" },
    { name: INTEGRATION_TYPE.AGENT_BASED, label: "Smart Agent" }
];

export const FIELD_GROUPS = {
    KUB_CREDENTIALS: "KUB_CREDENTIALS",
    AWS_CREDENTIALS: "AWS_CREDENTIALS",
}

const commonKubCredentialsFields = [
    {
        key: `${CP_CREDETIALS_BASE_PATH}.token`,
        label: "Token",
        component: Textarea,
        hide: (formikProps) => get(formikProps, `values.${CP_CREDETIALS_BASE_PATH}.credentialsProvider`) !== CREDENTIALS_PROVIDERS.TOKEN || get(formikProps, `values.accountDetails.integrationType`) !== INTEGRATION_TYPE.AGENTLESS,
        isCredentialInput: true,
    },
    {
        key: `${CP_CREDETIALS_BASE_PATH}.clientKeyData`,
        label: "Client Certificate Key",
        component: Textarea,
        hide: (formikProps) => get(formikProps, `values.${CP_CREDETIALS_BASE_PATH}.credentialsProvider`) !== CREDENTIALS_PROVIDERS.X509_CLIENT_CERT || get(formikProps, `values.accountDetails.integrationType`) !== INTEGRATION_TYPE.AGENTLESS,
        isCredentialInput: true
    },
    {
        key: `${CP_CREDETIALS_BASE_PATH}.clientCertData`,
        label: "Client Certificate Data",
        component: Textarea,
        hide: (formikProps) => get(formikProps, `values.${CP_CREDETIALS_BASE_PATH}.credentialsProvider`) !== CREDENTIALS_PROVIDERS.X509_CLIENT_CERT || get(formikProps, `values.accountDetails.integrationType`) !== INTEGRATION_TYPE.AGENTLESS,
        isCredentialInput: true
    },

];
const commonKubCredentialsFieldsValidations = {
    token: Yup.string().when('credentialsProvider', {
        is: CREDENTIALS_PROVIDERS.TOKEN,
        then: Yup.string().required(),
        otherwise: Yup.string().nullable(true),
    }),
    clientKeyData: Yup.string().when('credentialsProvider', {
        is: CREDENTIALS_PROVIDERS.X509_CLIENT_CERT,
        then: Yup.string().required(),
        otherwise: Yup.string().nullable(true),
    }),
    clientCertData: Yup.string().when('credentialsProvider', {
        is: CREDENTIALS_PROVIDERS.X509_CLIENT_CERT,
        then: Yup.string().required(),
        otherwise: Yup.string().nullable(true),
    }),
};

export const cloudProvidersConfig = {
    [CLOUD_PROVIDERS.AWS]: {
        key: CLOUD_PROVIDERS.AWS,
        type: CLOUD_PROVIDERS.AWS,
        monitoringProviders: getAccountMPsKeys(CLOUD_PROVIDERS.AWS),
        label: `AWS`,
        iconNames: ["aws"],
        hideTestConnection: (values) => get(values, `${CP_CREDETIALS_BASE_PATH}.credentialsProvider`) === CREDENTIALS_PROVIDERS.AWS_ENV_SUPPLIED,
        formFields: [{
            key: 'name',
            label: 'Account Nickname',
            component: Input,
            labelTooltip: "You can add multiple cloud accounts to Sedai. Give this account a unique name so that you can easily identify and filter its resources within Sedai.",
        },
        {
            key: `${CP_CREDETIALS_BASE_PATH}.credentialsProvider`,
            label: "Credentials",
            component: Dropdown,
            options: [
                { name: CREDENTIALS_PROVIDERS.AWS_STATIC, label: "Use AWS IAM Credentials" },
                { name: CREDENTIALS_PROVIDERS.AWS_ENV_SUPPLIED, label: "Use AWS IAM Role" },
            ],
            getHandler: (formikProps) => (v) => formikProps.setValues({
                ...formikProps.values,
                accountDetails: {
                    ...formikProps.values.accountDetails,
                    ...set({}, `${CP_CREDETIALS_BASE_PATH}.credentialsProvider`, v).accountDetails,
                }
            }),
            isCredentialInput: true
        },
        {
            key: `${CP_CREDETIALS_BASE_PATH}.accessKey`,
            label: "Access Key",
            component: Input,
            hide: (formikProps) => get(formikProps, `values.${CP_CREDETIALS_BASE_PATH}.credentialsProvider`) !== CREDENTIALS_PROVIDERS.AWS_STATIC,
            isCredentialInput: true,
            labelTooltip: "Use the Access Key that was generated from your new new IAM user for Sedai.",
        },
        {
            key: `${CP_CREDETIALS_BASE_PATH}.secretKey`,
            label: "Secret Key",
            type: 'password',
            component: Input,
            hide: (formikProps) => get(formikProps, `values.${CP_CREDETIALS_BASE_PATH}.credentialsProvider`) !== CREDENTIALS_PROVIDERS.AWS_STATIC,
            isCredentialInput: true,
            labelTooltip: "Use the Secret Key that was generated from your new new IAM user for Sedai.",
        },
        {
            key: `${CP_CREDETIALS_BASE_PATH}.role`,
            label: "Role ARN",
            component: Input,
            hide: (formikProps) => get(formikProps, `values.${CP_CREDETIALS_BASE_PATH}.credentialsProvider`) !== CREDENTIALS_PROVIDERS.AWS_ENV_SUPPLIED,
            isCredentialInput: true
        }],
        initValues: {
            accountDetails: {
                cloudProvider: CLOUD_PROVIDERS.AWS,
                ...set({}, `${CP_CREDETIALS_BASE_PATH}.credentialsProvider`, CREDENTIALS_PROVIDERS.AWS_STATIC).accountDetails,

            },
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required(),
            accountDetails: Yup.object().shape({
                cloudProvider: Yup.string().oneOf([CLOUD_PROVIDERS.AWS]).required(),
                credentialsDetail: Yup.object().shape({
                    credentials: Yup.object().shape({
                        credentialsProvider: Yup.string().oneOf([CREDENTIALS_PROVIDERS.AWS_STATIC, CREDENTIALS_PROVIDERS.AWS_ENV_SUPPLIED]).required(),
                        accessKey: Yup.string().when('credentialsProvider', {
                            is: CREDENTIALS_PROVIDERS.AWS_STATIC,
                            then: Yup.string().required(),
                            otherwise: Yup.string().nullable(true),
                        }),
                        secretKey: Yup.string().when('credentialsProvider', {
                            is: CREDENTIALS_PROVIDERS.AWS_STATIC,
                            then: Yup.string().required(),
                            otherwise: Yup.string().nullable(true),
                        }),
                        role: Yup.string().nullable(),
                    }).default(undefined), // credetials are nullable in update forms but in add we initialize it with data so its inner fields got validated
                })
            }),
        }),
    },
    [CLOUD_PROVIDERS.AZURE]: {
        key: CLOUD_PROVIDERS.AZURE,
        type: CLOUD_PROVIDERS.AZURE,
        monitoringProviders: getAccountMPsKeys(CLOUD_PROVIDERS.AZURE),
        label: `Azure`,
        iconNames: ["azure"],
        formFields: [
            {
                key: 'name',
                label: 'Account Nickname',
                component: Input,
                labelTooltip: "You can add multiple cloud accounts to Sedai. Give this account a unique name so that you can easily identify and filter its resources within Sedai.",
            },
            {
                key: 'accountDetails.metadata.apiServerId',
                label: 'Server ID',
                component: Input,

            },
            {
                key: 'accountDetails.metadata.tenantId',
                label: 'Tenant ID',
                component: Input,

            },
            {
                key: 'accountDetails.metadata.region',
                label: 'Region',
                component: Input,

            },
            {
                key: `${CP_CREDETIALS_BASE_PATH}.clientId`,
                label: 'Client ID',
                component: Input,
                isCredentialInput: true
            },
            {
                key: `${CP_CREDETIALS_BASE_PATH}.clientSecret`,
                label: 'Client Secret Key',
                component: Input,
                isCredentialInput: true

            }],
        initValues: {
            accountDetails: {
                cloudProvider: CLOUD_PROVIDERS.AZURE,
                ...set({}, `${CP_CREDETIALS_BASE_PATH}.credentialsProvider`, CREDENTIALS_PROVIDERS.AZURE_CLIENT_CREDENTIALS).accountDetails,
            },
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required(),
            accountDetails: Yup.object().shape({
                cloudProvider: Yup.string().oneOf([CLOUD_PROVIDERS.AZURE]).required(),
                credentialsDetail: Yup.object().shape({
                    credentials: Yup.object().shape({
                        credentialsProvider: Yup.string().oneOf([CREDENTIALS_PROVIDERS.AZURE_CLIENT_CREDENTIALS]).required(),
                        clientId: Yup.string().required(),
                        clientSecret: Yup.string().required(),
                    }).default(undefined), // credetials are nullable in update forms but in add we initialize it with data so its inner fields got validated
                }),
                metadata: Yup.object().shape({
                    apiServerId: Yup.string().required(),
                    tenantId: Yup.string().required(),
                    region: Yup.string().required(),
                }),
            }),
        }),
    },
    [KUBERNETES_TYPES.KUBERNETES_ON_AWS]: {
        key: KUBERNETES_TYPES.KUBERNETES_ON_AWS,
        type: CLOUD_PROVIDERS.KUBERNETES,
        clusterProvider: CLUSTER_PROVIDERS.AWS,
        monitoringProviders: getAccountMPsKeys(KUBERNETES_TYPES.KUBERNETES_ON_AWS),
        label: "Elastic Kubernetes Service",
        iconNames: ["awsEKS"],
        hideTestConnection: (values) => get(values, `accountDetails.integrationType`) === INTEGRATION_TYPE.AGENT_BASED,
        formFields: [
            {
                key: "accountDetails.integrationType",
                label: "Connection Preference",
                component: Dropdown,
                options: connectionPreferenceOptions,
                getHandler: (formikProps) => (v) => formikProps.setValues({
                    ...formikProps.values,
                    accountDetails: {
                        ...pick(formikProps.values.accountDetails, ['cloudProvider', 'metadata.clusterName', 'metadata.clusterProvider']),
                        integrationType: v,
                    }
                }),
                getDisabled: (formikProps) => !!formikProps?.initialValues?.id, // if account is already added disable changing the integrationType
            },
            {
                key: 'name',
                label: 'Account Nickname',
                component: Input,
                labelTooltip: "You can add multiple cloud accounts to Sedai. Give this account a unique name so that you can easily identify and filter its resources within Sedai.",
                fieldGroup: FIELD_GROUPS.AWS_CREDENTIALS,
            },
            {
                key: "accountDetails.metadata.clusterName",
                label: "Cluster Name",
                component: Input,
                fieldGroup: FIELD_GROUPS.KUB_CREDENTIALS,
            },
            {
                key: "accountDetails.metadata.clusterUrl",
                label: "Cluster URL",
                component: Input,
                hide: (formikProps) => get(formikProps, `values.accountDetails.integrationType`) !== INTEGRATION_TYPE.AGENTLESS,
                fieldGroup: FIELD_GROUPS.KUB_CREDENTIALS,
            },
            {
                key: "accountDetails.metadata.caCertData",
                label: "Certifying Authority Certificate",
                component: Textarea,
                hide: (formikProps) => get(formikProps, `values.accountDetails.integrationType`) !== INTEGRATION_TYPE.AGENTLESS,
                fieldGroup: FIELD_GROUPS.KUB_CREDENTIALS,
            },
            {
                key: "accountDetails.metadata.region",
                label: "Region",
                component: Input,
                hide: (formikProps) => get(formikProps, `values.accountDetails.integrationType`) !== INTEGRATION_TYPE.AGENTLESS,
                fieldGroup: FIELD_GROUPS.KUB_CREDENTIALS,
            },
            {
                key: `${CP_CREDETIALS_BASE_PATH}.credentialsProvider`,
                label: "Select a method to connect to Sedai",
                component: Dropdown,
                options: [
                    //...commonKubCredentialsOptions,
                    { name: CREDENTIALS_PROVIDERS.AWS_STATIC, label: "Use AWS IAM credentials" },
                    { name: CREDENTIALS_PROVIDERS.AWS_ENV_SUPPLIED, label: "Use AWS IAM Role" },
                ],
                getHandler: (formikProps) => (v) => formikProps.setValues({
                    ...formikProps.values,
                    accountDetails: {
                        ...formikProps.values.accountDetails,
                        ...set({}, `${CP_CREDETIALS_BASE_PATH}.credentialsProvider`, v).accountDetails,
                    }
                }),
                hide: (formikProps) => get(formikProps, `values.accountDetails.integrationType`) !== INTEGRATION_TYPE.AGENTLESS,
                isCredentialInput: true,
                fieldGroup: FIELD_GROUPS.AWS_CREDENTIALS,
            },
            ...commonKubCredentialsFields,
            {
                key: `${CP_CREDETIALS_BASE_PATH}.accessKey`,
                label: "Access Key",
                component: Input,
                hide: (formikProps) => get(formikProps, `values.${CP_CREDETIALS_BASE_PATH}.credentialsProvider`) !== CREDENTIALS_PROVIDERS.AWS_STATIC || get(formikProps, `values.accountDetails.integrationType`) !== INTEGRATION_TYPE.AGENTLESS,
                isCredentialInput: true,
                fieldGroup: FIELD_GROUPS.AWS_CREDENTIALS,
                labelTooltip: "Use the Access Key that was generated from your new new IAM user for Sedai.",
            },
            {
                key: `${CP_CREDETIALS_BASE_PATH}.secretKey`,
                label: "Secret Key",
                type: 'password',
                component: Input,
                hide: (formikProps) => get(formikProps, `values.${CP_CREDETIALS_BASE_PATH}.credentialsProvider`) !== CREDENTIALS_PROVIDERS.AWS_STATIC || get(formikProps, `values.accountDetails.integrationType`) !== INTEGRATION_TYPE.AGENTLESS,
                isCredentialInput: true,
                fieldGroup: FIELD_GROUPS.AWS_CREDENTIALS,
                labelTooltip: "Use the Secret Key that was generated from your new new IAM user for Sedai.",
            },
            {
                key: `${CP_CREDETIALS_BASE_PATH}.role`,
                label: "Role ARN",
                component: Input,
                hide: (formikProps) => get(formikProps, `values.${CP_CREDETIALS_BASE_PATH}.credentialsProvider`) !== CREDENTIALS_PROVIDERS.AWS_ENV_SUPPLIED || get(formikProps, `values.accountDetails.integrationType`) !== INTEGRATION_TYPE.AGENTLESS,
                isCredentialInput: true,
                fieldGroup: FIELD_GROUPS.AWS_CREDENTIALS,
            }],
        initValues: {
            accountDetails: {
                cloudProvider: CLOUD_PROVIDERS.KUBERNETES,
                integrationType: INTEGRATION_TYPE.AGENTLESS,
                ...set({}, `${CP_CREDETIALS_BASE_PATH}.credentialsProvider`, CREDENTIALS_PROVIDERS.AWS_STATIC).accountDetails,
                metadata: {
                    clusterProvider: CLOUD_PROVIDERS.AWS,
                },
            }
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required(),
            accountDetails: Yup.object().shape({
                cloudProvider: Yup.string().oneOf([CLOUD_PROVIDERS.KUBERNETES]).required(),
                integrationType: Yup.string().oneOf(Object.values(INTEGRATION_TYPE)).required(),
                credentialsDetail: Yup.object().when('integrationType', {
                    is: INTEGRATION_TYPE.AGENTLESS,
                    then: Yup.object().shape({
                        credentials: Yup.object().shape({
                            credentialsProvider: Yup.string().oneOf([/* ...commonKubCredentialsOptions.map(({ name }) => name), */ CREDENTIALS_PROVIDERS.AWS_STATIC, CREDENTIALS_PROVIDERS.AWS_ENV_SUPPLIED]).required(),
                            ...commonKubCredentialsFieldsValidations,
                            accessKey: Yup.string().when('credentialsProvider', {
                                is: CREDENTIALS_PROVIDERS.AWS_STATIC,
                                then: Yup.string().required(),
                                otherwise: Yup.string().nullable(true),
                            }),
                            secretKey: Yup.string().when('credentialsProvider', {
                                is: CREDENTIALS_PROVIDERS.AWS_STATIC,
                                then: Yup.string().required(),
                                otherwise: Yup.string().nullable(true),
                            }),
                            role: Yup.string().nullable(),
                        }).default(undefined), // credetials are nullable in update forms but in add we initialize it with data so its inner fields got validated
                    }),
                    otherwise: Yup.object().nullable(true),
                }),
                metadata: Yup.object().when('integrationType', {
                    is: INTEGRATION_TYPE.AGENTLESS,
                    then: Yup.object().shape({
                        clusterProvider: Yup.string().oneOf([CLOUD_PROVIDERS.AWS]).required(),
                        clusterUrl: Yup.string().required(),
                        region: Yup.string().required(),
                        clusterName: Yup.string().required(),
                        caCertData: Yup.string().nullable(),
                    }),
                    otherwise: Yup.object().shape({
                        clusterProvider: Yup.string().oneOf([CLOUD_PROVIDERS.AWS]).required(),
                        clusterName: Yup.string().required(),
                    })
                }),

            }),
        }),
    },
    [KUBERNETES_TYPES.KUBERNETES_ON_AZURE]: {
        key: KUBERNETES_TYPES.KUBERNETES_ON_AZURE,
        type: CLOUD_PROVIDERS.KUBERNETES,
        clusterProvider: CLUSTER_PROVIDERS.AZURE,
        monitoringProviders: getAccountMPsKeys(KUBERNETES_TYPES.KUBERNETES_ON_AZURE),
        label: "Azure / Kubernetes",
        iconNames: ["aks"],
        hideTestConnection: (values) => get(values, `accountDetails.integrationType`) === INTEGRATION_TYPE.AGENT_BASED,
        formFields: [
            {
                key: "accountDetails.integrationType",
                label: "Connection Preference",
                component: Dropdown,
                options: connectionPreferenceOptions,
                getHandler: (formikProps) => (v) => formikProps.setValues({
                    ...formikProps.values,
                    accountDetails: {
                        ...pick(formikProps.values.accountDetails, ['cloudProvider', 'metadata.clusterName', 'metadata.clusterProvider']),
                        integrationType: v,
                    }
                }),
                getDisabled: (formikProps) => !!formikProps?.initialValues?.id, // if account is already added disable changing the integrationType
            },
            {
                key: 'name',
                label: 'Account Nickname',
                component: Input,
                labelTooltip: "You can add multiple cloud accounts to Sedai. Give this account a unique name so that you can easily identify and filter its resources within Sedai.",
            },
            {
                key: "accountDetails.metadata.clusterName",
                label: "Cluster Name",
                component: Input,

            },
            {
                key: "accountDetails.metadata.clusterUrl",
                label: "Cluster URL",
                component: Input,
                hide: (formikProps) => get(formikProps, `values.accountDetails.integrationType`) !== INTEGRATION_TYPE.AGENTLESS,
            },
            {
                key: 'accountDetails.metadata.apiServerId',
                label: 'Server ID',
                component: Input,
                hide: (formikProps) => get(formikProps, `values.accountDetails.integrationType`) !== INTEGRATION_TYPE.AGENTLESS,
            },
            {
                key: 'accountDetails.metadata.tenantId',
                label: 'Tenant ID',
                component: Input,
                hide: (formikProps) => get(formikProps, `values.accountDetails.integrationType`) !== INTEGRATION_TYPE.AGENTLESS,
            },
            {
                key: 'accountDetails.metadata.region',
                label: 'Region',
                component: Input,
                hide: (formikProps) => get(formikProps, `values.accountDetails.integrationType`) !== INTEGRATION_TYPE.AGENTLESS,
            },
            {
                key: "accountDetails.metadata.caCertData",
                label: "Certifying Authority Certificate",
                component: Textarea,
                hide: (formikProps) => get(formikProps, `values.accountDetails.integrationType`) !== INTEGRATION_TYPE.AGENTLESS,
            },
            {
                key: `${CP_CREDETIALS_BASE_PATH}.credentialsProvider`,
                label: "Credentials Provider",
                component: Dropdown,
                options: [
                    ...commonKubCredentialsOptions,
                    { name: CREDENTIALS_PROVIDERS.AZURE_CLIENT_CREDENTIALS, label: "Azure Client Credentials" }
                ],
                getHandler: (formikProps) => (v) => formikProps.setValues({
                    ...formikProps.values,
                    accountDetails: {
                        ...formikProps.values.accountDetails,
                        ...set({}, `${CP_CREDETIALS_BASE_PATH}.credentialsProvider`, v).accountDetails,
                    }
                }),
                hide: (formikProps) => get(formikProps, `values.accountDetails.integrationType`) !== INTEGRATION_TYPE.AGENTLESS,
                isCredentialInput: true
            },
            ...commonKubCredentialsFields,
            {
                key: `${CP_CREDETIALS_BASE_PATH}.clientId`,
                label: "Client ID",
                component: Input,
                hide: (formikProps) => get(formikProps, `values.${CP_CREDETIALS_BASE_PATH}.credentialsProvider`) !== CREDENTIALS_PROVIDERS.AZURE_CLIENT_CREDENTIALS || get(formikProps, `values.accountDetails.integrationType`) !== INTEGRATION_TYPE.AGENTLESS,
                isCredentialInput: true
            },
            {
                key: `${CP_CREDETIALS_BASE_PATH}.clientSecret`,
                label: "Client Secret",
                component: Input,
                hide: (formikProps) => get(formikProps, `values.${CP_CREDETIALS_BASE_PATH}.credentialsProvider`) !== CREDENTIALS_PROVIDERS.AZURE_CLIENT_CREDENTIALS || get(formikProps, `values.accountDetails.integrationType`) !== INTEGRATION_TYPE.AGENTLESS,
                isCredentialInput: true
            },
        ],
        initValues: {
            accountDetails: {
                cloudProvider: CLOUD_PROVIDERS.KUBERNETES,
                integrationType: INTEGRATION_TYPE.AGENTLESS,
                ...set({}, `${CP_CREDETIALS_BASE_PATH}.credentialsProvider`, CREDENTIALS_PROVIDERS.TOKEN).accountDetails,
                metadata: {
                    clusterProvider: CLOUD_PROVIDERS.AZURE,
                },
            },
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required(),
            accountDetails: Yup.object().shape({
                cloudProvider: Yup.string().oneOf([CLOUD_PROVIDERS.KUBERNETES]).required(),
                integrationType: Yup.string().oneOf(Object.values(INTEGRATION_TYPE)).required(),

                credentialsDetail: Yup.object().when('integrationType', {
                    is: INTEGRATION_TYPE.AGENTLESS,
                    then: Yup.object().shape({
                        credentials: Yup.object().shape({
                            credentialsProvider: Yup.string().oneOf([...commonKubCredentialsOptions.map(({ name }) => name), CREDENTIALS_PROVIDERS.AZURE_CLIENT_CREDENTIALS]).required(),
                            ...commonKubCredentialsFieldsValidations,
                            clientId: Yup.string().when('credentialsProvider', {
                                is: CREDENTIALS_PROVIDERS.AZURE_CLIENT_CREDENTIALS,
                                then: Yup.string().required(),
                                otherwise: Yup.string().nullable(true),
                            }),
                            clientSecret: Yup.string().when('credentialsProvider', {
                                is: CREDENTIALS_PROVIDERS.AZURE_CLIENT_CREDENTIALS,
                                then: Yup.string().required(),
                                otherwise: Yup.string().nullable(true),
                            }),
                        }).default(undefined), // credetials are nullable in update forms but in add we initialize it with data so its inner fields got validated
                    }),
                    otherwise: Yup.object().nullable(true),
                }),
                metadata: Yup.object().when('integrationType', {
                    is: INTEGRATION_TYPE.AGENTLESS,
                    then: Yup.object().shape({
                        clusterProvider: Yup.string().oneOf([CLOUD_PROVIDERS.AZURE]).required(),
                        clusterUrl: Yup.string().required(),
                        caCertData: Yup.string().nullable(),
                        clusterName: Yup.string().required(),
                        apiServerId: Yup.string().required(),
                        tenantId: Yup.string().required(),
                        region: Yup.string().required(),
                    }),
                    otherwise: Yup.object().shape({
                        clusterProvider: Yup.string().oneOf([CLOUD_PROVIDERS.AZURE]).required(),
                        clusterName: Yup.string().required(),
                    })
                }),
            }),
        }),
    },
    [KUBERNETES_TYPES.KUBERNETES_ON_GCP]: {
        key: KUBERNETES_TYPES.KUBERNETES_ON_GCP,
        type: CLOUD_PROVIDERS.KUBERNETES,
        clusterProvider: CLUSTER_PROVIDERS.GCP,
        monitoringProviders: getAccountMPsKeys(KUBERNETES_TYPES.KUBERNETES_ON_GCP),
        label: "Google Kubernetes Engine",
        iconNames: ["googleKubEngine"],
        formFields: [
            {
                key: "accountDetails.integrationType",
                label: "Connection Preference",
                component: Dropdown,
                options: connectionPreferenceOptions,
                getHandler: (formikProps) => (v) => formikProps.setValues({
                    ...formikProps.values,
                    accountDetails: {
                        ...pick(formikProps.values.accountDetails, ['cloudProvider', 'metadata.clusterName', 'metadata.clusterProvider']),
                        integrationType: v,
                    }
                }),
                getDisabled: (formikProps) => !!formikProps?.initialValues?.id, // if account is already added disable changing the integrationType
            },
            {
                key: 'name',
                label: 'Account Nickname',
                component: Input,
                labelTooltip: "You can add multiple cloud accounts to Sedai. Give this account a unique name so that you can easily identify and filter its resources within Sedai.",
            },
            {
                key: "accountDetails.metadata.clusterName",
                label: "Cluster Name",
                component: Input,
            },
            {
                key: "accountDetails.metadata.clusterUrl",
                label: "Cluster URL",
                component: Input,
                hide: (formikProps) => get(formikProps, `values.accountDetails.integrationType`) !== INTEGRATION_TYPE.AGENTLESS,
            },
            {
                key: "accountDetails.metadata.caCertData",
                label: "Certifying Authority Certificate",
                component: Textarea,
                hide: (formikProps) => get(formikProps, `values.accountDetails.integrationType`) !== INTEGRATION_TYPE.AGENTLESS,
            },
            {
                key: "accountDetails.metadata.region",
                label: "Region",
                component: Input,
                hide: (formikProps) => get(formikProps, `values.accountDetails.integrationType`) !== INTEGRATION_TYPE.AGENTLESS,
            },
            {
                key: `${CP_CREDETIALS_BASE_PATH}.serviceAccountJson`,
                label: 'Service Account JSON',
                component: Textarea,
                isCredentialInput: true,
                hide: (formikProps) => get(formikProps, `values.accountDetails.integrationType`) !== INTEGRATION_TYPE.AGENTLESS,
            },
        ],
        initValues: {
            accountDetails: {
                cloudProvider: CLOUD_PROVIDERS.KUBERNETES,
                integrationType: INTEGRATION_TYPE.AGENTLESS,
                ...set({}, `${CP_CREDETIALS_BASE_PATH}.credentialsProvider`, CREDENTIALS_PROVIDERS.GCP_SERVICE_ACCOUNT_JSON).accountDetails,
                metadata: {
                    clusterProvider: CLOUD_PROVIDERS.GCP,
                },
            },
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required(),
            accountDetails: Yup.object().shape({
                cloudProvider: Yup.string().oneOf([CLOUD_PROVIDERS.KUBERNETES]).required(),
                credentialsDetail: Yup.object().when('integrationType', {
                    is: INTEGRATION_TYPE.AGENTLESS,
                    then: Yup.object().shape({
                        credentials: Yup.object().shape({
                            credentialsProvider: Yup.string().oneOf([CREDENTIALS_PROVIDERS.GCP_SERVICE_ACCOUNT_JSON]).required(),
                            serviceAccountJson: Yup.string().required(),
                        }).default(undefined), // credetials are nullable in update forms but in add we initialize it with data so its inner fields got validated
                    }),
                    otherwise: Yup.object().nullable(true),
                }),
                metadata: Yup.object().when('integrationType', {
                    is: INTEGRATION_TYPE.AGENTLESS,
                    then: Yup.object().shape({
                        clusterProvider: Yup.string().oneOf([CLOUD_PROVIDERS.GCP]).required(),
                        clusterUrl: Yup.string().required(),
                        region: Yup.string().required(),
                        clusterName: Yup.string().required(),
                        caCertData: Yup.string().nullable(),
                    }),
                    otherwise: Yup.object().shape({
                        clusterProvider: Yup.string().oneOf([CLOUD_PROVIDERS.GCP]).required(),
                        clusterName: Yup.string().required(),
                    })
                }),
            }),
        }),
    },
    [KUBERNETES_TYPES.KUBERNETES_SELF_MANAGED]: {
        key: KUBERNETES_TYPES.KUBERNETES_SELF_MANAGED,
        type: CLOUD_PROVIDERS.KUBERNETES,
        clusterProvider: CLUSTER_PROVIDERS.SELF_MANAGED,
        monitoringProviders: getAccountMPsKeys(KUBERNETES_TYPES.KUBERNETES_SELF_MANAGED),
        label: "Kubernetes",
        iconNames: ["kubernetes"],
        hideTestConnection: (values) => get(values, `accountDetails.integrationType`) === INTEGRATION_TYPE.AGENT_BASED,
        formFields: [
            {
                key: "accountDetails.integrationType",
                label: "Connection Preference",
                component: Dropdown,
                options: connectionPreferenceOptions,
                getHandler: (formikProps) => (v) => formikProps.setValues({
                    ...formikProps.values,
                    accountDetails: {
                        ...pick(formikProps.values.accountDetails, ['cloudProvider', 'metadata.clusterName', 'metadata.clusterProvider']),
                        integrationType: v,
                    }
                }),
                getDisabled: (formikProps) => !!formikProps?.initialValues?.id, // if account is already added disable changing the integrationType
            },
            {
                key: 'name',
                label: 'Account Nickname',
                component: Input,
                labelTooltip: "You can add multiple cloud accounts to Sedai. Give this account a unique name so that you can easily identify and filter its resources within Sedai.",
            },
            {
                key: "accountDetails.metadata.clusterName",
                label: "Cluster Name",
                component: Input,

            },
            {
                key: "accountDetails.metadata.clusterUrl",
                label: "Cluster URL",
                component: Input,
                hide: (formikProps) => get(formikProps, `values.accountDetails.integrationType`) !== INTEGRATION_TYPE.AGENTLESS,

            },
            {
                key: "accountDetails.metadata.caCertData",
                label: "Certifying Authority Certificate",
                component: Textarea,
                hide: (formikProps) => get(formikProps, `values.accountDetails.integrationType`) !== INTEGRATION_TYPE.AGENTLESS,
            },
            {
                key: `${CP_CREDETIALS_BASE_PATH}.credentialsProvider`,
                label: "Credentials Provider",
                component: Dropdown,
                options: commonKubCredentialsOptions,
                getHandler: (formikProps) => (v) => formikProps.setValues({
                    ...formikProps.values,
                    accountDetails: {
                        ...formikProps.values.accountDetails,
                        ...set({}, `${CP_CREDETIALS_BASE_PATH}.credentialsProvider`, v).accountDetails,
                    }
                }),
                hide: (formikProps) => get(formikProps, `values.accountDetails.integrationType`) !== INTEGRATION_TYPE.AGENTLESS,
                isCredentialInput: true
            },
            ...commonKubCredentialsFields,
        ],
        initValues: {
            accountDetails: {
                cloudProvider: CLOUD_PROVIDERS.KUBERNETES,
                integrationType: INTEGRATION_TYPE.AGENTLESS,
                ...set({}, `${CP_CREDETIALS_BASE_PATH}.credentialsProvider`, CREDENTIALS_PROVIDERS.TOKEN).accountDetails,
                metadata: {
                    clusterProvider: "SELF_MANAGED",
                },
            },
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required(),
            accountDetails: Yup.object().shape({
                cloudProvider: Yup.string().oneOf([CLOUD_PROVIDERS.KUBERNETES]).required(),
                integrationType: Yup.string().oneOf(Object.values(INTEGRATION_TYPE)).required(),
                credentialsDetail: Yup.object().when('integrationType', {
                    is: INTEGRATION_TYPE.AGENTLESS,
                    then: Yup.object().shape({
                        credentials: Yup.object().shape({
                            credentialsProvider: Yup.string().oneOf(commonKubCredentialsOptions.map(({ name }) => name)).required(),
                            ...commonKubCredentialsFieldsValidations,
                        }).default(undefined), // credetials are nullable in update forms but in add we initialize it with data so its inner fields got validated
                    }),
                    otherwise: Yup.object().nullable(true),
                }),
                metadata: Yup.object().when('integrationType', {
                    is: INTEGRATION_TYPE.AGENTLESS,
                    then: Yup.object().shape({
                        clusterProvider: Yup.string().oneOf(["SELF_MANAGED"]).required(),
                        clusterName: Yup.string().required(),
                        clusterUrl: Yup.string().required(),
                        caCertData: Yup.string().nullable(),
                    }),
                    otherwise: Yup.object().shape({
                        clusterProvider: Yup.string().oneOf(["SELF_MANAGED"]).required(),
                        clusterName: Yup.string().required(),
                    })
                }),
            }),
        }),
    },
    [CLOUD_PROVIDERS.VMWARE]: {
        key: CLOUD_PROVIDERS.VMWARE,
        type: CLOUD_PROVIDERS.VMWARE,
        monitoringProviders: getAccountMPsKeys(CLOUD_PROVIDERS.VMWARE),
        label: `VMware`,
        iconNames: ["vmware"],
        formFields: [
            {
                key: 'name',
                label: 'Account Nickname',
                component: Input,
                labelTooltip: "You can add multiple cloud accounts to Sedai. Give this account a unique name so that you can easily identify and filter its resources within Sedai.",
            },
            {
                key: 'accountDetails.server',
                label: 'Server',
                component: Input,

            },
            {
                key: `${CP_CREDETIALS_BASE_PATH}.username`,
                label: 'Username',
                component: Input,
                isCredentialInput: true
            },
            {
                key: `${CP_CREDETIALS_BASE_PATH}.password`,
                label: 'Password',
                type: 'password',
                component: Input,
                isCredentialInput: true

            },
            {
                key: 'accountDetails.skipServerVerification',
                label: 'Skip Server Verification',
                component: Switch,
                getHandler: (formikProps) => (v) => formikProps.setFieldValue("accountDetails.skipServerVerification", v),
            },
            {
                key: `${CP_CREDETIALS_BASE_PATH}.trustStorePath`,
                label: 'Trust Store Path',
                component: Input,
                isCredentialInput: true
            },
            {
                key: `${CP_CREDETIALS_BASE_PATH}.trustStorePassword`,
                label: 'Trust Store Password',
                type: 'password',
                component: Input,
                isCredentialInput: true

            }],
        initValues: {
            accountDetails: {
                cloudProvider: CLOUD_PROVIDERS.VMWARE,
                skipServerVerification: false,
                ...set({}, `${CP_CREDETIALS_BASE_PATH}.credentialsProvider`, CREDENTIALS_PROVIDERS.VMWARE).accountDetails,
            },
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required(),
            accountDetails: Yup.object().shape({
                cloudProvider: Yup.string().oneOf([CLOUD_PROVIDERS.VMWARE]).required(),
                skipServerVerification: Yup.bool().required(),
                server: Yup.string().required(),
                credentialsDetail: Yup.object().shape({
                    credentials: Yup.object().shape({
                        credentialsProvider: Yup.string().oneOf([CREDENTIALS_PROVIDERS.VMWARE]).required(),
                        username: Yup.string().required(),
                        password: Yup.string().required(),
                        trustStorePath: Yup.string().required(),
                        trustStorePassword: Yup.string().required(),
                    }).default(undefined), // credetials are nullable in update forms but in add we initialize it with data so its inner fields got validated
                }),
            }),
        }),
    },
    // smart agent only types
    [KUBERNETES_TYPES.KUBERNETES_ON_OPEN_SHIFT]: {
        key: KUBERNETES_TYPES.KUBERNETES_ON_OPEN_SHIFT,
        type: CLOUD_PROVIDERS.KUBERNETES,
        clusterProvider: CLUSTER_PROVIDERS.OPEN_SHIFT,
        monitoringProviders: getAccountMPsKeys(KUBERNETES_TYPES.KUBERNETES_ON_OPEN_SHIFT),
        label: "OpenShift / Kubernetes",
        iconNames: ["openshift", "kubernetes"],
        hideTestConnection: (values) => get(values, `accountDetails.integrationType`) === INTEGRATION_TYPE.AGENT_BASED,
        formFields: [
            {
                key: 'name',
                label: 'Account Nickname',
                component: Input,
                labelTooltip: "You can add multiple cloud accounts to Sedai. Give this account a unique name so that you can easily identify and filter its resources within Sedai.",
                fieldGroup: FIELD_GROUPS.AWS_CREDENTIALS,
            },
            {
                key: "accountDetails.metadata.clusterName",
                label: "Cluster Name",
                component: Input,
                fieldGroup: FIELD_GROUPS.KUB_CREDENTIALS,
            },
        ],
        initValues: {
            accountDetails: {
                cloudProvider: CLOUD_PROVIDERS.KUBERNETES,
                integrationType: INTEGRATION_TYPE.AGENT_BASED,
                metadata: {
                    clusterProvider: CLOUD_PROVIDERS.OPEN_SHIFT,
                },
            }
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required(),
            accountDetails: Yup.object().shape({
                cloudProvider: Yup.string().oneOf([CLOUD_PROVIDERS.KUBERNETES]).required(),
                integrationType: Yup.string().oneOf([INTEGRATION_TYPE.AGENT_BASED]).required(),
                metadata: Yup.object().shape({
                    clusterProvider: Yup.string().oneOf([CLUSTER_PROVIDERS.OPEN_SHIFT]).required(),
                    clusterName: Yup.string().required(),
                })

            }),
        }),
    },
    [KUBERNETES_TYPES.KUBERNETES_ON_ORACLE]: {
        key: KUBERNETES_TYPES.KUBERNETES_ON_ORACLE,
        type: CLOUD_PROVIDERS.KUBERNETES,
        clusterProvider: CLUSTER_PROVIDERS.ORACLE,
        monitoringProviders: getAccountMPsKeys(KUBERNETES_TYPES.KUBERNETES_ON_ORACLE),
        label: "Oracle / Kubernetes",
        iconNames: ["oracle", "kubernetes"],
        hideTestConnection: (values) => get(values, `accountDetails.integrationType`) === INTEGRATION_TYPE.AGENT_BASED,
        formFields: [
            {
                key: 'name',
                label: 'Account Nickname',
                component: Input,
                labelTooltip: "You can add multiple cloud accounts to Sedai. Give this account a unique name so that you can easily identify and filter its resources within Sedai.",
                fieldGroup: FIELD_GROUPS.AWS_CREDENTIALS,
            },
            {
                key: "accountDetails.metadata.clusterName",
                label: "Cluster Name",
                component: Input,
                fieldGroup: FIELD_GROUPS.KUB_CREDENTIALS,
            },
        ],
        initValues: {
            accountDetails: {
                cloudProvider: CLOUD_PROVIDERS.KUBERNETES,
                integrationType: INTEGRATION_TYPE.AGENT_BASED,
                metadata: {
                    clusterProvider: CLOUD_PROVIDERS.ORACLE,
                },
            }
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required(),
            accountDetails: Yup.object().shape({
                cloudProvider: Yup.string().oneOf([CLOUD_PROVIDERS.KUBERNETES]).required(),
                integrationType: Yup.string().oneOf([INTEGRATION_TYPE.AGENT_BASED]).required(),
                metadata: Yup.object().shape({
                    clusterProvider: Yup.string().oneOf([CLUSTER_PROVIDERS.ORACLE]).required(),
                    clusterName: Yup.string().required(),
                })

            }),
        }),
    },
    [KUBERNETES_TYPES.KUBERNETES_ON_PLATFORM_9]: {
        key: KUBERNETES_TYPES.KUBERNETES_ON_PLATFORM_9,
        type: CLOUD_PROVIDERS.KUBERNETES,
        clusterProvider: CLUSTER_PROVIDERS.PLATFORM_9,
        monitoringProviders: getAccountMPsKeys(KUBERNETES_TYPES.KUBERNETES_ON_PLATFORM_9),
        label: "Platform9",
        iconNames: ["platform9"],
        hideTestConnection: (values) => get(values, `accountDetails.integrationType`) === INTEGRATION_TYPE.AGENT_BASED,
        formFields: [
            {
                key: 'name',
                label: 'Account Nickname',
                component: Input,
                labelTooltip: "You can add multiple cloud accounts to Sedai. Give this account a unique name so that you can easily identify and filter its resources within Sedai.",
                fieldGroup: FIELD_GROUPS.AWS_CREDENTIALS,
            },
            {
                key: "accountDetails.metadata.clusterName",
                label: "Cluster Name",
                component: Input,
                fieldGroup: FIELD_GROUPS.KUB_CREDENTIALS,
            },
        ],
        initValues: {
            accountDetails: {
                cloudProvider: CLOUD_PROVIDERS.KUBERNETES,
                integrationType: INTEGRATION_TYPE.AGENT_BASED,
                metadata: {
                    clusterProvider: CLOUD_PROVIDERS.PLATFORM_9,
                },
            }
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required(),
            accountDetails: Yup.object().shape({
                cloudProvider: Yup.string().oneOf([CLOUD_PROVIDERS.KUBERNETES]).required(),
                integrationType: Yup.string().oneOf([INTEGRATION_TYPE.AGENT_BASED]).required(),
                metadata: Yup.object().shape({
                    clusterProvider: Yup.string().oneOf([CLUSTER_PROVIDERS.Platform9]).required(),
                    clusterName: Yup.string().required(),
                })

            }),
        }),
    },
    [KUBERNETES_TYPES.KUBERNETES_ON_VMWARE_TANZU]: {
        key: KUBERNETES_TYPES.KUBERNETES_ON_VMWARE_TANZU,
        type: CLOUD_PROVIDERS.KUBERNETES,
        clusterProvider: CLUSTER_PROVIDERS.VMWARE_TANZU,
        monitoringProviders: getAccountMPsKeys(KUBERNETES_TYPES.KUBERNETES_ON_VMWARE_TANZU),
        label: "VMware Tanzu",
        iconNames: ["vmwareTanzu"],
        hideTestConnection: (values) => get(values, `accountDetails.integrationType`) === INTEGRATION_TYPE.AGENT_BASED,
        formFields: [
            {
                key: 'name',
                label: 'Account Nickname',
                component: Input,
                labelTooltip: "You can add multiple cloud accounts to Sedai. Give this account a unique name so that you can easily identify and filter its resources within Sedai.",
                fieldGroup: FIELD_GROUPS.AWS_CREDENTIALS,
            },
            {
                key: "accountDetails.metadata.clusterName",
                label: "Cluster Name",
                component: Input,
                fieldGroup: FIELD_GROUPS.KUB_CREDENTIALS,
            },
        ],
        initValues: {
            accountDetails: {
                cloudProvider: CLOUD_PROVIDERS.KUBERNETES,
                integrationType: INTEGRATION_TYPE.AGENT_BASED,
                metadata: {
                    clusterProvider: CLOUD_PROVIDERS.VMWARE_TANZU,
                },
            }
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required(),
            accountDetails: Yup.object().shape({
                cloudProvider: Yup.string().oneOf([CLOUD_PROVIDERS.KUBERNETES]).required(),
                integrationType: Yup.string().oneOf([INTEGRATION_TYPE.AGENT_BASED]).required(),
                metadata: Yup.object().shape({
                    clusterProvider: Yup.string().oneOf([CLUSTER_PROVIDERS.VMWARE_TANZU]).required(),
                    clusterName: Yup.string().required(),
                })

            }),
        }),
    },
    [KUBERNETES_TYPES.KUBERNETES_ON_RANCHER]: {
        key: KUBERNETES_TYPES.KUBERNETES_ON_RANCHER,
        type: CLOUD_PROVIDERS.KUBERNETES,
        clusterProvider: CLUSTER_PROVIDERS.RANCHER,
        monitoringProviders: getAccountMPsKeys(KUBERNETES_TYPES.KUBERNETES_ON_RANCHER),
        label: "Rancher",
        iconNames: ["rancher"],
        hideTestConnection: (values) => get(values, `accountDetails.integrationType`) === INTEGRATION_TYPE.AGENT_BASED,
        formFields: [
            {
                key: 'name',
                label: 'Account Nickname',
                component: Input,
                labelTooltip: "You can add multiple cloud accounts to Sedai. Give this account a unique name so that you can easily identify and filter its resources within Sedai.",
                fieldGroup: FIELD_GROUPS.AWS_CREDENTIALS,
            },
            {
                key: "accountDetails.metadata.clusterName",
                label: "Cluster Name",
                component: Input,
                fieldGroup: FIELD_GROUPS.KUB_CREDENTIALS,
            },
        ],
        initValues: {
            accountDetails: {
                cloudProvider: CLOUD_PROVIDERS.KUBERNETES,
                integrationType: INTEGRATION_TYPE.AGENT_BASED,
                metadata: {
                    clusterProvider: CLOUD_PROVIDERS.RANCHER,
                },
            }
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required(),
            accountDetails: Yup.object().shape({
                cloudProvider: Yup.string().oneOf([CLOUD_PROVIDERS.KUBERNETES]).required(),
                integrationType: Yup.string().oneOf([INTEGRATION_TYPE.AGENT_BASED]).required(),
                metadata: Yup.object().shape({
                    clusterProvider: Yup.string().oneOf([CLUSTER_PROVIDERS.RANCHER]).required(),
                    clusterName: Yup.string().required(),
                })

            }),
        }),
    },
    [KUBERNETES_TYPES.KUBERNETES_ON_IBM]: {
        key: KUBERNETES_TYPES.KUBERNETES_ON_IBM,
        type: CLOUD_PROVIDERS.KUBERNETES,
        clusterProvider: CLUSTER_PROVIDERS.IBM,
        monitoringProviders: getAccountMPsKeys(KUBERNETES_TYPES.KUBERNETES_ON_IBM),
        label: "IBM Cloud Kubernetes Service",
        iconNames: ["ibmKubService"],
        hideTestConnection: (values) => get(values, `accountDetails.integrationType`) === INTEGRATION_TYPE.AGENT_BASED,
        formFields: [
            {
                key: 'name',
                label: 'Account Nickname',
                component: Input,
                labelTooltip: "You can add multiple cloud accounts to Sedai. Give this account a unique name so that you can easily identify and filter its resources within Sedai.",
                fieldGroup: FIELD_GROUPS.AWS_CREDENTIALS,
            },
            {
                key: "accountDetails.metadata.clusterName",
                label: "Cluster Name",
                component: Input,
                fieldGroup: FIELD_GROUPS.KUB_CREDENTIALS,
            },
        ],
        initValues: {
            accountDetails: {
                cloudProvider: CLOUD_PROVIDERS.KUBERNETES,
                integrationType: INTEGRATION_TYPE.AGENT_BASED,
                metadata: {
                    clusterProvider: CLOUD_PROVIDERS.IBM,
                },
            }
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required(),
            accountDetails: Yup.object().shape({
                cloudProvider: Yup.string().oneOf([CLOUD_PROVIDERS.KUBERNETES]).required(),
                integrationType: Yup.string().oneOf([INTEGRATION_TYPE.AGENT_BASED]).required(),
                metadata: Yup.object().shape({
                    clusterProvider: Yup.string().oneOf([CLUSTER_PROVIDERS.IBM]).required(),
                    clusterName: Yup.string().required(),
                })

            }),
        }),
    },
    [KUBERNETES_TYPES.KUBERNETES_ON_DIGITAL_OCEAN]: {
        key: KUBERNETES_TYPES.KUBERNETES_ON_DIGITAL_OCEAN,
        type: CLOUD_PROVIDERS.KUBERNETES,
        clusterProvider: CLUSTER_PROVIDERS.DIGITAL_OCEAN,
        monitoringProviders: getAccountMPsKeys(KUBERNETES_TYPES.KUBERNETES_ON_DIGITAL_OCEAN),
        label: "Digital Ocean / Kubernetes",
        iconNames: ["digitalOcean", "kubernetes"],
        hideTestConnection: (values) => get(values, `accountDetails.integrationType`) === INTEGRATION_TYPE.AGENT_BASED,
        formFields: [
            {
                key: 'name',
                label: 'Account Nickname',
                component: Input,
                labelTooltip: "You can add multiple cloud accounts to Sedai. Give this account a unique name so that you can easily identify and filter its resources within Sedai.",
                fieldGroup: FIELD_GROUPS.AWS_CREDENTIALS,
            },
            {
                key: "accountDetails.metadata.clusterName",
                label: "Cluster Name",
                component: Input,
                fieldGroup: FIELD_GROUPS.KUB_CREDENTIALS,
            },
        ],
        initValues: {
            accountDetails: {
                cloudProvider: CLOUD_PROVIDERS.KUBERNETES,
                integrationType: INTEGRATION_TYPE.AGENT_BASED,
                metadata: {
                    clusterProvider: CLOUD_PROVIDERS.DIGITAL_OCEAN,
                },
            }
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required(),
            accountDetails: Yup.object().shape({
                cloudProvider: Yup.string().oneOf([CLOUD_PROVIDERS.KUBERNETES]).required(),
                integrationType: Yup.string().oneOf([INTEGRATION_TYPE.AGENT_BASED]).required(),
                metadata: Yup.object().shape({
                    clusterProvider: Yup.string().oneOf([CLUSTER_PROVIDERS.DIGITAL_OCEAN]).required(),
                    clusterName: Yup.string().required(),
                })

            }),
        }),
    },
    [KUBERNETES_TYPES.KUBERNETES_ON_ALIBABA]: {
        key: KUBERNETES_TYPES.KUBERNETES_ON_ALIBABA,
        type: CLOUD_PROVIDERS.KUBERNETES,
        clusterProvider: CLUSTER_PROVIDERS.ALIBABA,
        monitoringProviders: getAccountMPsKeys(KUBERNETES_TYPES.KUBERNETES_ON_ALIBABA),
        label: "Alibaba Container Service",
        iconNames: ["alibabaKubService"],
        hideTestConnection: (values) => get(values, `accountDetails.integrationType`) === INTEGRATION_TYPE.AGENT_BASED,
        formFields: [
            {
                key: 'name',
                label: 'Account Nickname',
                component: Input,
                labelTooltip: "You can add multiple cloud accounts to Sedai. Give this account a unique name so that you can easily identify and filter its resources within Sedai.",
                fieldGroup: FIELD_GROUPS.AWS_CREDENTIALS,
            },
            {
                key: "accountDetails.metadata.clusterName",
                label: "Cluster Name",
                component: Input,
                fieldGroup: FIELD_GROUPS.KUB_CREDENTIALS,
            },
        ],
        initValues: {
            accountDetails: {
                cloudProvider: CLOUD_PROVIDERS.KUBERNETES,
                integrationType: INTEGRATION_TYPE.AGENT_BASED,
                metadata: {
                    clusterProvider: CLOUD_PROVIDERS.ALIBABA,
                },
            }
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required(),
            accountDetails: Yup.object().shape({
                cloudProvider: Yup.string().oneOf([CLOUD_PROVIDERS.KUBERNETES]).required(),
                integrationType: Yup.string().oneOf([INTEGRATION_TYPE.AGENT_BASED]).required(),
                metadata: Yup.object().shape({
                    clusterProvider: Yup.string().oneOf([CLUSTER_PROVIDERS.ALIBABA]).required(),
                    clusterName: Yup.string().required(),
                })

            }),
        }),
    },
};

const arrConfig = Object.values(cloudProvidersConfig);
export function getCloudProviderConfig(cpType, cpClusterProvider, configArrayToSearch = arrConfig) {
    return configArrayToSearch.find(({ type, clusterProvider }) => type === cpType && ((!cpClusterProvider && !clusterProvider) || clusterProvider === cpClusterProvider))
}