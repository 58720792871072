import React, { memo } from "react";
import cn from "classnames";
import useStyles from './Typography.styles';

export default memo(React.forwardRef(({ children, component = 'div', color, variant, style = {}, noWrap, wordBreakAll, textTransform, fontWeight, className, ...rest }, ref) => {
    const cls = useStyles();
    const Component = component;
    const colorClass = cls[color];
    return (
        <Component {...rest} ref={ref} style={{ color: colorClass ? undefined : color, fontWeight, textTransform, ...style }} className={cn(colorClass || cls.dynamicColor, cls[variant], noWrap && cls.noWrap, wordBreakAll && cls.wordBreakAll, fontWeight && cls.fontWeight, className)}>
            {children}
        </Component >
    );
}))

