import { createStylesWithTheme } from 'utils/functions';
import tinycolor from "tinycolor2";
import COLORS from 'themes/colors';

const getTextColor = ({ theme, props }) => {
    switch (props.color) {
        case 'primary':
            return theme.palette.primary.main;
        case 'secondary':
            return theme.palette.secondary.main;
        case 'error':
            return theme.palette.error.main;
        case 'textPrimary':
            return theme.palette.text.primary;
        case 'textSecondary':
            return theme.palette.text.secondary;
        default:
            return props.color || 'inherit';
    }
}
const styles = {
    input: {
        resize: "none",
        color: ({ theme }) => theme.palette.text.primary,
        "&.form-control": {
            height: 120,
        },
        "&:focus": {
            color: ({ theme }) => theme.palette.text.primary,
            boxShadow: ({ theme }) => `0 0 0 0.2rem ${tinycolor(theme.palette.primary.main).setAlpha(.2)}`
        },
        "&::placeholder": { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: ({ theme }) => theme.palette.text.primary,
            opacity: 1, /* Firefox */
        }
    },
    // type classes
    outline: ({ theme }) => ({
        ...(theme.typography.body1),
        border: (args) => `1px solid ${getTextColor(args)}`,
        borderRadius: 2,
        padding: "8px 12px",
        height: "fit-content",
        "&.form-control": {
            background: theme.type === "dark" ? COLORS.DARK_NEUTRALS[800] : COLORS.LIGHT_NEUTRALS[0],
            border: (args) => `1px solid ${getTextColor(args)}`,
        },
        "&:focus": {
            border: (args) => `1px solid ${getTextColor(args)}`,
        },
        "&::placeholder": { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: ({ theme }) => theme.palette.text.secondary,
            opacity: 1, /* Firefox */
        }
    }),
    filled: ({ theme }) => ({
        ...(theme.typography.body1),
        borderRadius: 2,
        background: getTextColor,
        border: 0,
        padding: "8px 12px",
        height: "fit-content",
        "&:focus": {
            background: getTextColor,
        },
        "&::placeholder": { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: ({ theme }) => theme.palette.text.secondary,
            opacity: 1, /* Firefox */
        }
    }),

};

export default createStylesWithTheme(styles);