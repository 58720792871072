import React from "react";
import cn from "classnames";
import Typography from "components/Typography/Typography";
import useStyles from './AlertMsgCard.styles';

export default function AlertMsgCard({ children, component = Typography, className, ...rest }) {
    const cls = useStyles({ color: rest.color });
    const Component = component;
    return (
        <Component color="primary" variant="body2" {...rest} className={cn(cls.container, className)}>
            {children}
        </Component >
    );
}


