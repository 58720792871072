import { createStylesWithTheme } from 'utils/functions';
import COLORS from 'themes/colors';
const styles = {
    container: {
        display: "flex",
        flexDirection: "column",
        flex: 1,
        backgroundColor: "#060b19",
        overflow: "auto",
    },
    pageContent: {
        margin: "0 auto",
        maxWidth: 448,
        padding: "120px 24px 0 24px",
        paddingBottom: 90, // margin for the chat bubble
    },
    headerTitle: {
        marginTop: 32,
        marginBottom: 32,
    },
    addEmailsSection: {
        display: "flex",
        flexDirection: "column",
        gap: "16px",
    },
    cardFooterBtns: {
        display: "flex",
        justifyContent: "flex-end",
        gap: "16px",
        marginTop: 12,
    },
    divider: {
        minHeight: 1,
        width: "100%",
        background: COLORS.DARK_NEUTRALS[500],
        margin: "32px 0px",
    },
    alreadyAddedEmailsSection: {
        margin: "24px 0px",
    },
    cardWrapper: {
        marginTop: 8,
        alignItems: "center",
        padding: 8,
        backgroundColor: COLORS.DARK_NEUTRALS[800],
        display: "flex",
        justifyContent: "space-between",
        borderRadius: 4,
        border: "1px solid",
        borderColor: COLORS.DARK_NEUTRALS[500]
    },
    textWithIconContainer: {
        alignItems: "center",
        gap: "8px",
        display: "flex",
        "&>svg": {
            minWidth: 24,
            display: "flex",
        }
    },
    alreadyAddedSectionHeader: {
        paddingBottom: 16,
    },
    curvedLine: {
        position: "absolute",
        bottom: 0,
        right: 0,
        '@media (max-width: 1200px)': {
            display: "none"
        },
        '@media (max-height: 800px)': {
            display: "none"
        }
    },
    inviteInput: {
        display: "flex",
        maxHeight: 200,
        alignItems: "flex-start",
    }
};

export default createStylesWithTheme(styles);