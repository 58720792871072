import COLORS from 'themes/colors';
import { createStylesWithTheme } from 'utils/functions';

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    maxHeight: "100dvh",
    overflow: "auto",
    padding: "44px 24px 32px 24px",
  },
  headerLogoHorizontal: {
    minHeight:24,
    height:24,
    marginBottom: 64,
  },
  avatar: {
    maxWidth: 267,
    marginBottom: 8,
  },
  creatingText: {
    marginBottom: 24,
  },
  waitingText: {
    marginBottom: 32,
  },
  barContainer: {
    display:"flex",
    position: "relative",
    borderRadius: 4,
    width: "100%",
    maxWidth: 400,
    background: COLORS.DARK_NEUTRALS[800],
    height: 4,
    minHeight: 4,
    marginBottom: 8,
  },
  completedBar: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    borderRadius: 4,
    background: ({ theme }) => theme.palette.primary.main
  },
};

export default createStylesWithTheme(styles);