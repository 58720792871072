import React, { useCallback, useReducer, useRef } from 'react';
import reducer from './reducer';
import * as wizardDataActionsTypes from './actionsTypes';


const WizardDataStateContext = React.createContext();
const WizardDataDispatchContext = React.createContext();
const WizardDataRefContext = React.createContext();

WizardDataStateContext.displayName = 'WizardDataStateContext';
WizardDataDispatchContext.displayName = 'WizardDataDispatchContext';
WizardDataRefContext.displayName = 'WizardDataRefContext';

const initialState = {
    companyDetails: {
        goal: null,
    },
    accountDetails: [],
    resourceDetails: {
        resourceTypes: [],
        kubeProviders: [],
        monitoringProviders: [],
        isUsingIac: false
    },
    uiStateDetails: {
        data: {},
        uiData: {},
    },
    userEmails: [],
    //currentState: string
};

function WizardDataProvider({ children }) {
    const lastState = useRef(initialState)
    const getState = useCallback(() => lastState.current, [])
    const reducerWrapper = useCallback((state, action) => lastState.current = reducer(state, action), []);
    const [state, dispatch] = useReducer(reducerWrapper, initialState)
    return (
        <WizardDataStateContext.Provider value={state}>
            <WizardDataDispatchContext.Provider value={dispatch}>
                <WizardDataRefContext.Provider value={getState}>
                    {children}
                </WizardDataRefContext.Provider>
            </WizardDataDispatchContext.Provider>
        </WizardDataStateContext.Provider>
    )
}


function useWizardDataState() {
    const context = React.useContext(WizardDataStateContext)
    if (context === undefined) {
        throw new Error('useWizardDataState must be used within a WizardDataProvider')
    }
    return context
}
function useWizardDataDispatch() {
    const context = React.useContext(WizardDataDispatchContext)
    if (context === undefined) {
        throw new Error('useWizardDataDispatch must be used within a WizardDataProvider')
    }
    return context
}

function useWizardDataRef() {
    const context = React.useContext(WizardDataRefContext)
    if (context === undefined) {
        throw new Error('useWizardDataRef must be used within a WizardDataProvider')
    }
    return context
}

function useWizardData() {
    return [useWizardDataState(), useWizardDataDispatch(), useWizardDataRef()]
}

export { WizardDataProvider, useWizardData, WizardDataStateContext, wizardDataActionsTypes }