const KUBERNETES_TYPES = {
    KUBERNETES_SELF_MANAGED: "KUBERNETES_SELF_MANAGED",
    KUBERNETES_ON_AWS: "KUBERNETES_ON_AWS",
    KUBERNETES_ON_AZURE: "KUBERNETES_ON_AZURE",
    KUBERNETES_ON_GCP: "KUBERNETES_ON_GCP",
    KUBERNETES_ON_OPEN_SHIFT: "KUBERNETES_ON_OPEN_SHIFT",
    KUBERNETES_ON_ORACLE: "KUBERNETES_ON_ORACLE",
    KUBERNETES_ON_PLATFORM_9: "KUBERNETES_ON_PLATFORM_9",
    KUBERNETES_ON_VMWARE_TANZU: "KUBERNETES_ON_VMWARE_TANZU",
    KUBERNETES_ON_RANCHER: "KUBERNETES_ON_RANCHER",
    KUBERNETES_ON_IBM: "KUBERNETES_ON_IBM",
    KUBERNETES_ON_DIGITAL_OCEAN: "KUBERNETES_ON_DIGITAL_OCEAN",
    KUBERNETES_ON_ALIBABA: "KUBERNETES_ON_ALIBABA",
};

export default KUBERNETES_TYPES;