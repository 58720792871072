import React from "react";
import cn from 'classnames';
import Typography from '../Typography/Typography';
import useStyles from './EmptyCardMessage.styles';

export default function EmptyCardMessage({ msg, className }) {
    const cls = useStyles();
    return (
        <Typography className={cn(cls.container, className)} variant="h6" color="textPrimary">{msg || "No data"}</Typography>
    );
}
