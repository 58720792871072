import { createStylesWithTheme } from 'utils/functions';

const styles = {
    bottomRightCircle: {
        position: "absolute",
        overflow: "hidden",
        bottom: 0,
        right: 0,
        pointerEvents: "none",
    },
    bottomRightCircle1: {
        maxWidth: 300,
        "& svg": {
            width: 717,
            transform: "rotate(13deg) translate(-36%, 13%)",
        },
    },
    bottomRightCircle2: {
        maxWidth: 300,
        "& svg": {
            width: 717,
            transform: "rotate(-13deg) translate(-58%, 6%)",
        },
    },

    bottomRightCircleLarge1: {
        "& svg": {
            width: 924,
        },
    },
    bottomRightCircleLarge2: {
        "& svg": {
            width: 880,
        },
    },
}

export default createStylesWithTheme(styles);