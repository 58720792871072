import React from "react";
import Icon from "components/Icon/Icon";
import Typography from "components/Typography/Typography";
import Button from "components/Button/Button";
import sedaiAvatarShockedFace from 'assets/img/sedaiAvatarShockedFace.png';
import useStyles from './EnvironmentCreationFailed.styles';
import { useAuthenticator } from "@aws-amplify/ui-react";


const EnvironmentCreationFailed = () => {
    const cls = useStyles();
    const { user } = useAuthenticator((context) => [context.user]);
    return (
        <div className={cls.container}>
            <Icon name="logoWithNameHorizonal" className={cls.headerLogoHorizontal} />

            <img className={cls.avatar} src={sedaiAvatarShockedFace} alt="Sedai's Shocked Octobus Avatar" />
            <Typography variant="h2" color="textPrimary">Well that inks...</Typography>
            <Typography className={cls.subTitle} variant="body3" color="textPrimary">
                Creating your environment took longer than expected. Our team has been alerted and will reach out to {user?.attributes?.email} once it is ready.
            </Typography>

        </div >
    )
};

export default EnvironmentCreationFailed;