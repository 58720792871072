import COLORS from 'themes/colors';
import { createStylesWithTheme } from 'utils/functions';

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    maxHeight: "100dvh",
    padding: "32px 24px 32px 24px",
    overflow: "auto",
    alignItems: "center",
  },
  headerLogoVertical: {
    minHeight: 86,
    height: 86,
    marginBottom: 40,
  },
  title: {
    alignSelf: "center",
    textAlign: "center",
  },
  alertMsgCard: {
    display: "inline-flex",
    alignItems: "flex-start",
    marginTop: 16,
    marginBottom: 40,
    "&>svg": {
      marginRight: "8px",
      minWidth: 16,
    }
  },
  avatar: {
    maxWidth: 333,
    marginBottom: 24,
  },
};

export default createStylesWithTheme(styles);