import { createStylesWithTheme } from 'utils/functions';

const styles = {
    container: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    formBtn: {
        "&:not(:last-child)": {
            marginRight: 12,
        }
    },
};

export default createStylesWithTheme(styles);