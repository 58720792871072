import { React, useState, useMemo } from "react";
import { useTheme } from "react-jss";
import { useQuery } from "react-query";
import moment from 'moment';
import { FiArrowLeft } from "react-icons/fi";
import { FiCheckCircle } from "react-icons/fi";
import { useToasts } from 'react-toast-notifications';
import Typography from 'components/Typography/Typography';
import Button from 'components/Button/Button';
import useStyles from './InviteTeam.styles';
import MultiChipInput from "views/common/MultiChipInput/MultiChipInput";
import { useMutation, useQueryClient } from "react-query";
import { inviteMembers } from "utils/api/invite";
import segmentioTracking from "utils/segmentioTracking";
import { getTenantDetails } from "utils/api/tenant";
import { Spinner } from "react-bootstrap";
import { QUERY_KEYS } from "enums";
import LoadingPage from "views/common/LoadingPage/LoadingPage";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { checkEnvironmentCreation } from "utils/api/health";
import CornerLines from "views/common/CornerLines/CornerLines";

function validateEmail(email) {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
}

export default function InviteTeam() {
  const queryClient = useQueryClient()
  const { user } = useAuthenticator((context) => [context.user]);
  const { email } = user?.attributes || {};
  const inviterEmail = email;
  const theme = useTheme();
  const cls = useStyles();
  const { addToast } = useToasts();
  const [emails, setEmails] = useState([]);
  const { data: tenantDetails, status: tenantDetailsStatus } = useQuery([QUERY_KEYS.TENANT_DETAILS, inviterEmail], ({ queryKey: [_, email] }) => getTenantDetails(email));
  const { data: healthData, status: healthStatus } = useQuery([QUERY_KEYS.CHECK_ENVIRONMENT_CREATION, user.attributes.email], ({ queryKey: [_, email] }) => checkEnvironmentCreation(email));

  const environmentURL = useMemo(() => {
    return healthData?.envUrl;
  }, [healthData]);

  const alreadyInvitedUsersWithInvitedDate = useMemo(() => {
    let invited = {};
    if (tenantDetails?.invitedUsers) {
      Object.keys(tenantDetails.invitedUsers).forEach((inviter) => {
        Object.keys(tenantDetails.invitedUsers[inviter]).forEach((invitedData) => {
          invited[invitedData] = tenantDetails.invitedUsers[inviter][invitedData].timestamp;
        });
      });
    }
    return invited;
  }, [tenantDetails]);

  const existingUserEmails = useMemo(() => { return Object.keys(alreadyInvitedUsersWithInvitedDate) }, [alreadyInvitedUsersWithInvitedDate]);
  const readdedEmails = useMemo(() => emails.filter(email => existingUserEmails.includes(email)), [emails, existingUserEmails]);
  const { status, mutate: inviteMembersMutation } = useMutation((emails) => inviteMembers(inviterEmail, tenantDetails.envDomain, emails, tenantDetails.userPoolId, "Inside product").then(() => Promise.resolve()), {
    onSuccess: (data) => {
      addToast(`Invitation Sent to ${emails.join(', ')}!`, {
        appearance: 'success',
        autoDismiss: true,
      })
      setEmails([]);
      queryClient.invalidateQueries(QUERY_KEYS.TENANT_DETAILS)
    },
    onError: (error) => {
      addToast("Failed to invite members", {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  })

  return (
    tenantDetailsStatus === 'loading' || healthStatus === "loading" ? <LoadingPage /> :
      <div className={cls.container}>
        <div className={cls.pageContent}>
          <Button variant="link" className={cls.textWithIconContainer} component="a" href={environmentURL} color="textSecondary" ><FiArrowLeft color={theme.palette.text.secondary} /><Typography variant="body2" color={theme.palette.text.secondary}>Back to Sedai app </Typography></Button>
          <Typography variant="h2" className={cls.headerTitle} color={theme.palette.text.primary}>Invite your team to Sedai</Typography>
          <div className={cls.addEmailsSection}>
            <Typography variant="body4" color={theme.palette.text.secondary}>Enter your team’s emails to send an invitation to join your workspace:</Typography>
            <MultiChipInput color="textSecondary" disableAddBtn={true} placeholder={emails.length === 0 ? "Seperate emails with commas" : ""} value={emails} onChange={setEmails} validateValue={validateEmail} className={cls.inviteInput} />
            <div className={cls.cardFooterBtns}>
              {emails.length > 0 && (
                <Button
                  size="medium"
                  variant="outline"
                  color="textSecondary"
                  onClick={(e) => { setEmails([]) }} >Clear</Button>
              )}
              <Button
                size="medium"
                type="submit"
                variant="filled"
                color="primary"
                disabled={emails.length === 0 || readdedEmails.length > 0}
                onClick={(e) => {
                  e.preventDefault();
                  segmentioTracking.sendInvitation({
                    current_step_url: window.location.href,
                    emails: emails,
                  })
                  inviteMembersMutation(emails);
                }}>
                Send {status === "loading" && <Spinner animation="border" role="status" size="sm" />}</Button>
            </div>
          </div>
          <div className={cls.divider} />
          <div className={cls.alreadyAddedEmailsSection}>
            <Typography className={cls.alreadyAddedSectionHeader} variant="body4" color={theme.palette.text.secondary}>{existingUserEmails.length > 0 ? "Invites have been sent to the following teammates:" : "No one from your team has been invited yet."}</Typography>
            {existingUserEmails.map((data) => {
              const invitedDate = alreadyInvitedUsersWithInvitedDate[data] ? moment.utc(parseInt(alreadyInvitedUsersWithInvitedDate[data])).format("MM/DD/YY") : "N/A"
              return <div className={cls.cardWrapper}>
                <div className={cls.textWithIconContainer}>
                  <FiCheckCircle color={theme.palette.primary.main} />
                  <Typography wordBreakAll variant="body2" color="textPrimary" >{data} </Typography>
                </div>
                <Typography variant="body2" color="textTernary" className={cls.time}>{invitedDate}</Typography>
              </div>
            })
            }
          </div>
        </div>
        <CornerLines className={cls.curvedLine} />
      </div>
  );
}

