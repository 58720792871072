import cn from "classnames";
import Icon from "components/Icon/Icon";
import Typography from "components/Typography/Typography";
import Card from "components/Card/Card";
import Button from "components/Button/Button";
import { supportedFlowTypes } from "views/AddCloudWizard/AddCloudWizard.utils";
import WizardLayout from "views/AddCloudWizard/WizardLayout/WizardLayout";
import segmentioTracking from "utils/segmentioTracking";
import { FiCheck } from "react-icons/fi";
import DefaultStepFooterContent from "views/common/WizardFooter/DefaultStepFooterContent/DefaultStepFooterContent";
import useStyles from './SelectAccountType.styles';
import { useState } from "react";
import { useWizardData, wizardDataActionsTypes } from "contexts/WizardData";
import { useToasts } from "react-toast-notifications";
import Bugsnag from "@bugsnag/js";


const SelectAccountType = ({ moveToNextStep, envDomain, email, currentStep, wizardProps }) => {
    const cls = useStyles();

    const [wizardData, wizardDataDispatch, getWizardData] = useWizardData();
    const { addToast } = useToasts();
    const [isLoading, setIsLoading] = useState(false);
    // managing flowtype in local state instead of the wizardData as changing the flowtype will affect other steps data so we don't want to persist it in the wizardData until the user clicks on next button
    const [flowType, setFlowType] = useState(wizardData?.resourceDetails?.resourceTypes?.[0]);

    return (
        <WizardLayout {...wizardProps}>
            <div className={cls.container}>
                <div className={cls.header}>
                    <Typography variant="h2" color="textPrimary">Which resource type do you want to manage first?</Typography>
                    <Typography variant="body3" color="textTernary">Select one resource type to get started (you can add more later too)</Typography>
                </div>
                <div className={cls.cloudEnvironmentTypes}>
                    {supportedFlowTypes.map((envType) => (
                        <Button key={envType.flowType} variant="content" onPress={() => {
                            setFlowType(envType.flowType)
                            segmentioTracking.accountTypeClick({ account_type_click: envType.flowType });
                        }}>
                            <Card className={cn(cls.cloudEnvCard, flowType === envType.flowType && cls.activeCloudEnvCard)}>
                                <div className={cls.multiIconConainer}>
                                    {envType.iconNames.map((iconName) => <Icon name={iconName} />)}
                                </div>
                                <Typography variant="body2" color="textPrimary">{envType.label}  <Typography component="span" variant="body3" color="textTernary">{envType.labelDesc}</Typography></Typography>
                                {flowType === envType.flowType && < FiCheck className={cls.checkMark} />}
                            </Card>
                        </Button>
                    ))}
                </div>
                <DefaultStepFooterContent
                    moveNextIsLoading={isLoading}
                    moveToNextStep={() => {
                        setIsLoading(true);
                        wizardDataDispatch({
                            type: wizardDataActionsTypes.SET_FLOW_TYPE,
                            payload: flowType,
                        })
                        const newWizardState = getWizardData();
                        currentStep.submit(newWizardState, { envDomain, email }).then(() => {
                            setIsLoading(false);
                            moveToNextStep();
                        }).catch((e) => {
                            setIsLoading(false);
                            Bugsnag.notify(e);
                            addToast("Failed to select resource type", {
                                appearance: 'error',
                                autoDismiss: true,
                            });
                        })
                    }}
                    disableNextBtn={!flowType} //not using isCurrentStepValid to disable next button as it won't be valid until the user clicks on next button
                />
            </div>
        </WizardLayout>
    )
};

export default SelectAccountType;