import { createStylesWithTheme } from 'utils/functions';
import { pick } from "lodash";

const getTextColor = ({ theme, props }) => {
    switch (props.color) {
        case 'primary':
            return theme.palette.primary.main;
        case 'secondary':
            return theme.palette.secondary.main;
        case 'accent':
            return theme.palette.accent.main;
        case 'availability':
            return theme.palette.availability.main;
        case 'operation':
            return theme.palette.operation.main;
        case 'success':
            return theme.palette.success.main;
        case 'error':
            return theme.palette.error.main;
        case 'warning':
            return theme.palette.warning.main;
        case 'textPrimary':
            return theme.palette.text.primary;
        case 'textSecondary':
            return theme.palette.text.secondary;
        case 'textTernary':
            return theme.palette.text.ternary;
        default:
            return props.color || 'inherit';
    }
}
const styles = (theme) => ({
    // type classes
    wordBreakAll: {
        wordBreak: "break-all",
    },
    noWrap: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
    },
    ...["primary", "secondary", "accent", "availability", "operation", "success", "error", "warning", "textPrimary", "textSecondary", "textTernary"].reduce((result, color) => {
        result[color] = { color: getTextColor({ theme, props: { color } }) }
        return result
    }, {}),
    ...pick(theme.typography, ["h1", "h2", "h3", "h4", "h5", "h6", "subtitle2", "body1", "body2", "body3", "body4", "body5", "h1mono", "xlmono", "mono1", "mono2", "caption"])
})
export default createStylesWithTheme(styles);
