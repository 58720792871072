
import { useCallback, useState, useRef, } from 'react';
import { debounce } from 'lodash';

export default function useDebouncedState(initValue, debounceMillis = 300, debounceOptions ) {
  const [, setState] = useState();
  const debouncedSetState = useCallback(debounce(setState, debounceMillis, debounceOptions), [setState]);

  const stateRef = useRef(initValue);
  const refSetState = useCallback((callback) => {
    if (typeof callback !== 'function') throw new Error('useDebouncedState changing state function requires function as first argument')
    const newVal = callback(stateRef.current);
    stateRef.current = newVal;
    debouncedSetState(newVal);
  }, [debouncedSetState]);

  return [stateRef.current, refSetState];
};
