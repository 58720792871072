const MONITORING_PROVIDERS = {
    INTERNAL: 'INTERNAL',
    CLOUDWATCH: 'CLOUDWATCH',
    FEDERATEDPROMETHEUS: 'FEDERATEDPROMETHEUS',
    NEWRELIC: 'NEWRELIC',
    NETDATA: 'NETDATA',
    APPDYNAMICS: 'APPDYNAMICS',
    DATADOG: 'DATADOG',
    SIGNALFX: 'SIGNALFX',
    WAVEFRONT: 'WAVEFRONT',
};

export default MONITORING_PROVIDERS;