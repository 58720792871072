import { createStylesWithTheme } from 'utils/functions';
import tinycolor from "tinycolor2";
import COLORS from 'themes/colors';

export const getTextColor = ({ theme, props }) => {
    switch (props.color) {
        case 'primary':
            return theme.palette.primary.main;
        case 'secondary':
            return theme.palette.secondary.main;
        case 'accent':
            return theme.palette.accent.main;
        case 'success':
            return theme.palette.success.main;
        case 'warning':
            return theme.palette.warning.main;
        case 'error':
            return theme.palette.error.main;
        case 'textPrimary':
            return theme.palette.text.primary;
        case 'textSecondary':
            return theme.palette.text.secondary;
        case 'textTernary':
            return theme.palette.text.ternary;
        default:
            return props.color || 'inherit';
    }
}

const styles = (theme) => ({
    container: {
    },
    dropdownTrigger: {
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "9.5px 11px 9.5px 11px",
        borderRadius: 4,
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: (props) => getTextColor({ theme, props }),
        color: theme.palette.text.ternary,
        background: theme.type === "dark" ? COLORS.DARK_NEUTRALS[800] : COLORS.LIGHT_NEUTRALS[0],
        "&$withSelection": {
            color: theme.palette.text.primary,
        },
        "&$active": {
            borderRadius: "4px 4px 0 0",
            //borderWidth: "1px 1px 0px 1px",
        },
        "&:focus": {
            border: (props) => `1px solid ${getTextColor({ theme, props })}`,
        },
        "&$hasError:not($active)": {
            borderColor: theme.palette.error.main,
            background: theme.type === "dark" ? COLORS.RED[1300] : COLORS.RED[50],

            "& $arrowIcon": {
                color: theme.palette.error.main,
            }
        },
        cursor: "pointer",
    },
    withSelection: {},
    active: {},
    hasError: {},
    disabled: {
        opacity: 0.7,
        pointerEvents: "none"
    },
    inputText: {
        display: "flex",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        flex: 1,
        color: theme.palette.text.ternary,
    },
    arrowIconContainer: {
        overflow: "hidden",
        margin: "-2px 0"
    },
    arrowIcon: {
        display: "flex",
        fontSize: 24,
    },
    clearBtnIcon: {
        fontSize: 22,
        color: theme.palette.primary.main,
    },
    dropdownMenuPositioner: {
        zIndex: 1,
        marginTop: -1, // to hide the border of the trigger
    },
    dropdownMenu: {
        background: theme.type === "dark" ? COLORS.DARK_NEUTRALS[800] : COLORS.LIGHT_NEUTRALS[0],
        borderRadius: "0 0 4px 4px",
        border: `solid ${theme.palette.primary.main}`,
        borderWidth: "0px 1px 1px 1px",
        maxHeight: "calc(50vh - 47px)",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
    },
    searchInputContainer: {
        display: "flex",
        alignItems: "center",
        position: "relative",
        padding: "8px 0",
        marginBottom: 8,
    },
    searchInputIcon: {
        position: "absolute",
        fontSize: 16,
        left: 8,
        color: theme.palette.text.ternary
    },
    searchInput: {
        background: "transparent",
        paddingLeft: 32,
        width: '100%',
        border: 0,
        flex: 1,
        color: theme.palette.text.primary,
        "&:focus": {
            outline: "none",
            border: 0,
            boxShadow: "unset",
        },
        "&::placeholder": {
            color: theme.palette.text.ternary,
            opacity: 1, /* Firefox */
            ...(theme.typography.body2),
        },
    },
    nonOptimizedListContainer: {
        display: "flex",
        flexDirection: "column",
    },
    optionContainer: {
        padding: "14px 10px",
        cursor: "pointer",
        "&:hover": {
            background: theme.type === "dark" ? COLORS.DARK_NEUTRALS[300] : COLORS.LIGHT_NEUTRALS[400],
        },
        color: theme.palette.text.ternary
    },
    actvOptionContainer: {
        padding: "14px 10px",
        cursor: "pointer",
        "&:hover": {
            background: theme.type === "dark" ? COLORS.DARK_NEUTRALS[300] : COLORS.LIGHT_NEUTRALS[400],
        },
        color: theme.palette.text.primary
    },
});

export default createStylesWithTheme(styles);