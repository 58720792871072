import { createStylesWithTheme } from 'utils/functions';

const styles = () => ({
    container: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flex: 1,
        padding: "40px 0",
    }
});

export default createStylesWithTheme(styles);