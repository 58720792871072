import { useState } from "react";
import { FiExternalLink } from "react-icons/fi";
import { VscCircleFilled } from "react-icons/vsc";
import Typography from "components/Typography/Typography";
import Button from "components/Button/Button";
import WizardLayout from "views/AddCloudWizard/WizardLayout/WizardLayout";
import DefaultStepFooterContent from "views/common/WizardFooter/DefaultStepFooterContent/DefaultStepFooterContent";

import useStyles from './AWSIAMUser.styles';
import { CONNECT_TO_AWS_CREDENTIALS_TYPES, STEP_KEYS } from "views/AddCloudWizard/AddCloudWizard.utils";
import { useWizardData, wizardDataActionsTypes } from "contexts/WizardData";
import { useToasts } from "react-toast-notifications";
import Bugsnag from "@bugsnag/js";


const AWSIAMUser = ({ wizardProps, currentStep, envDomain, email, moveToStep, moveToNextStep, isCurrentStepValid }) => {
    const cls = useStyles();

    const [isLoading, setIsLoading] = useState(false);
    const [wizardData, wizardDataDispatch, getWizardData] = useWizardData();
    const { addToast } = useToasts();
    return (
        <WizardLayout {...wizardProps}>
            <div className={cls.container}>
                <div className={cls.header}>
                    <Typography variant="h2" color="textPrimary">Create IAM User in your AWS Console</Typography>
                    <Typography variant="body3" color="textSecondary">Set up secure access for Sedai to connect to resources.</Typography>
                </div>
                <div className={cls.content}>
                    <ul className={cls.list}>
                        <li className={cls.listItem}>
                            <VscCircleFilled className={cls.listIcon} />
                            <div className={cls.listItemText}>
                                <Typography variant="body3" color="textPrimary">Go to <Typography component="span" color="primary"><Button variant="link" color="primary" target="_blank" rel="noreferrer noopener" component="a" href="https://console.aws.amazon.com/iam/" >{"AWS Console > IAM >Access Management > Users"}</Button></Typography> and  select Add User</Typography>
                            </div>
                        </li>
                        <li className={cls.listItem}>
                            <VscCircleFilled className={cls.listIcon} />
                            <div className={cls.listItemText}>
                                <Typography variant="body3" color="textPrimary">Select <Typography component="span" fontWeight={800}>Access Key – Programmatic Access</Typography></Typography>
                            </div>
                        </li>
                        <li className={cls.listItem}>
                            <VscCircleFilled className={cls.listIcon} />
                            <div className={cls.listItemText}>
                                <Typography variant="body3" color="textPrimary">Attach policy from previous step</Typography>
                            </div>
                        </li>
                        <li className={cls.listItem}>
                            <VscCircleFilled className={cls.listIcon} />
                            <div className={cls.listItemText}>
                                <Typography variant="body3" color="textPrimary">Copy the Access and Secret Key</Typography>
                            </div>
                        </li>
                    </ul>
                    <Button
                        variant="outline"
                        color="textSecondary"
                        component="a"
                        target="_blank"
                        rel="noreferrer noopener"
                        href="https://docs.sedai.io/get-started/setup/connect-cloud/amazon-web-services/configure-iam/create-iam-user">Learn More <FiExternalLink /></Button>

                    <Typography variant="body3" color="textSecondary">Or set up <Button color="primary" variant="link"
                        onPress={() => {
                            wizardDataDispatch({ type: wizardDataActionsTypes.SET_AWS_CREDENTIALS_TYPE, payload: CONNECT_TO_AWS_CREDENTIALS_TYPES.IAM_ROLE })
                            moveToStep(STEP_KEYS.AWS_MANUAL_IAM_ROLE);
                        }}
                    >IAM Role</Button></Typography>


                    <div>
                        <DefaultStepFooterContent
                            moveNextIsLoading={isLoading}
                            moveToNextStep={() => {
                                setIsLoading(true);
                                wizardDataDispatch({ type: wizardDataActionsTypes.SET_IS_IAM_USER_CREATED, payload: true })
                                const currentWizardData = getWizardData();
                                currentStep.submit(currentWizardData, { envDomain, email }).then(() => {
                                    setIsLoading(false);
                                    moveToNextStep();
                                }).catch((e) => {
                                    setIsLoading(false);
                                    Bugsnag.notify(e);
                                    addToast("Failed to submit data", {
                                        appearance: 'error',
                                        autoDismiss: true,
                                    });
                                })

                            }}
                            moveNextBtnText="I created Sedai’s IAM User"
                        //disableNextBtn={!isCurrentStepValid} // allow the user to click the button as the step is valid after submitting the form
                        />
                    </div>
                </div>
            </div>

        </WizardLayout >
    )
};

export default AWSIAMUser;