import React from 'react';
import { Amplify } from 'aws-amplify';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import awsConfig from './aws-exports';
import './index.css';

const updatedAwsConfig = {
  ...awsConfig,
  oauth: {
    ...awsConfig.oauth,
    domain: process.env.REACT_APP_COGNITO_AUTH_DOMAIN,
    redirectSignIn: process.env.REACT_APP_AUTH_REDIRECT_URL,
    redirectSignOut: process.env.REACT_APP_AUTH_REDIRECT_URL,
  }
}

Amplify.configure(updatedAwsConfig);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
