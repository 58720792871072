import { createStylesWithTheme } from 'utils/functions';

const getColor = ({ theme, props }) => {
    switch (props.color) {
        case 'primary':
            return theme.palette.primary.main;
        case 'secondary':
            return theme.palette.secondary.main;
        case 'availability':
            return theme.palette.availability.main;
        case 'operation':
            return theme.palette.operation.main;
        case 'success':
            return theme.palette.success.main;
        case 'error':
            return theme.palette.error.main;
        case 'warning':
            return theme.palette.warning.main;
        case 'textPrimary':
            return theme.palette.text.primary;
        case 'textSecondary':
            return theme.palette.text.secondary;
        case 'card':
            return theme.palette.background.card;
        case 'cardNested':
            return theme.palette.background.cardNested;
        case 'cardDoubleNested':
            return theme.palette.background.cardDoubleNested;
        default:
            return props.color || 'inherit';
    }
}

const styles = {
    tooltip: {
        zIndex: ({ theme }) => theme.zIndex.tooltip,
        "& .tooltip-inner": {
            minWidth: ({ props }) => props.minWidth,
            padding: 15,
            textAlign: "start",
            background: getColor,
        },
        "&.bs-tooltip-top .arrow:before": {
            borderTopColor: getColor,
        },
        "&.bs-tooltip-bottom .arrow:before": {
            borderBottomColor: getColor,
        },
        "&.bs-tooltip-right .arrow:before": {
            borderRightColor: getColor,
        },
        "&.bs-tooltip-left .arrow:before": {
            borderLeftColor: getColor,
        }
    },

};

export default createStylesWithTheme(styles);