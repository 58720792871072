import axios from "../axios";




export function testCloudProviderConnection(config) {
    return axios.post(`testconnection/cp`, config).then(({ data }) => data);
}



export function testMonitoringProviderConnection(config) {
    return axios.post(`testconnection/mp`, config).then(({ data }) => data);
}
