import COLORS from 'themes/colors';
import { createStylesWithTheme } from 'utils/functions';

// Create your Styles. Remember, since React-JSS uses the default preset,
// most plugins are available without further configuration needed.
const styles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 583,
    alignSelf: "center",
    width: "100%",
  },
  header: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "8px",
    marginBottom: 24,
  },
  pargraphsContainer: {
    marginBottom: 32,
    display: "flex",
    "&>*>*:not(:last-child)": {
      marginBottom: 4,
    },
    "&>*:not(:last-child)": {
      paddingRight: 32,
      borderRight: `1px solid ${theme.palette.divider}`,
    },
    "&>*:not(:first-child)": {
      paddingLeft: 32,
    },
  },
  supportBtn: {
    marginTop: 24,
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    width: "100%",
  },
  mpContent: {
    display: "flex",
    flexDirection: "column",
    marginTop: 16,
    gap: 8,
  },
  advanceSetupToggleWrapper: {
    display: "grid",
    gridTemplateColumns: "max-content max-content",
    gridGap: 8,
    alignItems: "center",
    marginBottom: 16,
    marginTop: 32,
  },
  collapsibleSection: {
    marginTop: 16,
  },
  advancesSetupSectionHeader: {
    marginTop: 24,
    marginBottom: 16,
  },
  submitBtn: {
    marginTop: 16,
  },
  testBtn: {
    marginTop: 16,
  },
  testErrorMsg: {
    marginTop: 8,
  },
  invitedMessage: {
    marginTop: 24,
    padding: 8,
    background: COLORS.GREEN[1300],
    borderRadius: 4,
  },
});

export default createStylesWithTheme(styles);