import { createStylesWithTheme } from 'utils/functions';
import { isObjectLike } from "lodash";
import tinycolor from 'tinycolor2';
import COLORS from 'themes/colors';

const getOutlinedColors = ({ theme, props }) => {
    switch (props.color) {
        case 'primary':
            return {
                outline: theme.type === "dark" ? COLORS.PRIMARY[400] : COLORS.PRIMARY[700],
                hoverBg: theme.type === "dark" ? COLORS.PRIMARY[1100] : COLORS.PRIMARY[100],
                activeBg: theme.type === "dark" ? COLORS.PRIMARY[1000] : COLORS.PRIMARY[200],
                textColor: theme.type === "dark" ? COLORS.PRIMARY[400] : COLORS.PRIMARY[700],
            };
        case 'accent':
            return {
                outline: theme.palette.accent.main,
                hoverBg: theme.type === "dark" ? tinycolor(theme.palette.accent.main).darken(30).toRgbString() : tinycolor(theme.palette.accent.main).lighten(40).toRgbString(),
                activeBg: theme.type === "dark" ? tinycolor(theme.palette.accent.main).darken(35).toRgbString() : tinycolor(theme.palette.accent.main).lighten(35).toRgbString(),
                textColor: theme.palette.accent.main,
            };
        case 'success':
            return {
                outline: theme.type === "dark" ? COLORS.GREEN[400] : COLORS.GREEN[700],
                hoverBg: theme.type === "dark" ? COLORS.GREEN[1100] : COLORS.GREEN[100],
                activeBg: theme.type === "dark" ? COLORS.GREEN[1000] : COLORS.GREEN[200],
                textColor: theme.type === "dark" ? COLORS.GREEN[400] : COLORS.GREEN[600],
            };
        case 'error':
            return {
                outline: theme.type === "dark" ? COLORS.RED[400] : COLORS.RED[700],
                hoverBg: theme.type === "dark" ? COLORS.RED[1100] : COLORS.RED[100],
                focusBorder: theme.type === "dark" ? COLORS.RED[400] : COLORS.RED[700],
                focusShadow: theme.type === "dark" ? COLORS.RED[1100] : COLORS.RED[200],
                activeBg: theme.type === "dark" ? COLORS.RED[1000] : COLORS.RED[200],
                textColor: theme.type === "dark" ? COLORS.RED[400] : COLORS.RED[700],
            };
        case 'warning':
            return {
                outline: theme.type === "dark" ? COLORS.YELLOW[400] : COLORS.YELLOW[700],
                hoverBg: theme.type === "dark" ? COLORS.YELLOW[1100] : COLORS.YELLOW[100],
                activeBg: theme.type === "dark" ? COLORS.YELLOW[1000] : COLORS.YELLOW[200],
                textColor: theme.type === "dark" ? COLORS.YELLOW[400] : COLORS.YELLOW[700],
            };
        case 'textPrimary':
            return {
                outline: theme.palette.text.primary,
                hoverBg: theme.type === "dark" ? tinycolor(theme.palette.text.primary).darken(75).toRgbString() : tinycolor(theme.palette.text.primary).lighten(70).toRgbString(),
                activeBg: theme.type === "dark" ? tinycolor(theme.palette.text.primary).darken(80).toRgbString() : tinycolor(theme.palette.text.primary).lighten(65).toRgbString(),
                textColor: theme.palette.text.primary,
            };
        case 'textSecondary':
            return {
                outline: theme.type === "dark" ? COLORS.DARK_NEUTRALS[50] : COLORS.LIGHT_NEUTRALS[600],
                hoverBg: theme.type === "dark" ? COLORS.DARK_NEUTRALS[700] : COLORS.LIGHT_NEUTRALS[200],
                activeBg: theme.type === "dark" ? COLORS.DARK_NEUTRALS[600] : COLORS.LIGHT_NEUTRALS[300],
                textColor: theme.palette.text.secondary,
            };
        default: {
            const color = props.color || COLORS.PRIMARY;
            if (isObjectLike(color)) return { outline: color[500], hoverBg: color[600], activeBg: color[700], textColor: color[500] }
            else return {
                outline: color,
                hoverBg: theme.type === "dark" ? tinycolor(color).darken(30).toRgbString() : tinycolor(color).lighten(40).toRgbString(),
                activeBg: theme.type === "dark" ? tinycolor(color).darken(35).toRgbString() : tinycolor(color).lighten(35).toRgbString(),
                textColor: color
            }
        }
    }
}

const getFilledColors = ({ theme, props }) => {
    switch (props.color) {
        case 'primary':
            return {
                bg: COLORS.PRIMARY[500],
                hoverBg: COLORS.PRIMARY[600],
                activeBg: COLORS.PRIMARY[700],
                textColor: COLORS.LIGHT_NEUTRALS[0],
            };
        case 'accent':
            return {
                bg: theme.palette.accent.main,
                hoverBg: tinycolor(theme.palette.accent.main).darken(5).toRgbString(),
                focusShadow: tinycolor(theme.palette.accent.main).lighten(5).toRgbString(),
                activeBg: tinycolor(theme.palette.accent.main).darken(10).toRgbString()
            };
        case 'success':
            return {
                bg: COLORS.GREEN[500],
                hoverBg: COLORS.GREEN[600],
                activeBg: COLORS.GREEN[700],
                textColor: COLORS.LIGHT_NEUTRALS[0]
            };
        case 'error':
            return {
                bg: theme.type === "dark" ? COLORS.RED[1200] : COLORS.RED[700],
                hoverBg: theme.type === "dark" ? COLORS.RED[1100] : COLORS.RED[800],
                focusBorder: theme.type === "dark" ? COLORS.RED[400] : COLORS.RED[800],
                focusShadow: theme.type === "dark" ? COLORS.RED[1100] : COLORS.RED[200],
                activeBg: theme.type === "dark" ? COLORS.RED[1000] : COLORS.RED[900],
                textColor: theme.type === "dark" ? COLORS.RED[400] : COLORS.LIGHT_NEUTRALS[0]
            };
        case 'warning':
            return {
                bg: COLORS.YELLOW[500],
                hoverBg: COLORS.YELLOW[600],
                activeBg: COLORS.YELLOW[700],
                textColor: COLORS.LIGHT_NEUTRALS[0]
            };
        case 'textPrimary':
            return {
                bg: theme.palette.text.primary,
                hoverBg: tinycolor(theme.palette.text.primary).darken(5).toRgbString(),
                activeBg: tinycolor(theme.palette.text.primary).darken(10).toRgbString()
            };;
        case 'textSecondary':
            return {
                bg: theme.palette.text.secondary,
                hoverBg: tinycolor(theme.palette.text.secondary).darken(5).toRgbString(),
                activeBg: tinycolor(theme.palette.text.secondary).darken(10).toRgbString()
            };;
        default: {
            const color = props.color || COLORS.PRIMARY;
            if (isObjectLike(color)) return { bg: color[500], hoverBg: color[600], activeBg: color[700] }
            else return { bg: color, hoverBg: tinycolor(color).darken(5).toRgbString(), activeBg: tinycolor(color).darken(10).toRgbString() }
        }
    }
}


const getLinkColors = ({ theme, props }) => {
    let color = props.color;
    switch (props.color) {
        case 'primary':
            color = COLORS.PRIMARY;
            break;
        case 'success':
            color = COLORS.GREEN;
            break;
        case 'error':
            color = COLORS.RED;
            break;
        case 'warning':
            color = COLORS.YELLOW;
            break;
        case 'accent':
            color = theme.palette.accent.main;
            break;
        case 'textPrimary':
            color = theme.palette.text.primary;
            break;
        case 'textSecondary':
            color = theme.palette.text.secondary;
            break;
        default:
            if (!color) color = COLORS.PRIMARY;
    }

    if (isObjectLike(color)) return {
        color: theme.type === "dark" ? color[400] : color[500],
        hoverColor: theme.type === "dark" ? color[300] : color[600],
        activeColor: theme.type === "dark" ? color[500] : color[700],
    }
    else return {
        color: color,
        hoverColor: tinycolor(color).lighten(15).toRgbString(),
        activeColor: tinycolor(color).darken(15).toRgbString(),
    }
}
const getTextCompactColors = ({ theme, props }) => {
    switch (props.color) {
        case 'primary':
            return {
                hoverTextColor: theme.type === "dark" ? COLORS.PRIMARY[300] : COLORS.PRIMARY[600],
                activeTextColor: theme.type === "dark" ? COLORS.PRIMARY[200] : COLORS.PRIMARY[700],
                textColor: theme.palette.primary.main,
            };
        case 'textSecondary':
            return {
                hoverTextColor: theme.type === "dark" ? COLORS.LIGHT_NEUTRALS[600] : COLORS.MID_NEURTRALS[800],
                activeTextColor: theme.type === "dark" ? COLORS.LIGHT_NEUTRALS[500] : COLORS.MID_NEURTRALS[800],
                textColor: theme.palette.text.secondary,
            };
        default: {
            const color = props.color || COLORS.PRIMARY;
            if (isObjectLike(color)) return { outline: color[500], hoverBg: color[600], activeBg: color[700], textColor: color[500] }
            else return {
                hoverTextColor: theme.type === "dark" ? COLORS.PRIMARY[300] : COLORS.PRIMARY[600],
                activeTextColor: theme.type === "dark" ? COLORS.PRIMARY[200] : COLORS.PRIMARY[700],
                textColor: color
            }
        }
    }
}
const styles = (theme) => ({
    default: {
        padding: 0,
        "&:hover": {
            textDecoration: "none"
        },
        "&:not($disabled)": {
            cursor: "pointer",
        },
        "a&": {
            alignItems: "inline-block"
        }
    },
    disabled: {
        pointerEvents: "none",
    },
    fitContent: {
        width: "fit-content",
    },
    smallSize: {
        ...theme.typography.body3,
        padding: "5px 8px",
    },
    mediumSize: {
        ...theme.typography.body2,
        padding: "8px 12px",
    },
    largeSize: {
        ...theme.typography.body1,
        padding: "12px 16px"
    },
    // type classes
    outlineVariant: {
        borderRadius: 4,
        height: "fit-content",
        background: "transparent",
        "-webkit-appearance": "none",
        textAlign: "center",
        "&$disabled": {
            borderColor: "transparent",
            color: theme.type === "dark" ? COLORS.LIGHT_NEUTRALS[900] : COLORS.MID_NEURTRALS[200],
            background: theme.type === "dark" ? COLORS.DARK_NEUTRALS[600] : COLORS.LIGHT_NEUTRALS[300],
        },
        "&:focus-visible": {
            outline: 0,
        }
    },
    outlineVariantDynamic: (props) => {
        const { outline, hoverBg, focusShadow, focusBorder, activeBg, textColor } = getOutlinedColors({ theme, props });
        const focShadow = focusShadow || (theme.type === "dark" ? COLORS.PRIMARY[1000] : COLORS.PRIMARY[100]);
        const focBorder = focusBorder || (theme.type === "dark" ? COLORS.PRIMARY[400] : COLORS.PRIMARY[600]);

        return {
            color: textColor,
            border: `1px solid ${outline}`,
            "&:hover": {
                color: textColor,
                background: hoverBg,

            },
            "&$active": {
                color: textColor,
                background: activeBg,
            },
            "&:focus-visible": {
                borderColor: focBorder,
                boxShadow: `0px 0px 0px 4px ${focShadow}`,
            }
        }
    },
    filledVariant: {
        borderRadius: 4,
        height: "fit-content",
        "-webkit-appearance": "none",
        border: "1px solid transparent",
        textAlign: "center",
        "&$disabled": {
            color: theme.type === "dark" ? COLORS.LIGHT_NEUTRALS[900] : COLORS.MID_NEURTRALS[200],
            background: theme.type === "dark" ? COLORS.DARK_NEUTRALS[600] : COLORS.LIGHT_NEUTRALS[300],
        },
        "&:focus-visible": {
            outline: 0,
        }
    },
    filledVariantDynamic: (props) => {
        const { bg, hoverBg, focusBorder, focusShadow, activeBg, textColor } = getFilledColors({ theme, props });
        const txtColor = textColor || tinycolor.mostReadable(bg, [theme.palette.text.primary, theme.palette.text.secondary, theme.palette.text.ternary]).toRgbString()
        const focShadow = focusShadow || (theme.type === "dark" ? COLORS.PRIMARY[1000] : COLORS.PRIMARY[100]);
        const focBorder = focusBorder || (theme.type === "dark" ? COLORS.PRIMARY[400] : COLORS.PRIMARY[700]);

        return {
            color: txtColor,
            background: bg,
            "&:hover": {
                color: txtColor,
                background: hoverBg,
            },
            "&$active": {
                color: txtColor,
                background: activeBg,
            },
            "&:focus-visible": {
                borderColor: focBorder,
                boxShadow: `0px 0px 0px 4px ${focShadow}`,
            }
        }
    },
    linkVariant: {
        borderRadius: 4,
        background: "transparent",
        height: "fit-content",
        textDecoration: "none",
        border: "1px solid transparent",
        "-webkit-appearance": "none",
        "&:hover": {
            textDecoration: "underline",
        },
        "&:disabled": {
            color: theme.type === "dark" ? COLORS.LIGHT_NEUTRALS[900] : COLORS.MID_NEURTRALS[200],
        },
        "&:focus-visible": {
            outline: 0,
        }
    },
    linkVariantDynamic: (props) => {
        const { color, activeColor, hoverColor, focusBorder } = getLinkColors({ theme, props });
        const focBorder = focusBorder || (theme.type === "dark" ? COLORS.PRIMARY[1000] : COLORS.PRIMARY[200]);

        return {
            color: color,
            "&:hover": {
                color: hoverColor,
            },
            "&$active": {
                color: activeColor,
            },
            "&:focus-visible": {
                outline: 0,
                borderColor: focBorder,
            }
        }
    },
    textVariant: {
        borderRadius: 4,
        height: "fit-content",
        background: "transparent",
        "-webkit-appearance": "none",
        border: `1px solid transparent`,
        textAlign: "center",
        "&$disabled": {
            color: theme.type === "dark" ? COLORS.LIGHT_NEUTRALS[900] : COLORS.MID_NEURTRALS[200],
            background: theme.type === "dark" ? COLORS.DARK_NEUTRALS[600] : COLORS.LIGHT_NEUTRALS[300],
        },
        "&:focus-visible": {
            outline: 0,
        }
    },
    textVariantDynamic: function (props) {
        const { hoverBg, focusShadow, focusBorder, activeBg, textColor } = getOutlinedColors({ theme, props });
        const focShadow = focusShadow || (theme.type === "dark" ? COLORS.PRIMARY[1000] : COLORS.PRIMARY[100]);
        const focBorder = focusBorder || (theme.type === "dark" ? COLORS.PRIMARY[400] : COLORS.PRIMARY[600]);

        return {
            color: textColor,
            "&:hover": {
                color: textColor,
                background: hoverBg,
            },
            "&$active": {
                color: textColor,
                background: activeBg,
            },
            "&:focus-visible": {
                borderColor: focBorder,
                boxShadow: `0px 0px 0px 4px ${focShadow}`,
            }
        }
    },
    textCompactVariant: {
        borderRadius: 4,
        height: "fit-content",
        background: "transparent",
        "-webkit-appearance": "none",
        border: `1px solid transparent`,
        textAlign: "center",
        "&$disabled": {
            color: theme.type === "dark" ? COLORS.DARK_NEUTRALS[50] : COLORS.MID_NEURTRALS[50],
        },
        "&:focus-visible": {
            outline: 0,
        }
    },
    textCompactVariantDynamic: function (props) {
        const { textColor, hoverTextColor, activeTextColor, focusShadow, focusBorder, } = getTextCompactColors({ theme, props });
        const focShadow = focusShadow || (theme.type === "dark" ? COLORS.PRIMARY[1000] : COLORS.PRIMARY[100]);
        const focBorder = focusBorder || (theme.type === "dark" ? COLORS.PRIMARY[400] : COLORS.PRIMARY[600]);

        return {
            color: textColor,
            "&:hover": {
                color: hoverTextColor,
            },
            "&$active": {
                color: activeTextColor,
            },
            "&:focus-visible": {
                borderColor: focBorder,
                boxShadow: `0px 0px 0px 4px ${focShadow}`,
            }
        }
    },
    active: {}

});

export default createStylesWithTheme(styles);
