import React, { useState } from "react";
import cn from "classnames";
import copy from 'copy-to-clipboard';
import { usePopper } from 'react-popper';
import { motion } from 'framer-motion';
import Typography from 'components/Typography/Typography';
import useStyles from './CopyTextBtn.styles';
import Button from "components/Button/Button";

export default function CopyTextBtn({ value, children, ...rest }) {
  const cls = useStyles();
  const [copyClicked, setCopyClicked] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    strategy: "fixed", placement: "top",
    modifiers: [
      {
        name: "offset",
        enabled: true,
        options: {
          offset: [0, 8]
        }
      },
      {
        name: "preventOverflow",
        options: {
          padding: 8,
        },
      },
    ]
  });

  return (
    <>
      {copyClicked && (
        <div
          ref={setPopperElement}
          className={cls.copiedTooltipWrapper}
          style={styles.popper}
          {...attributes.popper}
          onClick={(e) => {e.stopPropagation();}}>
          <motion.div
            className={cls.copiedTooltip}
            initial={{ opacity: 0.3, y: "50%" }}
            animate={{
              opacity: 1,
              y: "0px",
            }}
            transition={{ duration: 1 }}
            onAnimationComplete={() => setCopyClicked(false)}
          >
            <Typography variant="body2" color="#ffffff">Copied!</Typography>
          </motion.div>
        </div>
      )}
      <Button
        ref={setReferenceElement}
        variant="content"
        {...rest}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          copy(value);
          setCopyClicked(true)
        }}>
        {children}
      </Button>
    </>
  );
}

