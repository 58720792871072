import COLORS from 'themes/colors';
import { createStylesWithTheme } from 'utils/functions';

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 583,
    alignSelf: "center",
    alignItems: "center",
    width: "100%",
  },
  header: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "&>*:not(:last-child)": {
      marginBottom: 8,
    },
    marginBottom: 24,
  },
  nameInput: {
    marginTop: 24,
    marginBottom: 24,
  },
  dropdownInput: {
    display: "flex",
    flexDirection: "column",
    alignSelf: "stretch",
    gap: "8px",
    marginTop: 24,
    marginBottom: 24,
  },
  alertTriangle: {
    padding: 4,
    background: COLORS.YELLOW[1300],
    borderRadius: 4,
    marginRight: 8,
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
  },
  headsup: {
    display: "flex",
    alignItems: "center",
    marginBottom: 4,
  },
  scheduelButton: {
    marginTop: 24,
    marginBottom: 8,
  },
  inplaceText: {
    marginBottom: 24,
    textAlign: "center",
  },
};

export default createStylesWithTheme(styles);