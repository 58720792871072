import COLORS from 'themes/colors';
import { createStylesWithTheme } from 'utils/functions';

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    maxHeight: "100dvh",
    padding: "32px 24px 32px 24px",
    overflow: "auto",
    alignItems: "center",
    textAlign: "center",
  },
  headerLogoVertical: {
    minHeight: 86,
    height: 86,
    marginBottom: 40,
  },
  avatar: {
    maxWidth: 130,
    marginBottom: 24,
  },
  title: {
    marginBottom: 8,
  },
  desc: {
    marginBottom: 24,
  },
};

export default createStylesWithTheme(styles);