const CREDENTIALS_PROVIDERS = {
    AWS_ACCOUNT_SUPPLIED: 'AWS_ACCOUNT_SUPPLIED',
    AWS_STATIC: 'AWS_STATIC',
    AWS_ENV_SUPPLIED: 'AWS_ENV_SUPPLIED',
    AZURE_CLIENT_CREDENTIALS: 'AZURE_CLIENT_CREDENTIALS',
    VMWARE: 'VMWARE',
    NEWRELIC: 'NEWRELIC',
    WAVEFRONT: 'WAVEFRONT',
    APPDYNAMICS: 'APPDYNAMICS',
    DATADOG: 'DATADOG',
    SIGNALFX: 'SIGNALFX',
    TOKEN: 'TOKEN',
    X509_CLIENT_CERT: 'X509_CLIENT_CERT',
    CUSTOM: 'CUSTOM',
    VULNSCAN: 'VULNSCAN',
    PAGERDUTY: 'PAGERDUTY',
    USERNAME_PASSWORD: 'USERNAME_PASSWORD',
    FEDERATEDPROMETHEUS_JWT: 'FEDERATEDPROMETHEUS_JWT',
    FEDERATEDPROMETHEUS_NO_AUTH: 'FEDERATEDPROMETHEUS_NO_AUTH',
    FEDERATEDPROMETHEUS_CLIENT_CREDENTIALS: 'FEDERATEDPROMETHEUS_CLIENT_CREDENTIALS',
    FEDERATEDPROMETHEUS_ROP: 'FEDERATEDPROMETHEUS_ROP',
    KUBERNETES_ENV_SUPPLIED: 'KUBERNETES_ENV_SUPPLIED',
    GCP_SERVICE_ACCOUNT_JSON: 'GCP_SERVICE_ACCOUNT_JSON',
    WEBHOOK_NOTIFICATION_API_KEY: "WEBHOOK_NOTIFICATION_API_KEY",
    BASIC_AUTH: 'BASIC_AUTH',
    NETDATA: "NETDATA",
    PIXIE: "PIXIE",
};

export default CREDENTIALS_PROVIDERS;