/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:5ace5aa0-c65c-4e29-a209-d7a68576496d",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_tsEw9oHGt",
    "aws_user_pools_web_client_id": "2spn3omg1e971j4bd19e3htu4n",
    "oauth": {
        "domain": "sedaicustomeronboardd0998cb4-d0998cb4-prod.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/,https://provisioning.sedai.cloud/,https://provisioning.sedai.app/,https://setup.sedai.app/",
        "redirectSignOut": "http://localhost:3000/,https://provisioning.sedai.cloud/,https://provisioning.sedai.app/,https://setup.sedai.app/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
