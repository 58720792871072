import { createStylesWithTheme } from 'utils/functions';

const styles = {
    container: {
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "row-reverse",
    },
    cardHeader: {
        margin: "0 0 30px 0",
    },
    modal: {
        zIndex: ({ theme }) => theme.zIndex.modal,
        "& .modal-dialog": {
            maxWidth: "calc(100vw - 20px)",
            width: 550,
            borderRadius: 2,
            overflow: "hidden",
        },
        "& .modal-content": {
            border: 0,
        }
    },
    modalBackdrop: {
        zIndex: ({ theme }) => theme.zIndex.modal - 1,
    },
    modalCard: {
        padding: 30,
        background: ({ theme }) => theme.palette.background.modal,
    },
};

export default createStylesWithTheme(styles);