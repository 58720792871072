import React, { useState, useMemo, useRef, useEffect, useLayoutEffect } from "react";
import { Manager, Reference, Popper } from "react-popper";
import { motion, AnimatePresence } from "framer-motion";
import ResizeObserver from 'resize-observer-polyfill';
import cn from 'classnames';
import { omit } from 'lodash';
import ClickAwayListener from 'react-click-away-listener';
import { MdClose, MdKeyboardArrowDown } from "react-icons/md";
import { FiSearch } from "react-icons/fi";
import Typography from 'components/Typography/Typography';
import { useTheme } from "react-jss";
import useDebouncedState from "hooks/useDebouncedState";
import EmptyCardMessage from "components/EmptyCardMessage/EmptyCardMessage";
import Checkbox from "components/Checkbox/Checkbox";
import AutoSizer from "react-virtualized-auto-sizer";
import { VariableSizeList } from "react-window";
import useStyles, { getTextColor } from './Dropdown.styles';
import COLORS from "themes/colors";

const ITEM_HEIGHT = 52;
const GUTTER_SIZE = 0;
const LIST_SPACING = 0;
const OPTIMZATION_THRESHOLD = 50;

const Item = React.forwardRef(({ multiSelect, renderLabel, selectedOptionsMap, handleChange, option }, ref) => {
  const cls = useStyles({});

  return (
    <div ref={ref} className={selectedOptionsMap[option.name] ? cls.actvOptionContainer : cls.optionContainer} onClick={() => typeof handleChange === "function" && handleChange(option.name, option)}>
      {multiSelect && typeof renderLabel === 'function' && (
        renderLabel(option, selectedOptionsMap[option.name])
      )}
      {multiSelect && typeof renderLabel !== 'function' && (
        <Checkbox
          value={!!selectedOptionsMap[option.name]}
          color="primary"
          label={option.label}
          key={option.name}
          onClick={(e) => { e.stopPropagation(); }}
        />)
      }
      {!multiSelect && typeof renderLabel === 'function' && (
        renderLabel(option, selectedOptionsMap[option.name])
      )}
      {!multiSelect && typeof renderLabel !== 'function' &&
        <Typography
          variant="body1"
          color="textPrimary"
          key={option.name}
        >
          {option.label}
        </Typography>
      }
    </div>
  );
})
const RowItem = ({ index, style, data }) => {
  const root = useRef();
  const { renderLabel, selectedOptionsMap, handleChange, filteredOptions, multiSelect, setSize } = data;
  const o = filteredOptions[index];
  const approxHeight = root.current && root.current.clientHeight;

  useEffect(() => {
    const currentHeight = root.current?.getBoundingClientRect().height + (GUTTER_SIZE * 2);
    setSize(o, currentHeight);
  }, [approxHeight])


  return (
    <div
      key={o.name}
      style={{
        ...style,
        top: style.top,
        left: style.left + LIST_SPACING,
        height: style.height,
        width: `calc(${style.width || "100%"} - ${LIST_SPACING * 2}px)`,
        padding: `${GUTTER_SIZE}px 0`,
      }}>
      <Item ref={root} multiSelect={multiSelect} renderLabel={renderLabel} selectedOptionsMap={selectedOptionsMap} handleChange={handleChange} option={o} />
    </div>
  )
}
const estimatedItemSize = ITEM_HEIGHT + (GUTTER_SIZE * 2);
const getRowId = ({ name, label }) => {
  if (!name && typeof label !== "string") return null;
  return name + (typeof label === "string" ? `-${label}` : "");
}


const Dropdown = ({ options = [], searchable, disabled, triggerClassName, className, value,
  renderLabel,
  showClearBtn, renderSelectedLabel, onChange, color, onClick, multiSelect, placeholder = "Select Option",
  hasError }) => {
  const theme = useTheme();
  const usedColor = color || theme.palette.border.card
  const cls = useStyles({ color: usedColor });
  const listRef = useRef();
  const [listInnerRef, setListInnerRef] = useState();
  const [listHeight, setListHeight] = useDebouncedState(0, 100);
  const [dropdownOpen, setDropdownToggle] = useState(false);
  const [searchText, setSearchText] = useDebouncedState();
  const [sizeMap, setSizeMap] = useState({});

  const selectedOptions = useMemo(() => {
    return options.filter(({ name }) => {
      return name === value || (multiSelect && Array.isArray(value) && value.includes(name))
    })
  }, [options, value, multiSelect]);

  const selectedOptionsMap = useMemo(() => {
    return selectedOptions.reduce((result, o) => {
      result[o.name] = o;
      return result;
    }, {})
  }, [selectedOptions]);

  const selectedLabel = useMemo(() => {
    return typeof renderSelectedLabel === 'function' ? renderSelectedLabel(selectedOptions) : selectedOptions.map(({ label }) => label).join(', ');
  }, [selectedOptions, renderSelectedLabel]);

  const filteredOptions = useMemo(() => {
    if (!searchText) return options;
    const lowerSearchText = searchText.toLowerCase();
    return options.filter(({ label }) => String(label).toLowerCase().includes(lowerSearchText))
  }, [options, searchText]);

  const handleChange = (name, o) => {
    if (onChange && multiSelect) {
      const newSelectedMap = selectedOptionsMap[name] ? omit(selectedOptionsMap, name) : ({ ...selectedOptionsMap, [name]: o })
      onChange(Object.keys(newSelectedMap), Object.values(newSelectedMap))
    } else if (onChange) {
      onChange(name, o);
      setDropdownToggle(false)
    }
  }

  const dropdownToggle = () => {
    setDropdownToggle(!dropdownOpen);
    setSearchText(() => '')
  };

  const modifiers = [
    {
      name: "sameWidth",
      enabled: true,
      fn: ({ state }) => {
        state.styles.popper.width = `${state.rects.reference.width}px`;
      },
      effect: ({ state }) => {
        state.elements.popper.style.width = `${state.elements.reference.offsetWidth
          }px`;
      },
      phase: "beforeWrite",
      requires: ["computeStyles"],
    },
    {
      name: "preventOverflow",
      options: {
        padding: 0
      }
    },
    {
      name: "prevshiftentOverflow",
      options: {
        enabled: true
      }
    },
    {
      name: "flip",
      options: {
        enabled: true,
        flipVariationsByContent: true,
        behavior: "flip"
      }
    }
  ];


  const shouldShowClearBtn = showClearBtn && selectedOptions.length > 0;

  const getSize = React.useCallback(index => {
    const rowId = getRowId(options[index]);
    return rowId && sizeMap[rowId] ? sizeMap[rowId] : estimatedItemSize;
  }, [sizeMap, options]);

  const setSize = React.useCallback((o, size) => {
    const rowId = getRowId(o);
    if (!size || sizeMap[rowId] === size) return;
    if (listRef.current && listRef.current.resetAfterIndex) {
      listRef.current.resetAfterIndex(0, false)
    }
    setSizeMap((s) => ({ ...s, [rowId]: size }));
  }, [setSizeMap, sizeMap]);

  useLayoutEffect(() => {
    if (listInnerRef) {
      const ro = new ResizeObserver((entries, observer) => {
        const entry = entries[0];
        const { height } = entry.contentRect
        if (height !== listHeight)
          setListHeight(() => height)

      })
      ro.observe(listInnerRef);
      return () => ro.disconnect();
    }

  }, [listInnerRef])


  return (
    <Manager>
      <ClickAwayListener className={cn(cls.container, className)} onClickAway={() => setDropdownToggle(false)}>
        <Reference>
          {({ ref }) => (
            <motion.div
              className={cn(cls.dropdownTrigger, disabled && cls.disabled, (dropdownOpen || shouldShowClearBtn) && cls.active, selectedLabel && cls.withSelection, hasError && cls.hasError, triggerClassName)}
              ref={ref}
              onClick={(e) => {
                e.preventDefault();
                onClick && onClick(e);
                dropdownToggle();
              }}
              animate={(dropdownOpen || shouldShowClearBtn) ? "active" : "inActive"}
              variants={{
                inActive: { borderColor: hasError ? theme.palette.error.main : usedColor, transition: { duration: 0.4, delay: 0.3 } },
                active: { borderColor: theme.palette.primary.main }
              }}
            >
              {selectedLabel ? selectedLabel : (
                <Typography className={cls.inputText} variant="body1">{placeholder || 'Select Option'}</Typography>
              )}
              {shouldShowClearBtn && (
                <div onClick={(e) => {
                  e.stopPropagation()
                  onChange(undefined)
                }}>
                  <MdClose className={cls.clearBtnIcon} />
                </div>
              )}
              {!shouldShowClearBtn && (
                <motion.div
                  className={cls.arrowIconContainer}
                  animate={dropdownOpen ? "active" : "inActive"}
                  variants={{
                    inActive: { rotate: 0, color: hasError ? theme.palette.error.main : getTextColor({ theme, props: { color } }) },
                    active: { rotate: 180, color: theme.palette.primary.main }
                  }}><MdKeyboardArrowDown className={cls.arrowIcon} /></motion.div>
              )}

            </motion.div>
          )}
        </Reference>
        <AnimatePresence>
          {dropdownOpen && (
            <Popper placement="bottom-start" modifiers={modifiers}>
              {({ ref, style, placement }) => (
                <div
                  ref={ref}
                  style={style}
                  data-placement={placement}
                  className={cls.dropdownMenuPositioner}
                >
                  <motion.div
                    className={cls.dropdownMenu}
                    initial="collapsed"
                    animate="open"
                    exit="collapsed"
                    variants={{
                      open: { height: "auto", transition: { delay: 0.2, delayChildren: 0.7 /** delay to child animation after menu is fully oppened to control overflow */ } },
                      collapsed: { height: 0, transition: { duration: 0.2 } }
                    }}
                  >
                    {!!searchable && (
                      <div className={cls.searchInputContainer}>
                        <FiSearch className={cls.searchInputIcon} />
                        <input className={cls.searchInput} placeholder="Search" onChange={(e) => setSearchText(() => e.target.value)} />
                      </div>
                    )}
                    {options.length < OPTIMZATION_THRESHOLD && !!filteredOptions.length && (
                      <motion.div className={cls.nonOptimizedListContainer}
                        variants={{
                          open: { overflow: "hidden", transitionEnd: { overflow: "auto" } },
                          collapsed: { overflow: "hidden" }
                        }}
                      >
                        {filteredOptions.map((o) => (
                          <Item multiSelect={multiSelect} renderLabel={renderLabel} selectedOptionsMap={selectedOptionsMap} handleChange={handleChange} option={o} />
                        ))}
                      </motion.div>
                    )}
                    {options.length >= OPTIMZATION_THRESHOLD && !!filteredOptions.length && (
                      <div style={{ display: "flex", flexDirection: "column", overflow: "hidden", flex: `0 1 ${listInnerRef?.clientHeight || listHeight || estimatedItemSize * filteredOptions.length}px` }}>
                        <AutoSizer>
                          {({ height, width }) => (
                            <VariableSizeList
                              ref={listRef}
                              innerRef={setListInnerRef}
                              key={filteredOptions.length}
                              itemData={{ renderLabel, selectedOptionsMap, handleChange, filteredOptions, multiSelect, setSize }}
                              itemCount={filteredOptions.length}
                              estimatedItemSize={estimatedItemSize}
                              itemSize={getSize}
                              height={height}
                              width={width}
                            >
                              {RowItem}
                            </VariableSizeList>
                          )}
                        </AutoSizer>
                      </div>
                    )}
                    {!filteredOptions.length && <EmptyCardMessage msg="No Options" />}
                  </motion.div>
                </div>
              )}
            </Popper>
          )}
        </AnimatePresence>
      </ClickAwayListener >
    </Manager >
  );
};

export default Dropdown;
