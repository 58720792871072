import { createStylesWithTheme } from 'utils/functions';
import tinycolor from "tinycolor2";

const styles = {
    chipsContainer: {
        display: "flex",
        flexWrap: "wrap",
        marginBottom: 5,
    },
    chip: {
        marginRight: 10,
        marginBottom: 10,
        padding: "4px 6px",
        borderRadius: 2,
        background: ({ theme }) => tinycolor(theme.palette.text.primary).setAlpha(.1),
    },
    closeIcon: {
        fontSize: 18,
        color: ({ theme }) => theme.palette.text.secondary,
        marginLeft: 7,
        cursor: "pointer",
        "&:hover": {
            opacity: 0.7
        }
    },
    inputAddContainer: {
        display: "flex",
        alignItems: "center",
    },
    input: {
        marginRight: 15,
    },
    addBtn: {
        minWidth: 30,
        background: ({ theme }) => theme.type === "dark" ? tinycolor(theme.palette.primary.main).setAlpha(.1) : theme.palette.primary.main,
    }
}

export default createStylesWithTheme(styles);