import React from "react";
import cn from 'classnames';
import { useButton } from '@react-aria/button';
import useStyles from './Button.styles';

const getDefaultTypeSize = (variant) => {
    if (variant === "link") return undefined;
    if (variant === "content") return undefined;
    if (variant === "textCompact") return undefined;

    return "large";
}

const getDefaultTypeComponent = (variant) => {
    //if (variant === "link") return "a";
    if (variant === "content") return "div";
    return "button";
}
export default React.forwardRef((props, ref) => {
    const { className, component = getDefaultTypeComponent(props.variant), variant = "outline", color, size = getDefaultTypeSize(props.variant), active, fitContent, disabled, ...rest } = props;
    let { buttonProps, isPressed } = useButton({ ...rest, elementType: typeof component === "string" ? component : "span" }, ref);
    const cls = useStyles({ color, active, size });
    const Component = component;
    return (
        <Component {...rest} {...buttonProps} ref={ref} disabled={disabled} className={cn(
            cls.default,
            (active || isPressed) && cls.active,
            fitContent && cls.fitContent,
            variant && cls[`${variant}Variant`],
            variant && cls[`${variant}VariantDynamic`],
            disabled && cls.disabled,
            size && cls[`${size}Size`], // send size as null in props to ignore size styles and follow the parent fontsize
            className)}>
            {props.children}
        </Component>
    );
})

