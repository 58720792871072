import { createStylesWithTheme } from 'utils/functions';

const styles = (theme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        flex: 1,
        color: theme.palette.text.primary
    },
});

export default createStylesWithTheme(styles);