import { merge } from 'lodash';
import baseTheme from './baseTheme';
import COLORS from './colors';

const darktheme = {
    type: 'dark',
    palette: {
        primary: {
            main: COLORS.PRIMARY[400],
            light: COLORS.PRIMARY[200],
            dark: COLORS.PRIMARY[1100],
        },
        success: {
            main: COLORS.GREEN[400],
            light: COLORS.GREEN[100],
            dark: COLORS.GREEN[1100],
        },
        warning: {
            main: COLORS.YELLOW[400],
            light: COLORS.YELLOW[100],
            dark: COLORS.YELLOW[1100],
        },
        error: {
            main: COLORS.RED[400],
            light: COLORS.RED[100],
            dark: COLORS.RED[1100],
        },
        text: {
            primary: COLORS.LIGHT_NEUTRALS[400],
            secondary: COLORS.LIGHT_NEUTRALS[700],
            ternary: COLORS.LIGHT_NEUTRALS[900],
            links: "#3385FF",
        },
        background: {
            card: COLORS.DARK_NEUTRALS[700],
            navBar: COLORS.DARK_NEUTRALS[800],
            secondarySideBar: COLORS.DARK_NEUTRALS[900],
            modal: COLORS.DARK_NEUTRALS[700],
            default: COLORS.DARK_NEUTRALS[900],
            sideBar: COLORS.DARK_NEUTRALS[800],

            // from older desing
            popover: COLORS.DARK_NEUTRALS[900],

        },
        divider: COLORS.DARK_NEUTRALS[700],
        border: {
            card: COLORS.DARK_NEUTRALS[400],
        }
    },

}

export default merge({}, baseTheme, darktheme)