import { useMemo, useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { useAuthenticator } from "@aws-amplify/ui-react";
import Typography from "components/Typography/Typography";

import WizardLayout from "views/AddCloudWizard/WizardLayout/WizardLayout";

import { FLOW_TYPES, supportedFlowTypes, supportedKubEnvironments } from "views/AddCloudWizard/AddCloudWizard.utils";
import Icon from "components/Icon/Icon";
import TextField from "components/TextField/TextField";
import Button from "components/Button/Button";
import DefaultStepFooterContent from "views/common/WizardFooter/DefaultStepFooterContent/DefaultStepFooterContent";
import { CLOUD_PROVIDERS } from "enums";
import { defaultEnumsFormat } from "utils/functions";
import { useWizardData, wizardDataActionsTypes } from "contexts/WizardData";
import useStyles from './AccountName.styles';
import Dropdown from "components/BetaDropdown/Dropdown";
import { useTheme } from "theming";
import { FiAlertTriangle } from "react-icons/fi";
import segmentioTracking from "utils/segmentioTracking";
import { PopupModal } from "react-calendly";
import { Spinner } from "react-bootstrap";
import Bugsnag from "@bugsnag/js";



const AccountName = ({ wizardProps, currentStep, envDomain, email, moveToNextStep, isCurrentStepValid }) => {
    const cls = useStyles();
    const theme = useTheme();

    const [wizardData, wizardDataDispatch] = useWizardData()
    const { addToast } = useToasts();
    const [isLoading, setIsLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const { user } = useAuthenticator((context) => [context.user]);
    const flowType = wizardData?.resourceDetails?.resourceTypes?.[0];
    const flowTypeObj = supportedFlowTypes.find((f) => f.flowType === flowType);
    const cloudProvider = wizardData?.uiStateDetails?.data?.sedaiAccount?.accountDetails?.cloudProvider;

    const calendlyPageSettings = {
        hideEventTypeDetails: false,
        hideLandingPageDetails: false,
        primaryColor: theme.palette.primary.main,
    }

    const pageDetails = useMemo(() => {
        if (cloudProvider === CLOUD_PROVIDERS.AWS) return { title: "Great! Tell us more about your AWS account", desc: "You can add multiple AWS accounts to Sedai. Give this one a unique name so that you can easily identify its resources within the platform." };
        if (cloudProvider === CLOUD_PROVIDERS.KUBERNETES) return { title: "Great! Tell us more about your Kubernetes cluster", desc: <>Once you confirm setup and your environment is provisioned, we will send instructions for deploying Sedai’s <Button component="a" variant="link" color="primary" target="_blank" rel="noreferrer noopener" href="https://docs.sedai.io/get-started/setup/connect-cloud/kubernetes/sedai-smart-agent">Smart Agent</Button> using either Helm or Kubectl to {user?.attributes?.email || "your email"}</> };
        return { title: "Great! Tell us more about your account" };
    }, [cloudProvider, user]);



    const nameInputLabel = useMemo(() => {
        if (cloudProvider === CLOUD_PROVIDERS.KUBERNETES) return "Cluster Name";
        return "Account Nickname";
    }, [cloudProvider]);

    const usageInput = useMemo(() => {
        if (flowTypeObj?.flowType === FLOW_TYPES.LAMBDA)
            return {
                label: "How many total invocations per month in this account?",
                options: [{
                    label: "Less than 500k",
                    min: 0,
                    max: 500000,
                    name: "0-500000",
                },
                {
                    label: "Between 500k and 10M",
                    min: 500000,
                    max: 10000000,
                    name: "500000-10000000",
                },
                {
                    label: "Between 10M and 50M",
                    min: 10000000,
                    max: 50000000,
                    name: "10000000-50000000",
                },
                {
                    label: "Between 50M and 1B",
                    min: 50000000,
                    max: 10000000000,
                    name: "50000000-10000000000",
                },
                {
                    label: "More than 1B",
                    min: 10000000000,
                    max: Number.MAX_SAFE_INTEGER,
                    name: `10000000000-${Number.MAX_SAFE_INTEGER}`,
                }],
                veryLowUsageWarningMsg: "This is not enough data for Sedai’s algorithms to optimize resources. We do not recommend proceeding with this account unless it has a higher monthly invocation count. Chat with our team to discuss your goals with Sedai.",
                lowUsageWarningMsg: "This might not be sufficient data for Sedai’s algorithms to optimize all resources in this account. We recommend connecting a production account with a higher monthly invocation count, or chat with our team to discuss your goals with Sedai.",
            };
        if (flowTypeObj?.flowType === FLOW_TYPES.ECS)
            return {
                label: "How many total tasks are in this account?",
                options: [{
                    label: "Less than 10",
                    min: 1,
                    max: 10,
                    name: "1-10",
                },
                {
                    label: "Between 10 and 25",
                    min: 10,
                    max: 25,
                    name: "10-25",
                },
                {
                    label: "Between 25 and 50",
                    min: 25,
                    max: 50,
                    name: "25-50",
                },
                {
                    label: "Between 50 and 1,000",
                    min: 50,
                    max: 1000,
                    name: "50-1000",
                },
                {
                    label: "More than 1,000",
                    min: 1000,
                    max: Number.MAX_SAFE_INTEGER,
                    name: `1000-${Number.MAX_SAFE_INTEGER}`,
                }],
                veryLowUsageWarningMsg: "This is not enough data for Sedai’s algorithms to optimize resources. We do not recommend proceeding with this account unless it has more tasks. Chat with our team to discuss your goals with Sedai.",
                lowUsageWarningMsg: "This is not enough data for Sedai’s algorithms to optimize all resources in this account. We recommend connecting a production account with more tasks, or chat with our team to discuss your goals with Sedai.",

            };

        if (cloudProvider === CLOUD_PROVIDERS.KUBERNETES)
            return {
                label: "How many total pods are in this cluster?",
                options: [{
                    label: "Less than 10",
                    min: 1,
                    max: 10,
                    name: "1-10",
                },
                {
                    label: "Between 10 and 25",
                    min: 10,
                    max: 25,
                    name: "10-25",
                },
                {
                    label: "Between 25 and 50",
                    min: 25,
                    max: 50,
                    name: "25-50",
                },
                {
                    label: "Between 50 and 1,000",
                    min: 50,
                    max: 1000,
                    name: "50-1000",
                },
                {
                    label: "More than 1,000",
                    min: 1000,
                    max: Number.MAX_SAFE_INTEGER,
                    name: `1000-${Number.MAX_SAFE_INTEGER}`,
                }],
                veryLowUsageWarningMsg: "This is not enough data for Sedai’s algorithms to optimize resources. We do not recommend proceeding with this cluster unless it has more pods. Chat with our team to discuss your goals with Sedai.",
                lowUsageWarningMsg: "This is not enough data for Sedai’s algorithms to optimize all resources in this cluster. We recommend connecting a production account with more pods, or chat with our team to discuss your goals with Sedai.",
            };

        return "";
    }, [flowTypeObj, cloudProvider]);
    const accountName = wizardData?.accountDetails?.[0]?.name;
    const trafficMin = wizardData?.accountDetails?.[0]?.trafficRange?.min;
    const trafficMax = wizardData?.accountDetails?.[0]?.trafficRange?.max;

    const usageVal = `${trafficMin}-${trafficMax}`;
    const isVeryLowUsage = usageInput?.options?.[0]?.name === usageVal;
    const isLowUsage = usageInput?.options?.[1]?.name === usageVal;
    const isBelowExpectedUsage = isVeryLowUsage || isLowUsage;

    const initialName = useMemo(() => {
        const stringEnvDomain = envDomain ? `${envDomain} - ` : ""

        if (cloudProvider === CLOUD_PROVIDERS.KUBERNETES)
            return "";

        if (cloudProvider === CLOUD_PROVIDERS.AWS)
            return `${stringEnvDomain}AWS`;

        return `${stringEnvDomain}${defaultEnumsFormat(cloudProvider)}`;

    }, [cloudProvider]);

    const onNameChange = (val) => {
        wizardDataDispatch({ type: wizardDataActionsTypes.SET_ACCOUNT_NAME, payload: val })
    }

    const submitData = () => {
        if (isCurrentStepValid) {
            onNameChange(accountName.trim())
            setIsLoading(true);
            currentStep.submit(wizardData, { envDomain, email }).then(() => {
                setIsLoading(false);
                moveToNextStep();
            }).catch((e) => {
                setIsLoading(false);
                Bugsnag.notify(e);
                addToast("Failed to submit account details", {
                    appearance: 'error',
                    autoDismiss: true,
                });
            })
        }
    }

    useEffect(() => {
        if (!accountName)
            onNameChange(initialName);
    }, []);

    return (
        <>
            <WizardLayout {...wizardProps}>
                <div className={cls.container}>
                    <div className={cls.header}>
                        <Typography variant="h2" color="textPrimary">{pageDetails.title}</Typography>
                        <Typography variant="body3" color="textSecondary">{pageDetails.desc}</Typography>
                    </div>

                    <TextField label={nameInputLabel} value={accountName} className={cls.nameInput} onChange={(e) => onNameChange(e.target.value)} />

                    {usageInput && (
                        <div className={cls.dropdownInput} >
                            <Typography variant="body1" fontWeight={700} color="textSecondary">{usageInput.label}</Typography>
                            <Dropdown value={usageVal} options={usageInput.options} onChange={(v, o) => {
                                wizardDataDispatch({ type: wizardDataActionsTypes.SET_ACCOUNT_TRAFFIC, payload: { min: o.min, max: o.max } })
                            }} />
                        </div>
                    )}

                    {isBelowExpectedUsage && (
                        <>
                            <div>
                                <Typography className={cls.headsup} variant="body2" color="warning"><span className={cls.alertTriangle}><FiAlertTriangle /> </span> Heads up!</Typography>
                                <Typography variant="body3" color="textPrimary" >{isVeryLowUsage ? usageInput.veryLowUsageWarningMsg : usageInput.lowUsageWarningMsg}</Typography>
                            </div>
                            <Button className={cls.scheduelButton} color="primary" variant="filled" onPress={() => {
                                segmentioTracking.scheduleOnboardingCallClick({ current_step_url: window.location.href })
                                setIsOpen(true)
                            }}
                            >
                                Schedule call with Sedai team
                            </Button>
                            <PopupModal
                                url="https://calendly.com/sedai-eng/technical-onboarding-help"
                                pageSettings={calendlyPageSettings}
                                onModalClose={() => setIsOpen(false)}
                                open={isOpen}
                                rootElement={document.getElementById("root")}
                            />
                            <Typography className={cls.inplaceText} variant="body3" color="textSecondary">or <Button color="primary" variant="link"
                                disabled={isLoading || !isCurrentStepValid}
                                onPress={() => {
                                    submitData();
                                    segmentioTracking.wizardNextClick({ current_step_url: window.location.href, btn_text: "continue setup" })
                                }}
                            >continue setup {isLoading ? <Spinner animation="border" role="status" size="sm" /> : null}</Button></Typography>
                        </>
                    )}

                    {!isBelowExpectedUsage && (
                        <div>
                            <DefaultStepFooterContent
                                moveNextIsLoading={isLoading}
                                moveToNextStep={() => {
                                    submitData();
                                }}
                                disableNextBtn={!isCurrentStepValid}
                            />
                        </div>
                    )}
                </div>
            </WizardLayout>

        </>
    )
};

export default AccountName;