import React, { useMemo } from "react";
import { findIndex } from "lodash";
import { useTheme } from "react-jss";
import { FiCheck, FiInfo } from "react-icons/fi";
import COLORS from "themes/colors";
import { MdCircle, MdRadioButtonChecked, MdRadioButtonUnchecked } from "react-icons/md";
import Icon from "components/Icon/Icon";
import Typography from "components/Typography/Typography";
import Button from "components/Button/Button";
import { STEP_STATES, supportedFlowTypes, wizardGroupSteps } from "../AddCloudWizard.utils";
import useStyles from './WizardLayout.styles';
import { useWizardData, wizardDataActionsTypes } from "contexts/WizardData";
import { motion } from "framer-motion";
import useWindowTargetWidth from "hooks/useWindowTargetWidth";
import { MIN_SCREEN_WIDTH } from "utils/constants";




const WizardLayout = ({ children, onStepClick, steps, currentStep }) => {
    const cls = useStyles();
    const theme = useTheme();
    const { isSmallScreen } = useWindowTargetWidth(MIN_SCREEN_WIDTH);
    const [wizardData, wizardDataDispatch] = useWizardData()
    const filteredGroups = useMemo(() => {
        const setpsGroupsMap = Object.fromEntries(steps.map(({ group }) => [group, true]));
        return wizardGroupSteps.filter(({ key, isAlwaysVisible }) => setpsGroupsMap[key] || isAlwaysVisible);
    }, [steps]);
    const currentGroupIndex = findIndex(filteredGroups, ({ key }) => key === currentStep?.group);
    const specfiedHeaderFlow = wizardData?.forcedFlowType || (wizardData?.initialFlowType && !wizardData?.uiStateDetails?.uiData?.ignoreInitialFlowType ? wizardData?.initialFlowType : undefined)
    const flowTypeObj = specfiedHeaderFlow && supportedFlowTypes.find(({ flowType }) => flowType === specfiedHeaderFlow);

    const showIgnoreInitalFlowType = !wizardData?.forcedFlowType && wizardData?.initialFlowType && !wizardData?.uiStateDetails?.uiData?.ignoreInitialFlowType;

    return (
        <div className={cls.container}>
            {!isSmallScreen && (
                <div className={cls.sideNavigation}>
                    <div className={cls.headerLogo}>
                        <Icon name="logoWithNameHorizonal" />
                    </div>
                    {flowTypeObj && (
                        <Typography className={cls.sideNavTitle} variant="h2" color="textPrimary">Set up <Typography component="span" color="primary">{flowTypeObj.shortenedLabel}</Typography> autonomous management in less than 5 minutes</Typography>
                    )}
                    {!flowTypeObj && (
                        <Typography className={cls.sideNavTitle} variant="h2" color="textPrimary">Welcome to <Typography component="span" color="primary">autonomous</Typography></Typography>
                    )}
                    {showIgnoreInitalFlowType && (
                        <Typography variant="body3" color="textSecondary">Or get started with a <Button variant="link" color="primary" onClick={() => wizardDataDispatch({ type: wizardDataActionsTypes.SET_IGNORE_INITIAL_FLOW_TYPE, payload: true })}>different resource type</Button></Typography>
                    )}
                    {!showIgnoreInitalFlowType && (
                        <Typography variant="body3" color="textSecondary">Get started in less than 5 minutes:</Typography>
                    )}
                    <div className={cls.stepsContainer}>
                        {filteredGroups.map((group, index) => {
                            let radioState = STEP_STATES.IN_ACTIVE;
                            if (index < currentGroupIndex) radioState = STEP_STATES.DONE;
                            else if (index === currentGroupIndex) radioState = STEP_STATES.ACTIVE;

                            const secondaryText = typeof group.renderSecondaryText === "function" ? group.renderSecondaryText(wizardData) : "";
                            return (
                                <motion.div layout key={index} className={cls.stepWrapper}>
                                    {index < filteredGroups.length - 1 && radioState !== STEP_STATES.DONE && <div className={cls.stepLine} style={{ background: COLORS.DARK_NEUTRALS[50] }} />}
                                    {index < filteredGroups.length - 1 && radioState === STEP_STATES.DONE && <div className={cls.stepLine} style={{ background: COLORS.PRIMARY[400] }} />}
                                    <div className={cls.stepContainer}>
                                        {radioState === STEP_STATES.IN_ACTIVE && <MdRadioButtonUnchecked color={COLORS.DARK_NEUTRALS[50]} size={24} />}
                                        {radioState === STEP_STATES.ACTIVE && <MdRadioButtonChecked color={theme.palette.primary.main} size={24} />}
                                        {radioState === STEP_STATES.DONE && (
                                            <div className={cls.iconsContainer}>
                                                <MdCircle color={theme.palette.primary.main} size={24} />
                                                <FiCheck className={cls.checkIcon} color={theme.palette.text.primary} size={18} />
                                            </div>
                                        )}
                                        <div className={cls.labelsContainer}>
                                            {radioState !== STEP_STATES.IN_ACTIVE && (
                                                <Typography variant="body2">
                                                    <Button variant="link" className={cls.clickableStepLabel} onPress={() => {
                                                        onStepClick(group.key)
                                                    }}>
                                                        {(typeof group.renderLabel === "function") ? group.renderLabel(wizardData) : group.label}
                                                    </Button>
                                                </Typography>
                                            )}
                                            {radioState === STEP_STATES.IN_ACTIVE && (<Typography variant="body2" color="textPrimary">{(typeof group.renderLabel === "function") ? group.renderLabel(wizardData) : group.label}</Typography>)}
                                            {secondaryText && <Typography variant="body3" color="textTernary">{secondaryText}</Typography>}
                                        </div>
                                    </div>
                                </motion.div>
                            )
                        })}
                    </div>

                    <div className={cls.helpText}>
                        <div className={cls.helpTextTitle}>
                            <FiInfo color={theme.palette.primary.main} size={16} />
                            <Typography variant="body3" color="textPrimary">Need help?</Typography>
                        </div>
                        <Typography variant="body3" color="textTernary">View <Button variant="link" color="primary" target="_blank" rel="noreferrer noopener" component="a" href="https://docs.sedai.io/get-started/">help documentation</Button>, email <Button variant="link" color="primary" component="a" href="mailto:help@sedai.io">help@sedai.io</Button> or <Button variant="link" color="primary" target="_blank" rel="noreferrer noopener" component="a" href="https://join.slack.com/t/sedai-community/shared_invite/zt-14wcic3jo-UDVLYBZxklkbUPLrpbSe9A">join our Slack community</Button> to chat with our team.</Typography>

                    </div>
                </div>
            )}
            <div className={cls.contentWrapper}>
                <div className={cls.content}>
                    {children}
                </div>
            </div>
        </div>
    )
};

export default WizardLayout;