import { createStylesWithTheme } from 'utils/functions';

const styles = {
    container: {
        position: "relative",
        marginLeft: 10,
        display: "inline-flex",
    },
    copiedTooltipWrapper:{
        zIndex: 1,  
    },
    copiedTooltip: {
        background: "#000000",
        color: "#ffffff",
        borderRadius: 2,
        padding: "2px 4px",
    },
};

export default createStylesWithTheme(styles);