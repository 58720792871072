export const SET_ENV_DOMAIN = 'SET_ENV_DOMAIN';
export const SET_USER_TRACKING_ID = 'SET_USER_TRACKING_ID';

export const WIZARD_DATA_INIT = 'WIZARD_DATA_INIT';
export const SET_GOAL = 'SET_GOAL';
export const SET_FLOW_TYPE = 'SET_FLOW_TYPE';
export const SET_KUB_CLUSTER_PROVIDER = 'SET_KUB_CLUSTER_PROVIDER';
export const SET_ACCOUNT_NAME = 'SET_ACCOUNT_NAME';
export const SET_ACCOUNT_TRAFFIC = 'SET_ACCOUNT_TRAFFIC';
export const SET_AWS_ROLE_ARN = 'SET_AWS_ROLE_ARN';
export const SET_AWS_EXTERNAL_ID = 'SET_AWS_EXTERNAL_ID';

export const SET_IS_CLOUD_FORMATION_LAUNCHED = 'SET_IS_CLOUD_FORMATION_LAUNCHED';
export const SET_INVITED_EMAILS_BY_CURRENT_USER = 'SET_INVITED_EMAILS_BY_CURRENT_USER';
export const SET_AWS_CONNETION_METHOD = 'SET_AWS_CONNETION_METHOD';
export const SET_AWS_CREDENTIALS_TYPE = 'SET_AWS_CREDENTIALS_TYPE';
export const SET_AWS_MANUAL_CONNECTION_POLICY_TYPE = 'SET_AWS_MANUAL_CONNECTION_POLICY_TYPE';
export const SET_IS_READ_ONLY_POLICY_COPIED = 'SET_IS_READ_ONLY_POLICY_COPIED';
export const SET_IS_AUTONOMOUS_POLICY_COPIED = 'SET_IS_AUTONOMOUS_POLICY_COPIED';
export const SET_IS_IAM_ROLE_CREATED = 'SET_IS_IAM_ROLE_CREATED';
export const SET_IS_IAM_USER_CREATED = 'SET_IS_IAM_USER_CREATED';
export const SET_MONITORING_PROVIDER_DATA = 'SET_MONITORING_PROVIDER_DATA';

export const SET_AWS_ACCESS_KEY = 'SET_AWS_ACCESS_KEY';
export const SET_AWS_SECRET_KEY = 'SET_AWS_SECRET_KEY';
export const SET_MONITORING_PROVIDER_TYPE = 'SET_MONITORING_PROVIDER_TYPE';

export const SET_IGNORE_INITIAL_FLOW_TYPE = 'SET_IGNORE_INITIAL_FLOW_TYPE';

export const SET_ADDITIONAL_CLOUD_PRODUCTS = 'SET_ADDITIONAL_CLOUD_PRODUCTS';
