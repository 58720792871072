import React from "react";
import cn from "classnames";
import Spinner from 'react-bootstrap/Spinner';
import useStyles from './PageContentLoading.styles';

export default function PageContentLoading({ className }) {
    const cls = useStyles();
    return (
        <div className={cn(cls.container, className)}>
            <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>
        </div>
    );
}
