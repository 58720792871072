import { useState } from "react";
import { useQuery } from "react-query";
import { useToasts } from "react-toast-notifications";
import { FiCopy, FiExternalLink, FiCheckCircle, FiCheck } from "react-icons/fi";
import copy from 'copy-to-clipboard';
import Typography from "components/Typography/Typography";
import Button from "components/Button/Button";
import WizardLayout from "views/AddCloudWizard/WizardLayout/WizardLayout";
import DefaultStepFooterContent from "views/common/WizardFooter/DefaultStepFooterContent/DefaultStepFooterContent";

import { getAWSAutonomousPolicy } from "utils/api/policies";
import { QUERY_KEYS } from "enums";
import PageContentLoading from "components/PageContentLoading/PageContentLoading";
import PageContentError from "components/PageContentError/PageContentError";
import { useWizardData, wizardDataActionsTypes } from "contexts/WizardData";
import { CONNECT_TO_AWS_METHODS, CONNECT_TO_AWS_POLICY_TYPES, STEP_KEYS } from "views/AddCloudWizard/AddCloudWizard.utils";
import useStyles from './AWSAutonomousPolicy.styles';
import segmentioTracking from "utils/segmentioTracking";
import Bugsnag from "@bugsnag/js";


const AWSAutonomousPolicy = ({ wizardProps, moveToNextStep, moveToStep, envDomain, email, currentStep, isCurrentStepValid }) => {
    const cls = useStyles();
    const { data, status } = useQuery(QUERY_KEYS.AWS_AUTONOMOUS_POLICY, () => getAWSAutonomousPolicy());
    const [isLoading, setIsLoading] = useState(false);
    const [wizardData, wizardDataDispatch] = useWizardData();
    const { addToast } = useToasts();

    const isAutonomousPolicyCopied = wizardData?.uiStateDetails?.uiData?.isAutonomousPolicyCopied;
    return (
        <WizardLayout {...wizardProps}>
            <div className={cls.container}>
                <div className={cls.header}>
                    <Typography variant="h2" color="textPrimary">Create Sedai IAM Policy in your AWS Console</Typography>
                    <Typography variant="body3" color="textSecondary">Grant Sedai permission to discover and autonomously manage your resources.</Typography>
                </div>

                {status === 'loading' && (<div className={cls.noContentContainer}><PageContentLoading /></div>)}
                {status === 'error' && (<div className={cls.noContentContainer}><PageContentError msg="Failed to load policy" /></div>)}


                {status === 'success' && (
                    <div className={cls.content}>
                        <div className={cls.copyBtnContainer}>
                            <Button
                                variant="filled"
                                color="primary"
                                onPress={() => {
                                    wizardDataDispatch({ type: wizardDataActionsTypes.SET_IS_AUTONOMOUS_POLICY_COPIED, payload: true })
                                    copy(JSON.stringify(data, null, '\t'));
                                }}
                            > {isAutonomousPolicyCopied ? <>Copied Autonomous Policy <FiCheck /> </> : <>Copy Autonomous Policy <FiCopy /> </>} </Button>
                            <Typography variant="body3" color="textSecondary">Or set up with <Button color="primary" variant="link" onPress={() => {
                                wizardDataDispatch({ type: wizardDataActionsTypes.SET_AWS_CONNETION_METHOD, payload: CONNECT_TO_AWS_METHODS.CLOUD_FORMATION })
                                moveToStep(STEP_KEYS.CLOUD_FORMATION_CONNECT)
                            }}>CloudFormation</Button> (~1 minute)</Typography>
                        </div>

                        <ul className={cls.list}>
                            <li className={cls.listItem}>
                                <FiCheckCircle className={cls.listIcon} />
                                <div className={cls.listItemText}>
                                    <Typography variant="body3" color="textPrimary">Includes read-write access to Lambda, ECS, EC2 and Elastic Load Balancer; as well as read-only access to CloudWatch, Kinesis, X-Ray and DynamoDB</Typography>
                                </div>
                            </li>
                            <li className={cls.listItem}>
                                <FiCheckCircle className={cls.listIcon} />
                                <div className={cls.listItemText}>
                                    <Typography variant="body3" color="textPrimary">Allows Sedai to continuously learn and improve resource performance</Typography>
                                </div>
                            </li>
                            <li className={cls.listItem}>
                                <FiCheckCircle className={cls.listIcon} />
                                <div className={cls.listItemText}>
                                    <Typography variant="body3" color="textPrimary">Sedai will only modify Lambda and/or EC2 resources if it detects an opportunity <strong>to improve performance, cost, or availability</strong>. You can control which resources Sedai acts upon or generates recommendations for from the Settings page within Sedai.</Typography>
                                </div>
                            </li>
                        </ul>
                        <Button
                            variant="outline"
                            color="textSecondary"
                            component="a"
                            target="_blank"
                            rel="noreferrer noopener"
                            onClick={() => {
                                segmentioTracking.viewDocsClick({
                                    current_step_url: window.location.href,
                                    docs_url: "https://docs.sedai.io/get-started/setup/connect-cloud/amazon-web-services/configure-iam/create-iam-policy",
                                });
                            }}
                            href="https://docs.sedai.io/get-started/setup/connect-cloud/amazon-web-services/configure-iam/create-iam-policy">Learn More <FiExternalLink /></Button>

                        <Typography variant="body3" color="textSecondary">Or use <Button color="primary" variant="link"
                            onPress={() => {
                                wizardDataDispatch({ type: wizardDataActionsTypes.SET_AWS_MANUAL_CONNECTION_POLICY_TYPE, payload: CONNECT_TO_AWS_POLICY_TYPES.READ_ONLY })
                                moveToStep(STEP_KEYS.AWS_MANUAL_READ_ONLY);
                            }}>Read-only Policy</Button></Typography>


                        <div>
                            <DefaultStepFooterContent
                                moveNextIsLoading={isLoading}
                                moveToNextStep={() => {
                                    if (isCurrentStepValid) {
                                        setIsLoading(true);
                                        currentStep.submit(wizardData, { envDomain, email }).then(() => {
                                            setIsLoading(false);
                                            moveToNextStep();
                                        }).catch((e) => {
                                            setIsLoading(false);
                                            Bugsnag.notify(e);
                                            addToast("Failed to submit data", {
                                                appearance: 'error',
                                                autoDismiss: true,
                                            });
                                        })

                                    }
                                }}
                                moveNextBtnText="I created Sedai’s Autonomous Policy"
                                disableNextBtn={!isCurrentStepValid}
                            />
                        </div>
                    </div>
                )}

            </div>
        </WizardLayout >
    )
};

export default AWSAutonomousPolicy;