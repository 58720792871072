const API_WIZARD_STEPS = {
    WAITING_TO_SELECT_GOAL: "WAITING_TO_SELECT_GOAL",
    WAITING_FOR_RESOURCE_TYPE: "WAITING_FOR_RESOURCE_TYPE",
    WAITING_FOR_AWS_ONBOARDING_CONFIGS: "WAITING_FOR_AWS_ONBOARDING_CONFIGS",
    WAITING_FOR_KUBE_SERVICE_PROVIDER: "WAITING_FOR_KUBE_SERVICE_PROVIDER",
    WAITING_FOR_KUBE_MONITORING_PROVIDER_TYPE: "WAITING_FOR_KUBE_MONITORING_PROVIDER_TYPE",
    WAITING_FOR_KUBE_MONITORING_PROVIDER_DATA: "WAITING_FOR_KUBE_MONITORING_PROVIDER_DATA",
    WAITING_FOR_CLOUD_ACCOUNT_NAME: "WAITING_FOR_CLOUD_ACCOUNT_NAME",
    READY_TO_PROVISION: "READY_TO_PROVISION",
    PROVISIONING: "PROVISIONING",
    PROVISIONED: "PROVISIONED",
    PROVISIONING_FAILED: "PROVISIONING_FAILED",
};

export default API_WIZARD_STEPS;