import React, { useEffect, useMemo, useState } from "react";
import Button from "components/Button/Button";
import { getSubDomainFromURL } from "utils/functions";
import { motion } from "framer-motion";
import Typography from "components/Typography/Typography";
import Icon from "components/Icon/Icon";
import sedaiAvatarHappyFrontFace from 'assets/img/sedaiAvatarSpinningFrontFace.png';
import useStyles from './EnvironmentCreationInProgress.styles';

const FAKE_PROGRESS_EXPECTED_TIME_MS = 5 * 60 * 1000;
const MAX_FAKE_PERCENTAGE = 99;
const EnvironmentCreationInProgress = ({ envUrl }) => {
    const cls = useStyles();
    const [perc, setPerc] = useState(1);
    useEffect(() => {
        if (perc < MAX_FAKE_PERCENTAGE) {
            const timeout = setTimeout(() => {
                const incrementPerStep = MAX_FAKE_PERCENTAGE / (FAKE_PROGRESS_EXPECTED_TIME_MS / 1000);
                setPerc((v) => v + incrementPerStep)
            }, 1000); // updateOnceEach 1 sec;

            return () => setTimeout(timeout)
        }
    }, [perc]);

    const timeRemaining = useMemo(() => {
        return Math.ceil((100 - perc) * (FAKE_PROGRESS_EXPECTED_TIME_MS / 100) / (1000 * 60));
    }, [perc]);

    return (
        <div className={cls.container}>
            <Icon name="logoWithNameHorizonal" className={cls.headerLogoHorizontal} />

            <img className={cls.avatar} src={sedaiAvatarHappyFrontFace} alt="Sedai's Spinning Octobus Avatar" />

            <Typography className={cls.creatingText} variant="h2" color="textPrimary">Creating <Typography component="span" color="primary">{envUrl && getSubDomainFromURL(envUrl)}</Typography>...</Typography>
            <Typography className={cls.waitingText} variant="body2" color="textPrimary">(This will take a few minutes. You will receive an email once your environment is ready.)</Typography>
            <div className={cls.barContainer}>
                <motion.div initial={{ width: 0 }} className={cls.completedBar} animate={{ width: `${(perc)}%` }} />
            </div>
            <Typography className={cls.completedText} variant="body2" color="primary">{Math.floor(perc)}% complete</Typography>
            <Typography variant="body3" color="textTernary">About {timeRemaining} minute{timeRemaining !== 1 && "s"} remaining</Typography>
        </div>
    )
};

export default EnvironmentCreationInProgress;