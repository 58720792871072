import { createStylesWithTheme } from 'utils/functions';
import tinycolor from "tinycolor2";
import COLORS from 'themes/colors';

const getTextColor = ({ theme, props }) => {
    switch (props.color) {
        case 'primary':
            return theme.palette.primary.main;
        case 'secondary':
            return theme.palette.secondary.main;
        case 'accent':
            return theme.palette.accent.main;
        case 'success':
            return theme.palette.success.main;
        case 'warning':
            return theme.palette.warning.main;
        case 'error':
            return theme.palette.error.main;
        case 'textPrimary':
            return theme.palette.text.primary;
        case 'textSecondary':
            return theme.palette.text.secondary;
        default:
            return props.color || 'inherit';
    }
}
const styles = (theme) => ({
    input: {
        ...(theme.typography.body2),
        color: theme.palette.text.primary,
        "&:focus": {
            background: "transparent",
            color: theme.palette.text.primary,
            boxShadow: `0 0 0 0.2rem ${tinycolor(theme.palette.primary.main).setAlpha(.2)}`
        },
        "&::placeholder": { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: theme.palette.text.secondary,
            opacity: 1, /* Firefox */
        },
        "&:disabled": {
            background: "transparent",
            opacity: 0.7,
        },
        "&:read-only": {
            background: "transparent",
        }
    },
    // type classes
    outline: {
        border: (props) => `1px solid ${getTextColor({ theme, props })}`,
        borderRadius: 4,
        background: theme.type === "dark" ? COLORS.DARK_NEUTRALS[800] : COLORS.LIGHT_NEUTRALS[0],
        padding: "10px 11px",
        height: "fit-content",
        "&:focus": {
            border: (props) => `1px solid ${getTextColor({ theme, props })}`,
            background: theme.type === "dark" ? COLORS.DARK_NEUTRALS[800] : COLORS.LIGHT_NEUTRALS[0],
        },
    },



});

export default createStylesWithTheme(styles);