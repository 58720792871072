import React from "react";
import Icon from "components/Icon/Icon";
import '@aws-amplify/ui-react/styles.css';
import styles from './LoadingPage.module.css';


const LoadingPage = () => {
    return (
        <div className={styles.loadingContainer}>
            <div className={styles.headerLogoContainer}>
                <Icon name="logoWithNameVertical" className={styles.headerLogo} />
            </div>
        </div>
    )
};

export default LoadingPage;