import { createStylesWithTheme, pxToRem } from 'utils/functions';
import tinycolor from 'tinycolor2';
const switchHeight = 1.625; //rem
const switchWidth = 2.8125; // rem
const spacingArroundCircle = pxToRem(3);
const switchCircleDiameter = switchHeight - spacingArroundCircle * 2;

const styles = {
    switch: {
        paddingLeft: `${switchWidth}rem`,
        "& .custom-control-label::before": {
            top: 0,
            left: `-${switchWidth}rem`,
            width: `${switchWidth}rem`,
            height: `${switchHeight}rem`,
            borderRadius: `${switchHeight}rem`,
            borderColor: ({ theme }) => theme.palette.text.secondary,
            backgroundColor: "transparent",
            cursor: "pointer",
        },
        "& .custom-control-label::after": {
            top: `${spacingArroundCircle}rem`,
            left: `${-switchWidth + spacingArroundCircle}rem`,
            width: `${switchCircleDiameter}rem`,
            height: `${switchCircleDiameter}rem`,
            borderRadius: `${switchCircleDiameter}rem`,
            backgroundColor: ({ theme }) => theme.palette.text.secondary,
            cursor: "pointer",
        },
        "& .custom-control-input:disabled ~ .custom-control-label::before": {
            pointerEvents: "none",
        },
        "& .custom-control-input:disabled ~ .custom-control-label::after": {
            pointerEvents: "none",
        },
        "& .custom-control-input:disabled:checked ~ .custom-control-label::after": {
            backgroundColor: ({ theme }) => theme.palette.text.secondary,
        },
        "& .custom-control-input ~ .custom-control-label::before": {
            backgroundColor: ({ theme }) => tinycolor(theme.palette.text.secondary).setAlpha(.2),
        },
        "& .custom-control-input:checked ~ .custom-control-label::before": {
            backgroundColor: ({ theme }) => theme.palette.primary.main
        },
        "& .custom-control-input:checked ~ .custom-control-label::after": {
            transform: `translateX(${switchCircleDiameter - spacingArroundCircle}rem)`,
        },
        "& .custom-control-input:not(:disabled):active ~ .custom-control-label::before": {
            backgroundColor: ({ theme }) => theme.palette.primary.light
        }
    }

};

export default createStylesWithTheme(styles);