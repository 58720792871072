import React from "react";
import cn from "classnames";
import BSTooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import useStyles from './Tooltip.styles';

export default function Tooltip({ children, placement, minWidth, color = "primary", className, renderTooltip, ...rest }) {
    const cls = useStyles({ color, minWidth });
    return (
        <OverlayTrigger
            placement={placement || "auto"}
            {...rest}
            overlay={(props) => (
                <BSTooltip className={cn(cls.tooltip, className)} {...props}>
                    {typeof renderTooltip === 'function' && renderTooltip()}
                </BSTooltip>
            )}
        >
            {children}
        </OverlayTrigger>

    );
}

