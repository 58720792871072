import { useMemo, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useTheme } from "react-jss";
import { FiArrowLeft, FiArrowRight, FiCheckCircle } from "react-icons/fi";
import Typography from "components/Typography/Typography";
import Button from "components/Button/Button";
import IconButton from "components/IconButton/IconButton";
import Icon from "components/Icon/Icon";

import { getAccountMonitoringProvidersConfig } from "utils/monitoringProviders";
import { getCloudProviderConfig } from "utils/cloudProviders";
import sedaiAvatarHappyFrontFace from 'assets/img/sedaiAvatarHappyFrontFace.png';
import useStyles from './Confirm.styles';
import segmentioTracking from "utils/segmentioTracking";
import { useWizardData } from "contexts/WizardData";
import { OTHER_GOAL } from "utils/constants";
import { supportedFlowTypes } from "views/AddCloudWizard/AddCloudWizard.utils";
import { CLOUD_PROVIDERS } from "enums";
import { useToasts } from "react-toast-notifications";
import Bugsnag from "@bugsnag/js";


const Confirm = ({ currentStep, onSetupComplete, envDomain, moveToPrevStep }) => {
    const cls = useStyles();
    const [isCompleting, setIsCompleting] = useState(false);
    const theme = useTheme();
    const [wizardData] = useWizardData();
    const { addToast } = useToasts();
    const data = wizardData?.uiStateDetails?.data;
    const confirmMessage = useMemo(() => {
        const goal = wizardData?.companyDetails?.goal?.[0];
        const selectedFlowType = wizardData?.resourceDetails?.resourceTypes?.[0];
        const flowTypeObj = supportedFlowTypes.find(({ flowType }) => selectedFlowType === flowType);
        const accountDetails = data?.sedaiAccount?.accountDetails;
        const monitoringConfigs = getAccountMonitoringProvidersConfig({
            cloudProviderTypeConfig: getCloudProviderConfig(accountDetails?.cloudProvider, accountDetails?.metadata?.clusterProvider),
            cloudProviderIntegrationType: accountDetails?.integrationType
        });
        const mpLabel = data?.sedaiMonitoringProviders.map(({ monitoringProvider }) => monitoringConfigs[monitoringProvider].label)[0];

        if (accountDetails?.cloudProvider === CLOUD_PROVIDERS.KUBERNETES)
            return <>Sedai is ready to  {goal && goal !== OTHER_GOAL ? `${goal.toLowerCase()} for` : "manage"} your Kubernetes workloads in {data?.sedaiAccount?.name} and pull monitoring data from {mpLabel}.</>

        return <>Sedai is ready to {goal && goal !== OTHER_GOAL ? `${goal.toLowerCase()} for` : "manage"} your {flowTypeObj.shortenedLabel} resources in {data?.sedaiAccount?.name} and pull monitoring data from {mpLabel}.</> 
    }, [wizardData]);

    const youCanAddLaterMessage = useMemo(() => {
        const accountDetails = wizardData?.uiStateDetails?.data?.sedaiAccount?.accountDetails;
        if (accountDetails?.cloudProvider === CLOUD_PROVIDERS.KUBERNETES)
            return "You can connect more clusters to Sedai from the Integrations page once your environment is ready."

        return "You can add more AWS accounts to Sedai from the Integrations page once your environment is ready."

    }, [wizardData]);

    return (
        <div className={cls.container}>
            {process.env.NODE_ENV !== "production" && (
                <div className={cls.backArrowWrapper} >
                    <IconButton variant="text" color="textSecondary" onPress={moveToPrevStep}><FiArrowLeft /></IconButton>
                </div>
            )}
            <Icon name="logoWithNameHorizonal" className={cls.headerLogoHorizontal} />
            <div className={cls.completeLabel} >
                <FiCheckCircle color={theme.palette.success.main} />
                <Typography variant="body3" color="success">Setup Complete</Typography>
            </div>
            <Typography variant="h1" color="textPrimary">You’re all set for autonomous! 🎉</Typography>
            <img className={cls.avatar} src={sedaiAvatarHappyFrontFace} alt="Sedai's Happy Octobus Avatar" />
            <div className={cls.accountsText}>
                <Typography variant="body1" color="textPrimary">{confirmMessage}</Typography>
            </div>
            <Button className={cls.launchBtn} variant="filled" color="primary" disabled={isCompleting} onPress={async () => {
                segmentioTracking.wizardNextClick({ current_step_url: window.location.href, btn_text: "Launch Sedai" })
                setIsCompleting(true)
                currentStep.submit(wizardData, { envDomain }).then(() => {
                    setIsCompleting(false)
                    onSetupComplete();// complete the add account step in the main flow
                }).catch((e) => {
                    setIsCompleting(false);
                    Bugsnag.notify(e);
                    addToast("Failed to create account", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                })
            }}>Launch Sedai {isCompleting ? <Spinner animation="border" role="status" size="sm" /> : <FiArrowRight />}</Button>
            <Typography className={cls.bottomDesc} variant="body3" color="textTernary">{youCanAddLaterMessage}</Typography>
        </div>
    )
};

export default Confirm;