import React, { useReducer } from 'react';
import reducer from './reducer';
import * as setupDataActionsTypes from './actionsTypes';


const SetupDataStateContext = React.createContext();
const SetupDataDispatchContext = React.createContext();

SetupDataStateContext.displayName = 'SetupDataStateContext';
SetupDataDispatchContext.displayName = 'SetupDataDispatchContext';

const initialState = { };
function SetupDataProvider({ children }) {
    const [state, dispatch] = useReducer(reducer, initialState)
    return (
        <SetupDataStateContext.Provider value={state}>
            <SetupDataDispatchContext.Provider value={dispatch}>
                {children}
            </SetupDataDispatchContext.Provider>
        </SetupDataStateContext.Provider>
    )
}

function useSetupDataState() {
    const context = React.useContext(SetupDataStateContext)
    if (context === undefined) {
        throw new Error('useSetupDataState must be used within a SetupDataProvider')
    }
    return context
}
function useSetupDataDispatch() {
    const context = React.useContext(SetupDataDispatchContext)
    if (context === undefined) {
        throw new Error('useSetupDataDispatch must be used within a SetupDataProvider')
    }
    return context
}

function useSetupData() {
    return [useSetupDataState(), useSetupDataDispatch()]
}

export { SetupDataProvider, useSetupData, SetupDataStateContext, setupDataActionsTypes }