import axios from "axios";
import { get } from "lodash";
import { Auth } from 'aws-amplify';
import Bugsnag from '@bugsnag/js';
import { INTERNAL_DOIMAIN_HOSTS } from "./constants";

const isInternalEnv = INTERNAL_DOIMAIN_HOSTS.includes(window.location.host);

const instance = axios.create({
  baseURL: process.env.REACT_APP_AUTH_API_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  let userSession;
  try {
    userSession = await Auth.currentSession()
  } catch (e) { }
  let resultingConfig = {
    ...config,
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      "Authorization": userSession ? `Bearer ${await userSession.getIdToken().getJwtToken()}` : undefined,
      ...config.headers,
    },
  };
  return resultingConfig;
});

instance.interceptors.response.use(null, function (error) {
  if (isInternalEnv && (400 === get(error, 'response.status') || 500 === get(error, 'response.status'))) {
    const responseInfo = get(error, 'response')
    const requestInfo = get(error, 'request')
    const errorMessage = responseInfo.status === 400 ? 'API invalid payload' : 'API internal server error';
    Bugsnag.notify(new Error(errorMessage), function (event) {
      event.severity = 'error'
      event.addMetadata('api response', responseInfo)
      event.addMetadata('api request', {
        url: requestInfo["BS~~U"],
        method: requestInfo["BS~~M"],
        statusCode: requestInfo.status,
        statusText: requestInfo.statusText,
        response: requestInfo.response,
      })
      event.errors[0].errorClass = 'HTTPError'
    });
  }
  return Promise.reject(error);
});


export default instance;
