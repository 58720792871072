import { uniq, get } from 'lodash';
import { CLOUD_PROVIDERS, CREDENTIALS_PROVIDERS, INTEGRATION_TYPE, MONITORING_PROVIDERS, ONBOARDING_PRODUCTS } from 'enums';
import { CP_CREDETIALS_BASE_PATH, getCloudProviderConfig } from 'utils/cloudProviders';
import { immutableSet } from 'utils/functions';
import { getAccountMonitoringProvidersConfig } from 'utils/monitoringProviders';
import { CONNECT_TO_AWS_CREDENTIALS_TYPES, CONNECT_TO_AWS_METHODS, CONNECT_TO_AWS_POLICY_TYPES, FLOW_TYPES, getCredentialProviderForCredetialType, supportedFlowTypes } from 'views/AddCloudWizard/AddCloudWizard.utils';
import {
    WIZARD_DATA_INIT,
    SET_ENV_DOMAIN,
    SET_GOAL,
    SET_USER_TRACKING_ID,
    SET_FLOW_TYPE,
    SET_KUB_CLUSTER_PROVIDER,
    SET_ACCOUNT_NAME,
    SET_IS_CLOUD_FORMATION_LAUNCHED,
    SET_INVITED_EMAILS_BY_CURRENT_USER,
    SET_AWS_CONNETION_METHOD,
    SET_AWS_CREDENTIALS_TYPE,
    SET_AWS_MANUAL_CONNECTION_POLICY_TYPE,
    SET_IS_READ_ONLY_POLICY_COPIED,
    SET_AWS_ROLE_ARN,
    SET_IS_AUTONOMOUS_POLICY_COPIED,
    SET_AWS_ACCESS_KEY,
    SET_AWS_SECRET_KEY,
    SET_IS_IAM_USER_CREATED,
    SET_IS_IAM_ROLE_CREATED,
    SET_MONITORING_PROVIDER_TYPE,
    SET_MONITORING_PROVIDER_DATA,
    SET_IGNORE_INITIAL_FLOW_TYPE,
    SET_ACCOUNT_TRAFFIC,
    SET_AWS_EXTERNAL_ID,
    SET_ADDITIONAL_CLOUD_PRODUCTS
} from './actionsTypes';
import { ONBOARDING_SOURCES } from 'utils/constants';


/**
 * more details about the requirments can be found here https://sedai.atlassian.net/browse/SED-6026 and later issues
 * The plan is to make some modifications to the default flow whenever the user come form the market place /Sedai Landing webiste.
 * we will make auto selection for the account and disable the user from being able to change that selection
 * follow this variable `forcedFlowType`/`initialFlowType`  to find out the conditions that were added in all our components
 */
const getForcedFlowType = (selectedMarketPlaceSource) => {

    if (selectedMarketPlaceSource === ONBOARDING_SOURCES.AWS_MARKETPLACE_EKS) return FLOW_TYPES.EKS;
    if (selectedMarketPlaceSource === ONBOARDING_SOURCES.AWS_MARKETPLACE_LAMBDA) return FLOW_TYPES.LAMBDA;
    if (selectedMarketPlaceSource === ONBOARDING_SOURCES.AWS_MARKETPLACE_ECS) return FLOW_TYPES.ECS;
    return null;
};


const getInitialFlowType = (selectedProduct) => {
    if (selectedProduct === ONBOARDING_PRODUCTS.LAMBDA) return FLOW_TYPES.LAMBDA;
    if (selectedProduct === ONBOARDING_PRODUCTS.ECS) return FLOW_TYPES.ECS;
    return null;
};

export default function reducer(state, action) {
    let newState = { ...state };
    switch (action.type) {
        case WIZARD_DATA_INIT: {
            newState = {
                ...action.payload,
                forcedFlowType: getForcedFlowType(action.payload?.source), // holds the value for the forced flowType that is needed
                initialFlowType: getInitialFlowType(action.payload?.product), // holds the value for a default flowType selection, this can be sent if the user is coming from the marketing website with a selection 
            };
            break;
        }
        case SET_ENV_DOMAIN: {
            newState = { ...newState, envDomain: action.payload }
            break;
        }
        case SET_USER_TRACKING_ID: {
            newState = { ...newState, userTrackingId: action.payload }
            break;
        }
        case SET_GOAL: {
            newState = immutableSet(newState, 'companyDetails.goal', [action.payload])
            break;
        }
        case SET_FLOW_TYPE: {
            const flowTypeObj = supportedFlowTypes.find(({ flowType }) => flowType === action.payload);
            const flowTypeChanged = state?.resourceDetails?.resourceTypes?.[0] !== action.payload;
            newState = immutableSet(newState, 'resourceDetails.resourceTypes', [action.payload])

            if (flowTypeChanged && flowTypeObj) {
                newState = immutableSet(newState, 'accountDetails', [])

                const extraState = ([FLOW_TYPES.LAMBDA, FLOW_TYPES.ECS].includes(flowTypeObj.flowType) ? {
                    awsConnectionMethod: CONNECT_TO_AWS_METHODS.CLOUD_FORMATION,
                    awsCredetialsType: CONNECT_TO_AWS_CREDENTIALS_TYPES.CLOUD_FORMATION_ARN,
                    awsPolicyType: undefined,
                    isCloudFormationLinkClicked: false,
                    isAutonomousPolicyCopied: false,
                    isReadOnlyPolicyCopied: false,
                } : {});

                newState = immutableSet(newState, 'uiStateDetails.uiData', { ...(newState?.uiStateDetails?.uiData || {}), ...extraState })
                const cloudProvider = flowTypeObj.type;
                const clusterProvider = (flowTypeObj.flowType === FLOW_TYPES.GENERAL_KUB ? state?.resourceDetails?.kubeProviders?.[0] : flowTypeObj.clusterProvider); // if flow type is general kube we take the kube provider from the resource details otherwise we use clusterProvider. 
                const integrationType = cloudProvider === CLOUD_PROVIDERS.KUBERNETES ? INTEGRATION_TYPE.AGENT_BASED : INTEGRATION_TYPE.AGENTLESS;
                const monitoringConfigs = [FLOW_TYPES.LAMBDA, FLOW_TYPES.ECS].includes(flowTypeObj.flowType) && getAccountMonitoringProvidersConfig({
                    cloudProviderTypeConfig: getCloudProviderConfig(cloudProvider),
                    cloudProviderIntegrationType: integrationType
                })

                const sedaiMonitoringProviders = [FLOW_TYPES.LAMBDA, FLOW_TYPES.ECS].includes(flowTypeObj.flowType) ? [{ name: "cloudwatch", ...monitoringConfigs[MONITORING_PROVIDERS.CLOUDWATCH].initValues }] : [];
                const defaultAccountData = {
                    sedaiAccount: {
                        accountDetails: {
                            cloudProvider,
                            integrationType,
                            ...(clusterProvider ? { metadata: { clusterProvider } } : {}),

                        }
                    },
                    // we add cloudwatch by default for AWS LAMBDA and EC2 and ECs(in the future)
                    sedaiMonitoringProviders: sedaiMonitoringProviders,
                }
                const defaultAccDataWithCloudFormationCred = immutableSet(defaultAccountData, `sedaiAccount.${CP_CREDETIALS_BASE_PATH}.credentialsProvider`, getCredentialProviderForCredetialType(CONNECT_TO_AWS_CREDENTIALS_TYPES.CLOUD_FORMATION_ARN));
                newState = immutableSet(newState, 'uiStateDetails.data', defaultAccDataWithCloudFormationCred)
                newState = immutableSet(newState, 'resourceDetails.monitoringProviders', sedaiMonitoringProviders.map(({ monitoringProvider }) => monitoringProvider))

            }
            if (flowTypeObj.flowType !== FLOW_TYPES.GENERAL_KUB)
                newState = immutableSet(newState, 'resourceDetails.kubeProviders', [])
            break;
        }
        case SET_KUB_CLUSTER_PROVIDER: {
            newState = immutableSet(newState, 'resourceDetails.kubeProviders', [action.payload]);
            newState = immutableSet(newState, `uiStateDetails.data.sedaiAccount.accountDetails.metadata.clusterProvider`, action.payload);

            break;
        }
        case SET_ACCOUNT_NAME: {
            newState = immutableSet(newState, "accountDetails", [{ ...(newState?.accountDetails?.[0] || {}), name: action.payload }])
            newState = immutableSet(newState, "uiStateDetails.data.sedaiAccount.name", action.payload)
            if (newState?.uiStateDetails?.data?.sedaiAccount?.accountDetails?.cloudProvider === CLOUD_PROVIDERS.KUBERNETES)
                newState = immutableSet(newState, "uiStateDetails.data.sedaiAccount.accountDetails.metadata.clusterName", action.payload)

            break;
        }
        case SET_ACCOUNT_TRAFFIC: {
            newState = immutableSet(newState, "accountDetails", [{ ...(newState?.accountDetails?.[0] || {}), trafficRange: action.payload }])
            break;
        }
        case SET_AWS_ROLE_ARN: {
            newState = immutableSet(newState, `uiStateDetails.data.sedaiAccount.${CP_CREDETIALS_BASE_PATH}.role`, action.payload)
            break;
        }
        case SET_AWS_EXTERNAL_ID: {
            newState = immutableSet(newState, `uiStateDetails.data.sedaiAccount.${CP_CREDETIALS_BASE_PATH}.externalId`, action.payload);
            break;
        }
        case SET_IS_CLOUD_FORMATION_LAUNCHED: {
            newState = immutableSet(newState, 'uiStateDetails.uiData.isCloudFormationLinkClicked', action.payload.isCloudFormationLinkClicked);
            break;
        }
        case SET_INVITED_EMAILS_BY_CURRENT_USER: {
            const { invitedByEmail, invitedEmails } = action.payload;
            const newInvitedEmails = uniq((newState?.uiStateDetails?.uiData?.invitations?.[invitedByEmail] || []).concat(invitedEmails));
            newState = immutableSet(newState, `uiStateDetails.uiData.invitations`, { ...(newState?.uiStateDetails?.uiData?.invitations || {}), [invitedByEmail]: newInvitedEmails })
            newState = immutableSet(newState, `userEmails`, uniq((newState?.userEmails || []).concat(invitedEmails)))
            break;
        }
        case SET_AWS_CONNETION_METHOD: {
            const connectionMethod = action.payload;
            let currentAWSCredentialsType = state?.uiStateDetails?.uiData?.awsCredetialsType;
            let currentAWSPolicyType = state?.uiStateDetails?.uiData?.awsPolicyType;

            if (connectionMethod === CONNECT_TO_AWS_METHODS.CLOUD_FORMATION && currentAWSCredentialsType !== CONNECT_TO_AWS_CREDENTIALS_TYPES.CLOUD_FORMATION_ARN) {
                currentAWSCredentialsType = CONNECT_TO_AWS_CREDENTIALS_TYPES.CLOUD_FORMATION_ARN;
                newState = immutableSet(newState, `uiStateDetails.data.sedaiAccount.${CP_CREDETIALS_BASE_PATH}`, {});

            } else if (connectionMethod === CONNECT_TO_AWS_METHODS.MANUAL && ![CONNECT_TO_AWS_CREDENTIALS_TYPES.IAM_ROLE, CONNECT_TO_AWS_CREDENTIALS_TYPES.IAM_USER].includes(currentAWSCredentialsType)) {
                currentAWSCredentialsType = CONNECT_TO_AWS_CREDENTIALS_TYPES.IAM_ROLE;
                newState = immutableSet(newState, `uiStateDetails.data.sedaiAccount.${CP_CREDETIALS_BASE_PATH}`, {});
            }

            if (connectionMethod === CONNECT_TO_AWS_METHODS.CLOUD_FORMATION) {
                currentAWSPolicyType = undefined;

            } else if (connectionMethod === CONNECT_TO_AWS_METHODS.MANUAL && ![CONNECT_TO_AWS_POLICY_TYPES.AUTONOMOUS, CONNECT_TO_AWS_POLICY_TYPES.READ_ONLY].includes(currentAWSPolicyType)) {
                currentAWSPolicyType = CONNECT_TO_AWS_POLICY_TYPES.AUTONOMOUS;
            }

            const credentialsProvider = getCredentialProviderForCredetialType(currentAWSCredentialsType)

            if (get(state, `uiStateDetails.data.sedaiAccount.${CP_CREDETIALS_BASE_PATH}.credentialsProvider`) === credentialsProvider)
                newState = immutableSet(newState, `uiStateDetails.data.sedaiAccount.${CP_CREDETIALS_BASE_PATH}.credentialsProvider`, credentialsProvider);
            else
                newState = immutableSet(newState, `uiStateDetails.data.sedaiAccount.${CP_CREDETIALS_BASE_PATH}`, { credentialsProvider });// reset Credentials on changing provider

            newState = immutableSet(newState, `uiStateDetails.uiData`, {
                ...(newState.uiStateDetails.uiData || {}),
                isReadOnlyPolicyCopied: false,
                isAutonomousPolicyCopied: false,

                awsConnectionMethod: connectionMethod,
                awsCredetialsType: currentAWSCredentialsType,
                awsPolicyType: currentAWSPolicyType,
            });

            break;

        }
        case SET_AWS_CREDENTIALS_TYPE: {
            const credentialsType = action.payload;
            let currentAWSConnectionMethod = state?.uiStateDetails?.uiData?.awsConnectionMethod;
            let currentAWSPolicyType = state?.uiStateDetails?.uiData?.awsPolicyType;
            if (credentialsType === CONNECT_TO_AWS_CREDENTIALS_TYPES.CLOUD_FORMATION_ARN && currentAWSConnectionMethod !== CONNECT_TO_AWS_METHODS.CLOUD_FORMATION) {
                currentAWSConnectionMethod = CONNECT_TO_AWS_METHODS.CLOUD_FORMATION;
            } else if ([CONNECT_TO_AWS_CREDENTIALS_TYPES.IAM_ROLE, CONNECT_TO_AWS_CREDENTIALS_TYPES.IAM_USER].includes(credentialsType) && currentAWSConnectionMethod !== CONNECT_TO_AWS_METHODS.MANUAL) {
                currentAWSConnectionMethod = CONNECT_TO_AWS_METHODS.MANUAL;
            }

            const credentialsProvider = getCredentialProviderForCredetialType(credentialsType)
            if (get(state, `uiStateDetails.data.sedaiAccount.${CP_CREDETIALS_BASE_PATH}.credentialsProvider`) === credentialsProvider)
                newState = immutableSet(newState, `uiStateDetails.data.sedaiAccount.${CP_CREDETIALS_BASE_PATH}.credentialsProvider`, credentialsProvider);
            else
                newState = immutableSet(newState, `uiStateDetails.data.sedaiAccount.${CP_CREDETIALS_BASE_PATH}`, { credentialsProvider }); // reset Credentials on changing provider


            newState = immutableSet(newState, `uiStateDetails.uiData`, {
                ...(newState.uiStateDetails.uiData || {}),
                isReadOnlyPolicyCopied: false,
                isAutonomousPolicyCopied: false,

                awsConnectionMethod: currentAWSConnectionMethod,
                awsCredetialsType: credentialsType,
                awsPolicyType: currentAWSPolicyType,
            });

            break;

        }
        case SET_AWS_MANUAL_CONNECTION_POLICY_TYPE: {
            const policyType = action.payload;
            let currentAWSConnectionMethod = state?.uiStateDetails?.uiData?.awsConnectionMethod;
            let currentAWSCredentialsType = state?.uiStateDetails?.uiData?.awsCredetialsType;
            if ([CONNECT_TO_AWS_POLICY_TYPES.AUTONOMOUS, CONNECT_TO_AWS_POLICY_TYPES.READ_ONLY].includes(policyType) && currentAWSConnectionMethod !== CONNECT_TO_AWS_METHODS.MANUAL) {
                currentAWSConnectionMethod = CONNECT_TO_AWS_METHODS.MANUAL;
            }

            if ([CONNECT_TO_AWS_POLICY_TYPES.AUTONOMOUS, CONNECT_TO_AWS_POLICY_TYPES.READ_ONLY].includes(policyType) && ![CONNECT_TO_AWS_CREDENTIALS_TYPES.IAM_ROLE, CONNECT_TO_AWS_CREDENTIALS_TYPES.IAM_USER].includes(currentAWSCredentialsType)) {
                currentAWSCredentialsType = CONNECT_TO_AWS_CREDENTIALS_TYPES.IAM_ROLE;
            }

            newState = immutableSet(newState, `uiStateDetails.uiData`, {
                ...(newState.uiStateDetails.uiData || {}),
                isReadOnlyPolicyCopied: false,
                isAutonomousPolicyCopied: false,

                awsConnectionMethod: currentAWSConnectionMethod,
                awsCredetialsType: currentAWSCredentialsType,
                awsPolicyType: policyType,
            });
            break;
        }
        case SET_IS_READ_ONLY_POLICY_COPIED: {
            newState = immutableSet(newState, `uiStateDetails.uiData.isReadOnlyPolicyCopied`, action.payload);
            break;
        }
        case SET_IS_AUTONOMOUS_POLICY_COPIED: {
            newState = immutableSet(newState, `uiStateDetails.uiData.isAutonomousPolicyCopied`, action.payload);
            break;
        }
        case SET_IS_IAM_ROLE_CREATED: {
            newState = immutableSet(newState, `uiStateDetails.uiData.isIAMRoleCreated`, action.payload);
            break;
        }
        case SET_IS_IAM_USER_CREATED: {
            newState = immutableSet(newState, `uiStateDetails.uiData.isIAMUserCreated`, action.payload);
            break;
        }
        case SET_AWS_ACCESS_KEY: {
            newState = immutableSet(newState, `uiStateDetails.data.sedaiAccount.${CP_CREDETIALS_BASE_PATH}.credentialsProvider`, CREDENTIALS_PROVIDERS.AWS_STATIC)
            newState = immutableSet(newState, `uiStateDetails.data.sedaiAccount.${CP_CREDETIALS_BASE_PATH}.accessKey`, action.payload)
            break;
        }
        case SET_AWS_SECRET_KEY: {
            newState = immutableSet(newState, `uiStateDetails.data.sedaiAccount.${CP_CREDETIALS_BASE_PATH}.credentialsProvider`, CREDENTIALS_PROVIDERS.AWS_STATIC)
            newState = immutableSet(newState, `uiStateDetails.data.sedaiAccount.${CP_CREDETIALS_BASE_PATH}.secretKey`, action.payload)
            break;
        }
        case SET_MONITORING_PROVIDER_TYPE: {
            newState = immutableSet(newState, `resourceDetails.monitoringProviders`, [action.payload])
            const currentMPConfigIsDifferent = state?.uiStateDetails?.data?.sedaiMonitoringProviders?.[0]?.monitoringProvider !== action.payload;
            if (currentMPConfigIsDifferent)
                newState = immutableSet(newState, `uiStateDetails?.data?.sedaiMonitoringProviders`, [])
            break;
        }
        case SET_MONITORING_PROVIDER_DATA: {
            newState = immutableSet(newState, `uiStateDetails.data.sedaiMonitoringProviders`, action.payload ? [action.payload] : [])
            break;
        }
        case SET_IGNORE_INITIAL_FLOW_TYPE: {
            newState = immutableSet(newState, `uiStateDetails.uiData.ignoreInitialFlowType`, action.payload);
            break;
        }
        case SET_ADDITIONAL_CLOUD_PRODUCTS: {
            if (newState?.uiStateDetails?.data?.sedaiAccount?.accountDetails?.cloudProvider === CLOUD_PROVIDERS.AWS)
                newState = immutableSet(newState, "uiStateDetails.data.sedaiAccount.accountDetails.userSelectedManagedServices", action.payload)

            break;
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }

    }

    return newState;
};