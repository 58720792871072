import React, { useCallback } from "react";
import cn from "classnames";
import { FiInfo } from "react-icons/fi";
import Icon from "components/Icon/Icon";
import Tooltip from 'components/Tooltip/Tooltip';

import useStyles from './InfoIconTooltip.styles';

export default function InfoIconTooltip({ children, infoStyle, infoClassName }) {
    const cls = useStyles();
    const renderTooltip = useCallback(() => children, [children])
    return (
        <Tooltip minWidth={260} color="secondary" renderTooltip={renderTooltip}>
            <FiInfo size={16} style={infoStyle} className={cn(cls.infoIcon, infoClassName)} />
        </Tooltip>

    );
}

