import React, { useMemo } from "react";
import cn from 'classnames';
import Form from 'react-bootstrap/Form'
import useStyles from './Switch.styles';

export default function Switch({ label = "", value, disabled, onChange, className, ...rest }) {
    const cls = useStyles();
    const id = useMemo(() => {
        return `Switch_${Math.random() * 1000000000}`;
    }, []);
    return (
        <Form.Check
            {...rest}
            className={cn(cls.switch, className)}
            type="switch"
            label={label}
            id={id}
            checked={value}
            disabled={disabled}
            onChange={(e) => onChange && onChange(e.target.checked)}
        />
    );
}

