import React, { useState } from "react";
import { uniq } from "lodash";
import cn from "classnames";
import { MdAdd, MdCancel } from 'react-icons/md';
import IconButton from 'components/IconButton/IconButton';
import Typography from 'components/Typography/Typography';
import useStyles from './MultiChipInput.styles';

/**
 * @typedef {Object} MultiChipInputProps
 * @property {string} className
 * @property {string} color
 * @property {string} inputClassName
 * @property {string} value
 * @property {function} onChange
 * @property {boolean} disabled
 * @property {function} renderChipText
 * @property {function} renderInput
 * 
 */
export default function MultiChipInput({ className, color, inputClassName, value, onChange, disabled, renderChipText, renderInput, validateValue, disableAddBtn = false, ...rest }) {
  const cls = useStyles({ color });
  const [text, setText] = useState("");
  const isDisabled = disabled || !text || (typeof validateValue === "function" && !validateValue(text));
  return (
    <div className={cn(cls.container, className)}>
      {value && !!value.length && value.map((v, index) => (
        <Typography wordBreakAll color="textPrimary" variant="body1" className={cls.chip}>{typeof renderChipText === "function" ? renderChipText(v, index) : v} {!disabled && <MdCancel className={cls.closeIcon} onClick={() => onChange((value || []).filter((_, i) => i !== index))} />}</Typography>
      ))}
      <div className={cn(cls.buttonAndInputContainer, className)}>
        <input autoComplete="off" className={cn(cls.input, inputClassName)} disabled={disabled} {...rest} onChange={(e) => setText(e.target.value)} value={text}
          onKeyDown={(e) => {
            if (e.key === "Backspace" && !text) {
              onChange((value || []).slice(0, -1))
            }
            if ((e.key === "Enter" && text) || e.key === ",") {
              e.preventDefault()
              if (!isDisabled && typeof onChange === 'function' && text) {
                onChange(uniq([...(value || []), text]));
                setText("")
              }
            }
          }}
          onBlur={() => {
            if (!isDisabled && typeof onChange === 'function' && text) {
              onChange(uniq([...(value || []), text]));
              setText("")
            }
          }} />
        {!disableAddBtn && <IconButton className={cls.addBtn} variant="filled" color="primary" disabled={isDisabled} onClick={() => {
          onChange(uniq([...(value || []), text]));
          setText("")
        }}>
          <MdAdd />
        </IconButton>}
      </div>
    </div>
  );
};

