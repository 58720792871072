import tinycolor from "tinycolor2";
import COLORS from "./colors";

export default {
    palette: {
        secondary: {
            main: "#B473FF",
            light: tinycolor("#B473FF").lighten(0.2).toRgbString(),
            dark: tinycolor("#B473FF").darken(0.2).toRgbString(),
        },
        accent: {
            main: "#13E0DC",
            light: tinycolor("#13E0DC").lighten(0.2).toRgbString(),
            dark: tinycolor("#13E0DC").darken(0.2).toRgbString(),
        },
        availability: { //S-Run
            main: '#32DBFC',
            light: tinycolor("#32DBFC").lighten(0.2).toRgbString(),
            dark: tinycolor("#32DBFC").darken(0.2).toRgbString(),
        },
        efficiency: { //S-Eco
            main: '#AD68F2',
            light: "#D9B3FF",
            dark: "#5A1F66",
        },
        operation: { //S-Ops
            main: '#E68815',
            light: "#FFE8CC",
            dark: "#8C530E",
        },
        background: {
            cardTabs: "#0F2548",
        },
    },
    typography: {
        fontFamily: '"Outfit", "Helvetica", "Arial", sans-serif',
        h1: {
            fontSize: "2rem",
            lineHeight: 1.125,
            fontFamily: "Outfit",
            fontWeight: 500,
        },
        h2: {
            fontSize: "1.5rem",
            lineHeight: 1.2,
            fontFamily: "Outfit",
            fontWeight: 500,
        },
        h3: {
            fontSize: "1.25rem",
            lineHeight: 1.2,
            fontFamily: "Outfit",
            fontWeight: 600,
        },
        h4: {
            fontSize: "1.125rem",
            lineHeight: 1.11,
            fontFamily: "Outfit",
            fontWeight: 600,
        },
        h5: {
            fontSize: "1rem",
            lineHeight: 1.125,
            fontFamily: "Outfit",
            fontWeight: 600,
        },
        h6: {
            fontWeight: 500,
            fontSize: "0.75rem",
            lineHeight: 1.33,
            textTransform: "uppercase",
            fontFamily: "Outfit",
            letterSpacing: 0.72
        },
        subtitle2: {
            fontSize: "1.125rem",
            lineHeight: 1.33,
            fontFamily: "Outfit",
            fontWeight: 400,
        },
        body1: {
            fontSize: "1.125rem",
            lineHeight: 1.3,
            fontFamily: "Outfit",
            fontWeight: 400,
        },
        body2: {
            fontSize: "1rem",
            lineHeight: 1.5,
            fontFamily: "Outfit",
            fontWeight: 400,
        },
        body3: {
            fontSize: "0.875rem",
            lineHeight: 1.4,
            fontFamily: "Outfit",
            fontWeight: 400,
        },
        body4: {
            fontSize: "0.875rem",
            lineHeight: 1.285,
            fontFamily: "Outfit",
            fontWeight: 400,
        },
        body5: {
            fontSize: "0.75rem",
            lineHeight: 1.3,
            fontFamily: "Outfit",
            fontWeight: 400,
        },
    },
    zIndex: {
        appBar: 1100,
        drawer: 1200,
        modal: 1300,
        tooltip: 1500,
    },
    navBarHeight: 72,
    containerMaxWidth: "unset",
}