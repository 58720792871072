
import { useState } from "react";
import WizardLayout from "views/AddCloudWizard/WizardLayout/WizardLayout";
import { useToasts } from 'react-toast-notifications';
import DefaultStepFooterContent from "views/common/WizardFooter/DefaultStepFooterContent/DefaultStepFooterContent";
import Typography from "components/Typography/Typography";
import Button from "components/Button/Button";
import Card from "components/Card/Card";
import { FiCheck } from "react-icons/fi";
import { useWizardData, wizardDataActionsTypes } from "contexts/WizardData";
import useStyles from './SelectGoal.styles';
import { goals } from "utils/constants";
import Bugsnag from "@bugsnag/js";


const SelectGoal = ({ wizardProps, moveToNextStep, envDomain, email, currentStep, isCurrentStepValid }) => {
    const cls = useStyles();
    const [wizardData, wizardDataDispatch] = useWizardData()
    const { addToast } = useToasts();
    const [isLoading, setIsLoading] = useState(false);
    const goal = wizardData?.companyDetails?.goal?.[0];
    return (
        <>
            <WizardLayout {...wizardProps}>
                <div className={cls.container}>
                    <div className={cls.header}>
                        <Typography variant="h2" color="textPrimary">What is your main goal for using Sedai?</Typography>
                        <Typography variant="body2" color="textTernary" >Select one goal to focus on first</Typography>
                    </div>
                    <div className={cls.goalsList}>
                        {goals.map((g) => (
                            <Button key={g} className={cls.button} variant="content" onPress={() => {
                                wizardDataDispatch({ type: wizardDataActionsTypes.SET_GOAL, payload: g })
                            }}>
                                <Card noPadding className={goal === g ? cls.activeGoal : cls.goal}>
                                    <Typography variant="body2" color="textPrimary">{g}</Typography>
                                    {goal === g && < FiCheck className={cls.checkMark} color="#7373FF" />}
                                </Card>
                            </Button>
                        ))}
                    </div>
                    <DefaultStepFooterContent
                        moveNextIsLoading={isLoading}
                        moveToNextStep={() => {
                            if (isCurrentStepValid) {
                                setIsLoading(true);
                                currentStep.submit(wizardData, { envDomain, email }).then(() => {
                                    setIsLoading(false);
                                    moveToNextStep();
                                }).catch((e) => {
                                    setIsLoading(false);
                                    Bugsnag.notify(e);
                                    addToast("Failed to set goal", {
                                        appearance: 'error',
                                        autoDismiss: true,
                                    });
                                })

                            }
                        }}
                        disableNextBtn={!isCurrentStepValid}
                    />
                </div>
            </WizardLayout>

        </>
    )
};

export default SelectGoal;