import pkg from '../../package.json';

export const PKG_VERSION = pkg.version;


export const INTERNAL_DOIMAIN_HOSTS = ["provisioning.sedai.cloud"];

export const MIN_SCREEN_WIDTH = 1200;

export const ONBOARDING_SOURCES = {
    AWS_MARKETPLACE_EKS: "AWS_MARKETPLACE_EKS",
    AWS_MARKETPLACE_LAMBDA: "AWS_MARKETPLACE_LAMBDA",
    AWS_MARKETPLACE_ECS: "AWS_MARKETPLACE_ECS",
}

export const roleOptions = [{
    name: "SRE",
    label: "SRE",
},
{
    name: "DevOps",
    label: "DevOps",
    clearBitSubRoles: ["devops_engineer"],
},
{
    name: "Platform Engineering",
    label: "Platform Engineering",
},
{
    name: "Architect",
    label: "Architect",
    clearBitSubRoles: ["architect_it"],
},
{
    name: "App Developer",
    label: "App Developer",
},
{
    name: "Engineering Lead",
    label: "Engineering Lead",
},
{
    name: "FinOps",
    label: "FinOps",
},
{
    name: "CTO",
    label: "CTO",
},
{
    name: "Other",
    label: "Other",
}]

export const OTHER_GOAL = "Other";

export const goals = [
    "Reduce cloud cost",
    "Maximize performance",
    "Increase availability",
    "Improve operations productivity",
    "Accelerate release velocity",
    OTHER_GOAL
]
