import { createStylesWithTheme } from 'utils/functions';


const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 583,
    alignSelf: "center",
    width: "100%",
  },
  header: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "8px",
    marginBottom: 24,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    marginTop: 16,
    "&>*:not(:last-child)": {
      marginBottom: 16,
    },
  },
  list: {
    padding: 0,
    "&>*:not(:last-child)": {
      marginBottom: 12,
    },
  },
  listItem: {
    display: "flex",
  },
  listIcon: {
    color: ({ theme }) => theme.palette.primary.main,
    minWidth: 16,
    marginRight: 10,
    marginTop: 2,

  },
  listItemText: {
    display: "flex",
    flexDirection: "column",
    "&>*:not(:last-child)": {
      marginBottom: 8,
    },
  },

  policyCards: {
    display: "flex",
    flexWrap: "wrap",
    paddingTop: 24,
    margin: "-12px -12px",
  },
  policyCard: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    margin: "12px 12px",
    flex: "1 1 200px",
    padding: [24, "!important"],
    "&, & *": {
      transition: "all 0.4s"
    },
    "&  button": {
      opacity: 0.4,
    },
    "&:hover button": {
      opacity: 1,
    }
  },
  activePolicyCard: {
    borderColor: ({ theme }) => theme.palette.primary.main,
    "&  button": {
      opacity: 1
    },
    "& $checkMark": {
      opacity: 1,
    }
  },
  policyCardTitleContainer: {
    display: "flex",
    justifyContent: "space-between"
  },
  checkMark: {
    height: 16,
    minWidth: 16,
    opacity: 0,
    color: ({ theme }) => theme.palette.primary.main,
  },
  policyCardTitle: {
    marginBottom: 12,
  },
  supportLink: {
    marginTop: 8,
    width: "max-content",
  },
  buttonWithIcon: {
    display: "grid",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    gridTemplateColumns: "max-content max-content",
  },
  policyList: {
    flex: 1,
    listStyleType: "none",
    marginTop: 8,
    marginBottom: 24,
    paddingLeft: 0,
    "& > *": {
      display: "flex",
      alignItems: "center",
      margin: "8px 0"
    },
    "& svg": {
      marginRight: 8,
      color: ({ theme }) => theme.palette.primary.main
    }
  },

};

export default createStylesWithTheme(styles);