const COLORS = {
    LIGHT_NEUTRALS: {
        0: "#FFFFFF",
        100: "#FAFCFD",
        200: "#F5F7FA",
        300: "#F0F3F7",
        400: "#E6EBF0",
        500: "#DFE4EB",
        600: "#C9CFD6",
        700: "#ABB1B8",
        800: "#9A9FA5",
        900: "#82888F",
    },
    MID_NEURTRALS: {
        50: "#9AA0B2",
        100: "#7D8499",
        200: "#636A80",
        300: "#4B5466",
        400: "#3E4657",
        500: "#343D4D",
        600: "#272F3D",
        700: "#1F2633",
        800: "#1D222E",
        900: "#181C24",
    },
    DARK_NEUTRALS: {
        50: "#33363D",
        100: "#2E3138",
        200: "#292C33",
        300: "#262930",
        400: "#24272E",
        500: "#21242B",
        600: "#1F2229",
        700: "#1C1F26",
        800: "#181B22",
        900: "#0C0F16",
    },
    PRIMARY: {
        50: "#F0F1FF",
        100: "#D9DCFF",
        200: "#BFC5FF",
        300: "#8C96FF",
        400: "#7373FF",
        500: "#5D55F2",
        600: "#4840D6",
        700: "#3B34BA",
        800: "#292399",
        900: "#09064C",
        1000: "#333080",
        1100: "#292865",
        1200: "#1F204B",
        1300: "#191A39",
    },
    RED: {
        50: "#FEEFF1",
        100: "#FEE0E4",
        200: "#FFC7C7",
        300: "#FCA5A5",
        400: "#F87171",
        500: "#EF444A",
        600: "#CC313B",
        700: "#B82832",
        800: "#A31D26",
        900: "#850B13",
        1000: "#521D22",
        1100: "#451A1F",
        1200: "#3A191F",
        1300: "#2C161C",
    },
    GREEN: {
        50: "#E4FDF3",
        100: "#C6F7E4",
        200: "#7CE5BC",
        300: "#65DBAC",
        400: "#51C29C",
        500: "#36A882",
        600: "#23946E",
        700: "#1B8561",
        800: "#107553",
        900: "#085239",
        1000: "#20584A",
        1100: "#1B463D",
        1200: "#163430",
        1300: "#132727",
    },
    YELLOW: {
        50: "#FFFBEB",
        100: "#FEF3C7",
        200: "#FDE68A",
        300: "#FCD34D",
        400: "#FBBF24",
        500: "#F59E0B",
        600: "#D97706",
        700: "#B45309",
        800: "#92400E",
        900: "#78350F",
        1000: "#7C5411",
        1100: "#604212",
        1200: "#443113",
        1300: "#312614",
    },
    TANGERINE: {
        50: "#FFF1EA",
        100: "#FFDDCB",
        200: "#FFC8AC",
        300: "#FFB38D",
        400: "#FF9F6E",
        500: "#FF9159",
        600: "#F28249",
        700: "#ED773B",
        800: "#E0682B",
        900: "#CC5214",
        1000: "#814B34",
        1100: "#633A28",
        1200: "#462C23",
        1300: "#33201C",
    },
    CARIBBEAN: {
        50: "#E1FAF6",
        100: "#C4F5F0",
        200: "#A2E8E1",
        300: "#81DAD4",
        400: "#5FCFC8",
        500: "#38B6B2",
        600: "#2EA6A2",
        700: "#2A898C",
        800: "#197780",
        900: "#005259",
        1000: "#215F61",
        1100: "#1C4B4E",
        1200: "#17373B",
        1300: "#132A2F",
    },
    SKY: {
        50: "#F5FCFF",
        100: "#CEEEFF",
        200: "#B0E4FF",
        300: "#92DBFF",
        400: "#75D1FF",
        500: "#61CAFF",
        600: "#49BAF2",
        700: "#3CADE5",
        800: "#279ED9",
        900: "#0F85BF",
        1000: "#356986",
        1100: "#2B526A",
        1200: "#203C4E",
        1300: "#1A2D3B",
    },
    DAFFODIL: {
        50: "#FFFBF0",
        100: "#FFF5D9",
        200: "#FFEBB5",
        300: "#FFE395",
        400: "#FFDA75",
        500: "#FFCC55",
        600: "#FABF37",
        700: "#F5B82A",
        800: "#D99B00",
        900: "#B27D00",
        1000: "#816420",
        1100: "#635023",
        1200: "#463A20",
        1300: "#332C1C",
    },
    FLAMINGO: {
        50: "#FCE3EE",
        100: "#FFCCE1",
        200: "#FEB2D2",
        300: "#FE98C3",
        400: "#FE7FB4",
        500: "#FF66A6",
        600: "#F25596",
        700: "#D93D7E",
        800: "#BF306C",
        900: "#99174D",
        1000: "#81395B",
        1100: "#632E4A",
        1200: "#462438",
        1300: "#331D2D",
    },
    ORCHID: {
        50: "#F5E9F9",
        100: "#E5C9EF",
        200: "#D6A9E5",
        300: "#C788DC",
        400: "#B768D2",
        500: "#AD52CC",
        600: "#A644C8",
        700: "#8831A6",
        800: "#66257C",
        900: "#441853",
        1000: "#592F6D",
        1100: "#462758",
        1200: "#331F42",
        1300: "#261A33",
    },
    BLUE: {
        50: "##E5F2FF",
        100: "#CCE5FF",
        200: "#99C3FF",
        300: "#73ADFF",
        400: "#599EFF",
        500: "#3183F7",
        600: "#156DE8",
        700: "#156DE8",
        800: "#092F64",
        900: "#031021",
        1000: "#1E4782",
        1100: "#193967",
        1200: "#152B4C",
        1300: "#12223A",
    }
};

export default COLORS;