import React, { useImperativeHandle, useRef } from 'react';
import { useTextField } from '@react-aria/textfield';
import cn from "classnames";
import { FiAlertTriangle } from "react-icons/fi";
import Typography from 'components/Typography/Typography';
import useStyles from './TextField.styles';


/**
 * @typedef {Object} TextFieldProps
 * @property {string} className
 * @property {string} color
 * @property {string} inputClassName
 * @property {string} label
 * @property {string} ariaLabel
 * @property {string} errorMessage
 * @property {boolean} hasError
 * @property {React.ReactNode} startAdornment
 * @property {React.ReactNode} endAdornment
 * @property {React.RefObject} ref
 * @property {function} onChange
 * @property {function} onBlur
 */

export default React.forwardRef((props, ref) => {
    const cls = useStyles();
    const textInput = useRef(null);
    useImperativeHandle(ref, () => textInput.current); // expose the input ref to the parent

    const defaultEndAdornment = props.hasError ? (<FiAlertTriangle size={16} className={cls.alertIcon} />) : null;
    let { className, label, color = "textSecondary", hasError, ariaLabel, onChange, onBlur, inputClassName, startAdornment, endAdornment = defaultEndAdornment } = props;
    let { labelProps, inputProps, errorMessageProps } = useTextField({ ...props, ["aria-label"]: ariaLabel }, textInput);


    function handleClick() {
        setTimeout(() => textInput.current.focus(), 0);
    }

    return (
        <div className={cn(cls.inputContainer, className)}>
            {label && <label {...labelProps}><Typography variant="body1" color={hasError ? "error" : "textSecondary"} fontWeight={700}>{label}</Typography></label>}
            <div className={cn(cls.inputFieldContainer, cls[color], hasError && cls.hasError)} onMouseDown={handleClick}>
                {startAdornment && <div className={cls.inputStartAdornment} >{startAdornment}</div>}
                <input className={cn(cls.input, inputClassName)} {...inputProps} ref={textInput} onChange={onChange} onBlur={onBlur} />
                {endAdornment && <div className={cls.inputEndAdornment}>{endAdornment}</div>}
            </div>
            {Boolean(hasError && props.errorMessage) && <div {...errorMessageProps}><Typography className={cls.errorText} variant="body1" color="error">{props.errorMessage}</Typography></div>}
        </div>
    );
})