import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useOutlet, useOutletContext } from "react-router";

// delays rendering new content so that pages animations run smoothly
const BaseRoute = ({ delayContent = false, title, children }) => {
  const [mount, setmount] = useState(!delayContent);
  // if this route was placed as an outlet get the context
  const outletProps = useOutletContext();
  useEffect(() => {
    if (!mount) {
      setTimeout(() => setmount(true), 400)
      return () => setmount(false)
    }
  }, [])

  return (
    <>
      {title && <Helmet><title>{title}</title></Helmet>}
      {mount && (typeof children === "function" ? children(outletProps) : children)}
    </>
  )
};

export default BaseRoute;
