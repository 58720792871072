import COLORS from 'themes/colors';
import { createStylesWithTheme } from 'utils/functions';


const styles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 583,
    alignSelf: "center",
    width: "100%",
    textAlign: "center",
    gap: "24px"
  },
  header: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "8px",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
  awsServiceCard: {
    display: "flex",
    justifyContent: "space-between",
    "&:hover": {
      background: theme.type === "dark" ? COLORS.DARK_NEUTRALS[600] : COLORS.LIGHT_NEUTRALS[500]
    },
    "&:focus-visible": {
      borderColor: "none",
    },
    alignItems: 'center',
    "& svg": {
      marginRight: 8,
      height: 32,
    }
  },
  allowedServiceButton: ({
    borderColor: [theme.palette.primary.main, "!important"],
    background: theme.type === "dark" ? COLORS.PRIMARY[1300] : COLORS.PRIMARY[100],
    "&:hover": {
      background: theme.type === "dark" ? COLORS.PRIMARY[1200] : COLORS.PRIMARY[200]
    }
  }),
});

export default createStylesWithTheme(styles);