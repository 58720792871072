import React from "react";
import { Link } from "react-router-dom";
import Typography from 'components/Typography/Typography';
import Button from 'components/Button/Button';

import { ROUTE_PATHS } from 'enums';

import useStyles from './NotFound.styles';

export default function NotFound(props) {
    const cls = useStyles();
    return (
        <div className={cls.container}>
            <Typography variant="h2" color="textPrimary">Page not found</Typography>
            <Button component={Link} to={ROUTE_PATHS.HOME} className={cls.submitBtn} variant="outline" color="primary">Home</Button>
        </div>
    );
}

