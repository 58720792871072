import { createStylesWithTheme } from 'utils/functions';

const styles = {
    container: {
        display: "flex",
        flexDirection: "column",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#060b19",
    },
    submitBtn: {
        marginTop: 15,
    }
};

export default createStylesWithTheme(styles);