import React from "react";
import cn from "classnames";
import Button from 'components/Button/Button';
import useStyles from './IconButton.styles';

export default React.forwardRef(({ size = 32, iconSize = 20, children, ...rest }, ref) => {
  const iSize = iconSize || Math.max(size - 10, size);
  const cls = useStyles({ size, iconSize: iSize });
  return (
    <Button
      ref={ref}
      variant="outline"
      size={null}
      color="primary"
      {...rest}
      className={cn(cls.iconBtn, rest.className)}
    >
      {children}
    </Button>
  );
})