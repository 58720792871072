import { createStylesWithTheme } from 'utils/functions';
import darkTheme from 'themes/darkTheme';
import COLORS from 'themes/colors';

const styles = (theme) => ({
    checkboxContainer: {
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        marginBottom: 0,
        "&$disabledContainer": {
            cursor: "default",
        }
    },
    checkboxDesc: {
        alignItems: "flex-start",
    },
    visuallyHidden: {
        position: ["relative", "!important"], // input is hidden using absolute positioning by default which causes error on small screens, so  we need to use keep it in the DOM for react aria to work
    },
    checkbox: {
        minHeight: 24,
        minWidth: 24,
        borderRadius: 4,
        cursor: "pointer",
        borderWidth: 2,
        borderStyle: "solid",
        borderColor: theme.type === "dark" ? COLORS.LIGHT_NEUTRALS[700] : COLORS.MID_NEURTRALS[200],
        color: COLORS.LIGHT_NEUTRALS[0],
        display: "flex",
        "&$smallSize": {
            minHeight: 20,
            minWidth: 20,
        },
        "&$withLabel": {
            marginRight: 12,
        },
        "&$isSelected": {
            backgroundColor: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
            alignItems: "center",
            justifyContent: "center",
        },
        "&$isDisabled": {
            borderColor: darkTheme.palette.text.ternary,
            backgroundColor: COLORS.DARK_NEUTRALS[50],
            cursor: "default",
            pointerEvents: "none",
        },
        "&$isDisabled$isSelected": {
            borderColor: darkTheme.palette.text.ternary,
        },
        "&:hover:not($isDisabled)": {
            boxShadow: `0px 0px 0px 4px ${theme.type === "dark" ? COLORS.DARK_NEUTRALS[50] : COLORS.LIGHT_NEUTRALS[500]}`,
        },
        "&:focus:not($isDisabled)": {
            boxShadow: `0px 0px 0px 4px ${theme.type === "dark" ? COLORS.PRIMARY[1000] : COLORS.PRIMARY[100]}`,
        },
    },

    withLabel: {},
    isSelected: {},
    isDisabled: {},
    disabledContainer: {},
    smallSizeLabel: {
        ...theme.typography.body3
    },
    mediumSizeLabel: {
        ...theme.typography.body2
    },
    largeSizeLabel: {
        ...theme.typography.body1
    }
});

export default createStylesWithTheme(styles);
