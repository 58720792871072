import { get, isBoolean } from "lodash";
import MonitoringProviders from "./Steps/MonitoringProviders/MonitoringProviders";

import Confirm from "./Steps/Confirm/Confirm";
import AWSCloudFormation from "./Steps/AWSCloudFormation/AWSCloudFormation";
import CloudFormationARN from "./Steps/CloudFormationARN/CloudFormationARN";
import { cloudProvidersConfig, CP_CREDETIALS_BASE_PATH } from "utils/cloudProviders";
import AWSAutonomousPolicy from "./Steps/AWSAutonomousPolicy/AWSAutonomousPolicy";
import AWSReadOnlyPolicy from "./Steps/AWSReadOnlyPolicy/AWSReadOnlyPolicy";
import AWSARN from "./Steps/AWSARN/AWSARN";
import AWSIAMRole from "./Steps/AWSIAMRole/AWSIAMRole";
import AWSIAMUser from "./Steps/AWSIAMUser/AWSIAMUser";
import AWSCredentials from "./Steps/AWSCredentials/AWSCredentials";
import { API_WIZARD_STEPS, CLOUD_PROVIDERS, CREDENTIALS_PROVIDERS, KUBERNETES_TYPES, ROUTE_PATHS } from "enums";
import UpsertMonitoringProvider from "./Steps/UpsertMonitoringProvider/UpsertMonitoringProvider";
import { submitAccountDetails, submitFlowType, submitGoal, submitKubType, submitMonitoringProviderType, submitUIState } from "utils/api/build";
import { submitOnboarding } from "utils/api/onboard";
import AdditionalCloudProducts from "./Steps/AdditionalCloudProducts/AdditionalCloudProducts";

export const STEP_KEYS = {
    SELECT_GOAL: "SELECT_GOAL",
    SELECT_ACCOUNT_TYPE: "SELECT_ACCOUNT_TYPE",

    SELECT_KUB_ACCOUNT_TYPE: "SELECT_KUB_ACCOUNT_TYPE",

    NAME_CLOUD_ACCOUNT: "NAME_CLOUD_ACCOUNT",
    ADDITIONAL_CLOUD_PRODUCTS: "ADDITIONAL_CLOUD_PRODUCTS",
    CLOUD_FORMATION_CONNECT: "CLOUD_FORMATION_CONNECT",
    CLOUD_FORMATION_ARN: "CLOUD_FORMATION_ARN",

    AWS_MANUAL_AUTONOMOUS: "AWS_MANUAL_AUTONOMOUS",
    AWS_MANUAL_READ_ONLY: "AWS_MANUAL_READ_ONLY",
    AWS_MANUAL_IAM_ROLE: "AWS_MANUAL_IAM_ROLE",
    AWS_MANUAL_ARN: "AWS_MANUAL_ARN",

    AWS_MANUAL_IAM_USER: "AWS_MANUAL_IAM_USER",
    AWS_MANUAL_CREDENTIALS: "AWS_MANUAL_CREDENTIALS",

    MONITORING_PROVIDERS: "MONITORING_PROVIDERS",
    UPSERT_MONITORING_PROVIDER: "UPSERT_MONITORING_PROVIDER",
    CONFIRMATION: "CONFIRMATION",
}

export const FLOW_TYPES = {
    ECS: "ECS",
    //EC2:"EC2",
    EKS: "EKS",
    LAMBDA: "LAMBDA",
    GENERAL_KUB: "GENERAL_KUB",
}

export const STEP_GROUPS_KEY = {
    SELECT_GOAL: "SELECT_GOAL",
    SELECT_ACCOUNT_TYPE: "SELECT_ACCOUNT_TYPE",
    SELECT_KUB_ACCOUNT_TYPE: "SELECT_KUB_ACCOUNT_TYPE",
    NAME_CLOUD_ACCOUNT: "NAME_CLOUD_ACCOUNT",
    SELECT_AWS_ADDITIONAL_PRODUCTS: "SELECT_AWS_ADDITIONAL_PRODUCTS",
    SETUP_IAM: "SETUP_IAM",
    CONNECT_TO_SEDAI: "CONNECT_TO_SEDAI",
    SELECT_MONITORING_PROVIDER: "SELECT_MONITORING_PROVIDER",
    UPSERT_MONITORING_PROVIDER: "UPSERT_MONITORING_PROVIDER",
    CONFIRM: "CONFIRM",
};

export const STEP_STATES = {
    ACTIVE: "ACTIVE",
    IN_ACTIVE: "IN_ACTIVE",
    DONE: "DONE",
}


export const wizardGroupSteps = [
    {
        key: STEP_GROUPS_KEY.SELECT_GOAL,
        label: "Choose your goal",
    },
    {
        key: STEP_GROUPS_KEY.SELECT_ACCOUNT_TYPE,
        label: "Select resource type",
    },
    {
        key: STEP_GROUPS_KEY.SELECT_KUB_ACCOUNT_TYPE,
        label: "Select Kubernetes provider",
    },
    {
        key: STEP_GROUPS_KEY.SELECT_MONITORING_PROVIDER,
        label: "Select monitoring provider",
    },
    {
        key: STEP_GROUPS_KEY.NAME_CLOUD_ACCOUNT,
        renderLabel: (wizardData) => wizardData?.uiStateDetails?.data?.sedaiAccount?.accountDetails?.cloudProvider === CLOUD_PROVIDERS.KUBERNETES ? "Enter cluster details" : "Enter cloud details",
        isAlwaysVisible: true,
    },
    {
        key: STEP_GROUPS_KEY.SELECT_AWS_ADDITIONAL_PRODUCTS,
        label: "Select additional products",
    },
    {
        key: STEP_GROUPS_KEY.SETUP_IAM,
        label: "Set up IAM",
        renderSecondaryText: (wizardData) => {
            if (wizardData?.uiStateDetails?.uiData?.awsConnectionMethod === CONNECT_TO_AWS_METHODS.MANUAL)
                return "~10 minutes"
            return "2 minutes";
        },
    },
    {
        key: STEP_GROUPS_KEY.CONNECT_TO_SEDAI,
        label: "Connect to Sedai",
        renderSecondaryText: (wizardData) => {
            if (wizardData?.uiStateDetails?.data?.sedaiAccount?.accountDetails?.cloudProvider === CLOUD_PROVIDERS.KUBERNETES)
                return "2 minutes";
        },
        isAlwaysVisible: true,
    },
    {
        key: STEP_GROUPS_KEY.CONFIRM,
        label: "Confirm setup",
    }
];

export const supportedFlowTypes = [
    {
        label: "AWS Lambda",
        shortenedLabel: "Lambda",
        flowType: FLOW_TYPES.LAMBDA,
        iconNames: ["awsLambdaWithBg"],
        type: CLOUD_PROVIDERS.AWS,

    },
    {
        label: "AWS Elastic Container Service (ECS)",
        shortenedLabel: "ECS",
        flowType: FLOW_TYPES.ECS,
        iconNames: ["awsECSWithBg"],
        type: CLOUD_PROVIDERS.AWS,

    },
    /*     {
            label: "AWS EC2",
            flowType: FLOW_TYPES.EC2,
            iconNames: ["awsEC2WithBg"],
            type: CLOUD_PROVIDERS.AWS,
    
        }, */
    {
        ...cloudProvidersConfig[KUBERNETES_TYPES.KUBERNETES_ON_AWS],
        flowType: FLOW_TYPES.EKS,
        shortenedLabel: "EKS",
        label: "AWS Elastic Kubernetes Service (EKS)",
    },
    {
        label: "Kubernetes",
        shortenedLabel: "Kubernetes",
        labelDesc: "(Other)",
        flowType: FLOW_TYPES.GENERAL_KUB,
        iconNames: ["kubernetes"],
        type: CLOUD_PROVIDERS.KUBERNETES,

    },

];
export const supportedAWSEnvironments = [
    {
        label: "Lambda, ECS and EC2",
        flowType: FLOW_TYPES.LAMBDA_ECS_EC2,
        iconNames: ["awsLambdaWithBg", "awsECSWithBg", "awsEC2WithBg"],
        type: CLOUD_PROVIDERS.AWS,

    },
    {
        ...cloudProvidersConfig[KUBERNETES_TYPES.KUBERNETES_ON_AWS],
        flowType: FLOW_TYPES.EKS,
    },
];

export const supportedKubEnvironments = [
    {
        ...cloudProvidersConfig[KUBERNETES_TYPES.KUBERNETES_SELF_MANAGED],
        label: "Open source",
        flowType: FLOW_TYPES.GENERAL_KUB,

    },
    {
        ...cloudProvidersConfig[KUBERNETES_TYPES.KUBERNETES_ON_AWS],
        flowType: FLOW_TYPES.EKS,

    },
    {
        ...cloudProvidersConfig[KUBERNETES_TYPES.KUBERNETES_ON_AZURE],
        label: "Azure",
        flowType: FLOW_TYPES.GENERAL_KUB,
    },
    {
        ...cloudProvidersConfig[KUBERNETES_TYPES.KUBERNETES_ON_DIGITAL_OCEAN],
        label: "DigitalOcean",
        flowType: FLOW_TYPES.GENERAL_KUB,
    },
    {
        ...cloudProvidersConfig[KUBERNETES_TYPES.KUBERNETES_ON_GCP],
        flowType: FLOW_TYPES.GENERAL_KUB,
    },
    {
        ...cloudProvidersConfig[KUBERNETES_TYPES.KUBERNETES_ON_IBM],
        flowType: FLOW_TYPES.GENERAL_KUB,
    },
    {
        ...cloudProvidersConfig[KUBERNETES_TYPES.KUBERNETES_ON_ALIBABA],
        flowType: FLOW_TYPES.GENERAL_KUB,
    },
    {
        ...cloudProvidersConfig[KUBERNETES_TYPES.KUBERNETES_ON_RANCHER],
        flowType: FLOW_TYPES.GENERAL_KUB,
    },
    {
        ...cloudProvidersConfig[KUBERNETES_TYPES.KUBERNETES_ON_PLATFORM_9],
        flowType: FLOW_TYPES.GENERAL_KUB,
    },
    {
        ...cloudProvidersConfig[KUBERNETES_TYPES.KUBERNETES_ON_VMWARE_TANZU],
        flowType: FLOW_TYPES.GENERAL_KUB,
    },
    {
        ...cloudProvidersConfig[KUBERNETES_TYPES.KUBERNETES_ON_ORACLE],
        label: "Oracle",
        flowType: FLOW_TYPES.GENERAL_KUB,
    },
    {
        ...cloudProvidersConfig[KUBERNETES_TYPES.KUBERNETES_ON_OPEN_SHIFT],
        label: "OpenShift",
        flowType: FLOW_TYPES.GENERAL_KUB,
    },
].map((v) => ({ ...v, iconNames: v.iconNames.length > 1 ? v.iconNames.filter((iconName) => iconName !== "kubernetes") : v.iconNames }))

export const allSupportedEnvironments = [...supportedFlowTypes, ...supportedKubEnvironments];

export const CONNECT_TO_AWS_METHODS = {
    CLOUD_FORMATION: "CLOUD_FORMATION",
    MANUAL: "MANUAL",
}
export const CONNECT_TO_AWS_CREDENTIALS_TYPES = {
    CLOUD_FORMATION_ARN: "CLOUD_FORMATION_ARN",
    IAM_ROLE: "IAM_ROLE",
    IAM_USER: "IAM_USER",
}

export const CONNECT_TO_AWS_POLICY_TYPES = {
    AUTONOMOUS: "AUTONOMOUS",
    READ_ONLY: "READ_ONLY",
}

// this is the step that decides which flow to start either lambda_ecs_ec2 or EKS
export const initialSteps = [
    {
        key: STEP_KEYS.SELECT_GOAL,
        group: STEP_GROUPS_KEY.SELECT_GOAL,
        routePath: ROUTE_PATHS.SETUP_WIZARD_SELECT_GOAL,
        dataIsValid: (wizardData) => !!wizardData?.companyDetails?.goal?.length,
        submit: (wizardData, { email, envDomain }) => {
            return submitGoal(
                email,
                envDomain,
                API_WIZARD_STEPS.WAITING_TO_SELECT_GOAL,
                wizardData.companyDetails.goal,
            )
        }
    },
    {
        key: STEP_KEYS.SELECT_ACCOUNT_TYPE,
        group: STEP_GROUPS_KEY.SELECT_ACCOUNT_TYPE,
        routePath: ROUTE_PATHS.SETUP_WIZARD_SELECT_ACCOUNT_TYPE,
        dataIsValid: (wizardData) => wizardData?.resourceDetails?.resourceTypes?.[0],
        filter: (wizardData) => !(wizardData?.forcedFlowType || (wizardData?.initialFlowType && !wizardData?.uiStateDetails?.uiData?.ignoreInitialFlowType)),
        submit: (wizardData, { email, envDomain }) => {
            return submitFlowType(
                email,
                envDomain,
                API_WIZARD_STEPS.WAITING_FOR_RESOURCE_TYPE,
                wizardData.resourceDetails,
                wizardData.accountDetails, // since account selection change can affect the accountDetails too we need to pass it
                wizardData.uiStateDetails, // since account selection change can affect the uistate too we need to pass it
            )
        }
    },
];

export const confirmationStep = {
    key: STEP_KEYS.CONFIRMATION,
    component: Confirm,
    group: STEP_GROUPS_KEY.CONFIRM,
    routePath: ROUTE_PATHS.SETUP_WIZARD_CONFIRM,
    submit: (wizardData, { envDomain }) => {
        return submitOnboarding({ onboardingConfigs: [wizardData.uiStateDetails.data], envDomain })
    },
};

const iamSubmitFunction = (wizardData, { email, envDomain }) => {
    return submitUIState(
        email,
        envDomain,
        API_WIZARD_STEPS.WAITING_FOR_AWS_ONBOARDING_CONFIGS,
        wizardData?.uiStateDetails,
    )
}
export const lambdaEC2Steps = [
    {
        key: STEP_KEYS.NAME_CLOUD_ACCOUNT,
        group: STEP_GROUPS_KEY.NAME_CLOUD_ACCOUNT,
        routePath: ROUTE_PATHS.SETUP_WIZARD_AWS_NAME_CLOUD_ACCOUNT,
        dataIsValid: (wizardData) => wizardData?.accountDetails?.[0]?.name && wizardData?.accountDetails?.[0]?.trafficRange && (wizardData?.uiStateDetails?.data?.sedaiAccount?.name || "").trim().length > 0,
        submit: (wizardData, { email, envDomain }) => {
            return submitAccountDetails(
                email,
                envDomain,
                API_WIZARD_STEPS.WAITING_FOR_CLOUD_ACCOUNT_NAME,
                wizardData?.uiStateDetails,
                wizardData?.accountDetails,
            )
        },
    },
    {
        key: STEP_KEYS.ADDITIONAL_CLOUD_PRODUCTS,
        component: AdditionalCloudProducts,
        group: STEP_GROUPS_KEY.SELECT_AWS_ADDITIONAL_PRODUCTS,
        routePath: ROUTE_PATHS.SETUP_WIZARD_AWS_CONNECT_ADDITIONAL_PRODUCTS,
        dataIsValid: () => true,
        filter: (wizardData) => [FLOW_TYPES.ECS, FLOW_TYPES.LAMBDA].includes(wizardData?.resourceDetails?.resourceTypes?.[0]),
        submit: (wizardData, { email, envDomain }) => {
            return submitUIState(
                email,
                envDomain,
                API_WIZARD_STEPS.WAITING_FOR_AWS_ONBOARDING_CONFIGS,
                wizardData?.uiStateDetails,
            )
        },
        getIsBlockedOnMobile: () => false,
    },
    {
        key: STEP_KEYS.CLOUD_FORMATION_CONNECT,
        component: AWSCloudFormation,
        group: STEP_GROUPS_KEY.SETUP_IAM,
        routePath: ROUTE_PATHS.SETUP_WIZARD_CLOUD_FORMATION_CONNECT,
        dataIsValid: (wizardData) => !!wizardData?.uiStateDetails?.uiData.isCloudFormationLinkClicked,
        filter: (wizardData) => wizardData?.uiStateDetails?.uiData?.awsConnectionMethod === CONNECT_TO_AWS_METHODS.CLOUD_FORMATION,
        submit: iamSubmitFunction,
        invitationUIDataSubmit: (wizardData, { email, envDomain }) => {
            return submitUIState(
                email,
                envDomain,
                API_WIZARD_STEPS.WAITING_FOR_AWS_ONBOARDING_CONFIGS,
                wizardData?.uiStateDetails,
            )
        },
        getIsBlockedOnMobile: () => true,
    },
    {
        key: STEP_KEYS.CLOUD_FORMATION_ARN,
        component: CloudFormationARN,
        group: STEP_GROUPS_KEY.CONNECT_TO_SEDAI,
        routePath: ROUTE_PATHS.SETUP_WIZARD_CLOUD_FORMATION_ARN,
        dataIsValid: (wizardData) => !!get(wizardData, `uiStateDetails.data.sedaiAccount.${CP_CREDETIALS_BASE_PATH}.role`) && !!get(wizardData, `uiStateDetails.data.sedaiAccount.${CP_CREDETIALS_BASE_PATH}.externalId`),
        filter: (wizardData) => wizardData?.uiStateDetails?.uiData?.awsCredetialsType === CONNECT_TO_AWS_CREDENTIALS_TYPES.CLOUD_FORMATION_ARN,
        submit: iamSubmitFunction,
        getIsBlockedOnMobile: () => true,
    },

    {
        key: STEP_KEYS.AWS_MANUAL_AUTONOMOUS,
        component: AWSAutonomousPolicy,
        group: STEP_GROUPS_KEY.SETUP_IAM,
        routePath: ROUTE_PATHS.SETUP_WIZARD_AWS_MANUAL_AUTONOMOUS,
        dataIsValid: (wizardData) => !!wizardData?.uiStateDetails?.uiData.isAutonomousPolicyCopied,
        filter: (wizardData) => wizardData?.uiStateDetails?.uiData?.awsPolicyType === CONNECT_TO_AWS_POLICY_TYPES.AUTONOMOUS && wizardData?.uiStateDetails?.uiData?.awsConnectionMethod === CONNECT_TO_AWS_METHODS.MANUAL,
        submit: iamSubmitFunction,
        getIsBlockedOnMobile: () => true,
    },
    {
        key: STEP_KEYS.AWS_MANUAL_READ_ONLY,
        component: AWSReadOnlyPolicy,
        group: STEP_GROUPS_KEY.SETUP_IAM,
        routePath: ROUTE_PATHS.SETUP_WIZARD_AWS_MANUAL_READ_ONLY,
        dataIsValid: (wizardData) => !!wizardData?.uiStateDetails?.uiData.isReadOnlyPolicyCopied,
        filter: (wizardData) => wizardData?.uiStateDetails?.uiData?.awsPolicyType === CONNECT_TO_AWS_POLICY_TYPES.READ_ONLY && wizardData?.uiStateDetails?.uiData?.awsConnectionMethod === CONNECT_TO_AWS_METHODS.MANUAL,
        submit: iamSubmitFunction,
        getIsBlockedOnMobile: () => true,
    },
    {
        key: STEP_KEYS.AWS_MANUAL_IAM_ROLE,
        component: AWSIAMRole,
        group: STEP_GROUPS_KEY.SETUP_IAM,
        routePath: ROUTE_PATHS.SETUP_WIZARD_AWS_MANUAL_IAM_ROLE,
        dataIsValid: (wizardData) => !!wizardData?.uiStateDetails?.uiData.isIAMRoleCreated,
        filter: (wizardData) => wizardData?.uiStateDetails?.uiData?.awsCredetialsType === CONNECT_TO_AWS_CREDENTIALS_TYPES.IAM_ROLE,
        submit: iamSubmitFunction,
        getIsBlockedOnMobile: () => true,
    },
    {
        key: STEP_KEYS.AWS_MANUAL_ARN,
        component: AWSARN,
        group: STEP_GROUPS_KEY.CONNECT_TO_SEDAI,
        routePath: ROUTE_PATHS.SETUP_WIZARD_AWS_MANUAL_IAM_ROLE_ARN,
        dataIsValid: (wizardData) => !!get(wizardData, `uiStateDetails.data.sedaiAccount.${CP_CREDETIALS_BASE_PATH}.role`),
        filter: (wizardData) => wizardData?.uiStateDetails?.uiData?.awsCredetialsType === CONNECT_TO_AWS_CREDENTIALS_TYPES.IAM_ROLE,
        submit: iamSubmitFunction,
        getIsBlockedOnMobile: () => true,
    },
    {
        key: STEP_KEYS.AWS_MANUAL_IAM_USER,
        component: AWSIAMUser,
        group: STEP_GROUPS_KEY.SETUP_IAM,
        routePath: ROUTE_PATHS.SETUP_WIZARD_AWS_MANUAL_IAM_USER,
        dataIsValid: (wizardData) => !!wizardData?.uiStateDetails?.uiData.isIAMUserCreated,
        filter: (wizardData) => wizardData?.uiStateDetails?.uiData?.awsCredetialsType === CONNECT_TO_AWS_CREDENTIALS_TYPES.IAM_USER,
        submit: iamSubmitFunction,
        getIsBlockedOnMobile: () => true,
    },
    {
        key: STEP_KEYS.AWS_MANUAL_CREDENTIALS,
        component: AWSCredentials,
        group: STEP_GROUPS_KEY.SETUP_IAM,
        routePath: ROUTE_PATHS.SETUP_WIZARD_AWS_MANUAL_CREDENTIALS,
        dataIsValid: (wizardData) => !!(get(wizardData, `uiStateDetails.data.sedaiAccount.${CP_CREDETIALS_BASE_PATH}.credentialsProvider`) === CREDENTIALS_PROVIDERS.AWS_STATIC && get(wizardData, `uiStateDetails.data.sedaiAccount.${CP_CREDETIALS_BASE_PATH}.accessKey`, "").trim().length > 0 && get(wizardData, `uiStateDetails.data.sedaiAccount.${CP_CREDETIALS_BASE_PATH}.secretKey`, "").trim().length > 0),
        filter: (wizardData) => wizardData?.uiStateDetails?.uiData?.awsCredetialsType === CONNECT_TO_AWS_CREDENTIALS_TYPES.IAM_USER,
        submit: iamSubmitFunction,
        getIsBlockedOnMobile: () => true,
    },
];

export const kubSteps = [
    {
        key: STEP_KEYS.SELECT_KUB_ACCOUNT_TYPE,
        group: STEP_GROUPS_KEY.SELECT_KUB_ACCOUNT_TYPE,
        routePath: ROUTE_PATHS.SETUP_WIZARD_SELECT_KUB_ACCOUNT_TYPE,
        filter: (wizardData) => wizardData?.resourceDetails?.resourceTypes?.[0] === FLOW_TYPES.GENERAL_KUB,
        dataIsValid: (wizardData) => wizardData?.resourceDetails?.kubeProviders?.[0] && wizardData?.uiStateDetails?.data?.sedaiAccount?.accountDetails?.metadata?.clusterProvider, //uiStateDetails.data.sedaiAccount.accountDetails.metadata.clusterProvider
        submit: (wizardData, { email, envDomain }) => {
            return submitKubType(
                email,
                envDomain,
                API_WIZARD_STEPS.WAITING_FOR_KUBE_SERVICE_PROVIDER,
                wizardData.resourceDetails.kubeProviders,
                wizardData?.uiStateDetails,
            )
        }
    },
    {
        key: STEP_KEYS.MONITORING_PROVIDERS,
        component: MonitoringProviders,
        group: STEP_GROUPS_KEY.SELECT_MONITORING_PROVIDER,
        routePath: ROUTE_PATHS.SETUP_WIZARD_MONITORING_PROVIDERS,
        dataIsValid: (wizardData) => wizardData?.resourceDetails?.monitoringProviders?.[0],
        submit: (wizardData, { email, envDomain }) => {
            return submitMonitoringProviderType(
                email,
                envDomain,
                API_WIZARD_STEPS.WAITING_FOR_KUBE_MONITORING_PROVIDER_TYPE,
                wizardData.resourceDetails.monitoringProviders,
            )
        },
        getIsBlockedOnMobile: () => true,
    },
    {
        key: STEP_KEYS.NAME_CLOUD_ACCOUNT,
        group: STEP_GROUPS_KEY.NAME_CLOUD_ACCOUNT,
        routePath: ROUTE_PATHS.SETUP_WIZARD_KUB_NAME_CLOUD_ACCOUNT,
        dataIsValid: (wizardData) => wizardData?.accountDetails?.[0]?.name && wizardData?.accountDetails?.[0]?.trafficRange && (wizardData?.uiStateDetails?.data?.sedaiAccount?.name || "").trim().length > 0,
        submit: (wizardData, { email, envDomain }) => {
            return submitAccountDetails(
                email,
                envDomain,
                API_WIZARD_STEPS.WAITING_FOR_CLOUD_ACCOUNT_NAME,
                wizardData?.uiStateDetails,
                wizardData?.accountDetails,
            )
        },
        getIsBlockedOnMobile: () => true,
    },
    {
        key: STEP_KEYS.UPSERT_MONITORING_PROVIDER,
        component: UpsertMonitoringProvider,
        group: STEP_GROUPS_KEY.CONNECT_TO_SEDAI,
        routePath: ROUTE_PATHS.SETUP_WIZARD_UPSERT_MONITORING_PROVIDER,
        dataIsValid: (wizardData) => wizardData?.uiStateDetails?.data?.sedaiMonitoringProviders?.[0],
        submit: (wizardData, { email, envDomain }) => {
            return submitUIState(
                email,
                envDomain,
                API_WIZARD_STEPS.WAITING_FOR_KUBE_MONITORING_PROVIDER_DATA,
                wizardData?.uiStateDetails,
            )
        },
        invitationUIDataSubmit: (wizardData, { email, envDomain }) => {
            return submitUIState(
                email,
                envDomain,
                API_WIZARD_STEPS.WAITING_FOR_KUBE_MONITORING_PROVIDER_DATA,
                wizardData?.uiStateDetails,
            )
        },
        getIsBlockedOnMobile: () => true,
    },

];

export const getFlowTypeSteps = (flowType) => {
    if (flowType === FLOW_TYPES.ECS || flowType === FLOW_TYPES.LAMBDA) {
        return [...initialSteps, ...lambdaEC2Steps, confirmationStep];
    }
    if (flowType === FLOW_TYPES.EKS) {
        return [...initialSteps, ...kubSteps, confirmationStep];
    }
    if (flowType === FLOW_TYPES.GENERAL_KUB) {
        return [...initialSteps, ...kubSteps, confirmationStep];
    }
    return [...initialSteps, confirmationStep];
}


export const getCurrentStateSteps = (wizardData) => {
    const flowType = wizardData?.resourceDetails?.resourceTypes?.[0];
    return getFlowTypeSteps(flowType).filter(({ filter }) => (typeof filter === "function" ? filter(wizardData) : true));
}

export const getCredentialProviderForCredetialType = (credentialsType) => {
    if ([CONNECT_TO_AWS_CREDENTIALS_TYPES.CLOUD_FORMATION_ARN, CONNECT_TO_AWS_CREDENTIALS_TYPES.IAM_ROLE].includes(credentialsType)) {
        return CREDENTIALS_PROVIDERS.AWS_ENV_SUPPLIED;
    } else if (CONNECT_TO_AWS_CREDENTIALS_TYPES.IAM_USER === credentialsType) {
        return CREDENTIALS_PROVIDERS.AWS_STATIC;
    }
}


const AWS_SERVICE_NAMES = {
    LAMBDA: "LAMBDA",
    ECS: "ECS",
    EC2: "EC2",
    EBS: "EBS",
    S3: "S3",
}

export const supportedAWSManagedServices = [
    {
        key: AWS_SERVICE_NAMES.LAMBDA,
        label: "Lambda",
        icon: "awsLambdaWithBg"
    },
    {
        key: AWS_SERVICE_NAMES.ECS,
        label: "Elastic Container Service (ECS)",
        icon: "awsECSWithBg"
    },
    {
        key: AWS_SERVICE_NAMES.EC2,
        label: "Elastic Compute Cloud (EC2)",
        icon: "awsEC2WithBg"
    },
    {
        key: AWS_SERVICE_NAMES.EBS,
        label: "Elastic Block Storage (EBS)",
        icon: "awsEBS"
    },
    {
        key: AWS_SERVICE_NAMES.S3,
        label: "Simple Storage Service (S3)",
        icon: "s3"
    }
]