import { createStylesWithTheme } from 'utils/functions';

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxHeight: "100dvh",
    padding: "44px 24px 32px 24px",
    overflow: "auto",

  },
  headerLogoHorizontal: {
    minHeight: 24,
    height: 24,
    marginBottom: 64,
  },
  textContainer:{
    display: "flex",
    flexDirection: "column",
  },
  subTitle: {
    marginTop: 20,
    marginBottom: 20,
  },
  avatar: {
    maxWidth: 267,
    transform:"translateY(-25%) translateX(-25px)"
  },
  textAndAvatartContainer: {
    display: "flex",
  }
};

export default createStylesWithTheme(styles);