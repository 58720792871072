import { createStylesWithTheme } from 'utils/functions';
import tinycolor from 'tinycolor2';
import COLORS from 'themes/colors';

const styles = (theme) => ({
    infoIcon: {
        display: 'inherit',
        marginLeft: 4,
        minWidth: 16,
        borderRadius: 16,
        color: theme.type === "dark" ? COLORS.LIGHT_NEUTRALS[900] : COLORS.MID_NEURTRALS[50],
        "&:hover": {
            cursor: "pointer",
            background: tinycolor(theme.palette.primary.main).setAlpha(.2),
            color: theme.type === "dark" ? COLORS.PRIMARY[400] : COLORS.PRIMARY[500],
        },
    },
    targetContainerClassName: {
        display: 'initial',
    }
});

export default createStylesWithTheme(styles);
