import React from "react";
import { useTheme } from "react-jss";
import { FiMaximize2 } from "react-icons/fi";
import Typography from "components/Typography/Typography";
import Icon from "components/Icon/Icon";
import AlertMsgCard from "components/AlertMsgCard/AlertMsgCard";
import sedaiAvatarShockedFace from 'assets/img/sedaiAvatarShockedFace.png';
import useStyles from './WizardShrinkedScreenPlaceholder.styles';


const WizardShrinkedScreenPlaceholder = () => {
    const cls = useStyles();
    const theme = useTheme();

    return (
        <div className={cls.container}>
            <Icon name="logoWithNameVertical" className={cls.headerLogoVertical} />

            <Typography className={cls.title} variant="h2" color="textPrimary">Sometimes small screens can’t fit big ideas...</Typography>
            <AlertMsgCard className={cls.alertMsgCard} color="primary" variant="body3">
                <FiMaximize2 size={16} />For the best experience, we recommend expanding your browser to finish setup.
            </AlertMsgCard>

            <img className={cls.avatar} src={sedaiAvatarShockedFace} alt="Sedai's Shocked Octobus Avatar" />


        </div>
    )
};

export default WizardShrinkedScreenPlaceholder;
