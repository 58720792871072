import COLORS from 'themes/colors';
import { createStylesWithTheme } from 'utils/functions';

const styles = {
  cardHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
  },
  title: {
    flex: 1,
  },
  pickersContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
  cardFooterBtns: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "16px",
    marginTop: 16,
  },
};

export default createStylesWithTheme(styles);