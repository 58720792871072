import { useEffect, useMemo, useRef, useState } from "react";
import cn from "classnames";
import { useQuery } from "react-query";
import * as yup from 'yup';
import ReactDOM from 'react-dom';
import { Auth } from 'aws-amplify';
import { Authenticator, SelectField, useAuthenticator } from '@aws-amplify/ui-react';
import { FiCheckCircle } from "react-icons/fi";
import Icon from "components/Icon/Icon";
import Button from "components/Button/Button";
import Typography from "components/Typography/Typography";

import { ONBOARDING_PRODUCTS, PLAN_TIERS, QUERY_KEYS, ROUTE_PATHS } from "enums";
import segmentioTracking from "utils/segmentioTracking";
import { getEnrichForm } from "utils/api/enrichForm";
import { ONBOARDING_SOURCES, roleOptions } from "utils/constants";
import awsMarketPlace from 'assets/img/awsMarketPlace.png';

import '@aws-amplify/ui-react/styles.css';
import styles from './Signup.module.css';
import CornerLines from "views/common/CornerLines/CornerLines";

function getUrlParams() {
    const params = new URLSearchParams(window.location.search);
    return {
        sourceParam: params.get("source"),
        tierParam: params.get("tier")?.toLowerCase(),
        productParam: params.get("product")?.toLowerCase(),
        emailParam: params.get("email")?.toLowerCase(),
        tokenParam: params.get("token"),
    };
}

const components = {
    SignUp: {
        FormFields() {
            const { validationErrors, } = useAuthenticator();
            const [selectRef, setSelectRef] = useState(null);
            return (
                <>
                    {/* Re-use default `Authenticator.SignUp.FormFields` */}
                    <Authenticator.SignUp.FormFields />

                    {/* Append & require Terms & Conditions field to sign up  */}
                    <SelectField
                        ref={setSelectRef}
                        errorMessage={validationErrors["custom:role"]}
                        hasError={!!validationErrors["custom:role"]}
                        name="custom:role"
                        placeholder="Role"
                        required
                        labelHidden
                        style={{
                            color: !selectRef?.value ? "grey" : "#ffffff"
                        }}
                    >
                        {roleOptions.map(({ name, label }) => (
                            <option value={name}>{label}</option>
                        ))}
                    </SelectField>
                </>
            );
        },
    },
    Header() {
        const { sourceParam, tierParam } = useMemo(() => {
            return getUrlParams();
        }, []);

        const { route } = useAuthenticator((context) => [context.route]);
        if (route === 'confirmSignUp') return null;

        const getTitle = () => {
            if (tierParam === PLAN_TIERS.GROWTH || tierParam === PLAN_TIERS.BUSINESS)
                return "Start your 30 day free trial"
            if (tierParam === PLAN_TIERS.ENTERPRISE)
                return "Start your enterprise plan POC"
            else
                return "Create your free account"
        }
        if (sourceParam === ONBOARDING_SOURCES.AWS_MARKETPLACE_EKS || sourceParam === ONBOARDING_SOURCES.AWS_MARKETPLACE_LAMBDA || sourceParam === ONBOARDING_SOURCES.AWS_MARKETPLACE_ECS)
            return (
                <div className={styles.signUpHeader}>
                    <img className={styles.marketPlaceLogo} src={awsMarketPlace} alt="AWS Market Place" />
                    <Typography variant="body3" color="success" className={styles.marketPlaceHeader}>
                        <FiCheckCircle /> You have successfully subscribed to Sedai
                    </Typography>
                    <Typography variant="body1" color="textPrimary">Register your account to get started</Typography>
                </div>
            );
        else
            return (
                <div className={styles.signUpHeader}>
                    <Typography component="h2" variant="h2" color="textPrimary" className={styles.createAccountHeader}>
                        {getTitle()}
                    </Typography>
                    <Typography variant="body3" color="textTernary">No credit card required</Typography>
                </div>
            );
    },
    Footer() {
        const { route } = useAuthenticator((context) => [context.route]);
        if (route === 'confirmSignUp') return null;

        return (

            <div className={styles.signUpFooter}>
                <Typography className={styles.policyText} variant="body3" color="textTernary">By creating an account you agree to our <Button variant="link" color="primary" target="_blank" rel="noreferrer noopener" component="a" href="https://www.sedai.io/privacy-policy" >Privacy Policy</Button> and <Button variant="link" color="primary" target="_blank" rel="noreferrer noopener" component="a" href="https://www.sedai.io/service-agreement" >Service Agreement</Button>.</Typography>
                <Typography variant="body3" color="textTernary">Already have an account? <Button component="a" variant="link" color="primary" href={ROUTE_PATHS.LOGIN} >Log In</Button></Typography>
            </div>
        );
    },
};

const services = {
    async handleSignUp(formData) {
        let { attributes } = formData;
        segmentioTracking.customSignup({ email: attributes.email });
        const { sourceParam, tierParam, productParam, tokenParam } = getUrlParams();
        return Auth.signUp({
            ...formData,
            attributes: {
                ...formData.attributes,
                ["custom:tier"]: tierParam ? JSON.stringify({ plan: tierParam }) : null,
                ["custom:token"]: tokenParam,
                ["custom:product"]: productParam,
                ["custom:source"]: sourceParam,

            },
            autoSignIn: { enabled: true },

        }).catch((error) => {
            segmentioTracking.customSignupError({ email: attributes.email, error_message: error.message || "unknown" })
            return Promise.reject(error)
        });
    },
};

const sideBarContent = (productParam, sourceParam) => {
    if (productParam === ONBOARDING_PRODUCTS.LAMBDA || sourceParam === ONBOARDING_SOURCES.AWS_MARKETPLACE_LAMBDA)
        return (
            <div className={styles.marketingText}>
                <Typography component="h1" variant="h1" color="textPrimary">Optimize your Lambda functions for performance & cost — <Typography component="span" color="primary">autonomously.</Typography></Typography>
                <div className={styles.sidePanelSection}>
                    <Typography variant="body1" color="primary" fontWeight={600}>Optimize for performance & cost</Typography>
                    <Typography variant="body2" color="textPrimary">Machine learning configures your functions for the best performance and cost.</Typography>
                </div>
                <div className={styles.sidePanelSection}>
                    <Typography variant="body1" color="primary" fontWeight={600}>Eliminate cold starts</Typography>
                    <Typography variant="body2" color="textPrimary">Autonomous concurrency predicts your traffic and provides the right mix of provisioned and on-demand concurrency.</Typography>
                </div>
                <div className={styles.sidePanelSection}>
                    <Typography variant="body1" color="primary" fontWeight={600}>Setup takes 10 minutes</Typography>
                    <Typography variant="body2" color="textPrimary">Simply connect your cloud and sync monitoring data — Sedai will automatically begin learning how to improve your applications.</Typography>
                </div>
            </div>
        )
    else if (productParam === ONBOARDING_PRODUCTS.ECS || sourceParam === ONBOARDING_SOURCES.AWS_MARKETPLACE_ECS)
        return (
            <div className={styles.marketingText}>
                <Typography component="h1" variant="h1" color="textPrimary">Optimize your ECS services for cost & performance — <Typography component="span" color="primary">autonomously.</Typography></Typography>
                <div className={styles.sidePanelSection}>
                    <Typography variant="body1" color="primary" fontWeight={600}>Reduce cost by 40%</Typography>
                    <Typography variant="body2" color="textPrimary">Machine learning finds the most efficient veritical and horizontal scaling for your services.</Typography>
                </div>
                <div className={styles.sidePanelSection}>
                    <Typography variant="body1" color="primary" fontWeight={600}>Improve performance by 35%</Typography>
                    <Typography variant="body2" color="textPrimary">Sedai tunes customer-facing applications to minimize latency without additional spend.</Typography>
                </div>
                <div className={styles.sidePanelSection}>
                    <Typography variant="body1" color="primary" fontWeight={600}>Setup takes 10 minutes</Typography>
                    <Typography variant="body2" color="textPrimary">Simply connect your cloud and sync monitoring data — Sedai will automatically begin learning how to improve your services.</Typography>
                </div>
            </div>
        )
    else if (productParam === ONBOARDING_PRODUCTS.KUBERNETES || sourceParam === ONBOARDING_SOURCES.AWS_MARKETPLACE_EKS)
        return (
            <div className={styles.marketingText}>
                <Typography component="h1" variant="h1" color="textPrimary">Continuously optimize your Kubernetes workloads for cost & performance</Typography>
                <div className={styles.sidePanelSection}>
                    <Typography variant="body1" color="primary" fontWeight={600}>Reduce cost by 50%</Typography>
                    <Typography variant="body2" color="textPrimary">Fix overprovisioning and optimize resource efficiency at workload & node level.</Typography>
                </div>
                <div className={styles.sidePanelSection}>
                    <Typography variant="body1" color="primary" fontWeight={600}>Smart Controller for VPA/HPA</Typography>
                    <Typography variant="body2" color="textPrimary">Optimally configure your workloads without manual configuration.</Typography>
                </div>
                <div className={styles.sidePanelSection}>
                    <Typography variant="body1" color="primary" fontWeight={600}>Set up in 15 minutes</Typography>
                    <Typography variant="body2" color="textPrimary">Simply connect your cloud and sync monitoring data — Sedai will automatically begin learning how to improve your workloads.</Typography>
                </div>
            </div>
        )

    return (
        <div className={styles.marketingText}>
            <Typography component="h1" variant="h1" color="textPrimary">Optimize your modern apps (Serverless, ECS, Kubernetes) in production — <Typography component="span" color="primary">autonomously.</Typography></Typography>
            <div className={styles.sidePanelSection}>
                <Typography variant="body1" color="primary" fontWeight={600}>Optimize for cost & performance</Typography>
                <Typography variant="body2" color="textPrimary">Machine learning continuously configures your applications & infrastructure for the best results.</Typography>
            </div>
            <div className={styles.sidePanelSection}>
                <Typography variant="body1" color="primary" fontWeight={600}>See how every release performs</Typography>
                <Typography variant="body2" color="textPrimary">Get quantitative feedback on every release.</Typography>
            </div>
            <div className={styles.sidePanelSection}>
                <Typography variant="body1" color="primary" fontWeight={600}>Setup takes 10 minutes</Typography>
                <Typography variant="body2" color="textPrimary">Simply connect your cloud and sync monitoring data — Sedai will automatically infer your topology.</Typography>
            </div>
        </div>
    )
}


const Signup = (props) => {
    const { route, toSignUp } = useAuthenticator((context) => [context.route, context.toSignUp]);
    const formContainerRef = useRef(null);
    const { sourceParam, productParam, emailParam } = useMemo(() => {
        return getUrlParams();
    }, []);
    const [emailVal, setEmailVal] = useState("");
    const { data: enrichData, status: enrichStatus } = useQuery([QUERY_KEYS.GET_ENRICH_FORM, emailVal], ({ queryKey: [_, email] }) => getEnrichForm(email), { enabled: !!emailVal });

    // initialize the email input from query param
    useEffect(() => {
        if (route === "signUp" && formContainerRef.current && yup.string().email().required().isValidSync(emailParam)) {
            setEmailVal(emailParam);
            const emailInput = ReactDOM.findDOMNode(formContainerRef.current).querySelector("input[name=email]");
            emailInput.value = emailParam;
        };
    }, [route])

    // update the email value in the state when the user blurs the email input field
    useEffect(() => {
        if (route === "signUp" && formContainerRef.current) {
            const callback = (e) => {
                if (yup.string().email().required().isValidSync(e.target.value))
                    setEmailVal(e.target.value);
            }
            const emailInput = ReactDOM.findDOMNode(formContainerRef.current).querySelector("input[name=email]");
            emailInput.addEventListener("blur", callback);
            return () => emailInput.removeEventListener("blur", callback);
        };
    }, [route]);

    // update form values whenever enrich data is loaded
    useEffect(() => {
        if (route === "signUp" && formContainerRef.current && enrichData && enrichStatus === "success") {
            const form = ReactDOM.findDOMNode(formContainerRef.current);
            const firstNameInput = form.querySelector("input[name=given_name]");
            const lastNameInput = form.querySelector("input[name=family_name]");
            const roleInput = form.querySelector('select[name="custom:role"]');

            if (!firstNameInput?.value && enrichData?.person?.name?.givenName)
                firstNameInput.value = enrichData.person.name.givenName;

            if (!lastNameInput?.value && enrichData?.person?.name?.familyName)
                lastNameInput.value = enrichData.person.name.familyName;

            if (!roleInput?.value && enrichData?.person?.employment?.subRole) {
                const roleOption = roleOptions.find((option) => (option.clearBitSubRoles || []).some((v) => v === enrichData?.person?.employment?.subRole));
                if (roleOption)
                    roleInput.value = roleOption.name;
            }
        };
    }, [enrichData, enrichStatus, route]);

    // This useEffect is to make sure that the signup form is shown. if the navigation was made from the signin page without a full page load (SPA link navigation) the state of auth remains the same and the Authenticator component
    // shows the signIn form. so in order to avoid that case this line is needed.
    // This line is not a complete fix as navigating to signup from signup doesn't apply the formFields, signUpAttributes. this attributes applies only if the page just loaded fresh.
    // so try avoid using (SPA navigation) to this page and i will be keeping this fix as a last guard
    useEffect(() => {
        if (route === "signIn") toSignUp();
    }, [route])

    const hasPanel = ["signUp"].includes(route);





    const isMarketPlace = sourceParam === ONBOARDING_SOURCES.AWS_MARKETPLACE_EKS || sourceParam === ONBOARDING_SOURCES.AWS_MARKETPLACE_LAMBDA || sourceParam === ONBOARDING_SOURCES.AWS_MARKETPLACE_ECS;

    const formFields = useMemo(() => ({
        signUp: {
            email: {
                placeholder: 'Work Email',
                order: 1
            },

            given_name: {
                isRequired: true,
                labelHidden: true,
                placeholder: 'First Name',
                order: 1
            },
            family_name: {
                isRequired: true,
                labelHidden: true,
                placeholder: 'Last Name',
                order: 2
            },
            ...(isMarketPlace ? {
                phone_number: {
                    isRequired: false,
                    labelHidden: true,
                    placeholder: 'Phone (optional)',
                    order: 4
                }
            } : {}),
        },
    }), [isMarketPlace]);

    const sedaiLogo = (
        <div className={styles.sedaiLogoContainer}>
            <Icon name="logoWithNameVertical" className={styles.headerLogoVertical} />
            <Icon name="logoWithNameHorizonal" className={styles.headerLogoHorizontal} />
        </div>
    );


    return (

        <div className={cn(styles.container, hasPanel && styles.withPanel)}>
            {isMarketPlace && (
                <div className={styles.marketPlacePageHeader}>
                    {sedaiLogo}
                </div>
            )}
            <div className={styles.wrapper}>
                <CornerLines className={styles.cornerLines} size={hasPanel ? "large" : "small"} />


                <div className={styles.content}>
                    {!isMarketPlace && (
                        <div className={styles.contentSedaiLogo}>
                            {sedaiLogo}
                        </div>
                    )}
                    <div ref={formContainerRef} className={styles.formContainer}>
                        <Authenticator key={route} services={services} loginMechanisms={['email']} socialProviders={['google']} components={components} formFields={formFields} initialState="signUp"
                            signUpAttributes={[
                                'given_name',
                                'family_name',
                                'custom:role',
                                ...(isMarketPlace ? ['phone_number'] : []),
                            ]} />
                    </div>
                </div>
                {hasPanel && (
                    <>
                        <div className={styles.sidePanelContainer}>
                            <div className={styles.cicleBgContainer}>
                                <div className={styles.circleBg} />
                            </div>
                            <div className={styles.sidePanel}>
                                {sideBarContent(productParam, sourceParam)}
                                <div className={styles.customersSection}>
                                    <Typography variant="body2" color="textPrimary">Join the ranks of autonomous cloud teams:</Typography>
                                    <div className={styles.allCustomerLogosContainer}>
                                        <div>
                                            <div className={styles.customerLogosContainer}>
                                                <Icon name="fabric" height={24} />
                                                <Icon name="tasq" height={24} />
                                                <Icon className={styles.canopyLogo} name="canopy" height={24} />
                                            </div>
                                            <div className={styles.customerLogosContainer}>
                                                <Icon className={styles.campshotLogo} name="campspot" height={24} />
                                                <Icon name="inflection" height={24} />
                                            </div>
                                        </div>
                                        <Icon className={styles.belcorpLogo} name="belcorp" height={76} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>

                )}
            </div>
        </div>
    )
};

export default Signup;