import React from "react";
import { Navigate } from "react-router";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { ROUTE_PATHS } from "enums";
import BaseRoute from "./BaseRoute";

const AuthenticationRoute = (props) => {
  const { authStatus } = useAuthenticator(context => [context.authStatus]);
  if (authStatus !== "authenticated")
    return <BaseRoute {...props} />

  return (
    <Navigate
      to={{
        pathname: ROUTE_PATHS.HOME,
      }}
    />
  );
};

export default AuthenticationRoute;
