import React from "react";
import cn from 'classnames';
import Form from 'react-bootstrap/Form';
import useStyles from './Input.styles';

export default React.forwardRef(({ className, color, children, variant = "outline", ...rest }, ref) => {
    const cls = useStyles({ color });
    return (
        <Form.Control ref={ref} autoComplete="off" {...rest} className={cn(cls.input, cls[variant], className)}>
            {children}
        </Form.Control>
    );
})

