import React from "react";
import { useTheme } from "react-jss";
import { ReactComponent as AlibabaKubServiceIcon } from 'assets/img/alibabaKubService.svg';
import { ReactComponent as AppDynamicsIcon } from 'assets/img/appdynamics.svg';
import { ReactComponent as AWSLogoIcon } from 'assets/img/aws.svg';
import { ReactComponent as AwsLambdaWithBgIcon } from 'assets/img/awsLambdaWithBg.svg';
import { ReactComponent as AwsECSWithBgIcon } from 'assets/img/awsECSWithBg.svg';
import { ReactComponent as AwsEC2WithBgIcon } from 'assets/img/awsEC2WithBg.svg';
import { ReactComponent as AwsEKSIcon } from 'assets/img/awsEKS.svg';
import { ReactComponent as S3Icon } from 'assets/img/s3.svg';
import { ReactComponent as AwsEBSIcon } from 'assets/img/awsEBS.svg';
import { ReactComponent as AzureLogoIcon } from 'assets/img/azure.svg';
import { ReactComponent as AKSLogoIcon } from 'assets/img/aks.svg';
import { ReactComponent as BelcorpLogoIcon } from 'assets/img/belcorp.svg';
import { ReactComponent as CampspotLogoIcon } from 'assets/img/campspot.svg';
import { ReactComponent as CanopyLogoIcon } from 'assets/img/canopy.svg';
import { ReactComponent as CloudWatchLogoIcon } from 'assets/img/cloudwatch.svg';
import { ReactComponent as CornerCircleWavesIcon } from 'assets/img/cornerCircleWaves.svg';
import { ReactComponent as DatadogLogoIcon } from 'assets/img/datadog.svg';
import { ReactComponent as DigitalOceanLogoIcon } from 'assets/img/digitalOcean.svg';
import { ReactComponent as FabricLogoIcon } from 'assets/img/fabric.svg';
import { ReactComponent as GCPLogoIcon } from 'assets/img/gcp.svg';
import { ReactComponent as IBMKubServiceLogoIcon } from 'assets/img/ibmKubService.svg';
import { ReactComponent as HelmLogoIcon } from 'assets/img/helm.svg';
import { ReactComponent as GoogleKubEngineLogoIcon } from 'assets/img/googleKubEngine.svg';

import { ReactComponent as InflectionLogoIcon } from 'assets/img/inflection.svg';
import { ReactComponent as InfoIcon } from 'assets/img/info.svg';
import { ReactComponent as KubernetesLogoIcon } from 'assets/img/kubernetes.svg';
import { ReactComponent as LogoWithNameHorizontalIcon } from 'assets/img/logoWithNameHorizontal.svg';
import { ReactComponent as LogoWithNameVerticalIcon } from 'assets/img/logoWithNameVertical.svg';
import { ReactComponent as LogoIcon } from 'assets/img/logo.svg';
import { ReactComponent as NetdataLogoIcon } from 'assets/img/netdata.svg';
import { ReactComponent as NewrelicLogoIcon } from 'assets/img/newrelic.svg';
import { ReactComponent as OpenshiftLogoIcon } from 'assets/img/openshift.svg';
import { ReactComponent as OracleLogoIcon } from 'assets/img/oracle.svg';
import { ReactComponent as Plateform9LogoIcon } from 'assets/img/platform9.svg';
import { ReactComponent as PrometheusLogoIcon } from 'assets/img/prometheus.svg';
import { ReactComponent as RancherLogoIcon } from 'assets/img/rancher.svg';

import { ReactComponent as SedaiNameIcon } from 'assets/img/sedaiName.svg';
import { ReactComponent as SignalfxLogoIcon } from 'assets/img/signalfx.svg';
import { ReactComponent as SlackIcon } from 'assets/img/slack.svg';
import { ReactComponent as TasqLogoIcon } from 'assets/img/tasq.svg';
import { ReactComponent as VmwareLogoIcon } from 'assets/img/vmware.svg';
import { ReactComponent as VmwareTanzuLogoIcon } from 'assets/img/vmwareTanzu.svg';
import { ReactComponent as WavefrontLogoIcon } from 'assets/img/wavefront.svg';


// This component is managed separately than the main UI Icons
export default function Icon({ name, themeType, ...rest }) {
    const theme = useTheme();
    const defaultThemeType = themeType || theme.type;
    const getIcon = (iconName, props) => {
        switch (iconName) {
            case 'alibabaKubService':
                return <AlibabaKubServiceIcon {...props} />;
            case 'appdynamics':
                return <AppDynamicsIcon color={defaultThemeType === "dark" ? "#ffffff" : "#000000"} {...props} />;
            case 'aws':
                return <AWSLogoIcon color={defaultThemeType === "dark" ? "#ffffff" : "#252F3E"} {...props} />;
            case 'awsEC2WithBg':
                return <AwsEC2WithBgIcon {...props} />;
            case 'awsECSWithBg':
                return <AwsECSWithBgIcon {...props} />;
            case 'awsEKS':
                return <AwsEKSIcon {...props} />;
            case 'awsLambdaWithBg':
                return <AwsLambdaWithBgIcon {...props} />;
            case 'awsEBS':
                return <AwsEBSIcon {...props} />;
            case 's3':
                return <S3Icon {...props} />;
            case 'azure':
                return <AzureLogoIcon {...props} />;
            case 'aks':
                return <AKSLogoIcon {...props} />;
            case 'belcorp':
                return <BelcorpLogoIcon {...props} />;
            case 'campspot':
                return <CampspotLogoIcon {...props} />;
            case 'canopy':
                return <CanopyLogoIcon {...props} />;
            case 'cloudwatch':
                return <CloudWatchLogoIcon {...props} />;
            case 'cornerCircleWaves':
                return <CornerCircleWavesIcon {...props} />;
            case 'datadog':
                return <DatadogLogoIcon color={defaultThemeType === "dark" ? "#ffffff" : "#632ca6"} {...props} />;
            case 'digitalOcean':
                return <DigitalOceanLogoIcon {...props} />;
            case 'federatedprometheus':
                return <PrometheusLogoIcon {...props} />;
            case 'rancher':
                return <RancherLogoIcon {...props} />;
            case 'gcp':
                return <GCPLogoIcon {...props} />;
            case 'googleKubEngine':
                return <GoogleKubEngineLogoIcon {...props} />;
            case 'helm':
                return <HelmLogoIcon {...props} />;
            case 'ibmKubService':
                return <IBMKubServiceLogoIcon {...props} />;
            case 'fabric':
                return <FabricLogoIcon {...props} />;
            case 'inflection':
                return <InflectionLogoIcon {...props} />;
            case 'information':
                return <InfoIcon {...props} />;
            case 'kubernetes':
                return <KubernetesLogoIcon {...props} />;
            case 'logo':
                return <LogoIcon  {...props} />;
            case 'logoWithNameHorizonal':
                return <LogoWithNameHorizontalIcon  {...props} />;
            case 'logoWithNameVertical':
                return <LogoWithNameVerticalIcon  {...props} />;
            case 'sedaiName':
                return <SedaiNameIcon  {...props} />;
            case 'signalfx':
                return <SignalfxLogoIcon {...props} />;
            case 'slack':
                return <SlackIcon  {...props} />;
            case 'netdata':
                return <NetdataLogoIcon color="#00CB51" {...props} />;
            case 'newrelic':
                return <NewrelicLogoIcon {...props} />;
            case 'openshift':
                return <OpenshiftLogoIcon {...props} />;
            case 'oracle':
                return <OracleLogoIcon {...props} />;
            case 'platform9':
                return <Plateform9LogoIcon {...props} />;
            case 'tasq':
                return <TasqLogoIcon {...props} />;
            case 'vmware':
                return <VmwareLogoIcon color={defaultThemeType === 'dark' ? "#ffffff" : "#696566"} {...props} />;
            case 'vmwareTanzu':
                return <VmwareTanzuLogoIcon {...props} />
            case 'wavefront':
                return <WavefrontLogoIcon {...props} />
            default:
                return null;
        }
    }
    return getIcon(name, rest)
}