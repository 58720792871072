import { Formik } from "formik";
import { useToasts } from "react-toast-notifications";
import * as Yup from 'yup';
import { useAuthenticator } from '@aws-amplify/ui-react';
import Icon from "components/Icon/Icon";
import '@aws-amplify/ui-react/styles.css';
import styles from './UserRole.module.css';
import Typography from "components/Typography/Typography";
import Button from "components/Button/Button";
import { Spinner } from "react-bootstrap";
import Dropdown from "components/BetaDropdown/Dropdown";
import { FiArrowRight } from "react-icons/fi";
import { Auth } from "aws-amplify";
import { roleOptions } from "utils/constants";
import TextField from "components/TextField/TextField";
import { updateInvitedMemberDetails } from "utils/api/invite";
import CornerLines from "views/common/CornerLines/CornerLines";
import Bugsnag from "@bugsnag/js";



const UserRole = (props) => {
    const { onComplete, envDomain } = props;
    const { user } = useAuthenticator((context) => [context.user]);
    const { addToast } = useToasts();

    const currentFirstName = user?.attributes?.given_name;
    const currentLastName = user?.attributes?.family_name;

    const isInvitedMemberForm = !currentFirstName || !currentLastName;

    return (
        <div className={styles.container}>
            <div className={styles.cicleBgContainer}>
                <div className={styles.circleBg} />
            </div>
            <CornerLines className={styles.cornerLines} />
            
            <div>
                <Icon name="logoWithNameHorizonal" className={styles.headerLogoHorizontal} />
            </div>

            <Typography variant="h2" color="textPrimary" className={styles.subtitle}>
                {!isInvitedMemberForm && <>👋 Hi {user.given_name}! What best describes your role?</>}
                {isInvitedMemberForm && <>👋 Welcome to Sedai! Tell us about yourself:</>}
            </Typography>
            <Formik
                initialValues={{
                    role: user?.attributes?.['custom:role'] || '',
                    firstName: currentFirstName || '',
                    lastName: currentLastName || '',
                }}
                validationSchema={
                    Yup.object().shape({
                        role: Yup.string().required(),
                        firstName: Yup.string().required(),
                        lastName: Yup.string().required(),
                    })
                }
                onSubmit={async (values, { setSubmitting }) => {
                    try {
                        await Auth.updateUserAttributes(user, {
                            'custom:role': values.role,
                            'given_name': values.firstName,
                            'family_name': values.lastName,
                        });
                        // send details to API asyncronsly without waiting for the response
                        if (isInvitedMemberForm)
                            updateInvitedMemberDetails(user.attributes.email, envDomain, values.firstName, values.lastName, values.role)

                        setSubmitting(false);
                        onComplete();
                    } catch (e) {
                        Bugsnag.notify(e);
                        addToast("Failed to submit data", {
                            appearance: 'error',
                            autoDismiss: true,
                        });
                    }
                }}
            >
                {({
                    values,
                    errors,
                    handleSubmit,
                    isSubmitting,
                    isValidating,
                    setFieldValue,
                    isValid,
                    touched,
                }) => {
                    const isLoading = isSubmitting || isValidating
                    return (
                        <form className={styles.formContainer} onSubmit={handleSubmit}>
                            {!currentFirstName && (
                                <TextField
                                    aria-label="First Name"
                                    name="firstName"
                                    placeholder="First Name"
                                    value={values.firstName}
                                    hasError={!!errors.firstName && touched.firstName}
                                    onChange={(e) => setFieldValue("firstName", e.target.value)} />
                            )}
                            {!currentLastName && (
                                <TextField
                                    name="lastName"
                                    placeholder="Last Name"
                                    aria-label="Last Name"
                                    value={values.lastName}
                                    hasError={!!errors.lastName && touched.lastName}
                                    onChange={(e) => setFieldValue("lastName", e.target.value)} />
                            )}
                            <Dropdown
                                name="role"
                                placeholder="Select role"
                                aria-label="Select role"
                                options={roleOptions}
                                value={values.role}
                                hasError={!!errors.role && touched.role}
                                onChange={(v) => setFieldValue("role", v)}
                            />

                            <Button
                                className={styles.submitButton}
                                fitContent
                                variant="filled" color="primary" type="submit"
                                disabled={!isValid || isLoading}>
                                Next {isLoading ? (<Spinner animation="border" role="status" size="sm" />) : <FiArrowRight />}
                            </Button>
                        </form>
                    )
                }}
            </Formik>
        </div>
    )
};

export default UserRole;