import cn from "classnames";
import { FiCheck } from "react-icons/fi";
import Typography from "components/Typography/Typography";
import Card from "components/Card/Card";
import Button from "components/Button/Button";
import Icon from "components/Icon/Icon";
import { supportedKubEnvironments } from "views/AddCloudWizard/AddCloudWizard.utils";
import WizardLayout from "views/AddCloudWizard/WizardLayout/WizardLayout";
import useStyles from './SelectKubAccountType.styles';
import DefaultStepFooterContent from "views/common/WizardFooter/DefaultStepFooterContent/DefaultStepFooterContent";
import { useState } from "react";
import { useWizardData, wizardDataActionsTypes } from "contexts/WizardData";
import { useToasts } from "react-toast-notifications";
import Bugsnag from "@bugsnag/js";


const SelectKubAccountType = ({ envDomain, email, currentStep, moveToNextStep, isCurrentStepValid, wizardProps, ...rest }) => {
    const cls = useStyles();
    const { addToast } = useToasts();
    const [wizardData, wizardDataDispatch] = useWizardData()
    const [isLoading, setIsLoading] = useState(false);

    const clusterProvider = wizardData?.resourceDetails?.kubeProviders?.[0];
    return (
        <WizardLayout {...wizardProps}>
            <div className={cls.container}>
                <div className={cls.header}>
                    <Typography variant="h2" color="textPrimary">Which Kubernetes service do you want to connect?</Typography>
                </div>
                <div className={cls.cloudEnvironmentTypes}>
                    {supportedKubEnvironments.map((envType) => (
                        <Button key={envType.clusterProvider} variant="content" onPress={() => {
                            wizardDataDispatch({
                                type: wizardDataActionsTypes.SET_KUB_CLUSTER_PROVIDER,
                                payload: envType.clusterProvider,
                            });
                        }}>
                            <Card className={cn(cls.cloudEnvCard, clusterProvider === envType.clusterProvider && cls.activeCloudEnvCard)}>
                                <div className={cls.multiIconConainer}>
                                    {envType.iconNames.map((iconName) => <Icon name={iconName} />)}
                                </div>
                                <Typography variant="body2" color="textPrimary">{envType.label}  <Typography component="span" variant="body3" color="textTernary">{envType.labelDesc}</Typography></Typography>
                                {clusterProvider === envType.clusterProvider && <FiCheck className={cls.checkMark} />}
                            </Card>
                        </Button>
                    ))}
                </div>
                <DefaultStepFooterContent
                    moveNextIsLoading={isLoading}
                    moveToNextStep={() => {
                        if (isCurrentStepValid) {
                            setIsLoading(true);
                            currentStep.submit(wizardData, { envDomain, email }).then(() => {
                                setIsLoading(false);
                                moveToNextStep();
                            }).catch((e) => {
                                Bugsnag.notify(e);
                                setIsLoading(false);
                                addToast("Failed to select Kubernetes provider", {
                                    appearance: 'error',
                                    autoDismiss: true,
                                });
                            })
                        }
                    }}
                    disableNextBtn={!isCurrentStepValid}
                />
            </div>
        </WizardLayout>
    )
};

export default SelectKubAccountType;