import { capitalize, words, clone, setWith, isNil } from 'lodash';
import { MONITORING_PROVIDERS } from "enums";
import { createUseStyles, useTheme } from "react-jss";
import tinycolor from 'tinycolor2';

export function getDomainFromURL(url) {
    return url && (new URL(url)).hostname;
}

export function getSubDomainFromURL(url) {
    return url && getDomainFromURL(url).split(".")[0];
}


export function createStylesWithTheme(styles) {
    if (typeof styles === "function") return createUseStyles(styles);

    const useStyles = createUseStyles(styles);
    return (props) => {
        const theme = useTheme();
        return useStyles({ theme, props })
    }
}


export const getThemeColor = (color, theme, colorVariant = "main") => {
    switch (color) {
        case 'primary':
            return theme.palette.primary[colorVariant];
        case 'secondary':
            return theme.palette.secondary[colorVariant];
        case 'accent':
            return theme.palette.accent[colorVariant];
        case 'availability':
            return theme.palette.availability[colorVariant];
        case 'operation':
            return theme.palette.operation[colorVariant];
        case 'success':
            return theme.palette.success[colorVariant];
        case 'error':
            return theme.palette.error[colorVariant];
        case 'warning':
            return theme.palette.warning[colorVariant];
        case 'textPrimary':
            return theme.palette.text.primary;
        case 'textSecondary':
            return theme.palette.text.secondary;
        default: {
            const c = color || theme.palette.primary;
            if (colorVariant === "dark") return tinycolor(c).darken(10);
            if (colorVariant === "light") return tinycolor(c).lighten(10);
            return c;
        }
    }
}



export function getMonitoringProviderLabel(mp) {
    switch (mp) {
        case MONITORING_PROVIDERS.CLOUDWATCH:
            return 'CloudWatch';
        case MONITORING_PROVIDERS.FEDERATEDPROMETHEUS:
            return 'Prometheus';
        case MONITORING_PROVIDERS.NEWRELIC:
            return 'New Relic';
        case MONITORING_PROVIDERS.APPDYNAMICS:
            return 'AppDynamics';
        case MONITORING_PROVIDERS.SIGNALFX:
            return 'SignalFX';
        default:
            return words(mp).map(capitalize).join(' ');

    }
}


export function defaultEnumsFormat(s) {
    return words(s).map(capitalize).join(' ');
}



//////////////////// value Formatters//////////////
export function pxToRem(px) {
    return px / 16;
}
////////

export function immutableSet(obj, path, pathVal) {
    return setWith(clone(obj), path, pathVal, function (v) { return !isNil(v) ? clone(...arguments) : undefined })
}